import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { SubmitButton } from 'Components/EnhancedButtons';
import React from 'react';

const updateParticipantInfoMutation = gql`
    mutation UpdateParticipantInfo(
        $participantId: ID!
        $info: ParticipantRegistrationDataInput!
    ) {
        updateParticipantInfo(participantId: $participantId, info: $info) {
            __typename
            ... on UpdateParticipantInfoSuccess {
                participant {
                    _id
                    email
                    grade
                    name
                    surname
                    phone
                    townName
                    school
                }
            }
        }
    }
`;

export default function ParticipantDataForm({
    participant,
    onDeleteClick = null,
}) {
    const { register, handleSubmit } = useForm();
    const [updateParticipantInfo, { loading, data, error }] = useMutation(
        updateParticipantInfoMutation
    );
    const { t } = useTranslation();

    let errorMessage = null;

    if (
        error ||
        !['UpdateParticipantInfoSuccess', undefined].includes(
            data?.updateParticipantInfo.__typename
        )
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    return (
        <>
            <Form
                onSubmit={handleSubmit(formData => {
                    const { email, grade, ...info } = formData;

                    updateParticipantInfo({
                        variables: {
                            participantId: participant._id,
                            info: {
                                ...info,
                                grade: Number(grade),
                            },
                        },
                    });
                })}
            >
                <Form.Group as={Row} controlId="participant-data-email">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Email
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('email')}
                            defaultValue={participant.email}
                            disabled={true}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-name">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Имя
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('name', { required: true })}
                            defaultValue={participant.name}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-surname">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Фамилия
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('surname', { required: true })}
                            defaultValue={participant.surname}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-phone">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Телефон
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('phone', {
                                pattern: /^[0-9\- ,+#()]+$/,
                            })}
                            defaultValue={participant.phone}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-grade">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Класс
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="number"
                            {...register('grade', {
                                required: true,
                                pattern: /^(?:[1-9]|1[0-3])$/,
                            })}
                            defaultValue={participant.grade}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-townName">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Город
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('townName', { required: true })}
                            defaultValue={participant.townName}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="participant-data-school">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Школа
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('school', { required: true })}
                            defaultValue={participant.school}
                        />
                    </Col>
                </Form.Group>
                <Row className="mt-2">
                    <Col md={6}>
                        <SubmitButton loading={loading} mutationData={data}>
                            Сохранить
                        </SubmitButton>
                    </Col>
                    {onDeleteClick && (
                        <Col md={6} className="text-md-right">
                            <Button
                                variant="outline-danger"
                                onClick={onDeleteClick}
                            >
                                Удалить
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
            {errorMessage && (
                <Alert variant="warning" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
        </>
    );
}
