import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

interface RotatedImageProps extends React.HTMLAttributes<HTMLDivElement> {
    src: string;
    rotation?: 0 | 90 | 180 | 270;
    maxWidth: string;
    alt?: string;
    filter?: string;
    onAspectRatio?: (number) => void;
    wrapperRef?: any;
    children?: any;
    fullZoom?: boolean;
}

export default function RotatedImage({
    src,
    rotation,
    maxWidth,
    alt,
    filter,
    onAspectRatio,
    wrapperRef,
    children = null,
    fullZoom = false,
    ...wrapperProps
}: RotatedImageProps) {
    const measureDivRef = useRef(null);
    const [naturalWidth, setNaturalWidth] = useState(1);
    const [naturalHeight, setNaturalHeight] = useState(1);
    const [measureDivWidth, setMeasureDivWidth] = useState(0);

    useLayoutEffect(() => {
        setMeasureDivWidth(measureDivRef.current.clientWidth);
    }, []);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                setMeasureDivWidth(entry.contentRect.width);
            }
        });

        observer.observe(measureDivRef.current);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (onAspectRatio) {
            onAspectRatio(naturalWidth / naturalHeight);
        }
    }, [onAspectRatio, naturalWidth, naturalHeight]);

    rotation = rotation || 0;

    const rotated = rotation % 180 === 90;

    const measureDivStyle = useMemo(
        () => ({ maxWidth, height: 0 }),
        [maxWidth]
    );

    const imgStyle = useMemo(
        () => ({
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
            maxWidth:
                !fullZoom && !rotated ? `${measureDivWidth}px` : undefined,
            maxHeight:
                !fullZoom && rotated ? `${measureDivWidth}px` : undefined,
            width: fullZoom && !rotated ? `${measureDivWidth}px` : undefined,
            height: fullZoom && rotated ? `${measureDivWidth}px` : undefined,
            filter,
        }),
        [rotation, rotated, measureDivWidth, filter, fullZoom]
    );

    const wrapperDivStyle = useMemo(
        () => ({
            position: 'relative' as 'relative',
            width: Math.min(
                measureDivWidth,
                fullZoom ? Infinity : rotated ? naturalHeight : naturalWidth
            ),
            height:
                Math.min(
                    measureDivWidth,
                    fullZoom ? Infinity : rotated ? naturalHeight : naturalWidth
                ) *
                (rotated
                    ? naturalWidth / naturalHeight
                    : naturalHeight / naturalWidth),
        }),
        [naturalWidth, naturalHeight, rotated, measureDivWidth, fullZoom]
    );

    return (
        <>
            <div ref={measureDivRef} style={measureDivStyle} />
            <div style={wrapperDivStyle} {...wrapperProps} ref={wrapperRef}>
                <img
                    style={imgStyle}
                    src={src}
                    alt={alt}
                    onLoad={e => {
                        setNaturalWidth(e.currentTarget.naturalWidth);
                        setNaturalHeight(e.currentTarget.naturalHeight);
                    }}
                />
                {children}
            </div>
        </>
    );
}
