import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { InputterTournamentView } from './InputterTournamentPage';

const inputterHomePageQuery = gql`
    query InputterHomePageQuery {
        tournaments {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
            }
            venues {
                _id
                townName
                organization
            }
        }
    }
`;

export default function InputterHomePage() {
    const { data, loading } = useQuery(inputterHomePageQuery);
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        tournaments: [currentTournament, ...tournaments],
    } = data;

    return (
        <Page>
            <InputterTournamentView tournament={currentTournament} />
            <br />
            <br />
            <h4>Предыдущие Турниры</h4>
            {tournaments.map(tournament => (
                <>
                    <Link to={`/tournaments/${tournament.number}`}>
                        {tournament.number}-й Турнир
                    </Link>
                    <br />
                </>
            ))}
        </Page>
    );
}
