import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../../Page';
import { Button, Nav, Spinner } from 'react-bootstrap';
import { BatchesTable } from '../../TournamentBatchesPage';

const adminVenueBatchesQuery = gql`
    query AdminVenueBatches($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            tournament {
                number
            }
            batches {
                _id
                codeName
                season
                level
                tier
                venue {
                    _id
                    townName
                }
                statistics {
                    count
                    startedFirst
                    markedFirst
                    startedSecond
                    markedSecond
                    startedFinal
                    markedFinal
                    published
                }
                firstStarted
                firstFinished
                firstJury {
                    _id
                    name
                }
                secondStarted
                secondFinished
                secondJury {
                    _id
                    name
                }
                finalStarted
                finalFinished
                finalJury {
                    _id
                    name
                }
            }
        }
    }
`;

export default function AdminVenueBatchesPage() {
    const { venueId, round } = useParams<{ venueId: string; round: string }>();
    const { data, loading, refetch } = useQuery(adminVenueBatchesQuery, {
        variables: { venueId },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        venue: { batches, tournament, ...venue },
    } = data;

    return (
        <Page
            nav={
                <>
                    <Nav.Link
                        href={`/admin/${data.venue.tournament.number}/venues/${data.venue._id}`}
                    >
                        {data.venue.townName}
                    </Nav.Link>
                </>
            }
        >
            <h2>Пачки — {venue.townName}</h2>
            {(round === 'all' ||
                round === 'fall' ||
                round === 'fall-ordinary') && (
                <VenueRoundBatches
                    season="FALL"
                    level="ORDINARY"
                    venueId={venueId}
                    tournamentNumber={tournament.number}
                    onStartExaminationFail={refetch}
                    onSplitSuccess={refetch}
                    batches={batches.filter(
                        batch =>
                            batch.season === 'FALL' &&
                            batch.level === 'ORDINARY'
                    )}
                />
            )}
            {(round === 'all' ||
                round === 'fall' ||
                round === 'fall-advanced') && (
                <VenueRoundBatches
                    season="FALL"
                    level="ADVANCED"
                    venueId={venueId}
                    tournamentNumber={tournament.number}
                    onStartExaminationFail={refetch}
                    onSplitSuccess={refetch}
                    batches={batches.filter(
                        batch =>
                            batch.season === 'FALL' &&
                            batch.level === 'ADVANCED'
                    )}
                />
            )}
            {(round === 'all' ||
                round === 'spring' ||
                round === 'spring-ordinary') && (
                <VenueRoundBatches
                    season="SPRING"
                    level="ORDINARY"
                    venueId={venueId}
                    tournamentNumber={tournament.number}
                    onStartExaminationFail={refetch}
                    onSplitSuccess={refetch}
                    batches={batches.filter(
                        batch =>
                            batch.season === 'SPRING' &&
                            batch.level === 'ORDINARY'
                    )}
                />
            )}
            {(round === 'all' ||
                round === 'spring' ||
                round === 'spring-advanced') && (
                <VenueRoundBatches
                    season="SPRING"
                    level="ADVANCED"
                    venueId={venueId}
                    tournamentNumber={tournament.number}
                    onStartExaminationFail={refetch}
                    onSplitSuccess={refetch}
                    batches={batches.filter(
                        batch =>
                            batch.season === 'SPRING' &&
                            batch.level === 'ADVANCED'
                    )}
                />
            )}
        </Page>
    );
}

const splitIntoBatchesMutation = gql`
    mutation SplitIntoBatches(
        $venueId: ID!
        $season: Season!
        $level: Level!
        $splitStrategy: BatchSplitStrategy!
    ) {
        splitIntoBatches(
            venueId: $venueId
            season: $season
            level: $level
            splitStrategy: $splitStrategy
        ) {
            __typename
        }
    }
`;

function VenueRoundBatches({
    batches,
    season,
    level,
    venueId,
    tournamentNumber,
    onStartExaminationFail,
    onSplitSuccess,
}) {
    const [splitIntoBatches] = useMutation(splitIntoBatchesMutation);

    const split = splitStrategy =>
        splitIntoBatches({
            variables: {
                season,
                level,
                venueId,
                splitStrategy,
            },
        }).then(({ data }) => {
            if (
                data?.splitIntoBatches.__typename === 'SplitIntoBatchesSuccess'
            ) {
                onSplitSuccess();
            }
        });

    return (
        <>
            <h4>
                {season === 'FALL' ? 'Осенний' : 'Весенний'}{' '}
                {level === 'ORDINARY' ? 'базовый' : 'сложный'}
            </h4>
            {batches.length === 0 && (
                <>
                    <h5>Разбить на пачки</h5>
                    <Button
                        variant="outline-primary"
                        onClick={() => split('ALL')}
                    >
                        Все работы
                    </Button>
                    <br />
                    <br />
                    <Button
                        variant="outline-primary"
                        onClick={() => split('FIXED_SIZE')}
                    >
                        Все работы — порциями по 25
                    </Button>
                    <br />
                    <br />
                    <Button
                        variant="outline-primary"
                        onClick={() => split('FLAGGED_ONLY')}
                    >
                        Только с флагом «На центральную проверку»
                    </Button>
                    <br />
                    <br />
                    <Button
                        variant="outline-primary"
                        onClick={() => split('CONSIDER_LOCAL_AS_FIRST_CENTRAL')}
                    >
                        Засчитать местную проверку как первую центральную
                    </Button>
                    <br />
                    <br />
                </>
            )}
            {batches.length > 0 && (
                <>
                    <h5>Младший вариант</h5>
                    <BatchesTable
                        batches={batches.filter(
                            batch => batch.tier === 'JUNIOR'
                        )}
                        tournamentNumber={tournamentNumber}
                        onStartExaminationFail={onStartExaminationFail}
                        showPublished={true}
                    />
                    <br />
                    <h5>Старший вариант</h5>
                    <BatchesTable
                        batches={batches.filter(
                            batch => batch.tier === 'SENIOR'
                        )}
                        tournamentNumber={tournamentNumber}
                        onStartExaminationFail={onStartExaminationFail}
                        showPublished={true}
                    />
                    <br />
                </>
            )}
            <br />
            <br />
        </>
    );
}
