import classnames from 'classnames';
import { MutationButton } from './Components/EnhancedButtons';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
    MergeParticipants,
    MergeParticipantsVariables,
} from './GraphQL/types/MergeParticipants';

const mergeParticipantsMutation: TypedDocumentNode<
    MergeParticipants,
    MergeParticipantsVariables
> = gql`
    mutation MergeParticipants($ids: [ID!]!) {
        mergeParticipants(ids: $ids) {
            __typename
            ... on MergeParticipantsSuccess {
                participant {
                    _id
                    name
                    surname
                }
            }
        }
    }
`;

export default function DeduplicateParticipants({
    duplicatedParticipants,
    tournament,
    refetchQueryName,
}) {
    const [selectedParticipants, setSelectedParticipants] = useState<
        Array<string>
    >([]);
    const [mergedParticipant, setMergedParticipant] = useState(null);

    return (
        <>
            <h2>Убрать дубли</h2>
            <p
                className={classnames('page-actions-buttons', {
                    invisible:
                        selectedParticipants.length < 2 && !mergedParticipant,
                })}
            >
                <MutationButton
                    mutation={mergeParticipantsMutation}
                    variables={{ ids: selectedParticipants }}
                    mutationOptions={{
                        refetchQueries: [refetchQueryName],
                        awaitRefetchQueries: true,
                    }}
                    onMutate={({ data }) => {
                        if (
                            data?.mergeParticipants.__typename !==
                            'MergeParticipantsSuccess'
                        ) {
                            return;
                        }

                        setMergedParticipant(
                            data.mergeParticipants.participant
                        );
                        setTimeout(() => setSelectedParticipants([]), 1000);
                    }}
                    className={classnames('mr-5', {
                        invisible: selectedParticipants.length < 2,
                    })}
                >
                    Объединить выбранных
                </MutationButton>
                {mergedParticipant && (
                    <Link
                        to={`/tournaments/${tournament.number}/participants/${mergedParticipant._id}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="float-right mt-2"
                    >
                        <strong>
                            {mergedParticipant.name} {mergedParticipant.surname}
                        </strong>
                        : редактировать
                    </Link>
                )}
            </p>
            {duplicatedParticipants.length === 0 && (
                <h5>Ни одного дубля нет</h5>
            )}
            {duplicatedParticipants.map(participants => (
                <Row
                    key={participants.map(p => p._id).join(',')}
                    className="mb-4"
                >
                    {participants.map(participant => (
                        <ParticipantCard
                            participant={participant}
                            tournamentNumber={tournament.number}
                            key={participant._id}
                            selected={selectedParticipants.includes(
                                participant._id
                            )}
                            highlight={getDifferingFields(participants)}
                            showEmail={participants.some(p => p.email)}
                            showPhone={participants.some(p => p.phone)}
                            onClick={() => {
                                setSelectedParticipants(prevSelected => {
                                    if (
                                        prevSelected.includes(participant._id)
                                    ) {
                                        return prevSelected.filter(
                                            p => p !== participant._id
                                        );
                                    }

                                    if (
                                        prevSelected.length &&
                                        participants.some(
                                            p => p._id === prevSelected[0]
                                        )
                                    ) {
                                        return [
                                            ...prevSelected,
                                            participant._id,
                                        ];
                                    }

                                    return [participant._id];
                                });
                            }}
                        />
                    ))}
                </Row>
            ))}
        </>
    );
}

function ParticipantCard({
    participant,
    tournamentNumber,
    selected,
    onClick,
    highlight,
    showEmail,
    showPhone,
}) {
    return (
        <Col
            md={3}
            onClick={onClick}
            className={classnames('pt-2 pb-2 cursor-pointer', {
                'bg-light': selected,
            })}
        >
            <strong>
                {participant.name} {participant.surname}
            </strong>
            <br />
            {showEmail && (
                <>
                    <HighlightedIfDifferingField
                        field="email"
                        participant={participant}
                        highlight={highlight}
                    />
                    <br />
                </>
            )}
            {showPhone && (
                <>
                    <HighlightedIfDifferingField
                        field="phone"
                        participant={participant}
                        highlight={highlight}
                    />
                    <br />
                </>
            )}
            <HighlightedIfDifferingField
                field="townName"
                participant={participant}
                highlight={highlight}
            />
            <br />
            <HighlightedIfDifferingField
                field="grade"
                participant={participant}
                highlight={highlight}
            >
                -й класс
            </HighlightedIfDifferingField>
            <br />
            <HighlightedIfDifferingField
                field="school"
                participant={participant}
                highlight={highlight}
            />
            <br />
            {participant.papers.map(paper => (
                <ParticipantCardPaper
                    key={paper._id}
                    paper={paper}
                    tournamentNumber={tournamentNumber}
                />
            ))}
        </Col>
    );
}

function HighlightedIfDifferingField({
    field,
    participant,
    highlight,
    children = null,
}) {
    return (
        <span
            className={classnames({
                'text-danger': highlight[field],
            })}
        >
            {participant[field]}
            {children}
        </span>
    );
}

function ParticipantCardPaper({
    paper: { _id, type, season, level, tier, stage, venue },
    tournamentNumber,
}) {
    const { t } = useTranslation();

    return (
        <p className="mt-2">
            <Link
                to={`/tournaments/${tournamentNumber}/papers/${_id}`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={stopPropagation}
            >
                {t('common:fullTierName', { season, level, tier })}
            </Link>
            <br />
            {venue?.townName && `${venue.townName}, `}
            {type === 'ONLINE' ? 'онлайн' : 'очно'}
        </p>
    );
}

function stopPropagation(event) {
    event.stopPropagation();
}

function getDifferingFields(participants) {
    return {
        email: checkIfFieldDiffers(participants, 'email'),
        phone: checkIfFieldDiffers(participants, 'phone', phone =>
            phone?.trim().toLowerCase().replace(/^8/, '+7')
        ),
        grade: checkIfFieldDiffers(participants, 'grade'),
        school: checkIfFieldDiffers(participants, 'school'),
        townName: checkIfFieldDiffers(participants, 'townName'),
    };
}

function checkIfFieldDiffers(
    array,
    field,
    normalizer = s => s?.toString().trim().toLowerCase()
) {
    return (
        new Set(array.map(p => normalizer(p[field])).filter(value => value))
            .size > 1
    );
}
