import React, { useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import simplify from 'simplify-js';
import RotatedImage from './RotatedImage';
import classnames from 'classnames';
import { ArrowClockwise } from 'react-bootstrap-icons';

function getCoordinates(event, aspectRatio) {
    const bbox = event.currentTarget.getBoundingClientRect();

    return {
        x: ((event.clientX - bbox.left) / bbox.width) * 100 * aspectRatio,
        y: ((event.clientY - bbox.top) / bbox.height) * 100,
    };
}

const addPageCommentMutation = gql`
    mutation AddPageComment(
        $paperId: ID!
        $pageId: ID!
        $text: String
        $path: String
        $style: String
    ) {
        addPageComment(
            paperId: $paperId
            pageId: $pageId
            text: $text
            path: $path
            style: $style
        ) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

const deletePageCommentMutation = gql`
    mutation DeletePageComment($paperId: ID!, $pageId: ID!, $commentId: ID!) {
        deletePageComment(
            paperId: $paperId
            pageId: $pageId
            commentId: $commentId
        ) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

const rotatePageWithCommentsMutation = gql`
    mutation RotatePageWithComments($paperId: ID!, $pageId: ID!) {
        rotatePage(paperId: $paperId, pageId: $pageId) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        rotation
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

export default function PageOfPaperWithComments({
    paperId,
    page,
    zoom,
    tool,
    filter,
    alt,
    showComments,
    wrapperRef,
}) {
    const [aspectRatio, setAspectRatio] = useState(1);
    const [rotatePage] = useMutation(rotatePageWithCommentsMutation);

    return (
        <RotatedImage
            rotation={page.rotation}
            maxWidth={`${zoom}%`}
            className={classnames('examination-modal-page', {
                'drawing-tool-active': [
                    'pencil',
                    'highlighter',
                    'text',
                ].includes(tool),
                'eraser-active': tool === 'eraser',
            })}
            src={`${process.env.REACT_APP_BACKEND_URL}${page.file}`}
            alt={alt}
            filter={filter}
            onAspectRatio={setAspectRatio}
            wrapperRef={wrapperRef}
            fullZoom={true}
        >
            <PageComments
                aspectRatio={aspectRatio}
                paperId={paperId}
                pageId={page._id}
                comments={page.comments || []}
                tool={tool}
                showComments={showComments}
            />
            <button
                className="examination-modal-page-rotate-button"
                tabIndex={-1}
                onClick={() =>
                    rotatePage({
                        variables: {
                            paperId,
                            pageId: page._id,
                        },
                    })
                }
                title="Повернуть"
            >
                <ArrowClockwise size={32} />
            </button>
        </RotatedImage>
    );
}

function PageComments({
    paperId,
    pageId,
    comments,
    showComments,
    tool,
    aspectRatio,
}) {
    const [currentPath, setCurrentPath] = useState(null);
    const [currentPathStyle, setCurrentPathStyle] = useState(null);
    const [currentText, setCurrentText] = useState(null);
    const [currentTextStyle, setCurrentTextStyle] = useState(null);
    const lastPoint = useRef(null);
    const savingDrawing = useRef(false);

    const [addPageComment] = useMutation(addPageCommentMutation);
    const [deletePageComment] = useMutation(deletePageCommentMutation);

    const handleSvgClick = event => {
        if (
            tool === 'eraser' &&
            event.target.tagName.toLowerCase() === 'polyline'
        ) {
            const { id } = event.target.dataset;

            deletePageComment({
                variables: {
                    commentId: id,
                    paperId,
                    pageId,
                },
            });
        }

        if (tool === 'text' && !currentTextStyle) {
            let { x, y } = getCoordinates(event, aspectRatio);

            x = x / aspectRatio;
            setCurrentText('');
            setCurrentTextStyle({
                left: x + '%',
                top: y + '%',
            });
        }
    };

    const handleSvgMouseDown = event => {
        if (!['pencil', 'highlighter'].includes(tool)) {
            return;
        }

        if (savingDrawing.current) {
            return;
        }

        const { x, y } = getCoordinates(event, aspectRatio);

        setCurrentPath(`${x.toFixed(3)},${y.toFixed(3)}`);
        setCurrentPathStyle(
            JSON.stringify(
                tool === 'pencil'
                    ? { strokeWidth: '0.25px', stroke: 'red' }
                    : {
                          strokeWidth: '1.75px',
                          stroke: 'rgba(240, 240, 16, 0.5)',
                      }
            )
        );

        lastPoint.current = {
            x,
            y,
            time: performance.now(),
        };
    };

    const handleSvgMouseMove = event => {
        if (currentPath && !savingDrawing.current) {
            const { x, y } = getCoordinates(event, aspectRatio);
            const time = performance.now();
            const {
                x: lastX,
                y: lastY,
                time: lastTime,
            } = lastPoint.current || {};

            if (
                lastTime - time < 20 &&
                (x - lastX) ** 2 + (y - lastY) ** 2 < 0.05
            ) {
                return;
            }

            setCurrentPath(path => path + ` ${x.toFixed(3)},${y.toFixed(3)}`);

            lastPoint.current = { x, y, time };
        }
    };

    const handleSvgMouseUp = () => {
        if (currentPath && !savingDrawing.current) {
            const path = simplify(
                currentPath.split(' ').map(pair => {
                    const [x, y] = pair.split(',').map(str => +str);

                    return { x, y };
                }),
                0.1,
                true
            );

            savingDrawing.current = true;
            addPageComment({
                variables: {
                    path: `relative ${path
                        .map(({ x, y }) => `${x / aspectRatio},${y}`)
                        .join(' ')}`,
                    style: currentPathStyle,
                    paperId,
                    pageId,
                },
            }).then(
                () => {
                    savingDrawing.current = false;
                    setCurrentPath(null);
                    setCurrentPathStyle(null);
                },
                () => {
                    setCurrentPath(null);
                    setCurrentPathStyle(null);
                    savingDrawing.current = false;
                }
            );
        }
    };

    const handleDisableTextComment = () => {
        setCurrentText(null);
        setCurrentTextStyle(null);
    };

    const handleSaveTextComment = () => {
        if (currentText) {
            addPageComment({
                variables: {
                    text: currentText,
                    style: JSON.stringify(currentTextStyle),
                    paperId,
                    pageId,
                },
            }).then(() => {
                setCurrentText(null);
                setCurrentTextStyle(null);
            });
        } else {
            handleDisableTextComment();
        }
    };

    const handleTextAreaKeyDown = e => {
        if (e.key === 'Enter' && e.ctrlKey) {
            handleSaveTextComment();
        }

        if (e.key === 'Escape') {
            handleDisableTextComment();
        }
    };

    const handleTextDeleteClick = event => {
        if (tool === 'eraser') {
            const { id } = event.currentTarget.dataset;

            deletePageComment({
                variables: {
                    commentId: id,
                    paperId,
                    pageId,
                },
            });
        }
    };

    return (
        <>
            <svg
                className="examination-modal-page-comments"
                onMouseDown={handleSvgMouseDown}
                onMouseMove={handleSvgMouseMove}
                onMouseUp={handleSvgMouseUp}
                onMouseLeave={handleSvgMouseUp}
                onClick={handleSvgClick}
                viewBox={`0 0 ${100 * aspectRatio} 100`}
                preserveAspectRatio="none"
            >
                {showComments &&
                    comments
                        .filter(({ path }) => path)
                        .map(({ _id, path, style }) => (
                            <Polyline
                                path={path}
                                style={style}
                                id={_id}
                                key={_id}
                                aspectRatio={aspectRatio}
                            />
                        ))}
                {currentPath && (
                    <Polyline
                        path={currentPath}
                        style={currentPathStyle}
                        id="current"
                        key="current"
                        aspectRatio={aspectRatio}
                    />
                )}
            </svg>
            {showComments &&
                comments
                    .filter(({ text }) => text)
                    .map(({ _id, text, style }) => (
                        <div
                            data-id={_id}
                            onClick={handleTextDeleteClick}
                            key={_id}
                            style={JSON.parse(style)}
                            className="text-comment text-comment-saved"
                        >
                            <div className="text-comment-content">{text}</div>
                        </div>
                    ))}
            {currentTextStyle && (
                <>
                    <div
                        className="text-comment-click-away"
                        onClick={handleSaveTextComment}
                    />
                    <div style={currentTextStyle} className="text-comment">
                        <textarea
                            autoFocus
                            value={currentText}
                            onChange={e =>
                                setCurrentText(e.currentTarget.value)
                            }
                            onKeyDown={handleTextAreaKeyDown}
                        />
                    </div>
                </>
            )}
        </>
    );
}

interface PolylineProps {
    path: string;
    id: string;
    style: string;
    aspectRatio: number;
}

const Polyline = React.memo(function Polyline(props: PolylineProps) {
    const { path, id, style, aspectRatio, ...rest } = props;

    const points = path.startsWith('relative')
        ? path
              .replace(/^relative */, '')
              .split(' ')
              .map(point => {
                  const [left, top] = point.split(',').map(c => Number(c));

                  return `${left * aspectRatio},${top}`;
              })
              .join(' ')
        : path;

    return (
        <polyline
            fill="none"
            style={JSON.parse(style)}
            points={points}
            data-id={id}
            {...rest}
        />
    );
});
