import React, { useCallback, useRef, useState } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { provideLayoutAndData } from '../Page';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MutationButton, SubmitButton } from 'Components/EnhancedButtons';
import PageGallery from '../Paper/PageGallery';
import ParticipantDataForm from '../ParticipantDataForm';
import {
    AdminParticipantPageQuery,
    AdminParticipantPageQueryVariables,
} from '../GraphQL/types/AdminParticipantPageQuery';
import { venuesSortFn } from '@turgor/model-utils/lib/sort';
import { PaperResults } from '../ParticipantsResults';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
    DeleteParticipant,
    DeleteParticipantVariables,
} from '../GraphQL/types/DeleteParticipant';
import {
    DeletePaper,
    DeletePaperVariables,
} from '../GraphQL/types/DeletePaper';

const adminParticipantPageQuery = gql`
    query AdminParticipantPageQuery($participantId: ID!) {
        participant(participantId: $participantId) {
            _id
            email
            grade
            name
            surname
            phone
            townName
            school
            tournament {
                _id
                number
                venues {
                    _id
                    townName
                    seasons {
                        fall {
                            type
                        }
                        spring {
                            type
                        }
                    }
                }
            }
            papers {
                _id
                card
                season
                level
                tier
                stage
                type
                marks {
                    mark
                    points
                }
                venue {
                    _id
                    townName
                    seasons {
                        fall {
                            type
                        }
                        spring {
                            type
                        }
                    }
                }
                examinations {
                    marks {
                        _id
                    }
                }
                pages {
                    _id
                    file
                    fileName
                    problemNumber
                }
            }
        }
    }
`;

export default provideLayoutAndData<
    AdminParticipantPageQuery,
    AdminParticipantPageQueryVariables
>(adminParticipantPageQuery, {
    variablesGetter: ({ participantId }) => ({ participantId }),
})(function AdminParticipantPage({ data }) {
    const [showParticipantDeleteModal, setShowParticipantDeleteModal] =
        useState(false);
    const [paperIdToDelete, setPaperIdToDelete] = useState(null);

    const closeParticipantDeleteModal = useCallback(
        () => setShowParticipantDeleteModal(false),
        []
    );
    const openParticipantDeleteModal = useCallback(
        () => setShowParticipantDeleteModal(true),
        []
    );

    const closePaperDeleteModal = useCallback(
        () => setPaperIdToDelete(null),
        []
    );
    const openPaperDeleteModal = useCallback(
        paperId => setPaperIdToDelete(paperId),
        []
    );

    const {
        participant: { tournament, papers, ...participant },
    } = data;

    const venues = [...tournament.venues].sort(venuesSortFn);

    return (
        <>
            <h3>
                {tournament.number}-й Турнир, {participant.name}{' '}
                {participant.surname}
            </h3>
            <ParticipantDataForm
                participant={participant}
                onDeleteClick={openParticipantDeleteModal}
            />
            <br />
            <br />
            <br />
            {papers.map(paper => (
                <AdminPaperView
                    paper={paper}
                    venues={venues}
                    key={paper._id}
                    tournamentNumber={tournament.number}
                    openPaperDeleteModal={openPaperDeleteModal}
                />
            ))}
            <PaperDeleteModal
                show={Boolean(paperIdToDelete)}
                paperId={paperIdToDelete}
                onHide={closePaperDeleteModal}
            />
            <ParticipantDeleteModal
                show={showParticipantDeleteModal}
                participant={participant}
                tournamentNumber={tournament.number}
                onHide={closeParticipantDeleteModal}
            />
        </>
    );
});

const updatePaperInfoMutation = gql`
    mutation UpdatePaperInfo(
        $paperId: ID!
        $type: PaperType!
        $season: Season!
        $level: Level!
        $tier: Tier!
        $venueId: ID
    ) {
        updatePaperInfo(
            paperId: $paperId
            type: $type
            season: $season
            level: $level
            tier: $tier
            venueId: $venueId
        ) {
            __typename
            ... on UpdatePaperInfoSuccess {
                paper {
                    _id
                    card
                    season
                    level
                    tier
                    stage
                    type
                    venue {
                        _id
                        townName
                    }
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                    }
                    examinations {
                        marks {
                            _id
                        }
                    }
                }
            }
        }
    }
`;

function AdminPaperView({
    paper,
    venues,
    tournamentNumber,
    openPaperDeleteModal,
}) {
    const { register, handleSubmit, watch } = useForm();
    const formRef = useRef<HTMLFormElement>(null);
    const [updatePaperInfo, { loading, data, error }] = useMutation(
        updatePaperInfoMutation
    );

    const [showWarningModal, setShowWarningModal] = useState(false);
    const paperUnmarkingApprovedRef = useRef(false);
    const { t } = useTranslation();

    const handleCloseWarningModal = useCallback(
        () => setShowWarningModal(false),
        []
    );

    const onSubmit = useCallback(
        formData => {
            if (
                !paperUnmarkingApprovedRef.current &&
                (['MARKED', 'PUBLISHED'].includes(paper.stage) ||
                    paper.examinations?.find(e => e.marks?.length)) &&
                (paper.season !== formData.season ||
                    paper.level !== formData.level ||
                    paper.tier !== formData.tier)
            ) {
                setShowWarningModal(true);
                return;
            }

            updatePaperInfo({
                variables: {
                    paperId: paper._id,
                    ...formData,
                    venueId: formData.venue || null,
                },
            });

            paperUnmarkingApprovedRef.current = false;
        },
        [updatePaperInfo, paper]
    );

    const handleSaveAnywayClick = useCallback(() => {
        paperUnmarkingApprovedRef.current = true;
        setShowWarningModal(false);
        handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    let errorMessage = null;

    if (
        error ||
        !['UpdatePaperInfoSuccess', undefined].includes(
            data?.updatePaperInfo.__typename
        )
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    return (
        <>
            <h5>
                <Link
                    to={`/tournaments/${tournamentNumber}/papers/${paper._id}`}
                >
                    {paper.season === 'FALL' ? 'Осенний' : 'Весенний'}{' '}
                    {paper.level === 'ORDINARY' ? 'базовый' : 'сложный'}{' '}
                    {paper.tier === 'JUNIOR' ? 'младший' : 'старший'}
                </Link>
            </h5>
            {['MARKED', 'PUBLISHED'].includes(paper.stage) ? (
                <Row>
                    <Col md={{ span: 10, offset: 1 }} className="mb-4">
                        <PaperResults
                            paper={paper}
                            showProblemSetName={false}
                        />
                    </Col>
                </Row>
            ) : null}
            <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={{ span: 3, offset: 2 }}>
                        <Form.Group as={Row} controlId="paper-data-tier">
                            <Form.Label
                                column={true}
                                md={4}
                                className="text-md-right"
                            >
                                Тур
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    as="select"
                                    {...register('season', { required: true })}
                                    defaultValue={paper.season}
                                >
                                    <option value="FALL">Осенний</option>
                                    <option value="SPRING">Весенний</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group as={Row} controlId="paper-data-tier">
                            <Form.Label
                                column={true}
                                md={4}
                                className="text-md-right"
                            >
                                Вариант
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    as="select"
                                    {...register('level', { required: true })}
                                    defaultValue={paper.level}
                                >
                                    <option value="ORDINARY">Базовый</option>
                                    <option value="ADVANCED">Сложный</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group as={Row} controlId="paper-data-tier">
                            <Col md={8}>
                                <Form.Control
                                    as="select"
                                    {...register('tier', { required: true })}
                                    defaultValue={paper.tier}
                                >
                                    <option value="JUNIOR">Младший</option>
                                    <option value="SENIOR">Старший</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 3, offset: 2 }}>
                        <Form.Group as={Row} controlId="paper-data-type">
                            <Form.Label
                                column={true}
                                md={4}
                                className="text-md-right"
                            >
                                Участие
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    as="select"
                                    {...register('type', { required: true })}
                                    defaultValue={paper.type}
                                >
                                    <option value="OFFLINE">Очно</option>
                                    <option value="ONLINE">Онлайн</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Row} controlId="paper-data-venue">
                            <Form.Label
                                column={true}
                                md={2}
                                className="text-md-right"
                            >
                                Точка
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    as="select"
                                    {...register('venue')}
                                    defaultValue={paper.venue?._id || ''}
                                >
                                    <option
                                        value=""
                                        disabled={
                                            watch('type', paper.type) ===
                                            'OFFLINE'
                                        }
                                    >
                                        -- Вне --
                                    </option>
                                    {venues
                                        .filter(
                                            venue =>
                                                venue.seasons?.[
                                                    paper.season.toLowerCase()
                                                ]?.type
                                        )
                                        .map(venue => (
                                            <option
                                                value={venue._id}
                                                key={venue._id}
                                                disabled={
                                                    venue.seasons?.[
                                                        paper.season.toLowerCase()
                                                    ]?.type === 'ONLINE' &&
                                                    watch(
                                                        'type',
                                                        paper.type
                                                    ) === 'OFFLINE'
                                                }
                                            >
                                                {venue.townName},{' '}
                                                {venue.seasons?.[
                                                    paper.season.toLowerCase()
                                                ]?.type === 'OFFLINE'
                                                    ? 'очно'
                                                    : 'онлайн'}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={{ span: 4, offset: 2 }}>
                        <SubmitButton loading={loading} mutationData={data}>
                            Сохранить
                        </SubmitButton>
                    </Col>
                    <Col md={4} className="text-md-right">
                        <Button
                            variant="outline-danger"
                            onClick={() => openPaperDeleteModal(paper._id)}
                        >
                            Удалить
                        </Button>
                    </Col>
                </Row>
            </Form>
            {errorMessage && (
                <Alert variant="warning" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
            <Modal show={showWarningModal} onHide={handleCloseWarningModal}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Осторожно</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {['MARKED', 'PUBLISHED'].includes(paper.stage)
                        ? 'Работа уже проверена.'
                        : 'Проверка работы уже начата.'}{' '}
                    При изменении варианта некоторые оценки в проверках могут
                    быть удалены
                    {paper.stage === 'PUBLISHED'
                        ? ' и работа будет распубликована'
                        : '.'}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        onClick={handleCloseWarningModal}
                    >
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleSaveAnywayClick}>
                        Всё равно сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
            <br />
            {paper.pages && paper.type === 'ONLINE' && (
                <PageGallery
                    pages={Array.from(
                        new Set(paper.pages.map(page => page.problemNumber))
                    )
                        .sort((a: number, b: number) => a - b)
                        .flatMap((problemNumber: number) =>
                            paper.pages.filter(
                                page => page.problemNumber === problemNumber
                            )
                        )}
                />
            )}
            {paper.pages && paper.type === 'OFFLINE' && (
                <PageGallery pages={paper.pages} />
            )}
            <br />
            <br />
            <br />
        </>
    );
}

const deletePaperMutation: TypedDocumentNode<
    DeletePaper,
    DeletePaperVariables
> = gql`
    mutation DeletePaper($paperId: ID!) {
        deletePaper(paperId: $paperId) {
            __typename
            ... on DeletePaperSuccess {
                participant {
                    _id
                }
            }
        }
    }
`;

function PaperDeleteModal({ show, paperId, onHide }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Осторожно</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы точно хотите удалить работу?</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={onHide}>
                    Отмена
                </Button>
                <MutationButton
                    mutation={deletePaperMutation}
                    mutationOptions={{
                        refetchQueries: ['AdminParticipantPageQuery'],
                        awaitRefetchQueries: true,
                    }}
                    variables={{ paperId }}
                    variant="danger"
                    onMutate={() => setTimeout(onHide, 600)}
                >
                    Удалить
                </MutationButton>
            </Modal.Footer>
        </Modal>
    );
}

const deleteParticipantMutation: TypedDocumentNode<
    DeleteParticipant,
    DeleteParticipantVariables
> = gql`
    mutation DeleteParticipant($participantId: ID!) {
        deleteParticipant(participantId: $participantId) {
            __typename
            ... on DeleteParticipantSuccess {
                success
            }
        }
    }
`;

function ParticipantDeleteModal({
    show,
    participant,
    tournamentNumber,
    onHide,
}) {
    const history = useHistory();
    const { cache } = useApolloClient();

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Осторожно</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Вы точно хотите удалить участника вместе с работами, вопросами и
                апелляциями?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={onHide}>
                    Отмена
                </Button>
                <MutationButton
                    mutation={deleteParticipantMutation}
                    variables={{ participantId: participant._id }}
                    variant="danger"
                    onMutate={({ data }) => {
                        if (
                            data.deleteParticipant?.__typename !==
                            'DeleteParticipantSuccess'
                        ) {
                            return;
                        }

                        setTimeout(() => {
                            try {
                                // @ts-ignore
                                const navigationEntries = navigation.entries();
                                const previousNavigationEntry =
                                    navigationEntries[
                                        navigationEntries.length - 2
                                    ];

                                if (
                                    previousNavigationEntry?.url.endsWith(
                                        '/participants/fall'
                                    ) ||
                                    previousNavigationEntry?.url.endsWith(
                                        '/participants/spring'
                                    )
                                ) {
                                    const { pathname } = new URL(
                                        previousNavigationEntry.url
                                    );
                                    history.replace(pathname);
                                } else {
                                    history.replace(
                                        `/tournaments/${tournamentNumber}`
                                    );
                                }
                            } catch {
                                history.replace(
                                    `/tournaments/${tournamentNumber}`
                                );
                            }

                            cache.evict({ id: cache.identify(participant) });
                        }, 600);
                    }}
                >
                    Удалить
                </MutationButton>
            </Modal.Footer>
        </Modal>
    );
}
