import { gql } from '@apollo/client';
import { provideLayoutAndData } from '../Page';
import React from 'react';
import { AdminDeduplicateTournamentParticipantsPageQuery } from 'GraphQL/types/AdminDeduplicateTournamentParticipantsPageQuery';
import DeduplicateParticipants from '../DeduplicateParticipants';

const adminDeduplicateTournamentParticipantsPageQuery = gql`
    query AdminDeduplicateTournamentParticipantsPageQuery($number: Int!) {
        tournament(number: $number) {
            _id
            number
            duplicatedParticipants {
                _id
                email
                phone
                name
                surname
                townName
                school
                grade
                user {
                    _id
                    email
                }
                papers(filter: { submittedOnly: true }) {
                    _id
                    type
                    season
                    level
                    tier
                    stage
                    venue {
                        _id
                        townName
                    }
                }
            }
        }
    }
`;

export default provideLayoutAndData<AdminDeduplicateTournamentParticipantsPageQuery>(
    adminDeduplicateTournamentParticipantsPageQuery,
    {
        variablesGetter: params => ({ number: Number(params.number) }),
    }
)(function AdminDeduplicateTournamentParticipantsPage({ data }) {
    const {
        tournament: { duplicatedParticipants, ...tournament },
    } = data;

    return (
        <DeduplicateParticipants
            duplicatedParticipants={duplicatedParticipants}
            tournament={tournament}
            refetchQueryName="AdminDeduplicateTournamentParticipantsPageQuery"
        />
    );
});
