import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Col, Row, FormControl } from 'react-bootstrap';
import {
    ArrowClockwise,
    ArrowLeft,
    ArrowRight,
    Download,
} from 'react-bootstrap-icons';
import { useViewer } from '../ViewerContext';
import RotatedImage from './RotatedImage';
import { ExaminationModal } from './ExaminationModal';

import './ExaminationPaperView.css';
import FileDropzone from '../FileDropzone';
import { useTranslation } from 'react-i18next';

export default function ExaminationPaperView({
    paper,
    pages,
    problemSet,
    onMarksSaved,
}) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedPageId, setSelectedPageId] = useState(null);
    const { viewer } = useViewer();

    const examinationType = viewer.role === 'ORGANIZER' ? 'LOCAL' : 'CENTRAL';
    const examination = paper.examinations
        .filter(exam => exam.type === examinationType)
        .pop() || { type: examinationType, stage: 'FIRST', marks: [] };

    return (
        <>
            {paper.type === 'ONLINE' &&
                problemSet.problems.map((problem, problemNumber) => (
                    <ProblemPagesRow
                        key={problemNumber}
                        pages={pages}
                        paperId={paper._id}
                        problemNumber={problemNumber}
                        problemsCount={problemSet.problems.length}
                        onPageClick={pageId => {
                            setSelectedPageId(pageId);
                            setModalOpen(true);
                        }}
                    />
                ))}
            {paper.type === 'OFFLINE' && (
                <PagesRow
                    pages={pages}
                    paperId={paper._id}
                    onPageClick={pageId => {
                        setSelectedPageId(pageId);
                        setModalOpen(true);
                    }}
                    fileName={paper.fileName}
                    file={paper.file}
                />
            )}
            {modalOpen && (
                <ExaminationModal
                    onMarksSaved={onMarksSaved}
                    paper={paper}
                    pages={pages}
                    problemSet={problemSet}
                    selectedPageId={selectedPageId}
                    onClose={() => setModalOpen(false)}
                    examination={examination}
                />
            )}
        </>
    );
}

const addPageMutation = gql`
    mutation AddPage(
        $paperId: ID!
        $fileData: String!
        $fileName: String!
        $problemNumber: Int
    ) {
        addPageToOnlinePaper(
            paperId: $paperId
            fileData: $fileData
            fileName: $fileName
            problemNumber: $problemNumber
        ) {
            __typename
            ... on UnknownFileFormat {
                detectedExt
                detectedMime
            }
            ... on AddPageToOnlinePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                        rotation
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

export function ProblemPagesRow({
    pages,
    problemNumber,
    paperId,
    onPageClick,
    problemsCount,
}) {
    const problemPages = pages.filter(
        page => page.problemNumber === problemNumber
    );
    const [addPage] = useMutation(addPageMutation);
    const { viewer } = useViewer();
    const { t } = useTranslation();

    return (
        <div>
            <h4>
                {t('ProblemWithNumber', { problemNumber: problemNumber + 1 })}
            </h4>
            {problemPages.length === 0 && (
                <h6>{t('ExaminationPaperView.ProblemSolutionHasNotBeenUploaded')}</h6>
            )}
            <Row className="align-items-end">
                {problemPages.map((page, index) => (
                    <PagePreview
                        paperId={paperId}
                        page={page}
                        showMoveLeft={index > 0}
                        showMoveRight={index < problemPages.length - 1}
                        key={page._id}
                        onClick={onPageClick}
                        problemNumber={problemNumber}
                        problemsCount={problemsCount}
                    />
                ))}
                {viewer.role === 'ADMIN' && (
                    <Col
                        sm={4}
                        xs={6}
                        className="text-center align-self-center flex-grow-1"
                    >
                        <FileDropzone
                            buttonText={t('ExaminationPaperView.Choose')}
                            upload={(fileData, fileName) => {
                                return addPage({
                                    variables: {
                                        fileData,
                                        paperId: paperId,
                                        fileName,
                                        problemNumber,
                                    },
                                });
                            }}
                        />
                    </Col>
                )}
            </Row>
            <br />
        </div>
    );
}

export function PagesRow({ pages, paperId, onPageClick, file, fileName }) {
    const [addPage] = useMutation(addPageMutation);
    const { viewer } = useViewer();

    const showFileDownload = Boolean(file && fileName);

    return (
        <div>
            <Row className="align-items-end">
                {showFileDownload && (
                    <Col sm={2} xs={4} className="text-center mb-4 pb-4">
                        <a
                            className="examination-paper-view-page-preview p-4 mb-2 d-block"
                            href={`${process.env.REACT_APP_BACKEND_URL}${file}`}
                            download={fileName}
                        >
                            <Download size={32} />
                        </a>
                        <strong className="d-block mb-1 paper-page-preview-filename">
                            {fileName}
                        </strong>
                    </Col>
                )}
                {pages.map((page, index) => (
                    <PagePreview
                        paperId={paperId}
                        page={page}
                        showMoveLeft={index > 0}
                        showMoveRight={index < pages.length - 1}
                        key={page._id}
                        onClick={onPageClick}
                        problemNumber={null}
                        problemsCount={0}
                    />
                ))}
                {viewer.role === 'ADMIN' && (
                    <Col
                        sm={4}
                        xs={6}
                        className="text-center align-self-center flex-grow-1"
                    >
                        <FileDropzone
                            buttonText="Выбрать"
                            upload={(fileData, fileName) => {
                                return addPage({
                                    variables: {
                                        fileData,
                                        paperId: paperId,
                                        fileName,
                                        problemNumber: null,
                                    },
                                });
                            }}
                        />
                    </Col>
                )}
            </Row>
            <br />
        </div>
    );
}

const rotatePageMutation = gql`
    mutation RotatePage($paperId: ID!, $pageId: ID!) {
        rotatePage(paperId: $paperId, pageId: $pageId) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        rotation
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

const deletePageMutation = gql`
    mutation DeletePage($paperId: ID!, $pageId: ID!) {
        deletePage(paperId: $paperId, pageId: $pageId) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                    }
                }
            }
        }
    }
`;

const movePageMutation = gql`
    mutation MovePage($paperId: ID!, $pageId: ID!, $direction: Int!) {
        movePage(paperId: $paperId, pageId: $pageId, direction: $direction) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                        rotation
                        comments {
                            _id
                            jury {
                                name
                            }
                            text
                            path
                            style
                        }
                    }
                }
            }
        }
    }
`;

const setPageProblemNumberMutation = gql`
    mutation SetPageProblemNumber(
        $paperId: ID!
        $pageId: ID!
        $problemNumber: Int!
    ) {
        setPageProblemNumber(
            paperId: $paperId
            pageId: $pageId
            problemNumber: $problemNumber
        ) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    pages {
                        _id
                        problemNumber
                    }
                }
            }
        }
    }
`;

function PagePreview({
    paperId,
    page,
    onClick,
    showMoveLeft,
    showMoveRight,
    problemNumber,
    problemsCount,
}) {
    const [rotatePage] = useMutation(rotatePageMutation);
    const [deletePage] = useMutation(deletePageMutation);
    const [movePage] = useMutation(movePageMutation);
    const [setPageProblemNumber] = useMutation(setPageProblemNumberMutation);
    const { viewer } = useViewer();

    return (
        <Col sm={2} xs={4} className="text-center mb-4">
            <RotatedImage
                src={`${process.env.REACT_APP_BACKEND_URL}${page.file}`}
                maxWidth="100%"
                rotation={page.rotation}
                className="examination-paper-view-page-preview"
                onClick={() => onClick(page._id)}
            />
            <strong className="d-block mb-1 paper-page-preview-filename">
                {page.fileName}
            </strong>
            {viewer.role !== 'PARTICIPANT' && (
                <div className="examination-paper-view-page-buttons">
                    {showMoveLeft && (
                        <Button
                            variant="outline-dark"
                            size="sm"
                            className="mt-1 mr-2"
                            title="Подвинуть влево"
                            onClick={() => {
                                movePage({
                                    variables: {
                                        paperId,
                                        pageId: page._id,
                                        direction: -1,
                                    },
                                });
                            }}
                        >
                            <ArrowLeft size={20} />
                        </Button>
                    )}
                    <Button
                        variant="outline-dark"
                        size="sm"
                        className="mt-1"
                        title="Повернуть"
                        onClick={() =>
                            rotatePage({
                                variables: { paperId, pageId: page._id },
                            })
                        }
                    >
                        <ArrowClockwise size={20} />
                    </Button>
                    {showMoveRight && (
                        <Button
                            variant="outline-dark"
                            size="sm"
                            className="mt-1 ml-2"
                            title="Подвинуть вправо"
                            onClick={() => {
                                movePage({
                                    variables: {
                                        paperId,
                                        pageId: page._id,
                                        direction: 1,
                                    },
                                });
                            }}
                        >
                            <ArrowRight size={20} />
                        </Button>
                    )}
                    <br />
                    {problemNumber !== null && (
                        <FormControl
                            as="select"
                            className="mt-2 d-inline-block w-auto"
                            value={problemNumber}
                            size="sm"
                            onChange={e => {
                                setPageProblemNumber({
                                    variables: {
                                        paperId,
                                        pageId: page._id,
                                        problemNumber: Number(
                                            e.currentTarget.value
                                        ),
                                    },
                                });
                            }}
                        >
                            {Array(problemsCount)
                                .fill(0)
                                .map((_, i) => (
                                    <option key={i} value={i}>
                                        Задача {i + 1}
                                    </option>
                                ))}
                        </FormControl>
                    )}
                    <br />
                    {viewer.role === 'ADMIN' && (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="mt-2"
                            onClick={() =>
                                deletePage({
                                    variables: { paperId, pageId: page._id },
                                })
                            }
                        >
                            Удалить
                        </Button>
                    )}
                </div>
            )}
        </Col>
    );
}
