import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { changeLocale } from './i18n';
import { useViewer } from './ViewerContext';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer bg-light">
            <Container>
                <Row>
                    <Col md={3}>
                        <a href="https://turgor.ru">{t('Footer.TurgorLink')}</a>
                    </Col>
                    <Col md={3}>
                        <Link to="/faq">{t('Footer.Faq')}</Link>
                    </Col>
                    <Col md={3}>
                        <Link to="/privacy-policy">
                            {t('Footer.PrivacyPolicy')}
                        </Link>
                    </Col>
                    <Col>
                        <LanguageButton locale="ru">Русский</LanguageButton>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <LanguageButton locale="en">English</LanguageButton>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

function LanguageButton({ locale, children }) {
    const { i18n } = useTranslation();
    const { viewer } = useViewer();

    if (i18n.language === locale) {
        return <span className="btn-link disabled text-body">{children}</span>;
    }

    return (
        <span
            className="btn-link cursor-pointer"
            onClick={() => changeLocale(locale, true, Boolean(viewer))}
        >
            {children}
        </span>
    );
}
