import { Viewer } from './ViewerContext';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { SpinnerButton } from './Components/EnhancedButtons';
import { Alert } from 'react-bootstrap';
import React from 'react';

const resendVerificationEmailMutation = gql`
    mutation ResendVerificationEmail {
        resendVerificationEmail {
            __typename
            ... on ResendVerificationEmailSuccess {
                email
            }
        }
    }
`;

export function NotVerifiedMessage({ viewer }: { viewer: Viewer }) {
    const { email } = viewer;
    const { t } = useTranslation();
    const [resendVerificationEmail, { data, error, called, loading }] =
        useMutation(resendVerificationEmailMutation);

    const linkSent =
        data?.resendVerificationEmail.__typename ===
        'ResendVerificationEmailSuccess';

    const errorSending = error || (called && !loading && !linkSent);

    return (
        <>
            <h4>{t('ParticipantHomePage.VerifyEmailHeader')}</h4>
            <p>
                <Trans
                    i18nKey="ParticipantHomePage.PleaseVerifyYourEmail"
                    values={{ email }}
                />
            </p>

            <p>
                <Trans i18nKey="ParticipantHomePage.DidNotReceive" />{' '}
                <SpinnerButton
                    loading={loading}
                    onClick={() => resendVerificationEmail()}
                    variant="outline-primary"
                    className="align-baseline ml-1"
                >
                    {t('ParticipantHomePage.ResendLink')}
                </SpinnerButton>
            </p>
            {errorSending && (
                <Alert variant="warning">{t('UnknownErrorMessage')}</Alert>
            )}
            {linkSent && (
                <Alert variant="success">
                    <Trans
                        i18nKey="ParticipantHomePage.LinkSent"
                        values={{ email }}
                    />
                </Alert>
            )}
            <p>
                {t('ParticipantHomePage.StillNoLink')}
                <a href="mailto:support@turgor.ru">support@turgor.ru</a>.
            </p>
        </>
    );
}
