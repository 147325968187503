import React from 'react';
import PageGallery from '../Paper/PageGallery';
import FileDropzone from '../FileDropzone';

export default function OnlineRoundUploadZone({
    pages,
    upload,
    onRemoveClick,
}) {
    return (
        <>
            <FileDropzone upload={upload} />
            <br />
            <PageGallery pages={pages} onRemoveClick={onRemoveClick} />
        </>
    );
}
