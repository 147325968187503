import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FetchResult } from '@apollo/client/link/core';
import Dropzone, { FileError } from 'react-dropzone';
import { Alert, Button, Spinner } from 'react-bootstrap';
import classnames from 'classnames';

const defaultDropzoneSettings = {
    accept: ['image/jpeg', 'image/png', 'image/webp', 'image/jp2', 'image/jpx'],
    maxFiles: 30,
    maxSize: 10000000,
    minSize: 50000,
};

export default function FileDropzone({
    upload,
    buttonText,
    dropzoneSettings = {},
}: {
    upload: (fileData: string, fileName: string) => Promise<FetchResult>;
    buttonText?: string;
    dropzoneSettings?: object;
}) {
    const { t } = useTranslation();
    const [fileDropErrors, setFileDropErrors] = useState<
        { fileName: string; errors: FileError[] }[]
    >([]);
    const [fileUploadErrors, setFileUploadErrors] = useState<
        { fileName: string; errorCode: string }[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);

    const isMobile = /android|iphone/i.test(navigator.userAgent);

    return (
        <div className="file-dropzone-wrapper mb-4">
            <Dropzone
                {...{ ...defaultDropzoneSettings, ...dropzoneSettings }}
                multiple={true}
                onDrop={async (acceptedFiles, fileRejections) => {
                    setLoading(true);
                    setFileDropErrors(
                        fileRejections.map(fr => ({
                            fileName: fr.file.name,
                            errors: fr.errors,
                        }))
                    );

                    let uploadErrors = [];

                    for (let file of acceptedFiles) {
                        let uploadResult: FetchResult;

                        try {
                            const fileData = await fileToBase64(file);

                            uploadResult = await upload(fileData, file.name);
                        } catch {
                            uploadErrors.push({
                                fileName: file.name,
                                errorCode: 'unknown',
                            });
                            continue;
                        }

                        if (
                            uploadResult.data?.[
                                Object.keys(uploadResult.data)[0]
                            ]?.__typename === 'UnknownFileFormat'
                        ) {
                            uploadErrors.push({
                                fileName: file.name,
                                errorCode: 'file-invalid-type',
                            });
                        }
                    }

                    setFileUploadErrors(uploadErrors);
                    setLoading(false);
                }}
            >
                {({ getRootProps, getInputProps, isDragActive }) =>
                    isMobile ? (
                        <div {...getRootProps()} className="text-center">
                            <input {...getInputProps()} />
                            <Button variant="outline-primary">
                                {t('OnlineRoundUploadZone.ClickToSelectFiles')}
                            </Button>
                        </div>
                    ) : (
                        <div
                            {...getRootProps()}
                            className={classnames(
                                'file-dropzone',
                                'text-center',
                                {
                                    'file-dropzone-active': isDragActive,
                                }
                            )}
                        >
                            <input {...getInputProps()} />
                            <h6>{t('OnlineRoundUploadZone.DropSomeFiles')}</h6>
                            <br />
                            <Button variant="outline-primary">
                                {buttonText ||
                                    t(
                                        'OnlineRoundUploadZone.OrClickToSelectFiles'
                                    )}
                            </Button>
                        </div>
                    )
                }
            </Dropzone>
            {(fileDropErrors.length > 0 || fileUploadErrors.length > 0) &&
                !loading && (
                    <Alert variant="warning" className="mt-4">
                        <Alert.Heading>
                            {t('OnlineRoundUploadZone.UploadUnsuccessful')}
                        </Alert.Heading>
                        {fileDropErrors.map(({ fileName, errors }) => (
                            <p key={fileName}>
                                <strong>{fileName}</strong>:{' '}
                                <FileErrorMessage code={errors[0]?.code} />
                            </p>
                        ))}
                        {fileUploadErrors.map(({ fileName, errorCode }) => (
                            <p key={fileName}>
                                <strong>{fileName}</strong>:{' '}
                                <FileErrorMessage code={errorCode} />
                            </p>
                        ))}
                    </Alert>
                )}
            {loading && (
                <div className="file-dropzone-overlay">
                    <Spinner animation="border" />
                </div>
            )}
        </div>
    );
}

function FileErrorMessage({ code }: { code?: string }) {
    const { t } = useTranslation();
    let message = t('OnlineRoundUploadZone.CantProcessFile');

    switch (code) {
        case 'file-invalid-type':
            message = t('OnlineRoundUploadZone.InvalidFileType');
            break;
        case 'file-too-large':
            message = t('OnlineRoundUploadZone.FileTooLarge');
            break;
        case 'file-too-small':
            message = t('OnlineRoundUploadZone.FileTooSmall');
            break;
        default:
            break;
    }

    return <>{message}</>;
}

export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
            // @ts-ignore
            resolve(e.target?.result?.split(',').pop());
        };

        reader.onerror = reader.onabort = e => {
            reject(e);
        };

        reader.readAsDataURL(file);
    });
}
