import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Alert, Spinner } from 'react-bootstrap';

const verifyEmailMutation = gql`
    mutation VerifyEmail($encodedEmail: String!, $token: String!) {
        verifyEmail(encodedEmail: $encodedEmail, token: $token) {
            __typename
            ... on Error {
                message
            }
            ... on VerifyEmailSuccess {
                role
            }
        }
    }
`;

export default function VerifyEmailPage() {
    const { t } = useTranslation();
    const [verifyEmail, { called, data, loading, error }] =
        useMutation(verifyEmailMutation);

    useEffect(() => {
        const search = window.location.search.replace(/^\?/, '');
        const kvPairs = search.split('&');
        let variables: any = {};

        for (let pair of kvPairs) {
            const [key, value] = pair.split('=');

            if (key === 'e' || key === 't') {
                variables[key] = value;
            }
        }

        if (variables.e && variables.t) {
            verifyEmail({
                variables: {
                    encodedEmail: variables.e,
                    token: variables.t,
                },
            }).then(({ data }) =>
                setTimeout(() => {
                    window.location.href =
                        data?.verifyEmail.role === 'ORGANIZER'
                            ? window.location.origin + '/org'
                            : window.location.origin;
                }, 2000)
            );

            return;
        }

        window.location.href = window.location.origin;
    }, [verifyEmail]);

    if (!called || loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    if (
        error ||
        !['VerifyEmailSuccess', 'AlreadyVerifiedError'].includes(
            data?.verifyEmail.__typename
        )
    ) {
        // TODO: Give the user options to recover from the error
        return (
            <Page>
                <Alert variant="danger">{t('VerifyEmailPage.Error')}</Alert>
            </Page>
        );
    }

    return (
        <Page>
            <Alert variant="success">{t('VerifyEmailPage.Success')}</Alert>
        </Page>
    );
}
