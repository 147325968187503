import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { provideLayoutAndData } from './Page';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import React, {
    FormEvent,
    KeyboardEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    ResultsInputPageQuery,
    ResultsInputPageQuery_venue_participants,
    ResultsInputPageQuery_venue_tournament,
    ResultsInputPageQueryVariables,
} from 'GraphQL/types/ResultsInputPageQuery';
import tournamentProblemsCountFields from 'GraphQL/fragments/tournamentProblemsCountFields';
import { Mark, Marks } from './Components/Marks';
import { findProblemsInTournament } from '@turgor/model-utils/lib/findByFields';
import { useForm } from 'react-hook-form';
import { getProblemsNames } from '@turgor/model-utils/lib/getProblemsNames';

import './ResultsInputPage.scss';
import classnames from 'classnames';
import { SubmitButton } from './Components/EnhancedButtons';
import { PencilFill } from 'react-bootstrap-icons';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';
import {
    createParamsParser,
    getOptionalGrade,
    getSeasonAndLevel,
    getTier,
    getVenueId,
} from './routeParams';

const resultsInputPageQuery = gql`
    query ResultsInputPageQuery(
        $venueId: ID!
        $papersFilter: PapersFilter
        $grade: Int
    ) {
        venue(id: $venueId) {
            _id
            townName
            tournament {
                _id
                number
                ...tournamentProblemsCountFields
            }
            participants(papersFilter: $papersFilter, grade: $grade) {
                _id
                name
                surname
                grade
                school
                email
                papers(filter: $papersFilter) {
                    _id
                    card
                    season
                    level
                    tier
                    marks {
                        mark
                    }
                }
            }
        }
    }

    ${tournamentProblemsCountFields}
`;

interface ResultsInputPageRouteParams {
    venueId: string;
    round: `${'fall' | 'spring'}-${'ordinary' | 'advanced'}`;
    tier: 'junior' | 'senior';
    grade?: string;
}

const paramsParser = createParamsParser(
    getVenueId,
    getSeasonAndLevel,
    getTier,
    getOptionalGrade
);

export default provideLayoutAndData<
    ResultsInputPageQuery,
    ResultsInputPageQueryVariables,
    ResultsInputPageRouteParams
>(resultsInputPageQuery, {
    variablesGetter: params => {
        const { venueId, season, level, tier, grade } = paramsParser(params);

        return {
            venueId,
            papersFilter: {
                submittedOnly: true,
                season,
                level,
                tier,
            },
            grade,
        };
    },
})(function ResultsInputPage({ data, queryResult: { refetch } }) {
    const params = useParams<ResultsInputPageRouteParams>();
    const { season, level, tier, grade } = paramsParser(params);

    const [modalOpen, setModalOpen] = useState(false);
    const [lastAddedParticipantId, setLastAddedParticipantId] = useState(null);

    if (!data?.venue) {
        return null;
    }

    const { tournament, participants, ...venue } = data.venue;

    return (
        <>
            <h2>Вбивка — {venue.townName}</h2>
            {participants.length ? (
                <ResultsInputTable
                    participants={[...participants].sort(participantsSortFn)}
                    tournament={tournament}
                    season={season}
                    level={level}
                    tier={tier}
                    lastAddedParticipantId={lastAddedParticipantId}
                />
            ) : (
                <h5>Нет участников</h5>
            )}
            <ResultsInputFooter onModalButtonClick={() => setModalOpen(true)} />
            {modalOpen && (
                <Modal
                    show={true}
                    size="lg"
                    onHide={() => setModalOpen(false)}
                    centered={true}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Добавить участника</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddSubmittedPaperForm
                            venueId={venue._id}
                            season={season}
                            level={level}
                            tier={tier}
                            grade={grade}
                            onSuccess={async participantId => {
                                await refetch();
                                setTimeout(() => {
                                    setModalOpen(false);
                                    setLastAddedParticipantId(participantId);
                                }, 500);
                            }}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
});

interface ResultsInputTableProps {
    participants: ResultsInputPageQuery_venue_participants[];
    tournament: ResultsInputPageQuery_venue_tournament;
    season: 'FALL' | 'SPRING';
    level: 'ORDINARY' | 'ADVANCED';
    tier: 'JUNIOR' | 'SENIOR';
    lastAddedParticipantId: null | string;
}

function ResultsInputTable({
    participants,
    tournament,
    season,
    level,
    tier,
    lastAddedParticipantId,
}: ResultsInputTableProps) {
    const [selectedParticipantId, setSelectedParticipantId] = useState<
        string | null
    >(null);
    const mounted = useRef(false);
    const handledAddedParticipant = useRef(null);

    useEffect(() => {
        if (!mounted.current) {
            const firstEmptyParticipant = participants.find(
                ({ papers: [paper] }) => !paper.marks?.length
            );

            if (firstEmptyParticipant) {
                setSelectedParticipantId(firstEmptyParticipant._id);
            }

            mounted.current = true;
        }
    }, [participants]);

    useEffect(() => {
        if (
            lastAddedParticipantId &&
            handledAddedParticipant.current !== lastAddedParticipantId
        ) {
            const lastAddedParticipant = participants.find(
                ({ _id }) => _id === lastAddedParticipantId
            );

            if (lastAddedParticipant) {
                setSelectedParticipantId(lastAddedParticipantId);
            }

            handledAddedParticipant.current = lastAddedParticipantId;
        }
    }, [participants, lastAddedParticipantId]);

    const handleMarksSuccess = paperId => {
        const previousParticipantIndex = participants.findIndex(
            p => p.papers[0]._id === paperId
        );

        const nextEmptyParticipant = participants.find(
            ({ papers: [paper] }, i) =>
                !paper.marks?.length && i > previousParticipantIndex
        );

        setTimeout(
            () => setSelectedParticipantId(nextEmptyParticipant?._id || null),
            500
        );
    };
    const handleSelect = participantId =>
        setSelectedParticipantId(participantId);

    const problems = findProblemsInTournament(tournament, season, level, tier);

    return (
        <Table className="results-input-papers-table">
            <thead>
                <tr>
                    <th>Анкета</th>
                    <th>Имя</th>
                    <th>Данные</th>
                    <th>Оценки</th>
                </tr>
            </thead>
            <tbody>
                {participants.map(participant => (
                    <ParticipantRow
                        key={participant._id}
                        participant={participant}
                        problems={problems}
                        selected={selectedParticipantId === participant._id}
                        onSuccess={handleMarksSuccess}
                        onSelect={handleSelect}
                        tournamentNumber={tournament.number}
                    />
                ))}
            </tbody>
        </Table>
    );
}

function stopPropagation(event) {
    event.stopPropagation();
}

function ParticipantRow({
    participant,
    problems,
    selected,
    onSuccess,
    onSelect,
    tournamentNumber,
}) {
    return (
        <tr
            className={classnames('cursor-pointer', {
                'results-input-selected-participant': selected,
            })}
            onClick={() => onSelect(participant._id)}
        >
            <td>{participant.papers[0].card}</td>
            <td>
                {participant.surname} {participant.name}
                <a
                    className="d-inline results-input-participant-edit-link ml-2"
                    href={`/tournaments/${tournamentNumber}/participants/${participant._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={stopPropagation}
                >
                    <PencilFill size="0.8rem" />
                </a>
            </td>
            <td className="small">
                {participant.grade}-й класс, {participant.school}
                {participant.email && (
                    <>
                        <br />
                        {participant.email}
                    </>
                )}
            </td>
            <td>
                {selected ? (
                    <MarksInputForm
                        paper={participant.papers[0]}
                        problems={problems}
                        onSuccess={onSuccess}
                    />
                ) : (
                    <Marks
                        marks={participant.papers[0].marks}
                        problems={problems}
                        size="lg"
                    />
                )}
            </td>
        </tr>
    );
}

const setPaperMarksMutation = gql`
    mutation SetPaperMarks($paperId: ID!, $marks: [MarkInput!]!) {
        setPaperMarks(paperId: $paperId, marks: $marks) {
            __typename
            ... on SetPaperMarksSuccess {
                paper {
                    _id
                    card
                    season
                    level
                    tier
                    marks {
                        mark
                    }
                }
            }
        }
    }
`;

const deletePaperMarksMutation = gql`
    mutation DeletePaperMarks($paperId: ID!) {
        deletePaperMarks(paperId: $paperId) {
            __typename
            ... on DeletePaperMarksSuccess {
                paper {
                    _id
                    card
                    season
                    level
                    tier
                    marks {
                        mark
                    }
                }
            }
        }
    }
`;

const validMarkPattern = /^\+!|\+|\+\.|\+-|\+\/2|-\+|-\.|-|0$/;
const validMarks = ['+!', '+', '+.', '+-', '+/2', '-+', '-.', '-', '0'];

function MarksInputForm({ paper, problems, onSuccess }) {
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        reset,
        formState: { errors: formErrors },
    } = useForm();
    const [setPaperMarks, { loading, data }] = useMutation(
        setPaperMarksMutation
    );
    const [
        deletePaperMarks,
        { loading: deleteMarksLoading, data: deleteMarksData },
    ] = useMutation(deletePaperMarksMutation);

    const markInputsRef = useRef<Array<HTMLInputElement | null>>([]);
    const formRef = useRef<HTMLFormElement | null>(null);

    const { t } = useTranslation();
    const problemNames = getProblemsNames(problems, t);
    const flatProblems = problems.flatMap(({ subproblems }, i) =>
        subproblems.map((subproblem, j) => ({
            problemNumber: i,
            subproblemNumber: j,
            key: `${i}-${j}`,
        }))
    );

    useEffect(() => {
        setFocus('mark-0-0');
        formRef.current?.scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth',
        });
    }, [setFocus]);

    const handleBeforeInput = (
        event: FormEvent<HTMLInputElement> & InputEvent
    ) => {
        const {
            value,
            dataset: { problem },
        } = event.currentTarget;
        const addedChars = event.data || '';

        if (addedChars === '=') {
            event.preventDefault();
            setValue(`mark-${problem}`, '+');
        }

        if (
            /^[0-9]{1}$/.test(addedChars) &&
            !(value === '+/' && addedChars === '2')
        ) {
            event.preventDefault();
            setValue(
                `mark-${problem}`,
                addedChars === '0' ? '0' : validMarks[Number(addedChars) - 1]
            );

            const problemIndex = flatProblems.findIndex(
                ({ key }) => key === problem
            );

            if (problemIndex < flatProblems.length - 1) {
                setFocus(`mark-${flatProblems[problemIndex + 1].key}`);
                markInputsRef.current[problemIndex + 1]?.setSelectionRange(
                    0,
                    0
                );
            }
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const {
            selectionStart,
            selectionEnd,
            value,
            dataset: { problem },
        } = event.currentTarget;
        const { shiftKey, key } = event;

        if (shiftKey) {
            return;
        }

        const problemIndex = flatProblems.findIndex(
            ({ key }) => key === problem
        );

        if (
            key === 'ArrowLeft' &&
            selectionStart === 0 &&
            selectionEnd === 0 &&
            problemIndex > 0
        ) {
            setFocus(`mark-${flatProblems[problemIndex - 1].key}`);
            markInputsRef.current[problemIndex - 1]?.setSelectionRange(
                markInputsRef.current[problemIndex - 1].value.length,
                markInputsRef.current[problemIndex - 1].value.length
            );
            event.preventDefault();
        }

        if (
            key === 'ArrowRight' &&
            selectionStart === value.length &&
            selectionEnd === value.length &&
            problemIndex < flatProblems.length - 1
        ) {
            setFocus(`mark-${flatProblems[problemIndex + 1].key}`);
            markInputsRef.current[problemIndex + 1]?.setSelectionRange(0, 0);
            event.preventDefault();
        }
    };

    return (
        <Form
            onSubmit={handleSubmit(async formData => {
                const { data } = await setPaperMarks({
                    variables: {
                        paperId: paper._id,
                        marks: flatProblems.map(
                            ({ problemNumber, subproblemNumber, key }) => ({
                                problemNumber,
                                subproblemNumber,
                                mark: formData[`mark-${key}`],
                            })
                        ),
                    },
                });

                if (data?.setPaperMarks.__typename.endsWith('Success')) {
                    onSuccess(paper._id);
                }
            })}
            className="d-inline-block"
            ref={formRef}
        >
            <table className="results-input-marks-table">
                <tbody>
                    <tr>
                        {problemNames.map(name => (
                            <td key={name}>{name}</td>
                        ))}
                    </tr>
                    <tr>
                        {flatProblems.map(
                            (
                                { problemNumber, subproblemNumber, key },
                                index
                            ) => {
                                const { ref, ...restRegister } = register(
                                    `mark-${key}`,
                                    {
                                        required: true,
                                        pattern: validMarkPattern,
                                    }
                                );

                                return (
                                    <td key={key}>
                                        <Form.Control
                                            {...restRegister}
                                            ref={element => {
                                                ref(element);
                                                markInputsRef.current[index] =
                                                    element;
                                            }}
                                            onBeforeInput={handleBeforeInput}
                                            onKeyDown={handleKeyDown}
                                            className="results-input-mark-input"
                                            data-problem={key}
                                            defaultValue={
                                                paper.marks?.[problemNumber]?.[
                                                    subproblemNumber
                                                ]?.mark || ''
                                            }
                                            isInvalid={
                                                formErrors[`mark-${key}`]
                                            }
                                        />
                                    </td>
                                );
                            }
                        )}
                    </tr>
                </tbody>
            </table>
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="float-right mt-2"
                size="sm"
            >
                Сохранить
            </SubmitButton>
            {paper.marks?.length ? (
                <SubmitButton
                    loading={deleteMarksLoading}
                    mutationData={deleteMarksData}
                    className="float-right mt-2 mr-2"
                    size="sm"
                    type="button"
                    variant="danger"
                    onClick={async () => {
                        const { data } = await deletePaperMarks({
                            variables: { paperId: paper._id },
                        });

                        if (
                            data?.deletePaperMarks.__typename.endsWith(
                                'Success'
                            )
                        ) {
                            reset();
                            setFocus('mark-0-0');
                        }
                    }}
                >
                    Удалить оценки
                </SubmitButton>
            ) : null}
        </Form>
    );
}

function ResultsInputFooter({ onModalButtonClick }) {
    return (
        <Row className="result-input-hint">
            <Col md={9}>
                <Table borderless={true} className="w-auto">
                    <thead>
                        <tr>
                            {validMarks.map((mark, i) => (
                                <th key={i} className="pb-0">
                                    {i + 1}
                                </th>
                            ))}
                            <th className="pb-0">Enter</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {validMarks.map(mark => (
                                <td key={mark}>
                                    <Mark mark={mark} size="lg" />
                                </td>
                            ))}
                            <td className="small">Сохранить</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <Button
                    variant="outline-primary"
                    className="float-right mt-4"
                    onClick={onModalButtonClick}
                >
                    Добавить участника
                </Button>
            </Col>
        </Row>
    );
}

const addSubmittedPaperMutation = gql`
    mutation AddSubmittedPaper(
        $season: Season!
        $level: Level!
        $tier: Tier!
        $venueId: ID!
        $participantData: ParticipantDataInput!
    ) {
        addSubmittedPaper(
            season: $season
            level: $level
            tier: $tier
            venueId: $venueId
            participantData: $participantData
        ) {
            __typename
            ... on AddSubmittedPaperSuccess {
                participant {
                    _id
                }
            }
        }
    }
`;

function AddSubmittedPaperForm({
    venueId,
    season,
    level,
    tier,
    grade,
    onSuccess,
}) {
    const [addSubmittedPaper, { data, loading }] = useMutation(
        addSubmittedPaperMutation
    );
    const { register, handleSubmit, reset } = useForm();

    return (
        <Form
            onSubmit={handleSubmit(formData => {
                addSubmittedPaper({
                    variables: {
                        venueId,
                        season,
                        level,
                        tier,
                        participantData: {
                            ...formData,
                            grade: Number(formData.grade),
                        },
                    },
                }).then(({ data }) => {
                    if (
                        data?.addSubmittedPaper.__typename.endsWith('Success')
                    ) {
                        reset();
                        onSuccess(data.addSubmittedPaper.participant._id);
                    }
                });
            })}
        >
            <Form.Group controlId="add-submitted-paper-surname">
                <Form.Label>Фамилия</Form.Label>
                <Form.Control
                    className="mb-2"
                    defaultValue=""
                    {...register('surname', { required: true })}
                />
            </Form.Group>
            <Form.Group controlId="add-submitted-paper-name">
                <Form.Label>Имя</Form.Label>
                <Form.Control
                    className="mb-2"
                    defaultValue=""
                    {...register('name', { required: true })}
                />
            </Form.Group>

            <Form.Group controlId="add-submitted-paper-school">
                <Form.Label>Школа</Form.Label>
                <Form.Control
                    className="mb-2"
                    defaultValue=""
                    {...register('school', { required: true })}
                />
            </Form.Group>
            <Form.Group controlId="add-submitted-paper-grade">
                <Form.Label>Класс</Form.Label>
                <Form.Control
                    className="mb-2"
                    defaultValue={grade || ''}
                    {...register('grade', {
                        required: true,
                        pattern: /^(?:[1-9]|1[0-3])$/,
                    })}
                    type="number"
                />
            </Form.Group>
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="mb-2"
            >
                Добавить
            </SubmitButton>
        </Form>
    );
}
