import QRCode from 'react-qr-code';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { provideLayoutAndData } from '../Page';
import {
    AwardInfoPageQuery,
    AwardInfoPageQueryVariables,
} from 'GraphQL/types/AwardInfoPageQuery';

const awardInfoPageQuery = gql`
    query AwardInfoPageQuery($code: ID!) {
        awardInfo(code: $code) {
            code
            award
            bestResult
            maskedName
            grade
            school
            townName
            tournament {
                _id
                number
            }
        }
    }
`;

export default provideLayoutAndData<
    AwardInfoPageQuery,
    AwardInfoPageQueryVariables
>(awardInfoPageQuery, {
    variablesGetter: params => ({ code: params.code }),
    layout: 'narrow',
})(function AwardInfoPage({ data }) {
    const { t } = useTranslation();

    if (!data.awardInfo) {
        return <h1>Диплом с таким кодом не найден</h1>;
    }

    const {
        code,
        award,
        bestResult,
        maskedName,
        grade,
        school,
        townName,
        tournament: { number },
    } = data.awardInfo;

    return (
        <>
            <QRCode
                value={`${document.location.origin}/award/${code}`}
                size={128}
            />
            <br />
            <br />
            <h1>{maskedName}</h1>
            <p>
                {grade}-й класс, {school}, {townName}
            </p>
            <br />
            <h3>награждается</h3>
            <h3>
                {award === 'DIPLOMA'
                    ? 'Дипломом победителя Турнира'
                    : 'Премией Турнира городов'}
            </h3>
            <p>
                за {number + 1978} – {number + 1979} учебный год
            </p>
            <br />
            <p>
                Итоговый показатель —{' '}
                {t('common:count.points', { count: bestResult })}
            </p>
        </>
    );
});
