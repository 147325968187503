import React from 'react';
import { useParams } from 'react-router-dom';
import Page from './Page';
import { QuestionsList } from './QuestionsList';

export default function TournamentAppealsPage() {
    const params = useParams<{ number: string; round: string; tier: string }>();
    const number = Number(params.number);
    const [season, level] = params.round.split('-').map(s => s.toUpperCase());
    const tier = params.tier.toUpperCase();

    return (
        <Page>
            <QuestionsList
                number={number}
                season={season}
                level={level}
                tier={tier}
                type="APPEAL"
                paperMode="LINK"
            />
        </Page>
    );
}
