export function getSeasonAndLevel(params: {
    round: `${'fall' | 'spring'}-${'ordinary' | 'advanced'}`;
}): { season: 'FALL' | 'SPRING'; level: 'ORDINARY' | 'ADVANCED' } {
    let [season, level] = params.round.split('-').map(s => s.toUpperCase());

    return {
        season: season as 'FALL' | 'SPRING',
        level: level as 'ORDINARY' | 'ADVANCED',
    };
}

export function getTier(params: { tier: 'junior' | 'senior' }): {
    tier: 'JUNIOR' | 'SENIOR';
} {
    return { tier: params.tier.toUpperCase() as 'JUNIOR' | 'SENIOR' };
}

export function getGrade(params: { grade: string }): { grade: number } {
    return { grade: Number(params.grade) };
}

export function getOptionalGrade(params: { grade?: string }): {
    grade: number | null;
} {
    return { grade: params.grade ? Number(params.grade) : null };
}

export function getNumber(params: { number: string }): { number: number } {
    return { number: Number(params.number) };
}

export function getVenueId(params: { venueId: string }): { venueId: string } {
    return { venueId: params.venueId };
}

type ParamGetter = (params: {}) => {};

export function createParamsParser<G1 extends ParamGetter>(
    getter1: G1
): (params: Parameters<G1>[0]) => ReturnType<G1>;
export function createParamsParser<
    G1 extends ParamGetter,
    G2 extends ParamGetter
>(
    getter1: G1,
    getter2: G2
): (
    params: Parameters<G1>[0] & Parameters<G2>[0]
) => ReturnType<G1> & ReturnType<G2>;
export function createParamsParser<
    G1 extends ParamGetter,
    G2 extends ParamGetter,
    G3 extends ParamGetter
>(
    getter1: G1,
    getter2: G2,
    getter3: G3
): (
    params: Parameters<G1>[0] & Parameters<G2>[0] & Parameters<G3>[0]
) => ReturnType<G1> & ReturnType<G2> & ReturnType<G3>;
export function createParamsParser<
    G1 extends ParamGetter,
    G2 extends ParamGetter,
    G3 extends ParamGetter,
    G4 extends ParamGetter
>(
    getter1: G1,
    getter2: G2,
    getter3: G3,
    getter4: G4
): (
    params: Parameters<G1>[0] &
        Parameters<G2>[0] &
        Parameters<G3>[0] &
        Parameters<G4>[0]
) => ReturnType<G1> & ReturnType<G2> & ReturnType<G3> & ReturnType<G4>;
export function createParamsParser<
    G1 extends ParamGetter,
    G2 extends ParamGetter,
    G3 extends ParamGetter,
    G4 extends ParamGetter,
    G5 extends ParamGetter
>(
    getter1: G1,
    getter2: G2,
    getter3: G3,
    getter4: G4,
    getter5: G5
): (
    params: Parameters<G1>[0] &
        Parameters<G2>[0] &
        Parameters<G3>[0] &
        Parameters<G4>[0] &
        Parameters<G5>[0]
) => ReturnType<G1> &
    ReturnType<G2> &
    ReturnType<G3> &
    ReturnType<G4> &
    ReturnType<G5>;
export function createParamsParser(...getters) {
    return params =>
        getters.reduce(
            (result, getter) => ({ ...result, ...getter(params) }),
            {}
        );
}
