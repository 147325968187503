import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner } from 'react-bootstrap';
import ParticipantDataForm from '../ParticipantDataForm';

const inputterParticipantQuery = gql`
    query InputterParticipantQuery($participantId: ID!) {
        participant(participantId: $participantId) {
            _id
            email
            grade
            name
            surname
            phone
            townName
            school
            tournament {
                _id
                number
            }
        }
    }
`;

export default function InputterParticipantPage() {
    const { participantId } = useParams<{ participantId: string }>();
    const { data, loading } = useQuery(inputterParticipantQuery, {
        variables: { participantId },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        participant: { tournament, ...participant },
    } = data;

    return (
        <Page>
            <h3>
                {tournament.number}-й Турнир, {participant.name}{' '}
                {participant.surname}
            </h3>
            <ParticipantDataForm participant={participant} />
        </Page>
    );
}
