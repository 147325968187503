import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { provideLayoutAndData } from '../../Page';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    Row,
} from 'react-bootstrap';
import { SubmitButton } from 'Components/EnhancedButtons';
import { useForm } from 'react-hook-form';
import {
    AdminVenuePageQuery,
    AdminVenuePageQueryVariables,
} from '../../GraphQL/types/AdminVenuePageQuery';
import { VenueTopNavigation } from '../../TopNavigation';
import { VenueForm } from './VenueForm';

const adminVenuePageQuery = gql`
    query AdminVenuePageQuery(
        $venueId: ID!
        $venueApplicationId: ID
        $withVenueApplication: Boolean!
    ) {
        venue(id: $venueId) {
            _id
            townName
            townNameEn
            diplomaTownName
            organization
            organizationDescription
            email
            phone
            comment
            awardsPrizes
            juryPresident
            organizingCommitteePresident
            gradeNormalizationShift
            gradeChangesAtWinter
            seasons {
                fall {
                    levels
                    address
                    infoMessage
                    contacts {
                        email
                        phone
                        website
                        telegram
                        whatsapp
                        viber
                        fbMessenger
                    }
                    startTime
                    access
                    registrationOpen
                    accessComment
                    accessDescription
                    participantsNumberLimit
                    type
                }
                spring {
                    levels
                    address
                    infoMessage
                    contacts {
                        email
                        phone
                        website
                        telegram
                        whatsapp
                        viber
                        fbMessenger
                    }
                    startTime
                    access
                    registrationOpen
                    accessComment
                    accessDescription
                    participantsNumberLimit
                    type
                }
            }
            organizers {
                _id
                name
                email
            }
            tournament {
                _id
                number
                stage
                seasons {
                    fall {
                        stage
                        ordinary {
                            date
                            stage
                        }
                        advanced {
                            date
                            stage
                        }
                    }
                    spring {
                        stage
                        ordinary {
                            date
                            stage
                        }
                        advanced {
                            date
                            stage
                        }
                    }
                }
            }
            statistics {
                fall {
                    participantsCount
                    ordinary {
                        uploadedPapersCount
                        markedPapersCount
                        publishedPapersCount
                    }
                    advanced {
                        uploadedPapersCount
                        markedPapersCount
                        publishedPapersCount
                    }
                }
                spring {
                    participantsCount
                    ordinary {
                        uploadedPapersCount
                        markedPapersCount
                        publishedPapersCount
                    }
                    advanced {
                        uploadedPapersCount
                        markedPapersCount
                        publishedPapersCount
                    }
                }
            }
        }
        venueApplication(id: $venueApplicationId)
            @include(if: $withVenueApplication) {
            _id
            submittedAt
            stage
            townName
            season
            tournament {
                _id
                number
                seasons {
                    fall {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                    spring {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                }
            }
            levels
            organization
            organizationDescription
            email
            phone
            address
            infoMessage
            contacts {
                email
                phone
                website
                telegram
                whatsapp
                viber
                fbMessenger
            }
            startTime
            access
            accessComment
            accessDescription
            participantsNumberLimit
            comment
            venue {
                _id
                townName
            }
        }
    }
`;

export default provideLayoutAndData<
    AdminVenuePageQuery,
    AdminVenuePageQueryVariables
>(adminVenuePageQuery, {
    variablesGetter: ({ venueId, venueApplicationId }) => ({
        venueId,
        venueApplicationId,
        withVenueApplication: Boolean(venueApplicationId),
    }),
    NavComponent: ({
        params: { venueId },
        data: {
            venue: {
                townName,
                townNameEn,
                tournament: { number: tournamentNumber },
            },
        },
    }) => (
        <VenueTopNavigation
            venueId={venueId}
            townName={townName}
            townNameEn={townNameEn}
            tournamentNumber={tournamentNumber}
        />
    ),
})(function AdminVenuePage({ data }) {
    const {
        venue: { tournament, ...venue },
        venueApplication,
    } = data;

    const [
        updateVenueInfo,
        { data: updateData, error: updateError, loading: updateLoading },
    ] = useMutation(updateVenueInfoMutation);

    const [usersTabActive, setUsersTabActive] = useState(false);

    const [applicationApproved, setApplicationApproved] = useState(false);

    const springApplication = Boolean(venueApplication);

    return (
        <>
            <h2>{venue.townName}</h2>
            {venue.organization && <h5>{venue.organization}</h5>}
            {!springApplication && (
                <>
                    <VenueCommentForm venue={venue} />
                    <Row className="mt-4">
                        <Col md={6}>
                            <AdminVenueSeasonStats
                                tournamentNumber={tournament.number}
                                venue={venue}
                                season="FALL"
                            />
                        </Col>
                        <Col md={6}>
                            {['REGISTRATION', 'UPLOADING', 'FINISHED'].includes(
                                tournament.seasons.spring.stage
                            ) && (
                                <AdminVenueSeasonStats
                                    tournamentNumber={tournament.number}
                                    venue={venue}
                                    season="SPRING"
                                />
                            )}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={4}>
                            <h4>
                                <Link
                                    to={`/tournaments/${tournament.number}/venues/${venue._id}/papers/all`}
                                >
                                    Работы
                                </Link>
                            </h4>
                            <h6>Загрузить</h6>
                            <a
                                href={`/admin/${tournament.number}/venues/${venue._id}/upload/fall`}
                            >
                                Осень
                            </a>
                            <br />
                            <a
                                href={`/admin/${tournament.number}/venues/${venue._id}/upload/spring`}
                            >
                                Весна
                            </a>
                            <br />
                            <br />
                            <h6>Зарегистрировать по QR</h6>
                            <Link
                                to={`/tournaments/${tournament.number}/venues/${venue._id}/scan-qr/fall`}
                            >
                                Осень
                            </Link>
                            <br />
                            <Link
                                to={`/tournaments/${tournament.number}/venues/${venue._id}/scan-qr/spring`}
                            >
                                Весна
                            </Link>
                        </Col>
                        <Col md={4}>
                            <h4>
                                <Link
                                    to={`/tournaments/${tournament.number}/venues/${venue._id}/batches/all`}
                                >
                                    Пачки
                                </Link>
                            </h4>
                            <br />
                            <br />
                            <h4>
                                <Link
                                    to={`/tournaments/${tournament.number}/venues/${venue._id}/deduplicate-participants`}
                                >
                                    Убрать дубли
                                </Link>
                            </h4>
                        </Col>
                        <Col md={4}>
                            <h4>
                                <Link
                                    to={`/tournaments/${tournament.number}/venues/${venue._id}/results`}
                                >
                                    Результаты
                                </Link>
                            </h4>
                            <Link
                                to={`/tournaments/${tournament.number}/venues/${venue._id}/results-input`}
                            >
                                Вбить
                            </Link>
                            <br />
                            <Link
                                to={`/tournaments/${tournament.number}/venues/${venue._id}/schools`}
                            >
                                Причесать школы
                            </Link>
                            <br />
                            <br />
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="RU"
                                format="PDF"
                            />{' '}
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="RU"
                                format="TEX"
                            />{' '}
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="RU"
                                format="HTML"
                                withGrades={true}
                            />
                            <br />
                            <br />
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="EN"
                                format="PDF"
                            />{' '}
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="EN"
                                format="TEX"
                            />{' '}
                            <DownloadVenueReportButton
                                venueId={venue._id}
                                language="EN"
                                format="HTML"
                                withGrades={true}
                            />
                            <br />
                            <br />
                            <DownloadTournamentResultsButton
                                tournamentId={tournament._id}
                                venueId={venue._id}
                            >
                                results.xlsx
                            </DownloadTournamentResultsButton>
                            <br />
                            <br />
                            <DownloadVenueAwardsButton
                                venueId={venue._id}
                                format="PDF"
                                awardType="DIPLOMA"
                            />{' '}
                            <DownloadVenueAwardsButton
                                venueId={venue._id}
                                format="TEX"
                                awardType="DIPLOMA"
                            />
                            {venue.awardsPrizes && (
                                <>
                                    <br />
                                    <br />
                                    <DownloadVenueAwardsButton
                                        venueId={venue._id}
                                        format="PDF"
                                        awardType="PRIZE"
                                    />{' '}
                                    <DownloadVenueAwardsButton
                                        venueId={venue._id}
                                        format="TEX"
                                        awardType="PRIZE"
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                </>
            )}
            {venueApplication && (
                <>
                    Заявка от{' '}
                    <a href={`mailto:${venueApplication.email}`}>
                        {venueApplication.email}
                    </a>
                    , подана{' '}
                    <Trans
                        i18nKey="AdminHomePage.VenueApplicationSubmittedAt"
                        values={{
                            submittedAt: new Date(venueApplication.submittedAt),
                        }}
                    />
                    <br />
                    {venueApplication.comment?.trim() && (
                        <div className="text-pre-line p-4 bg-light mt-3">
                            <strong>
                                Комментарии, предложения, пожелания, вопросы
                            </strong>
                            <br />
                            {venueApplication.comment}
                            <br />
                        </div>
                    )}
                    <br />
                </>
            )}
            <VenueForm
                tournament={tournament}
                venue={venue}
                venueApplication={venueApplication}
                scrollToSpring={springApplication}
                submitButtonText="Сохранить"
                showSubmitOnEveryTab={true}
                loading={updateLoading}
                mutationData={updateData}
                mutationError={updateError}
                onSubmit={async ({ venueInfo, fallInfo, springInfo }) => {
                    await updateVenueInfo({
                        variables: {
                            venueId: venue._id,
                            venueApplicationId: venueApplication?._id,
                            venueInfo,
                            fallInfo,
                            springInfo,
                        },
                    });

                    if (venueApplication) {
                        setApplicationApproved(true);
                        setTimeout(
                            () =>
                                (window.location.href = `/admin/${tournament.number}/venues/${venue._id}`),
                            5000
                        );
                    }
                }}
                customTabName="Пользователи"
                onSwitchCustomTab={active => setUsersTabActive(active)}
            />
            <div className="tab-content">
                <div
                    role="tabpanel"
                    aria-hidden={!usersTabActive}
                    className={
                        'fade tab-pane' + (usersTabActive ? ' active show' : '')
                    }
                >
                    <br />
                    {venue.organizers.length === 0 &&
                        'Пока нет пользователей-организаторов'}
                    {venue.organizers.length > 0 &&
                        venue.organizers
                            .map(org =>
                                org.name
                                    ? `${org.name} <${org.email}>`
                                    : org.email
                            )
                            .join(', ')}
                    <br />
                    <br />
                    <AddOrganizerToVenueForm venue={venue} />
                    <br />
                </div>
            </div>
            <br />
            {applicationApproved && (
                <>
                    <Alert variant="success">
                        Заявка успешно принята. Перенаправление...
                    </Alert>
                    <Link
                        to={`/tournaments/${
                            tournament.number
                        }/venue-applications/${venueApplication?.season.toLowerCase()}`}
                    >
                        Вернуться к списку заявок
                    </Link>
                </>
            )}
            <br />
        </>
    );
});

function AdminVenueSeasonStats({ tournamentNumber, season, venue }) {
    const { t } = useTranslation();
    const seasonKey = season.toLowerCase();
    const { statistics } = venue;
    const stats = statistics[seasonKey];

    return (
        <>
            <h5>
                <Link
                    to={`/tournaments/${tournamentNumber}/venues/${venue._id}/participants/${seasonKey}`}
                >
                    {t('common:SeasonNoun', { context: season })} —{' '}
                    {t('common:count.participants', {
                        count: stats.participantsCount,
                    })}
                </Link>
            </h5>
            <Row>
                <Col xs={6}>
                    <h6>Базовый</h6>
                    <RoundStatistics
                        venueId={venue._id}
                        statistics={statistics}
                        tournamentNumber={tournamentNumber}
                        season={season}
                        level="ORDINARY"
                    />
                    <br />
                </Col>
                <Col xs={6}>
                    <h6>Сложный</h6>
                    <RoundStatistics
                        venueId={venue._id}
                        statistics={statistics}
                        tournamentNumber={tournamentNumber}
                        season={season}
                        level="ADVANCED"
                    />
                    <br />
                </Col>
            </Row>
        </>
    );
}

const publishVenueResultsMutation = gql`
    mutation PublishVenueResults(
        $venueId: ID!
        $season: Season!
        $level: Level!
    ) {
        publishVenueResults(venueId: $venueId, season: $season, level: $level) {
            __typename
            ... on PublishVenueResultsSuccess {
                venue {
                    _id
                    statistics {
                        fall {
                            participantsCount
                            ordinary {
                                uploadedPapersCount
                                markedPapersCount
                                publishedPapersCount
                            }
                            advanced {
                                uploadedPapersCount
                                markedPapersCount
                                publishedPapersCount
                            }
                        }
                        spring {
                            participantsCount
                            ordinary {
                                uploadedPapersCount
                                markedPapersCount
                                publishedPapersCount
                            }
                            advanced {
                                uploadedPapersCount
                                markedPapersCount
                                publishedPapersCount
                            }
                        }
                    }
                }
            }
        }
    }
`;

function RoundStatistics({
    tournamentNumber,
    venueId,
    statistics,
    season,
    level,
}) {
    const [publishVenueResults, { data, loading }] = useMutation(
        publishVenueResultsMutation
    );
    const { t } = useTranslation();

    const { uploadedPapersCount, markedPapersCount, publishedPapersCount } =
        statistics[season.toLowerCase()][level.toLowerCase()];

    return (
        <>
            <Link
                to={`/tournaments/${tournamentNumber}/venues/${venueId}/papers/${season.toLowerCase()}-${level.toLowerCase()}`}
            >
                {t('common:count.papers', { count: uploadedPapersCount })}
            </Link>
            <br />
            {t('common:count.marked', { count: markedPapersCount })}
            <br />
            {t('common:count.published', { count: publishedPapersCount })}
            <br />
            {markedPapersCount > 0 &&
            publishedPapersCount < markedPapersCount ? (
                <SubmitButton
                    className="mt-3"
                    variant="outline-primary"
                    loading={loading}
                    mutationData={data}
                    onClick={() =>
                        publishVenueResults({
                            variables: { venueId, season, level },
                        })
                    }
                >
                    Опубликовать
                </SubmitButton>
            ) : null}
            <br />
        </>
    );
}

const updateVenueInfoMutation = gql`
    mutation UpdateVenueInfo(
        $venueId: ID!
        $venueApplicationId: ID
        $venueInfo: VenueInfoInput
        $fallInfo: VenueSeasonInfoInput
        $springInfo: VenueSeasonInfoInput
    ) {
        updateVenueInfo(
            venueId: $venueId
            venueApplicationId: $venueApplicationId
            venueInfo: $venueInfo
            fallInfo: $fallInfo
            springInfo: $springInfo
        ) {
            __typename
            ... on UpdateVenueInfoSuccess {
                venue {
                    _id
                    townName
                    townNameEn
                    diplomaTownName
                    organization
                    organizationDescription
                    email
                    phone
                    comment
                    awardsPrizes
                    juryPresident
                    organizingCommitteePresident
                    gradeNormalizationShift
                    gradeChangesAtWinter
                    seasons {
                        fall {
                            levels
                            address
                            infoMessage
                            contacts {
                                email
                                phone
                                website
                                telegram
                                whatsapp
                                viber
                                fbMessenger
                            }
                            startTime
                            access
                            registrationOpen
                            accessComment
                            accessDescription
                            participantsNumberLimit
                            type
                        }
                        spring {
                            levels
                            address
                            infoMessage
                            contacts {
                                email
                                phone
                                website
                                telegram
                                whatsapp
                                viber
                                fbMessenger
                            }
                            startTime
                            access
                            registrationOpen
                            accessComment
                            accessDescription
                            participantsNumberLimit
                            type
                        }
                    }
                }
            }
        }
    }
`;

function VenueCommentForm({ venue }) {
    const { register, handleSubmit } = useForm();
    const [updateVenueInfo, { loading, data }] = useMutation(
        updateVenueInfoMutation
    );

    return (
        <Form
            onSubmit={handleSubmit(({ comment }) => {
                updateVenueInfo({
                    variables: {
                        venueId: venue._id,
                        venueInfo: { comment },
                    },
                });
            })}
            className="mt-4"
        >
            <Form.Group controlId="venue-page-comment">
                <Form.Control
                    as="textarea"
                    rows={4}
                    {...register('comment')}
                    placeholder="Здесь вы можете оставить общий комментарий"
                    defaultValue={venue.comment || ''}
                />
            </Form.Group>
            <SubmitButton
                loading={loading}
                mutationData={data}
                variant="outline-primary"
            >
                Обновить комментарий
            </SubmitButton>
        </Form>
    );
}

const addOrganizerToVenueMutation = gql`
    mutation AddOrganizerToVenue(
        $venueId: ID!
        $email: String!
        $name: String!
        $language: UserLanguage!
    ) {
        addOrganizerToVenue(
            venueId: $venueId
            email: $email
            name: $name
            language: $language
        ) {
            __typename
            ... on AddOrganizerToVenueSuccess {
                venue {
                    _id
                    organizers {
                        _id
                        email
                        name
                    }
                }
            }
        }
    }
`;

function AddOrganizerToVenueForm({ venue }) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm();
    const [addOrganizerToVenue, { data, error, loading }] = useMutation(
        addOrganizerToVenueMutation
    );

    let errorMessage = null;

    if (
        error ||
        ![
            'AddOrganizerToVenueSuccess',
            'EmailDoesNotBelongToAnOrganizerError',
            undefined,
        ].includes(data?.addOrganizerToVenue.__typename)
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (
        data?.addOrganizerToVenue.__typename ===
        'EmailDoesNotBelongToAnOrganizerError'
    ) {
        errorMessage = 'Email принадлежит пользователю-не-организатору';
    }

    return (
        <>
            <Form
                onSubmit={handleSubmit(({ email, name }) => {
                    addOrganizerToVenue({
                        variables: {
                            venueId: venue._id,
                            language: 'RU',
                            email,
                            name,
                        },
                    });
                })}
            >
                <Form.Group as={Row} controlId="add-organizer-to-venue-name">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Имя
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('name', {
                                required: true,
                            })}
                            defaultValue=""
                        />
                        {formErrors.name && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                            >
                                Укажите имя
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="add-organizer-to-venue-email">
                    <Form.Label column={true} md={2} className="text-md-right">
                        Email
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            type="text"
                            {...register('email', {
                                required: true,
                                pattern: /^.+@.+$/,
                            })}
                            defaultValue=""
                        />
                        {formErrors.email?.type === 'required' && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                            >
                                Укажите email
                            </Form.Control.Feedback>
                        )}
                        {formErrors.email?.type === 'pattern' && (
                            <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                            >
                                Неправильный формат email
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Form.Group>
                <SubmitButton
                    loading={loading}
                    mutationData={data}
                    className="mt-2"
                >
                    Добавить организатора
                </SubmitButton>
            </Form>
            {errorMessage && (
                <Alert variant="warning" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
        </>
    );
}

const exportVenueResultsMutation = gql`
    mutation ExportVenueResults(
        $venueId: ID!
        $language: ReportLanguage
        $format: ReportFormat
        $grades: [Int!]
    ) {
        exportVenueResults(
            venueId: $venueId
            language: $language
            format: $format
            grades: $grades
        ) {
            __typename
            ... on ExportVenueResultsSuccess {
                url
            }
        }
    }
`;

function DownloadVenueReportButton({
    venueId,
    language,
    format,
    withGrades = false,
}) {
    const [exportVenueResults] = useMutation(exportVenueResultsMutation);

    const download = useCallback(
        (grades?: number[]) =>
            exportVenueResults({
                variables: {
                    venueId,
                    language,
                    format,
                    grades,
                },
            }).then(({ data }) => {
                if (
                    data?.exportVenueResults.__typename ===
                    'ExportVenueResultsSuccess'
                ) {
                    window.location.href = `${process.env.REACT_APP_BACKEND_URL}${data.exportVenueResults.url}`;
                }
            }),
        [venueId, format, language, exportVenueResults]
    );

    if (withGrades) {
        return (
            <Dropdown as={ButtonGroup}>
                <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => download()}
                >
                    {language.toLowerCase()}.{format.toLowerCase()}
                </Button>

                <Dropdown.Toggle
                    split
                    size="sm"
                    variant="outline-primary"
                    id={`download-results-${venueId}-${language}-${format}`}
                />

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => download([1, 2, 3, 4, 5, 6])}>
                        1-6 класс
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => download([7])}>
                        7 класс
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => download([8])}>
                        8 класс
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => download([9])}>
                        9 класс
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => download([10])}>
                        10 класс
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => download([11, 12, 13])}>
                        11-13 класс
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Button size="sm" variant="outline-primary" onClick={() => download()}>
            {language.toLowerCase()}.{format.toLowerCase()}
        </Button>
    );
}

const exportVenueAwardsMutation = gql`
    mutation ExportVenueAwards(
        $venueId: ID!
        $format: AwardExportFormat
        $awardType: Award!
    ) {
        exportVenueAwards(
            venueId: $venueId
            format: $format
            awardType: $awardType
        ) {
            __typename
            ... on ExportAwardsSuccess {
                url
            }
        }
    }
`;

function DownloadVenueAwardsButton({ venueId, format, awardType }) {
    const [exportVenueAwards] = useMutation(exportVenueAwardsMutation);

    const download = useCallback(
        () =>
            exportVenueAwards({
                variables: {
                    venueId,
                    format,
                    awardType,
                },
            }).then(({ data }) => {
                if (
                    data?.exportVenueAwards.__typename === 'ExportAwardsSuccess'
                ) {
                    window.location.href = `${process.env.REACT_APP_BACKEND_URL}${data.exportVenueAwards.url}`;
                }
            }),
        [venueId, format, awardType, exportVenueAwards]
    );

    return (
        <Button size="sm" variant="outline-primary" onClick={download}>
            {awardType === 'DIPLOMA' ? 'diplomas' : 'prizes'}.
            {format.toLowerCase()}
        </Button>
    );
}

const downloadTournamentResultsMutation = gql`
    mutation DownloadTournamentResults($tournamentId: ID!, $venueId: ID) {
        generateTournamentResultsSpreadsheet(
            tournamentId: $tournamentId
            venueId: $venueId
        ) {
            __typename
            ... on GenerateTournamentResultsSpreadsheetSuccess {
                url
            }
        }
    }
`;

export function DownloadTournamentResultsButton({
    tournamentId,
    venueId,
    children,
}: {
    tournamentId: string;
    venueId?: string;
    children?: any;
}) {
    const [downloadTournamentResults] = useMutation(
        downloadTournamentResultsMutation
    );

    const download = useCallback(
        () =>
            downloadTournamentResults({
                variables: {
                    tournamentId,
                    venueId,
                },
            }).then(({ data }) => {
                if (
                    data?.generateTournamentResultsSpreadsheet.__typename ===
                    'GenerateTournamentResultsSpreadsheetSuccess'
                ) {
                    window.location.href = `${process.env.REACT_APP_BACKEND_URL}${data.generateTournamentResultsSpreadsheet.url}`;
                }
            }),
        [tournamentId, venueId, downloadTournamentResults]
    );

    return (
        <Button size="sm" variant="outline-primary" onClick={download}>
            {children ? children : 'Скачать xlsx'}
        </Button>
    );
}
