import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Spinner } from 'react-bootstrap';
import { provideLayoutAndData } from '../../Page';
import {
    AdminAddVenuePageQuery,
    AdminAddVenuePageQueryVariables,
} from '../../GraphQL/types/AdminAddVenuePageQuery';
import { Link, useParams } from 'react-router-dom';
import { VenueForm } from './VenueForm';

const adminAddVenuePageQuery = gql`
    query AdminAddVenuePageQuery($number: Int!) {
        tournament(number: $number) {
            _id
            stage
            number
            seasons {
                fall {
                    ordinary {
                        date
                    }
                    advanced {
                        date
                    }
                }
                spring {
                    ordinary {
                        date
                    }
                    advanced {
                        date
                    }
                }
            }
        }
    }
`;

const adminAddVenueByApplicationQuery = gql`
    query AdminAddVenueByApplicationQuery($venueApplicationId: ID!) {
        venueApplication(id: $venueApplicationId) {
            _id
            submittedAt
            stage
            townName
            season
            tournament {
                _id
                number
                stage
                seasons {
                    fall {
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                    spring {
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                }
            }
            levels
            organization
            organizationDescription
            email
            phone
            address
            infoMessage
            contacts {
                email
                phone
                website
                telegram
                whatsapp
                viber
                fbMessenger
            }
            startTime
            access
            accessComment
            accessDescription
            participantsNumberLimit
            comment
            venue {
                _id
                townName
            }
        }
    }
`;

const createVenueMutation = gql`
    mutation CreateVenue(
        $tournamentId: ID!
        $venueInfo: VenueInfoInput!
        $fallInfo: VenueSeasonInfoInput
        $springInfo: VenueSeasonInfoInput
        $venueApplicationId: ID
    ) {
        createVenue(
            tournamentId: $tournamentId
            venueInfo: $venueInfo
            fallInfo: $fallInfo
            springInfo: $springInfo
            venueApplicationId: $venueApplicationId
        ) {
            __typename
            ... on CreateVenueSuccess {
                venue {
                    _id
                    townName
                }
            }
        }
    }
`;

export default provideLayoutAndData<
    AdminAddVenuePageQuery,
    AdminAddVenuePageQueryVariables
>(adminAddVenuePageQuery, {
    variablesGetter: params => ({ number: Number(params.number) }),
    authenticatedOnly: true,
})(function AdminAddVenuePage({ data: { tournament } }) {
    const { t } = useTranslation();
    const { venueApplicationId } = useParams<{ venueApplicationId?: string }>();

    const [formCompleted, setFormCompleted] = useState(false);

    const {
        data: { venueApplication } = {},
        loading: venueApplicationLoading,
    } = useQuery(adminAddVenueByApplicationQuery, {
        variables: { venueApplicationId },
        skip: !venueApplicationId,
    });

    const [createVenue, { data, error, loading }] = useMutation(
        createVenueMutation,
        {
            refetchQueries: venueApplicationId
                ? ['AdminVenueApplicationsPageQuery']
                : undefined,
        }
    );

    if (venueApplicationLoading) {
        return (
            <div className="text-center">
                <Spinner animation="border">
                    <span className="sr-only">{t('Loading')}</span>
                </Spinner>
            </div>
        );
    }

    const alreadyProcessed =
        venueApplication && venueApplication.stage !== 'CREATED';

    return (
        <>
            <h2>Добавить место проведения</h2>
            {venueApplication && (
                <>
                    Заявка от{' '}
                    <a href={`mailto:${venueApplication.email}`}>
                        {venueApplication.email}
                    </a>
                    , подана{' '}
                    <Trans
                        i18nKey="AdminHomePage.VenueApplicationSubmittedAt"
                        values={{
                            submittedAt: new Date(venueApplication.submittedAt),
                        }}
                    />
                    <br />
                    {venueApplication.comment?.trim() && (
                        <div className="text-pre-line p-4 bg-light mt-3">
                            <strong>
                                Комментарии, предложения, пожелания, вопросы
                            </strong>
                            <br />
                            {venueApplication.comment}
                            <br />
                        </div>
                    )}
                </>
            )}
            <br />
            {venueApplication?.stage === 'REJECTED' && (
                <>
                    <Alert variant="danger">Данная заявка отклонена.</Alert>
                    <br />
                </>
            )}
            {venueApplication?.stage === 'APPROVED' && (
                <>
                    <Alert variant="success">
                        Заявка уже одобрена. Место проведения:{' '}
                        <a
                            href={`/admin/${tournament.number}/venues/${venueApplication.venue._id}`}
                        >
                            {venueApplication.venue.townName}
                        </a>
                    </Alert>
                    <br />
                </>
            )}
            {!formCompleted && !alreadyProcessed && (
                <VenueForm
                    tournament={tournament}
                    venueApplication={venueApplication}
                    submitButtonText={'Добавить точку'}
                    showCommentTab={true}
                    mutationData={data}
                    mutationError={error}
                    loading={loading}
                    onSubmit={({ venueInfo, fallInfo, springInfo }) => {
                        createVenue({
                            variables: {
                                tournamentId: tournament._id,
                                venueInfo,
                                fallInfo,
                                springInfo,
                                venueApplicationId,
                            },
                        }).then(({ data }) => {
                            if (
                                data.createVenue.__typename ===
                                'CreateVenueSuccess'
                            ) {
                                setFormCompleted(true);
                            }
                        });
                    }}
                />
            )}
            {formCompleted && (
                <>
                    <Alert variant="success">
                        Место проведения успешно создано:{' '}
                        <a
                            href={`/admin/${tournament.number}/venues/${data.createVenue.venue._id}`}
                        >
                            {data.createVenue.venue.townName}
                        </a>
                    </Alert>
                    <br />
                    {venueApplication && (
                        <Link
                            to={`/tournaments/${
                                tournament.number
                            }/venue-applications/${venueApplication.season.toLowerCase()}`}
                        >
                            Вернуться к списку заявок
                        </Link>
                    )}
                </>
            )}
            <br />
            <br />
            <br />
        </>
    );
});
