import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import React from 'react';
import { InputterTournamentView } from '../Inputter/InputterTournamentPage';

const juryTournamentResultsInputPageQuery = gql`
    query JuryTournamentResultsInputPageQuery($number: Int!) {
        tournament(number: $number) {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
            }
            venues {
                _id
                townName
                organization
            }
        }
    }
`;

export default function JuryTournamentResultsInputPage() {
    const params = useParams<{ number: string }>();
    const number = Number(params.number);
    const { data, loading } = useQuery(juryTournamentResultsInputPageQuery, {
        variables: { number },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { tournament } = data;

    return (
        <Page>
            <InputterTournamentView tournament={tournament} />
        </Page>
    );
}
