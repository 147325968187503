import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { provideLayoutAndData } from '../Page';
import { Button, Col, Collapse, Modal, Pagination, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OnlineRoundUploadZone from './OnlineRoundUploadZone';
import { formatDuration, intervalToDuration } from 'date-fns';
import dateFnsRu from 'date-fns/locale/ru';
import PaperUploadInstructions from './PaperUploadInstructions';
import { SpinnerButton } from 'Components/EnhancedButtons';
import { ProblemView } from '../Components/Problem';
import ParticipantQuestionModal from './ParticipantQuestionModal';
import { Redirect, useParams } from 'react-router-dom';
import { OnlineRoundPageQuery } from '../GraphQL/types/OnlineRoundPageQuery';

const onlineRoundPageQuery = gql`
    query OnlineRoundPageQuery {
        viewer {
            _id
            participants {
                _id
                townName
                papers {
                    _id
                    stage
                    season
                    level
                    tier
                    card
                    onlineDeadline
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                    }
                }
            }
        }
        currentTournament {
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                        junior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                        senior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                    }
                    advanced {
                        date
                        stage
                        junior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                        senior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                        junior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                        senior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                    }
                    advanced {
                        date
                        stage
                        junior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                        senior {
                            files {
                                file
                                language
                            }
                            problems {
                                text
                                image
                                author
                                subproblems {
                                    text
                                    points
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const addPageToOnlinePaperMutation = gql`
    mutation AddPageToOnlinePaper(
        $paperId: ID!
        $fileData: String!
        $fileName: String!
        $problemNumber: Int!
    ) {
        addPageToOnlinePaper(
            paperId: $paperId
            fileData: $fileData
            fileName: $fileName
            problemNumber: $problemNumber
        ) {
            __typename
            ... on UnknownFileFormat {
                detectedExt
                detectedMime
            }
            ... on AddPageToOnlinePaperSuccess {
                paper {
                    _id
                    stage
                    season
                    level
                    tier
                    onlineDeadline
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                    }
                }
            }
        }
    }
`;

const removePageFromOnlinePaperMutation = gql`
    mutation RemovePageFromOnlinePaper($paperId: ID!, $pageId: ID!) {
        removePageFromOnlinePaper(paperId: $paperId, pageId: $pageId) {
            __typename
            ... on RemovePageFromOnlinePaperSuccess {
                paper {
                    _id
                    stage
                    season
                    level
                    tier
                    onlineDeadline
                    pages {
                        _id
                        file
                        fileName
                        problemNumber
                    }
                }
            }
        }
    }
`;

const additionalLocaleNames = {
    KK: 'на казахском',
    UK: 'на украинском',
};

export default provideLayoutAndData<OnlineRoundPageQuery>(onlineRoundPageQuery)(
    function OnlineRoundPage({ data }) {
        const { t } = useTranslation();
        const { paperId } = useParams<{ paperId: string }>();
        const [problemNumber, setProblemNumber] = useState(0);
        const [uploadInstructionsOpen, setUploadInstructionsOpen] =
            useState(false);
        const [questionModalOpen, setQuestionModalOpen] = useState(false);

        const [addPageToOnlinePaper] = useMutation(
            addPageToOnlinePaperMutation
        );
        const [removePageFromOnlinePaper] = useMutation(
            removePageFromOnlinePaperMutation
        );

        const bringAttention = useCallback(
            () => setQuestionModalOpen(true),
            []
        );

        const participant = data.viewer.participants.find(p =>
            p.papers?.some(paper => paper._id === paperId)
        );
        const paper = participant.papers.find(p => p._id === paperId);

        let additionalLocale = null;

        if (
            /Актобе|Астана|Нур-Султан|Алматы|Алма-Ата|Алма-Аты|Казахстан|Караганда|Костанай|Павлодар/i.test(
                participant.townName
            )
        ) {
            additionalLocale = 'KK';
        }

        if (
            /Петропавловск/i.test(participant.townName) &&
            !/Камчатский/i.test(participant.townName)
        ) {
            additionalLocale = 'KK';
        }

        if (
            /Киев|Харьков|Евпатория|Кривой Рог|Симферополь/i.test(
                participant.townName
            )
        ) {
            additionalLocale = 'UK';
        }

        const { problems, files } =
            data.currentTournament.seasons[paper.season.toLowerCase()][
                paper.level.toLowerCase()
            ][paper.tier.toLowerCase()];

        if (paper.stage !== 'ONLINE_STARTED') {
            return <Redirect to="/" />;
        }

        return (
            <>
                <h3>
                    {paper.season === 'FALL'
                        ? t('OnlineRoundPage.Fall')
                        : t('OnlineRoundPage.Spring')}{' '}
                    {paper.level === 'ORDINARY'
                        ? t('OnlineRoundPage.ordinary')
                        : t('OnlineRoundPage.advanced')}{' '}
                    тур,{' '}
                    {paper.tier === 'JUNIOR'
                        ? t('OnlineRoundPage.junior')
                        : t('OnlineRoundPage.senior')}{' '}
                    вариант
                </h3>
                <p>
                    Вы можете решать задачи в любом порядке, возвращаться к
                    предыдущим задачам.
                    <br />
                    Итог подводится по трём задачам, по которым достигнуты
                    наилучшие результаты, баллы за пункты одной задачи
                    суммируются.
                    {files?.length > 0 && (
                        <>
                            <br />
                            <br />
                            Чтобы не сидеть всё время Турнира перед экраном, вы
                            можете скачать и распечатать{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_BACKEND_URL}${
                                    files.find(f => f.language === 'RU').file
                                }`}
                                download={`${paper.season.toLowerCase()}-${paper.level.toLowerCase()}-${paper.tier.toLowerCase()}-${paper.card.toString(
                                    36
                                )}.${files
                                    .find(f => f.language === 'RU')
                                    .file.split('.')
                                    .pop()}`}
                            >
                                условия задач
                            </a>
                            {additionalLocale &&
                                files.find(
                                    f => f.language === additionalLocale
                                ) && (
                                    <>
                                        {' '}
                                        (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${
                                                process.env
                                                    .REACT_APP_BACKEND_URL
                                            }${
                                                files.find(
                                                    f =>
                                                        f.language ===
                                                        additionalLocale
                                                ).file
                                            }`}
                                            download={`${paper.season.toLowerCase()}-${paper.level.toLowerCase()}-${paper.tier.toLowerCase()}-${additionalLocale.toLowerCase()}-${paper.card.toString(
                                                36
                                            )}.${files
                                                .find(
                                                    f =>
                                                        f.language ===
                                                        additionalLocale
                                                )
                                                .file.split('.')
                                                .pop()}`}
                                        >
                                            {
                                                additionalLocaleNames[
                                                    additionalLocale
                                                ]
                                            }
                                        </a>
                                        )
                                    </>
                                )}
                            .
                        </>
                    )}
                </p>
                <CountDown
                    deadline={paper.onlineDeadline}
                    paperId={paper._id}
                />
                <Row className="align-items-center">
                    <Col md={9} sm={12}>
                        <Pagination
                            size={window.innerWidth >= 768 ? 'lg' : undefined}
                        >
                            {problems.map((problem, i) => (
                                <Pagination.Item
                                    key={i}
                                    active={problemNumber === i}
                                    onClick={() => setProblemNumber(i)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Col>
                    <Col md={3} sm={12} className="text-md-right">
                        <Button
                            variant="outline-primary"
                            className="mb-4"
                            onClick={() => setQuestionModalOpen(true)}
                        >
                            Задать вопрос
                        </Button>
                        <ParticipantQuestionModal
                            modalTitle="Вопросы по условиям задач"
                            open={questionModalOpen}
                            onHide={() => setQuestionModalOpen(false)}
                            bringAttention={bringAttention}
                            participantId={participant._id}
                            season={paper.season}
                            level={paper.level}
                            tier={paper.tier}
                            type="LIVE"
                            problemsCount={problems.length}
                        />
                    </Col>
                </Row>
                <br />
                <ProblemView
                    problem={problems[problemNumber]}
                    problemNumber={problemNumber}
                />
                <br />
                <span
                    className="pseudo-link"
                    onClick={() => {
                        setUploadInstructionsOpen(open => !open);
                    }}
                >
                    {t('OnlineTestPaper.HowToUpload')}
                </span>
                <Collapse in={uploadInstructionsOpen}>
                    <div>
                        <PaperUploadInstructions />
                    </div>
                </Collapse>
                <br />
                <br />
                <h5>Ваше решение задачи {problemNumber + 1}</h5>
                <OnlineRoundUploadZone
                    pages={(paper.pages || []).filter(
                        page => page.problemNumber === problemNumber
                    )}
                    upload={(fileData, fileName) => {
                        return addPageToOnlinePaper({
                            variables: {
                                fileData,
                                paperId: paper._id,
                                fileName,
                                problemNumber,
                            },
                        });
                    }}
                    onRemoveClick={pageId =>
                        removePageFromOnlinePaper({
                            variables: {
                                paperId: paper._id,
                                pageId,
                            },
                        })
                    }
                />
            </>
        );
    }
);

function CountDown({ deadline, paperId }) {
    const [clockSkew, setClockSkew] = useState(0);
    const [duration, setDuration] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(true);
    const [showFinishEarlyModal, setShowFinishEarlyModal] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/current-time`)
            .then(res => res.json())
            .then(res => {
                setClockSkew(Date.now() - res.timestamp);
            });
    }, []);

    useEffect(() => {
        setDuration(
            intervalToDuration({
                start: Date.now() - clockSkew,
                end: new Date(deadline).getTime(),
            })
        );

        setTimeLeft(new Date(deadline).getTime() - (Date.now() - clockSkew));

        const interval = setInterval(() => {
            setDuration(
                intervalToDuration({
                    start: Date.now() - clockSkew,
                    end: new Date(deadline).getTime(),
                })
            );
            setTimeLeft(
                new Date(deadline).getTime() - (Date.now() - clockSkew)
            );
        }, 15 * 1000);

        return () => clearInterval(interval);
    }, [deadline, clockSkew]);

    const formattedDuration =
        duration !== null &&
        (timeLeft !== null && timeLeft < 60
            ? 'меньше минуты'
            : formatDuration(duration, {
                  format: ['hours', 'minutes'],
                  locale: dateFnsRu,
              }));

    return (
        <p>
            Осталось: {formattedDuration}, до{' '}
            <strong>
                {new Date(new Date(deadline).getTime() + clockSkew)
                    .toTimeString()
                    .substring(0, 5)}
            </strong>
            <Button
                size="sm"
                variant="outline-dark"
                className="ml-2"
                onClick={() => setShowFinishEarlyModal(true)}
            >
                Закончить раньше
            </Button>
            <FinishEarlyModal
                show={showFinishEarlyModal}
                onHide={() => setShowFinishEarlyModal(false)}
                formattedDuration={formattedDuration}
                paperId={paperId}
            />
            <WarningModal
                show={
                    timeLeft !== null &&
                    timeLeft <= 30 * 60 * 1000 &&
                    timeLeft > 0 &&
                    showWarningModal
                }
                onHide={() => setShowWarningModal(false)}
            />
            <EndedModal show={timeLeft !== null && timeLeft < 0} />
        </p>
    );
}

const finishOnlineParticipationMutation = gql`
    mutation FinishOnlineParticipation($paperId: ID!) {
        finishOnlineParticipation(paperId: $paperId) {
            __typename
            ... on FinishOnlineParticipationSuccess {
                paper {
                    _id
                    stage
                }
            }
        }
    }
`;

function FinishEarlyModal({ formattedDuration, show, onHide, paperId }) {
    const [finishOnlineParticipation, { loading }] = useMutation(
        finishOnlineParticipationMutation
    );

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="finish-early-modal"
            centered={true}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title id="finish-early-modal">
                    Закончить раньше
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>У вас ещё осталось время.</p>
                {formattedDuration && (
                    <p>
                        Работа будет сдана автоматически через{' '}
                        {formattedDuration}.
                    </p>
                )}
                <p>
                    Если вы закончите раньше, вы не сможете вернуться к решению
                    задач и загрузке решений.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="primary">
                    Вернуться к задачам
                </Button>
                <SpinnerButton
                    loading={loading}
                    onClick={() => {
                        finishOnlineParticipation({
                            variables: {
                                paperId,
                            },
                        });
                    }}
                    variant="danger"
                >
                    Закончить
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}

function WarningModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="warning-modal"
            centered={true}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title id="warning-modal">
                    У вас осталось меньше 30 минут
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Загрузите все страницы своей работы, которые вы ещё не
                    успели загрузить.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Хорошо</Button>
            </Modal.Footer>
        </Modal>
    );
}

function EndedModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="ended-modal"
            centered={true}
        >
            <Modal.Header>
                <Modal.Title id="ended-modal">Ваша работа принята</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Время закончилось.</p>
            </Modal.Body>
        </Modal>
    );
}
