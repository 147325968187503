import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/types';
import { Check } from 'react-bootstrap-icons';
import { useMutation } from '@apollo/client';
import {
    ApolloCache,
    DefaultContext,
    OperationVariables,
} from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client/react/types/types';

interface SpinnerButtonProps extends ButtonProps {
    loading: boolean;
    children: any;
}

export function SpinnerButton(props: SpinnerButtonProps) {
    const { loading, children, ...buttonProps } = props;

    return (
        <Button {...buttonProps}>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={classnames('button-spinner', {
                    'button-spinner-hidden': !loading,
                })}
            />
            {children}
        </Button>
    );
}

interface SubmitButtonProps extends SpinnerButtonProps {
    loading: boolean;
    children: any;
    size?: 'sm' | 'lg';
    variant?: ButtonVariant;
    replaceContent?: boolean;
}

interface SubmitButtonMutationData {
    [key: string]: {
        __typename: string;
    };
}

interface SubmitButtonMutationProps extends SubmitButtonProps {
    mutationData: SubmitButtonMutationData | undefined;
}

interface SubmitButtonSuccessProps extends SubmitButtonProps {
    success: boolean;
}

export function SubmitButton(
    props: SubmitButtonSuccessProps | SubmitButtonMutationProps
) {
    let {
        mutationData,
        success,
        loading,
        children,
        size,
        variant = 'primary',
        type = 'submit',
        replaceContent = false,
        ...buttonProps
    } = props as SubmitButtonMutationProps & SubmitButtonSuccessProps;

    if (
        typeof success !== 'boolean' &&
        mutationData?.[Object.keys(mutationData)[0]]?.__typename?.endsWith(
            'Success'
        )
    ) {
        success = true;
    }

    const [showSuccess, setShowSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (success) {
            setShowSuccess(true);

            const timeout = setTimeout(() => setShowSuccess(false), 1000);
            return () => {
                setShowSuccess(false);
                clearTimeout(timeout);
            };
        }

        return () => setShowSuccess(false);
    }, [success]);

    useEffect(() => {
        if (loading) {
            const timeoutShow = setTimeout(() => setShowLoading(true), 50);

            return () => {
                setShowLoading(false);
                clearTimeout(timeoutShow);
            };
        }

        return () => setShowLoading(false);
    }, [loading]);

    return (
        <SpinnerButton
            {...buttonProps}
            type={type}
            size={size}
            variant={showSuccess ? 'success' : variant}
            disabled={loading}
            loading={loading && showLoading && !showSuccess}
        >
            <span
                className={classnames('submit-button-check-mark', {
                    'submit-button-hidden-check-mark': !showSuccess,
                })}
            >
                <Check size={size === 'sm' ? 16 : 24} />
            </span>
            {replaceContent && ((loading && showLoading) || showSuccess)
                ? null
                : children}
        </SpinnerButton>
    );
}

type Awaited<Type> = Type extends PromiseLike<infer ValueType>
    ? ValueType
    : Type;

interface MutationButtonProps<
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
> extends ButtonProps {
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>;
    mutationOptions?: MutationHookOptions<TData, TVariables, TContext>;
    onMutate?: (
        result: Awaited<
            ReturnType<MutationTuple<TData, TVariables, TContext, TCache>[0]>
        >
    ) => unknown;
    variables: TVariables;
    children: any;
}

export function MutationButton<
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>
>(props: MutationButtonProps<TData, TVariables, TContext, TCache>) {
    const {
        mutation,
        mutationOptions,
        onMutate,
        variables,
        children,
        ...buttonProps
    } = props;
    const [mutate, { data, loading }] = useMutation<
        TData,
        TVariables,
        TContext,
        TCache
    >(mutation, mutationOptions);

    const handleClick = useCallback(async () => {
        const result = await mutate({ variables });

        onMutate && onMutate(result);
    }, [mutate, variables, onMutate]);

    return (
        <SubmitButton
            {...buttonProps}
            onClick={handleClick}
            loading={loading}
            mutationData={data as unknown as SubmitButtonMutationData}
        >
            {children}
        </SubmitButton>
    );
}
