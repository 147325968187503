"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.papersSortFn = exports.venuesSortFn = exports.participantsByTownSortFn = exports.participantsSortFn = exports.compareStrings = exports.createSortFn = void 0;
const isAscSortOption = (sortOption) => !sortOption.startsWith('-');
function createSortFn(...sortOrder) {
    return (a, b) => {
        for (const sortOption of sortOrder) {
            const greaterReturnValue = isAscSortOption(sortOption)
                ? 1
                : -1;
            const lessReturnValue = isAscSortOption(sortOption) ? -1 : 1;
            const field = isAscSortOption(sortOption)
                ? sortOption
                : sortOption.substring(1);
            const aFieldValue = a[field];
            const bFieldValue = b[field];
            if (aFieldValue === bFieldValue) {
                continue;
            }
            const fieldType = aFieldValue === undefined || aFieldValue === null
                ? typeof bFieldValue
                : typeof aFieldValue;
            switch (fieldType) {
                case 'boolean':
                    if (aFieldValue && !bFieldValue) {
                        return greaterReturnValue;
                    }
                    if (!aFieldValue && bFieldValue) {
                        return lessReturnValue;
                    }
                    break;
                case 'number':
                    if (Math.abs((aFieldValue || 0) -
                        (bFieldValue || 0)) < Number.EPSILON) {
                        continue;
                    }
                    if (aFieldValue > bFieldValue) {
                        return greaterReturnValue;
                    }
                    if (aFieldValue < bFieldValue) {
                        return lessReturnValue;
                    }
                    break;
                case 'string':
                case 'undefined':
                    const compareResult = compareStrings(aFieldValue, bFieldValue);
                    if (compareResult === 1) {
                        return greaterReturnValue;
                    }
                    if (compareResult === -1) {
                        return lessReturnValue;
                    }
                    break;
            }
        }
        return 0;
    };
}
exports.createSortFn = createSortFn;
function compareStrings(a, b) {
    const aNormalized = (a || '').toLowerCase().normalize('NFD');
    const bNormalized = (b || '').toLowerCase().normalize('NFD');
    if (aNormalized > bNormalized)
        return 1;
    if (aNormalized < bNormalized)
        return -1;
    return 0;
}
exports.compareStrings = compareStrings;
exports.participantsSortFn = createSortFn('grade', 'surname', 'name');
exports.participantsByTownSortFn = createSortFn('townName', 'grade', 'surname', 'name');
exports.venuesSortFn = createSortFn('townName', 'school');
exports.papersSortFn = createSortFn('season', '-level', 'tier');
