import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ms from 'ms';
import { Button, Card, Col, Collapse, Row, Spinner } from 'react-bootstrap';
import { MutationButton } from '../Components/EnhancedButtons';
import { gql, useQuery } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
    RegisterForAdditionalLevel,
    RegisterForAdditionalLevelVariables,
} from '../GraphQL/types/RegisterForAdditionalLevel';
import {
    ChangePaperVenue,
    ChangePaperVenueVariables,
} from '../GraphQL/types/ChangePaperVenue';
import { VenueParticipationInfo } from '../Components/VenueParticipationInfo';
import { SelectVenue, venuesQuery } from './RegisterForTournament';
import { createSortFn } from '@turgor/model-utils/lib/sort';

const registerForAdditionalLevelMutation: TypedDocumentNode<
    RegisterForAdditionalLevel,
    RegisterForAdditionalLevelVariables
> = gql`
    mutation RegisterForAdditionalLevel(
        $season: Season!
        $participantId: ID!
        $level: Level!
        $venueId: ID
    ) {
        registerForAdditionalLevel(
            season: $season
            participantId: $participantId
            level: $level
            venueId: $venueId
        ) {
            __typename
            ... on RegisterForTournamentSeasonSuccess {
                participant {
                    _id
                }
            }
        }
    }
`;

const changePaperVenueMutation: TypedDocumentNode<
    ChangePaperVenue,
    ChangePaperVenueVariables
> = gql`
    mutation ChangePaperVenue($paperId: ID!, $venueId: ID!) {
        changePaperVenue(paperId: $paperId, venueId: $venueId) {
            __typename
            ... on RegisterForTournamentSeasonSuccess {
                participant {
                    _id
                }
            }
        }
    }
`;

export default function ParticipationInfo({
    participant,
    tournament,
    season,
    level,
}: {
    participant: any;
    tournament: any;
    season: 'FALL' | 'SPRING';
    level: 'ORDINARY' | 'ADVANCED';
}) {
    const { t } = useTranslation();
    const paper = participant.papers.find(
        p => p.season === season && p.level === level
    );
    const otherLevelPaper = participant.papers.find(
        p =>
            p.season === season &&
            p.level === (level === 'ORDINARY' ? 'ADVANCED' : 'ORDINARY')
    );
    const { ordinary, advanced } = tournament.seasons[season.toLowerCase()];
    const [additionalLevelVenueSelectOpen, setAdditionalLevelVenueSelectOpen] =
        useState(false);
    const [venueChangeSelectOpen, setVenueChangeSelectOpen] = useState(false);

    if (additionalLevelVenueSelectOpen) {
        return (
            <>
                <h4>
                    {t('SeasonTitle', { season })}. {t('LevelTitle', { level })}
                </h4>
                <VenueSelectionForAdditionalLevel
                    onReady={() => setAdditionalLevelVenueSelectOpen(false)}
                    season={season}
                    level={level}
                    participantId={participant._id}
                />
            </>
        );
    }

    if (venueChangeSelectOpen) {
        return (
            <>
                <h4>
                    {t('SeasonTitle', { season })}. {t('LevelTitle', { level })}
                </h4>
                <VenueChangeSelection
                    onReady={() => setVenueChangeSelectOpen(false)}
                    paper={paper}
                />
            </>
        );
    }

    return (
        <>
            <h2>
                {season === 'FALL'
                    ? t('ParticipationInfo.YouAreRegisteredToFallRound')
                    : t('ParticipationInfo.YouAreRegisteredToSpringRound')}
            </h2>
            <p>
                {level === 'ADVANCED' ? (
                    <Trans
                        i18nKey="ParticipationInfo.AdvancedRoundDate"
                        values={{
                            advancedDate: new Date(advanced.date),
                        }}
                    />
                ) : (
                    <Trans
                        i18nKey="ParticipationInfo.OrdinaryRoundDate"
                        values={{
                            ordinaryDate: new Date(ordinary.date),
                            advancedDate: new Date(advanced.date),
                        }}
                    />
                )}
            </p>
            {!paper &&
                otherLevelPaper?.venue?.seasons?.[season.toLowerCase()]
                    ?.registrationOpen &&
                otherLevelPaper?.venue?.seasons?.[
                    season.toLowerCase()
                ]?.levels?.includes(level) && (
                    <>
                        {t('ParticipationInfo.YouOnlyWantedOneLevel', {
                            level:
                                level !== 'ORDINARY' ? 'ORDINARY' : 'ADVANCED',
                        })}{' '}
                        <MutationButton
                            variant="outline-primary"
                            size="sm"
                            mutation={
                                registerForAdditionalLevelMutation /*todo: handle NoSpotsLeftError*/
                            }
                            variables={{
                                participantId: participant._id,
                                season,
                                level,
                            }}
                            mutationOptions={{
                                refetchQueries: ['ParticipantHomePageQuery'],
                                awaitRefetchQueries: true,
                            }}
                        >
                            {t('ParticipationInfo.RegisterToLevel', { level })}
                        </MutationButton>
                        <br />
                        <br />
                    </>
                )}
            {!paper &&
                (!otherLevelPaper?.venue?.seasons?.[season.toLowerCase()]
                    ?.registrationOpen ||
                    !otherLevelPaper?.venue?.seasons?.[
                        season.toLowerCase()
                    ]?.levels?.includes(level)) && (
                    <>
                        {t('ParticipationInfo.YouOnlyWantedOneLevel', {
                            level:
                                level !== 'ORDINARY' ? 'ORDINARY' : 'ADVANCED',
                        })}
                        <br />
                        <Trans
                            i18nKey="ParticipationInfo.LevelIsNotAvailableAtSelectedVenue"
                            values={{ level }}
                        />
                        <br />
                        <br />
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() =>
                                setAdditionalLevelVenueSelectOpen(true)
                            }
                        >
                            {t('ParticipationInfo.SelectAnotherVenueForLevel', {
                                level,
                            })}
                        </Button>
                    </>
                )}
            {participant.townName === 'Москва' && season === 'SPRING' && (
                <p>
                    Обращаем внимание, что традиционно в Москве не проводится
                    весенний тур Турнира городов.
                    <br />
                    Вместо этого предлагаем вам поучаствовать в{' '}
                    <a href="https://olympiads.mccme.ru/mmo/">
                        Московской Математической Олимпиаде
                    </a>
                    , которая так же пройдёт <strong>10 марта</strong>.
                    <br />
                    Участвовать можно только в одной из двух олимпиад.
                </p>
            )}
            {paper && (
                <PersonalRegistrationData
                    participant={participant}
                    paper={paper}
                />
            )}
            {paper?.type === 'ONLINE' && (
                <OnlineParticipationInfo
                    ordinary={ordinary}
                    advanced={advanced}
                />
            )}
            {paper?.type === 'OFFLINE' && (
                <OfflineParticipationInformation
                    paper={paper}
                    participant={participant}
                    ordinary={ordinary}
                    advanced={advanced}
                    setVenueChangeSelectOpen={setVenueChangeSelectOpen}
                />
            )}
        </>
    );
}

function PersonalRegistrationData({ participant, paper }) {
    const [registrationInfoOpen, setRegistrationInfoOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Card className="mb-2">
            <Card.Body>
                <Card.Title>
                    <span
                        className="pseudo-link"
                        onClick={() => {
                            setRegistrationInfoOpen(open => !open);
                        }}
                    >
                        {t('ParticipationInfo.RegistrationData')}
                    </span>
                    {paper.type === 'ONLINE' &&
                        ` (${
                            paper.tier === 'JUNIOR'
                                ? t('ParticipationInfo.juniorTier')
                                : t('ParticipationInfo.seniorTier')
                        })`}
                </Card.Title>
                <Collapse in={registrationInfoOpen}>
                    <div className="text-pre-line">
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>{t('ParticipationInfo.Email')}</strong>
                            </Col>
                            <Col md={9}>{participant.email}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>{t('ParticipationInfo.Name')}</strong>
                            </Col>
                            <Col md={9}>{participant.name}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>
                                    {t('ParticipationInfo.Surname')}
                                </strong>
                            </Col>
                            <Col md={9}>{participant.surname}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>{t('ParticipationInfo.Phone')}</strong>
                            </Col>
                            <Col md={9}>{participant.phone}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>{t('ParticipationInfo.Grade')}</strong>
                            </Col>
                            <Col md={9}>{participant.grade}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>
                                    {t('ParticipationInfo.TownName')}
                                </strong>
                            </Col>
                            <Col md={9}>{participant.townName}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>{t('ParticipationInfo.School')}</strong>
                            </Col>
                            <Col md={9}>{participant.school}</Col>
                        </Row>
                        <Row>
                            <Col md={3} className="text-md-right">
                                <strong>
                                    {t('ParticipationInfo.CardNumber')}
                                </strong>
                            </Col>
                            <Col md={9}>{paper.card}</Col>
                        </Row>
                    </div>
                </Collapse>
            </Card.Body>
        </Card>
    );
}

function OnlineParticipationInfo({ ordinary, advanced }) {
    const level = ordinary.stage === 'ONLINE_FINISHED' ? advanced : ordinary;
    const startDate = new Date(level.onlineStart);
    const endDate = new Date(level.onlineDeadline);

    return (
        <>
            <p>
                {ordinary.stage === 'ONLINE_FINISHED' ? (
                    <Trans i18nKey="ParticipationInfo.YouWillBeAbleToParticipateOnlineAdvanced" />
                ) : (
                    <Trans i18nKey="ParticipationInfo.YouWillBeAbleToParticipateOnlineOrdinary" />
                )}
            </p>
            <p>
                <Trans
                    i18nKey="ParticipationInfo.OnlineDetails"
                    values={{
                        startDate,
                        endDate,
                    }}
                />
                <br />
                Например, если вы начнёте в{' '}
                <strong>
                    {new Date(startDate.getTime() + ms('15m'))
                        .toTimeString()
                        .substr(0, 5)}
                </strong>
                , то сможете решать задачи до{' '}
                <strong>
                    {new Date(startDate.getTime() + ms('345m'))
                        .toTimeString()
                        .substr(0, 5)}
                </strong>
                , если начнёте в{' '}
                <strong>
                    {new Date(startDate.getTime() + ms('30m'))
                        .toTimeString()
                        .substr(0, 5)}
                </strong>{' '}
                — до{' '}
                <strong>
                    {new Date(startDate.getTime() + ms('360m'))
                        .toTimeString()
                        .substr(0, 5)}
                </strong>
                , а если в{' '}
                <strong>
                    {new Date(startDate.getTime() + ms('60m'))
                        .toTimeString()
                        .substr(0, 5)}
                </strong>{' '}
                — тоже только до{' '}
                <strong>{endDate.toTimeString().substr(0, 5)}</strong>
                .<br />
                <br />
                Все задания должны быть выполнены самостоятельно.
                <br />
                Работы, которые жюри сочтёт выполненными несамостоятельно, будут
                аннулированы.
                <br />
                До 16:00 мск запрещены передача и обсуждение условий задач
                (кроме вопросов по условиям членам жюри).
            </p>
        </>
    );
}

function OfflineParticipationInformation({
    paper,
    participant,
    ordinary,
    advanced,
    setVenueChangeSelectOpen,
}) {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const townName =
        language === 'ru'
            ? paper.venue?.townName
            : paper.venue?.townNameEn || paper.venue?.townName;

    const venueChangeAllowed =
        Date.now() <
        new Date(
            paper.level === 'ORDINARY' ? ordinary.date : advanced.date
        ).getTime() -
            ms('10h');

    return (
        <>
            <p>
                {paper.level === 'ADVANCED' ? (
                    <Trans i18nKey="ParticipationInfo.YouWillBeAbleToParticipateOfflineAdvanced" />
                ) : (
                    <Trans i18nKey="ParticipationInfo.YouWillBeAbleToParticipateOfflineOrdinary" />
                )}
            </p>
            <VenueParticipationInfo
                venueInfo={paper.venue?.seasons?.[paper.season.toLowerCase()]}
                cardTitle={
                    townName
                        ? `${t('VenueParticipationInfo.Venue')}: ${townName}`
                        : null
                }
            />
            {venueChangeAllowed && (
                <p className="mt-3">
                    {t('ParticipationInfo.YouCanChangeVenue')}{' '}
                    <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={() => setVenueChangeSelectOpen(true)}
                    >
                        {t('ParticipationInfo.ChangeVenue')}
                    </Button>
                </p>
            )}
            <p className={venueChangeAllowed ? 'mt-3' : 'mt-4'}>
                {t('ParticipationInfo.PrintOut')}{' '}
                <a
                    href={`${
                        process.env.REACT_APP_BACKEND_URL
                    }/documents?template=${
                        language === 'ru' ? 'titlePage' : 'titlePageEn'
                    }&data=${encodeURIComponent(
                        JSON.stringify({
                            t: participant.tournament.number,
                            s: paper.season,
                            name: participant.name,
                            surname: participant.surname,
                            school: participant.school,
                            townName: participant.townName,
                            email: participant.email,
                            grade: participant.grade,
                            cardNumber: paper.card,
                        })
                    )}`}
                >
                    {t('ParticipationInfo.titlePage')}
                </a>
                <br />
                <Trans
                    i18nKey="ParticipationInfo.OrWriteDownYourCard"
                    values={{ card: paper.card }}
                />
                <br />
                <Trans i18nKey="ParticipationInfo.NotesForParticipants" />
            </p>
        </>
    );
}

function VenueSelectionForAdditionalLevel({
    onReady,
    season,
    level,
    participantId,
}) {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { data, loading } = useQuery(venuesQuery);
    const [venueId, setVenueId] = useState<string | null>(null);

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border">
                    <span className="sr-only">{t('Loading')}</span>
                </Spinner>
            </div>
        );
    }

    const venues = data.currentTournament.venues
        .filter(
            venue =>
                venue.seasons?.[season.toLowerCase()]?.registrationOpen &&
                venue.seasons?.[
                    season.toLowerCase()
                ]?.levelsWithAvailableSpots?.includes(level)
        )
        .map(
            ({
                _id,
                townName,
                townNameEn,
                organization,
                gradeChangesAtWinter,
                seasons,
            }) => ({
                _id,
                townName:
                    language === 'ru' || !townNameEn ? townName : townNameEn,
                organization,
                gradeChangesAtWinter,
                ...(seasons?.[season.toLowerCase()] || {}),
                accessPriority: ['FREE', 'REGISTRATION', 'LIMITED'].indexOf(
                    seasons?.[season.toLowerCase()]?.access
                ),
            })
        )
        .sort(createSortFn('townName', 'accessPriority', 'organization'));

    const venue = venueId ? venues.find(({ _id }) => _id === venueId) : null;

    if (!venueId) {
        return (
            <SelectVenue
                venues={venues}
                onSelect={venueId => setVenueId(venueId)}
            />
        );
    }

    return (
        <>
            <VenueParticipationInfo
                venueInfo={venue}
                cardTitle={venue.townName}
            />
            <br />
            <MutationButton
                variant="primary"
                mutation={
                    registerForAdditionalLevelMutation /*todo: handle NoSpotsLeftError*/
                }
                variables={{
                    participantId,
                    season,
                    level: level === 'ORDINARY' ? 'ORDINARY' : 'ADVANCED',
                    venueId,
                }}
                mutationOptions={{
                    refetchQueries: ['ParticipantHomePageQuery'],
                    awaitRefetchQueries: true,
                }}
                onMutate={onReady}
            >
                {t('ParticipationInfo.RegisterToLevel', { level })}
            </MutationButton>
        </>
    );
}

function VenueChangeSelection({ onReady, paper }) {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { data, loading } = useQuery(venuesQuery);
    const [venueId, setVenueId] = useState<string | null>(null);

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border">
                    <span className="sr-only">{t('Loading')}</span>
                </Spinner>
            </div>
        );
    }

    const season = paper.season.toLowerCase();
    const level = paper.level.toLowerCase();

    const venues = data.currentTournament.venues
        .filter(
            venue =>
                venue.seasons?.[season]?.registrationOpen &&
                venue.seasons?.[season]?.levelsWithAvailableSpots?.includes(
                    paper.level
                )
        )
        .map(
            ({
                _id,
                townName,
                townNameEn,
                organization,
                gradeChangesAtWinter,
                seasons,
            }) => ({
                _id,
                townName:
                    language === 'ru' || !townNameEn ? townName : townNameEn,
                organization,
                gradeChangesAtWinter,
                ...(seasons?.[season] || {}),
                accessPriority: ['FREE', 'REGISTRATION', 'LIMITED'].indexOf(
                    seasons?.[season]?.access
                ),
            })
        )
        .sort(createSortFn('townName', 'accessPriority', 'organization'));

    const venue = venueId ? venues.find(({ _id }) => _id === venueId) : null;

    if (!venueId) {
        return (
            <SelectVenue
                venues={venues}
                onSelect={venueId => setVenueId(venueId)}
            />
        );
    }

    return (
        <>
            <VenueParticipationInfo
                venueInfo={venue}
                cardTitle={venue.townName}
            />
            <br />
            <MutationButton
                variant="primary"
                mutation={
                    changePaperVenueMutation /*todo: handle NoSpotsLeftError*/
                }
                variables={{
                    paperId: paper._id,
                    venueId,
                }}
                mutationOptions={{
                    refetchQueries: ['ParticipantHomePageQuery'],
                    awaitRefetchQueries: true,
                }}
                onMutate={onReady}
            >
                {t('ParticipationInfo.RegisterToLevel', { level })}
            </MutationButton>
        </>
    );
}
