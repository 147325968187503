import { gql } from '@apollo/client';
import { provideLayoutAndData } from '../Page';
import {
    AdminVenueApplicationsPageQuery,
    AdminVenueApplicationsPageQueryVariables,
} from '../GraphQL/types/AdminVenueApplicationsPageQuery';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { Season } from '../GraphQL/types/globalTypes';

const adminVenueApplicationsPageQuery = gql`
    query AdminVenueApplicationsPageQuery($number: Int!, $season: Season) {
        tournament(number: $number) {
            _id
            number
            venueApplications(season: $season) {
                _id
                email
                townName
                stage
                submittedAt
                levels
                access
                venue {
                    _id
                    townName
                }
                comment
            }
        }
    }
`;

export default provideLayoutAndData<
    AdminVenueApplicationsPageQuery,
    AdminVenueApplicationsPageQueryVariables
>(adminVenueApplicationsPageQuery, {
    variablesGetter: params => ({
        number: Number(params.number),
        season: params.season.toUpperCase() as Season,
    }),
    authenticatedOnly: true,
})(function AdminVenueApplicationsPage({
    data: {
        tournament: { number: tournamentNumber, venueApplications },
    },
}) {
    const { t } = useTranslation();
    let { season }: { season: string } = useParams<{
        season: 'fall' | 'spring';
    }>();
    season = season.toUpperCase();

    const newApplications = venueApplications.filter(
        application => application.stage === 'CREATED'
    );
    const approvedApplications = venueApplications.filter(
        application => application.stage === 'APPROVED'
    );
    const rejectedApplications = venueApplications.filter(
        application => application.stage === 'REJECTED'
    );

    return (
        <>
            <h2>
                {t('SeasonFullTitle', {
                    number: tournamentNumber,
                    season,
                })}{' '}
                — заявки на проведение
            </h2>
            {!venueApplications.length && 'Пока не подано ни одной заявки'}
            {newApplications.length ? (
                <>
                    <h5>Новые заявки</h5>
                    <VenueApplicationsList
                        applications={newApplications}
                        tournamentNumber={tournamentNumber}
                        showComments={true}
                    />
                    <br />
                    <br />
                </>
            ) : null}
            {approvedApplications.length ? (
                <>
                    <h5>Одобренные заявки</h5>
                    <VenueApplicationsList
                        applications={approvedApplications}
                        tournamentNumber={tournamentNumber}
                        showVenueLink={true}
                    />
                    <br />
                    <br />
                </>
            ) : null}
            {rejectedApplications.length ? (
                <>
                    <h5>Отклонённые заявки</h5>
                    <VenueApplicationsList
                        applications={rejectedApplications}
                        tournamentNumber={tournamentNumber}
                    />
                    <br />
                    <br />
                </>
            ) : null}
        </>
    );
});

function VenueApplicationsList({
    applications,
    tournamentNumber,
    showVenueLink = false,
    showComments = false,
}) {
    return (
        <Table striped={true} hover={true} borderless={true} size="sm">
            <thead>
                <tr>
                    <th>Город</th>
                    <th>Email</th>
                    <th>Ограничения</th>
                    <th>Базовый</th>
                    <th>Сложный</th>
                    <th>Дата заявки</th>
                    {showComments && (
                        <th style={{ maxWidth: '20%' }}>Комментарии</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {applications.map(application => (
                    <tr key={application._id}>
                        {showVenueLink ? (
                            <td className="align-middle">
                                <a
                                    href={`/admin/${tournamentNumber}/venues/${application.venue._id}`}
                                >
                                    {application.venue.townName}
                                </a>{' '}
                                (
                                <Link
                                    to={`/tournaments/${tournamentNumber}/venue-applications/${application._id}`}
                                >
                                    заявка
                                </Link>
                                )
                            </td>
                        ) : (
                            <td className="align-middle">
                                <Link
                                    to={`/tournaments/${tournamentNumber}/venue-applications/${application._id}`}
                                >
                                    {application.townName}
                                </Link>
                            </td>
                        )}
                        <td>{application.email}</td>
                        <td>
                            {application.access === 'LIMITED' && 'Для своих'}
                            {application.access === 'REGISTRATION' &&
                                'Только с регистрацией'}
                            {application.access === 'FREE' && 'Для всех'}
                        </td>
                        <td>
                            {application.levels.includes('ORDINARY') && '✓'}
                        </td>
                        <td>
                            {application.levels.includes('ADVANCED') && '✓'}
                        </td>
                        <td>
                            <Trans
                                i18nKey="AdminHomePage.VenueApplicationSubmittedAt"
                                values={{
                                    submittedAt: new Date(
                                        application.submittedAt
                                    ),
                                }}
                            />
                        </td>
                        {showComments && <td>{application.comment}</td>}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
