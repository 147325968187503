import React, { Suspense, useCallback, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Page from '../../Page';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';

const QrScanner = React.lazy(() => import('../../Components/QrScanner'));

const venueScanQrQuery = gql`
    query VenueScanQr($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            tournament {
                _id
                number
            }
        }
    }
`;

const registerParticipationByCardMutation = gql`
    mutation RegisterParticipationByCard(
        $card: Int!
        $season: Season!
        $level: Level!
        $tier: Tier!
        $venueId: ID!
        $tournamentId: ID!
    ) {
        registerParticipationByCard(
            card: $card
            season: $season
            level: $level
            tier: $tier
            venueId: $venueId
            tournamentId: $tournamentId
        ) {
            __typename
            ... on RegisterParticipationByCardSuccess {
                participant {
                    _id
                    grade
                    name
                    surname
                    school
                    papers {
                        card
                        season
                        level
                        tier
                    }
                }
            }
        }
    }
`;

export default function AdminVenueScanQrPage() {
    let { venueId, season } = useParams<{
        venueId: string;
        season: string;
    }>();
    season = season.toUpperCase();
    const [level, setLevel] = useState('');
    const [tier, setTier] = useState('');
    const { register, handleSubmit } = useForm();

    const { data, loading } = useQuery(venueScanQrQuery, {
        variables: { venueId },
    });
    const [registerParticipationByCard, { data: mutationData }] = useMutation(
        registerParticipationByCardMutation
    );
    const { t } = useTranslation();
    const tournamentId = data?.venue.tournament._id;

    const registerByCard = useCallback(
        card =>
            registerParticipationByCard({
                variables: {
                    card,
                    tournamentId,
                    venueId,
                    season,
                    tier,
                    level,
                },
            }).then(({ data }) => {
                if (
                    data?.registerParticipationByCard.__typename !==
                    'RegisterParticipationByCardSuccess'
                ) {
                    return 'error';
                }

                return 'success';
            }),
        [
            registerParticipationByCard,
            tournamentId,
            venueId,
            season,
            tier,
            level,
        ]
    );

    const onResult = useCallback(
        result => {
            // TODO check if tournament and season is correct
            if (result.startsWith('card:') || /^t[0-9]{2}[sf]:/.test(result)) {
                return registerByCard(Number(result.split(':')[1]));
            }
        },
        [registerByCard]
    );

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        venue: { tournament, ...venue },
    } = data;

    const participant =
        mutationData?.registerParticipationByCard.__typename ===
        'RegisterParticipationByCardSuccess'
            ? mutationData.registerParticipationByCard.participant
            : null;

    const paper =
        participant?.papers.find(
            paper =>
                paper.season === season &&
                paper.level === level &&
                paper.tier === tier
        ) || null;

    let error = null;

    if (
        mutationData?.registerParticipationByCard.__typename === 'NotFoundError'
    ) {
        error = 'Анкета не найдена';
    }

    if (
        mutationData?.registerParticipationByCard.__typename ===
        'PaperAlreadyUploadedError'
    ) {
        error = 'Работа уже загружена';
    }

    return (
        <Page
            nav={
                <>
                    <Nav.Link
                        href={`/admin/${tournament.number}/venues/${venue._id}`}
                    >
                        {venue.townName}
                    </Nav.Link>
                </>
            }
        >
            <h3>
                QR — {season === 'FALL' ? 'осень' : 'весна'}, {venue.townName}
            </h3>
            {level && tier ? (
                <h4>{t('common:TierName', { level, tier })}</h4>
            ) : null}
            {!level || !tier ? (
                <Form
                    onSubmit={handleSubmit(formData => {
                        setTier(formData.tier);
                        setLevel(formData.level);
                    })}
                >
                    <Form.Group controlId="scan-qr-level">
                        <Form.Label>Тур</Form.Label>
                        <Form.Control
                            as="select"
                            {...register('level', { required: true })}
                        >
                            <option value="">{''}</option>
                            <option value="ORDINARY">Базовый</option>
                            <option value="ADVANCED">Сложный</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="scan-qr-tier">
                        <Form.Label>Вариант</Form.Label>
                        <Form.Control
                            as="select"
                            {...register('tier', { required: true })}
                        >
                            <option value="">{''}</option>
                            <option value="JUNIOR">Младший</option>
                            <option value="SENIOR">Старший</option>
                        </Form.Control>
                    </Form.Group>
                    <Button type="submit" className="mt-2" variant="primary">
                        Сканировать
                    </Button>
                </Form>
            ) : (
                <Suspense
                    fallback={
                        <div className="text-center">
                            <Spinner animation="border">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    }
                >
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <QrScanner onResult={onResult} />
                        </Col>
                    </Row>
                    <ManualCardForm onAdd={registerByCard} />
                    <br />
                    {participant ? (
                        <>
                            <h5>{paper.card}</h5>
                            {participant.surname} {participant.name}
                            <br />
                            {participant.grade}-й класс
                            <br />
                            {participant.school}
                        </>
                    ) : null}
                    {error ? <Alert variant="danger">{error}</Alert> : null}
                </Suspense>
            )}
        </Page>
    );
}

function ManualCardForm({ onAdd }) {
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    return (
        <Form
            onSubmit={handleSubmit(formData => {
                setSuccess(false);
                setLoading(true);
                onAdd(Number(formData.card))
                    .then(result => {
                        setLoading(false);
                        if (result === 'success') {
                            setSuccess(true);
                            reset();
                        }
                    })
                    .catch(() => setLoading(false));
            })}
        >
            <Row>
                <Col md={{ span: 4, offset: 3 }} xs={8}>
                    <Form.Label htmlFor="manual-card-number" srOnly>
                        Номер анкеты
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="mb-2 mr-sm-2"
                        id="manual-card-number"
                        placeholder="Номер анкеты"
                        defaultValue=""
                        {...register('card', { required: true })}
                    />
                </Col>
                <Col md={{ span: 2 }} xs={4}>
                    <SubmitButton
                        loading={loading}
                        success={success}
                        className="mb-2"
                    >
                        Добавить
                    </SubmitButton>
                </Col>
            </Row>
        </Form>
    );
}
