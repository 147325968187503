import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button, Col, Form } from 'react-bootstrap';
import classnames from 'classnames';
import { SubmitButton } from 'Components/EnhancedButtons';
import { FileEarmarkText, QuestionSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const setExaminationMarksMutation = gql`
    mutation SetExaminationMarks(
        $paperId: ID!
        $examinationType: PaperExaminationType!
        $examinationStage: PaperExaminationStage!
        $marks: [MarkInput!]!
    ) {
        setExaminationMarks(
            paperId: $paperId
            examinationType: $examinationType
            examinationStage: $examinationStage
            marks: $marks
        ) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    flags {
                        centralExamination
                        suspicious
                        annulled
                        pagesProblem
                    }
                    examinations {
                        type
                        stage
                        marks {
                            _id
                            mark
                            jury {
                                _id
                                name
                            }
                            problemNumber
                            subproblemNumber
                        }
                    }
                }
            }
        }
    }
`;

function MarksWrapper({ view, children }) {
    if (view === 'row') {
        return <Form.Row className="align-items-end">{children}</Form.Row>;
    }

    return <>{children}</>;
}

function SubmitButtonWrapper({ view, children }) {
    if (view === 'row') {
        return <Col xs={2}>{children}</Col>;
    }

    return <>{children}</>;
}

export default function PaperExaminationForm({
    paper,
    problemSet,
    examination,
    unfocusable = false,
    view = 'row',
    onGoToPageClick = null,
    onGoToProblemTextClick = null,
    onMarksSaved = null,
    disabled = false,
}) {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, getValues } = useForm();
    const [setExaminationMarks, { loading, data }] = useMutation(
        setExaminationMarksMutation
    );

    const flatProblems = problemSet.problems.flatMap((problem, i) =>
        problem.subproblems.map((subproblem, j) => ({
            problemNumber: i,
            subproblemNumber: j,
        }))
    );

    return (
        <Form
            onSubmit={handleSubmit(formData => {
                setExaminationMarks({
                    variables: {
                        paperId: paper._id,
                        examinationType: examination.type,
                        examinationStage: examination.stage,
                        marks: Object.keys(formData).map(key => {
                            const [, problemNumber, subproblemNumber] =
                                key.split('-');

                            return {
                                problemNumber: Number(problemNumber),
                                subproblemNumber: Number(subproblemNumber),
                                mark: formData[key],
                            };
                        }),
                    },
                }).then(({ data }) => {
                    if (
                        onMarksSaved &&
                        data?.setExaminationMarks.__typename ===
                            'ExaminePaperSuccess'
                    ) {
                        onMarksSaved();
                    }
                });
            })}
            className={classnames('paper-examination', {
                'paper-examination-sidebar': view === 'sidebar',
            })}
        >
            <MarksWrapper view={view}>
                {flatProblems.map(fp => {
                    const mark = examination.marks.find(
                        ({ problemNumber, subproblemNumber }) =>
                            problemNumber === fp.problemNumber &&
                            subproblemNumber === fp.subproblemNumber
                    );

                    return (
                        <Form.Group
                            key={`${fp.problemNumber}-${fp.subproblemNumber}`}
                            as={view === 'row' ? Col : undefined}
                            xs={view === 'row' ? 1 : undefined}
                            controlId={`${examination.type}-${examination.stage}-${fp.problemNumber}-${fp.subproblemNumber}`}
                        >
                            <Form.Label className="problemLabel">
                                {fp.problemNumber + 1}
                                {problemSet.problems[fp.problemNumber]
                                    .subproblems.length > 1
                                    ? t('common:subproblemLabels').split('')[
                                          fp.subproblemNumber
                                      ]
                                    : ''}
                                {onGoToPageClick &&
                                    fp.subproblemNumber === 0 &&
                                    paper.pages.some(
                                        page =>
                                            page.problemNumber ===
                                            fp.problemNumber
                                    ) && (
                                        <button
                                            className="examination-modal-form-go-to-button"
                                            title={t(
                                                'ExaminationPaperView.OpenPage'
                                            )}
                                            type="button"
                                            tabIndex={-1}
                                            onClick={() =>
                                                onGoToPageClick(
                                                    fp.problemNumber
                                                )
                                            }
                                        >
                                            <FileEarmarkText size={16} />
                                        </button>
                                    )}
                                {onGoToProblemTextClick &&
                                    fp.subproblemNumber === 0 && (
                                        <button
                                            className="examination-modal-form-go-to-button float-right"
                                            title={t(
                                                'ExaminationPaperView.ShowProblem'
                                            )}
                                            type="button"
                                            tabIndex={-1}
                                            onClick={() =>
                                                onGoToProblemTextClick(
                                                    fp.problemNumber
                                                )
                                            }
                                        >
                                            <QuestionSquare size={16} />
                                        </button>
                                    )}
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-2"
                                {...register(
                                    `mark-${fp.problemNumber}-${fp.subproblemNumber}`
                                )}
                                defaultValue={mark?.mark || ''}
                                size={view === 'sidebar' ? 'sm' : undefined}
                                tabIndex={unfocusable ? -1 : undefined}
                                title={mark?.jury?.name}
                                disabled={disabled}
                            >
                                {[
                                    '',
                                    '0',
                                    '-',
                                    '-.',
                                    '-+',
                                    '+/2',
                                    '+-',
                                    '+.',
                                    '+',
                                    '+!',
                                ].map(m => (
                                    <option key={m} value={m}>
                                        {m}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    );
                })}
                <SubmitButtonWrapper view={view}>
                    <SubmitButton
                        loading={loading}
                        mutationData={data}
                        className="mb-3"
                        tabIndex={unfocusable ? -1 : undefined}
                    >
                        {t('Save')}
                    </SubmitButton>
                </SubmitButtonWrapper>
                {view === 'sidebar' &&
                    paper.type === 'ONLINE' &&
                    flatProblems.length >
                        examination.marks.filter(mark => Boolean(mark.mark))
                            .length && (
                        <>
                            <br />
                            <Button
                                variant="secondary"
                                onClick={() =>
                                    determineNullSolutions(
                                        paper,
                                        problemSet
                                    ).forEach(field =>
                                        !getValues(field)
                                            ? setValue(field, '0')
                                            : null
                                    )
                                }
                            >
                                {t('ExaminationPaperView.DetermineZeros')}
                            </Button>
                        </>
                    )}
            </MarksWrapper>
        </Form>
    );
}

function determineNullSolutions(paper, problemSet) {
    let nullSolutions = [];

    for (let [number, problem] of problemSet.problems.entries()) {
        if (!paper.pages.some(page => page.problemNumber === number)) {
            nullSolutions.push(
                ...problem.subproblems.map((_, i) => `mark-${number}-${i}`)
            );
        }
    }

    return nullSolutions;
}
