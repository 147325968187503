import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getProblemsNames } from '@turgor/model-utils';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';

import './ParticipantsResults.scss';
import classnames from 'classnames';

const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const defaultFilter = {
    townName: '',
    name: '',
    school: '',
    grades: grades,
    flagSuspicious: false,
    flagAnnulled: false,
};

export default function ParticipantsResults({
    participants,
    tournamentNumber,
}) {
    const { t } = useTranslation();
    const [filter, setFilter] = useState(defaultFilter);

    const participantsToShow = participants
        .filter(participant =>
            participant.papers.some(paper => paper.marks?.length > 0)
        )
        .filter(participant => {
            if (
                filter.townName &&
                !participant.townName
                    .toLowerCase()
                    .includes(filter.townName.toLowerCase())
            ) {
                return false;
            }

            const fullName = `${participant.surname || ''} ${
                participant.name || ''
            }`
                .trim()
                .toLowerCase();
            const fullName2 = `${participant.name || ''} ${
                participant.surname || ''
            }`
                .trim()
                .toLowerCase();

            if (
                filter.name &&
                !fullName.includes(filter.name.toLowerCase()) &&
                !fullName2.includes(filter.name.toLowerCase())
            ) {
                return false;
            }

            if (
                filter.school &&
                !participant.school
                    .toLowerCase()
                    .includes(filter.school.toLowerCase())
            ) {
                return false;
            }

            if (!filter.grades.includes(participant.grade)) {
                return false;
            }

            if (
                filter.flagSuspicious &&
                !participant.papers.some(
                    paper => paper.marks?.length > 0 && paper.flags?.suspicious
                )
            ) {
                return false;
            }

            if (
                filter.flagAnnulled &&
                !participant.papers.some(
                    paper => paper.marks?.length > 0 && paper.flags?.annulled
                )
            ) {
                return false;
            }

            return true;
        })
        .sort(participantsSortFn);

    const papersCount = participantsToShow.reduce(
        (acc, curr) =>
            acc + curr.papers.filter(paper => paper.marks?.length > 0).length,
        0
    );

    const diplomaCount = participantsToShow.reduce(
        (acc, curr) => (curr.award === 'DIPLOMA' ? acc + 1 : acc),
        0
    );

    const prizeCount = participantsToShow.reduce(
        (acc, curr) => (curr.award === 'PRIZE' ? acc + 1 : acc),
        0
    );

    return (
        <Row>
            <Col md={9}>
                <ol>
                    {participantsToShow.map(participant => (
                        <ParticipantResults
                            participant={participant}
                            key={participant._id}
                            tournamentNumber={tournamentNumber}
                        />
                    ))}
                </ol>
            </Col>
            <Col md={3}>
                <h4>
                    {t('common:count.participants', {
                        count: participantsToShow.length,
                    })}
                </h4>
                <h4>{t('common:count.papers', { count: papersCount })}</h4>
                <h4>{t('common:count.diplomas', { count: diplomaCount })}</h4>
                <h4>{t('common:count.prizes', { count: prizeCount })}</h4>
                <br />
                <ParticipantsFilter filter={filter} setFilter={setFilter} />
            </Col>
        </Row>
    );
}

function ParticipantsFilter({ filter, setFilter }) {
    return (
        <>
            <Form.Group as={Row} controlId="filter-townName">
                <Form.Label column={true} md={4} className="text-md-right">
                    Город
                </Form.Label>
                <Col md={8}>
                    <Form.Control
                        name="townName"
                        value={filter.townName}
                        onChange={({ currentTarget: { value } }) =>
                            setFilter(f => ({
                                ...f,
                                townName: value.trim(),
                            }))
                        }
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="filter-name">
                <Form.Label column={true} md={4} className="text-md-right">
                    Имя
                </Form.Label>
                <Col md={8}>
                    <Form.Control
                        name="name"
                        value={filter.name}
                        onChange={({ currentTarget: { value } }) =>
                            setFilter(f => ({
                                ...f,
                                name: value.trim(),
                            }))
                        }
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="filter-school">
                <Form.Label column={true} md={4} className="text-md-right">
                    Школа
                </Form.Label>
                <Col md={8}>
                    <Form.Control
                        name="school"
                        value={filter.school}
                        onChange={({ currentTarget: { value } }) =>
                            setFilter(f => ({
                                ...f,
                                school: value.trim(),
                            }))
                        }
                    />
                </Col>
            </Form.Group>
            <Row>
                <Col md={4} className="text-md-right">
                    Классы
                </Col>
                <Col md={8}>
                    <Row>
                        <Col xs={12}>
                            <Form.Check
                                name="grade-all"
                                label="Все"
                                id="filter-grade-all"
                                checked={filter.grades.length === grades.length}
                                onChange={({ currentTarget: { checked } }) =>
                                    setFilter(f => ({
                                        ...f,
                                        grades: checked ? grades : [],
                                    }))
                                }
                            />
                        </Col>
                        {grades.map(grade => (
                            <Col xs={6} key={grade}>
                                <Form.Check
                                    name={`grade-${grade}`}
                                    label={grade}
                                    id={`filter-grade-${grade}`}
                                    checked={filter.grades.includes(grade)}
                                    onChange={({
                                        currentTarget: { checked },
                                    }) =>
                                        setFilter(f => ({
                                            ...f,
                                            grades: checked
                                                ? [...f.grades, grade]
                                                : f.grades.filter(
                                                      g => g !== grade
                                                  ),
                                        }))
                                    }
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={4} className="text-md-right">
                    Флаги
                </Col>
                <Col md={8}>
                    <Form.Check
                        name="flag-suspicious"
                        label="Подозрение на списывание"
                        id="filter-flag-suspicious"
                        checked={filter.flagSuspicious}
                        onChange={({ currentTarget: { checked } }) =>
                            setFilter(f => ({
                                ...f,
                                flagSuspicious: checked,
                            }))
                        }
                    />
                    <Form.Check
                        name="flag-annulled"
                        label="Аннулирована"
                        id="filter-flag-annulled"
                        checked={filter.flagAnnulled}
                        onChange={({ currentTarget: { checked } }) =>
                            setFilter(f => ({
                                ...f,
                                flagAnnulled: checked,
                            }))
                        }
                    />
                </Col>
            </Row>
        </>
    );
}

function ParticipantResults({ participant, tournamentNumber }) {
    const {
        award,
        bestResult,
        name,
        surname,
        school,
        grade,
        townName,
        papers,
    } = participant;
    const { t } = useTranslation();

    return (
        <li>
            <h5>
                <span>
                    {surname} {name}
                </span>
                <span className="results-page-participant-data">
                    {grade}-й класс, {school}, {townName}
                </span>
            </h5>
            {papers.map(paper => (
                <PaperResults
                    key={paper._id}
                    paper={paper}
                    tournamentNumber={tournamentNumber}
                    showLink={true}
                />
            ))}
            <div className="results-page-participant-result">
                Итоговый результат:{' '}
                {t('common:count.points', { count: bestResult })}
                {award === 'PRIZE' && (
                    <>
                        <br />
                        Премия Турнира городов
                    </>
                )}
                {award === 'DIPLOMA' && (
                    <>
                        <br />
                        Диплом Турнира городов
                    </>
                )}
            </div>
        </li>
    );
}

export function PaperResults({
    paper,
    tournamentNumber = null,
    showProblemSetName = true,
    showLink = false,
}) {
    const { season, level, tier, sum, result, marks } = paper;
    const { t } = useTranslation();

    if (!paper.marks?.length) {
        return null;
    }

    const firstRowLabel = showProblemSetName
        ? t('common:fullTierName', {
              season,
              level,
              tier,
          })
        : t('common:problems');

    return (
        <Table className="results-page-paper-table" borderless={true}>
            <thead>
                <tr>
                    <th
                        className={classnames({
                            'results-page-paper-short-header':
                                !showProblemSetName,
                        })}
                    >
                        {showLink ? (
                            <Link
                                to={`/tournaments/${tournamentNumber}/papers/${paper._id}`}
                            >
                                {firstRowLabel}
                            </Link>
                        ) : (
                            firstRowLabel
                        )}
                    </th>
                    {getProblemsNames(marks, t).map(problemName => (
                        <th key={problemName}>{problemName}</th>
                    ))}
                    <td />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t('marks')}</td>
                    {marks.flatMap((problemMarks, i) =>
                        problemMarks.map((mark, j) => (
                            <td key={`${i}-${j}`}>{mark.mark}</td>
                        ))
                    )}
                </tr>
                <tr>
                    <td>{t('points')}</td>
                    {marks.flatMap((problemMarks, i) =>
                        problemMarks.map((mark, j) => (
                            <td key={`${i}-${j}`}>{mark.points}</td>
                        ))
                    )}
                </tr>
                <tr>
                    <td>{t('result')}</td>
                    <td colSpan={11}>
                        {sum
                            ? `${(result / sum).toFixed(2)} × ${t(
                                  'common:count.points',
                                  { count: sum }
                              )} = ${t('common:count.points', {
                                  count: result,
                              })}`
                            : t('common:count.points', { count: 0 })}
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}
