import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';
import Latex from './Latex';
import React from 'react';

export function ProblemView({ problem, problemNumber }) {
    return (
        <Row className="justify-content-center">
            <Col md={10} lg={8}>
                <h5>Задача {problemNumber + 1}</h5>
                <ProblemText problem={problem} />
            </Col>
        </Row>
    );
}

export function ProblemText({ problem }) {
    const { t } = useTranslation();

    return (
        <>
            <p className="text-muted">
                {t('common:count.points', {
                    count: problem.subproblems.reduce(
                        (acc, curr) => acc + curr.points,
                        0
                    ),
                })}
            </p>
            <p className="text-pre-line notranslate">
                {problem.image ? (
                    <Row>
                        <Col md={9}>
                            <Latex raw={problem.text} />
                        </Col>
                        <Col md={3}>
                            <Image
                                src={`${process.env.REACT_APP_BACKEND_URL}${problem.image}`}
                                className="problem-image"
                            />
                        </Col>
                    </Row>
                ) : (
                    <Latex raw={problem.text} />
                )}
            </p>
            {problem.subproblems.length > 1 &&
                problem.subproblems.map((subproblem, i) => (
                    <p key={i} className="notranslate">
                        {['a', 'б', 'в', 'г'][i]}){' '}
                        <Latex raw={subproblem.text} />{' '}
                        <span className="text-muted">
                            (
                            {t('common:count.points', {
                                count: subproblem.points,
                            })}
                            )
                        </span>
                    </p>
                ))}
            {problem.author && (
                <p className="text-right font-italic">{problem.author}</p>
            )}
        </>
    );
}
