import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Image, Modal, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';
import { PageGalleryModal } from '../Paper/PageGallery';

const participantOnlineRoundQuestionsQuery = gql`
    query ParticipantOnlineRoundQuestions(
        $season: Season
        $level: Level
        $type: QuestionType!
    ) {
        viewer {
            participants {
                _id
                questions(season: $season, level: $level, type: $type) {
                    _id
                    question
                    askedAt
                    level
                    season
                    problemNumber
                    answers {
                        answer
                        answeredAt
                        file
                    }
                }
            }
        }
    }
`;

const noop = () => {};

export default function ParticipantQuestionModal({
    open,
    modalTitle,
    bringAttention = noop, // TODO: bring attention during round
    onHide,
    participantId,
    season,
    level,
    tier,
    type,
    problemsCount,
}) {
    const { t } = useTranslation();
    const { data, loading, startPolling, stopPolling } = useQuery(
        participantOnlineRoundQuestionsQuery,
        {
            variables: { season, level, tier, type },
        }
    );

    const { questions = [] } =
        data?.viewer.participants.find(p => p._id === participantId) || {};

    useEffect(() => {
        if (questions.length && type === 'LIVE') {
            startPolling(60 * 1000);
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [questions.length, type, startPolling, stopPolling]);

    const pages = questions
        .flatMap(question => question.answers.map(answer => answer.file))
        .filter(file => Boolean(file))
        .map((file, index) => ({
            file,
            _id: `page_${index}`,
        }));

    const [clickedPageIndex, setClickedPageIndex] = useState<number | null>(
        null
    );

    return (
        <>
            <Modal
                show={open}
                onHide={onHide}
                size="lg"
                aria-labelledby="questions-modal"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="questions-modal">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {questions.length === 0 && (
                                <div className="online-round-question-empty text-muted">
                                    {t(
                                        'QuestionModal.HereYouCanAskYourQuestions'
                                    )}
                                </div>
                            )}
                            {questions.length > 0 &&
                                questions.map(question => (
                                    <div key={question._id}>
                                        <div className="online-round-question-bubble online-round-question-bubble-me">
                                            {Number.isInteger(
                                                question.problemNumber
                                            ) && (
                                                <h6>
                                                    {t('ProblemWithNumber', {
                                                        problemNumber:
                                                            question.problemNumber +
                                                            1,
                                                    })}
                                                </h6>
                                            )}
                                            {question.question}
                                        </div>
                                        {question.answers.map(answer =>
                                            answer.answer ? (
                                                <div className="online-round-question-bubble online-round-question-bubble-them">
                                                    {answer.answer}
                                                </div>
                                            ) : answer.file ? (
                                                <div className="online-round-question-bubble online-round-question-bubble-them">
                                                    <Image
                                                        src={`${process.env.REACT_APP_BACKEND_URL}${answer.file}`}
                                                        thumbnail={true}
                                                        width={200}
                                                        onClick={() => {
                                                            setClickedPageIndex(
                                                                pages.findIndex(
                                                                    page =>
                                                                        page.file ===
                                                                        answer.file
                                                                )
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                ))}
                            <AskQuestionForm
                                participantId={participantId}
                                season={season}
                                level={level}
                                tier={tier}
                                type={type}
                                problemsCount={problemsCount}
                            />
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {clickedPageIndex !== null && (
                <PageGalleryModal
                    initialPage={clickedPageIndex}
                    pages={pages}
                    onClose={() => setClickedPageIndex(null)}
                />
            )}
        </>
    );
}

const askQuestionMutation = gql`
    mutation AskQuestion(
        $participantId: ID!
        $season: Season!
        $level: Level!
        $tier: Tier!
        $type: QuestionType!
        $problemNumber: Int
        $question: String!
    ) {
        askQuestion(
            participantId: $participantId
            season: $season
            level: $level
            tier: $tier
            type: $type
            problemNumber: $problemNumber
            question: $question
        ) {
            __typename
            ... on AskQuestionSuccess {
                participant {
                    _id
                    questions(season: $season, level: $level, type: $type) {
                        _id
                        question
                        askedAt
                        level
                        season
                        problemNumber
                        answers {
                            answer
                            answeredAt
                            file
                        }
                    }
                }
            }
        }
    }
`;

function AskQuestionForm({
    participantId,
    season,
    level,
    tier,
    type,
    problemsCount,
}) {
    const { t } = useTranslation();
    const [askQuestion, { loading, data }] = useMutation(askQuestionMutation);
    const {
        register,
        handleSubmit,
        reset,

        formState: { errors },
    } = useForm();

    return (
        <Form
            noValidate={true}
            className="mt-4"
            onSubmit={handleSubmit(({ question, problemNumber }) => {
                askQuestion({
                    variables: {
                        question,
                        participantId,
                        season,
                        level,
                        type,
                        problemNumber:
                            problemNumber !== 'other'
                                ? Number(problemNumber)
                                : null,
                        tier,
                    },
                }).then(({ data }) => {
                    if (data?.askQuestion.__typename === 'AskQuestionSuccess') {
                        reset();
                    }
                });
            })}
        >
            <Form.Group controlId="ask-question-problemNumber">
                <Form.Label>{t('QuestionModal.QuestionTopic')}</Form.Label>
                <Form.Control
                    as="select"
                    {...register('problemNumber', { required: true })}
                    defaultValue=""
                >
                    <option value="" disabled={true} key="none" />
                    {new Array(problemsCount).fill(0).map((_, i) => (
                        <option key={i} value={i}>
                            {t('ProblemWithNumber', { problemNumber: i + 1 })}
                        </option>
                    ))}
                    <option key="other" value="other">
                        {t('QuestionModal.other')}
                    </option>
                </Form.Control>
                {errors.problemNumber && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {t('QuestionModal.ChooseQuestionTopic')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="ask-question-text">
                <Form.Label>{t('QuestionModal.YourQuestion')}</Form.Label>
                <Form.Control
                    as="textarea"
                    {...register('question', { required: true })}
                    rows={5}
                    defaultValue=""
                />
                {errors.question && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {t('QuestionModal.EnterYourQuestion')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="mt-2 mb-2"
            >
                {t('QuestionModal.Send')}
            </SubmitButton>
        </Form>
    );
}
