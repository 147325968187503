import { Alert, Form } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import Page from '../Page';
import { SubmitButton } from 'Components/EnhancedButtons';
import { Link } from 'react-router-dom';
import { getLocalSetting } from '../localSettings';

const signInMutation = gql`
    mutation SignIn($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
            __typename
            ... on Error {
                message
            }
            ... on SignInSuccess {
                role
            }
        }
    }
`;

export default function SignInPage() {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        watch,

        formState: { errors: formErrors },
    } = useForm();
    const [signIn, { loading, error, data }] = useMutation(signInMutation);

    let errorMessage = null;

    if (
        error ||
        !['SignInSuccess', 'InvalidUserOrPasswordError', undefined].includes(
            data?.signIn.__typename
        )
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (data?.signIn.__typename === 'InvalidUserOrPasswordError') {
        errorMessage = t('SignInPage.InvalidUserOrPasswordErrorMessage');
    }

    const successMessage =
        data?.signIn.__typename === 'SignInSuccess'
            ? t('SignInPage.SignInSuccessMessage')
            : null;

    return (
        <Page layout="narrow">
            <h1 className="h2">{t('SignInPage.Header')}</h1>
            <p>
                {t('SignInPage.NotRegistered')}{' '}
                <Link
                    to={`/sign-up${
                        getLocalSetting('sign-up', 'isOrganizer', false)
                            ? '/organizer'
                            : ''
                    }`}
                >
                    {t('SignInPage.CreateAccount')}
                </Link>
            </p>
            <Alert
                variant={errorMessage ? 'warning' : 'success'}
                className={classnames({
                    invisible: !errorMessage && !successMessage,
                })}
            >
                {errorMessage || successMessage || '&nbsp;'}
            </Alert>
            <Form
                onSubmit={handleSubmit(formData => {
                    signIn({ variables: formData }).then(({ data }) => {
                        if (data?.signIn.__typename === 'SignInSuccess') {
                            try {
                                localStorage.setItem(
                                    'hasBeenSignedIn',
                                    new Date().toISOString()
                                );
                            } catch {}

                            setTimeout(() => {
                                window.location.href =
                                    data?.signIn.role === 'ORGANIZER'
                                        ? window.location.origin + '/org'
                                        : data?.signIn.role === 'ADMIN'
                                        ? window.location.origin + '/admin'
                                        : window.location.origin;
                            }, 2000);
                        }
                    });
                })}
                noValidate={true}
            >
                <Form.Group controlId="sign-in-email">
                    <Form.Label>{t('SignInPage.EmailLabel')}</Form.Label>
                    <Form.Control
                        {...register('email', {
                            required: true,
                            pattern: /^.+@.+$/,
                        })}
                        type="email"
                        defaultValue=""
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classnames('d-block', {
                            invisible: !formErrors.email,
                        })}
                    >
                        {!formErrors.email && '&nbsp;'}
                        {formErrors.email?.type === 'required' &&
                            t('SignInPage.EmailRequired')}
                        {formErrors.email?.type === 'pattern' &&
                            t('SignInPage.EmailPatternFailed')}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="sign-in-password">
                    <Form.Label>{t('SignInPage.PasswordLabel')}</Form.Label>
                    <Form.Control
                        {...register('password', { required: true })}
                        type="password"
                        defaultValue=""
                    />
                    {!formErrors.password && (
                        <Form.Text muted={true}>
                            {t('SignInPage.ForgotYourPassword')}{' '}
                            <Link
                                to={{
                                    pathname: '/reset-password',
                                    state: { email: watch('email', '') },
                                }}
                            >
                                {t('SignInPage.RestorePassword')}
                            </Link>
                        </Form.Text>
                    )}
                    {formErrors.password && (
                        <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                        >
                            {formErrors.password
                                ? t('SignInPage.PasswordRequired')
                                : '&nbsp;'}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <SubmitButton
                    loading={loading}
                    mutationData={data}
                    className="mt-2"
                >
                    {t('SignInPage.SignInButton')}
                </SubmitButton>
            </Form>
        </Page>
    );
}
