import React from 'react';
import katex from 'katex';

import 'katex/dist/katex.min.css';

export default function Latex({ raw }: { raw: string }) {
    const chunks = raw.split(/\\\[|\\\]/).flatMap((str, i) => {
        if (i % 2 === 1) {
            return [{ type: 'blockTex', data: str }];
        }

        return str.split('$').map((s, i) => ({
            type: i % 2 === 0 ? 'text' : 'inlineTex',
            data: i % 2 === 0 ? basicTypography(s) : s,
        }));
    });

    return (
        <>
            {chunks.map(({ type, data }, i) => {
                if (type === 'text') {
                    return (
                        <span
                            key={i}
                            dangerouslySetInnerHTML={{
                                __html: data,
                            }}
                        />
                    );
                }

                if (type === 'inlineTex') {
                    return (
                        <span
                            key={i}
                            dangerouslySetInnerHTML={{
                                __html: katex.renderToString(data, {
                                    throwOnError: false,
                                }),
                            }}
                        />
                    );
                }

                if (type === 'blockTex') {
                    return (
                        <span
                            key={i}
                            dangerouslySetInnerHTML={{
                                __html: katex.renderToString(data, {
                                    throwOnError: false,
                                    displayMode: true,
                                }),
                            }}
                        />
                    );
                }

                return null;
            })}
        </>
    );
}

function basicTypography(str: string): string {
    return str
        .replace(/~/g, ' ')
        .replace(/---/g, '—')
        .replace(
            /{\\em (.+)}/,
            str => `<em>${str.substring(4, str.length - 1)}</em>`
        );
}
