import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import {
    Route,
    Switch,
    Link,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { ProblemText } from '../Components/Problem';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';

const TournamentProblemsFragments = gql`
    fragment problemsPageProblemSetData on ProblemSet {
        problems {
            text
            criteria
            author
            solution
            image
            subproblems {
                text
                points
                markToPointsMap {
                    mark
                    points
                }
            }
        }
        files {
            file
            language
        }
    }

    fragment problemsPageRoundData on TournamentRound {
        junior {
            ...problemsPageProblemSetData
        }
        senior {
            ...problemsPageProblemSetData
        }
        date
    }

    fragment problemsPageSeasonData on TournamentSeason {
        ordinary {
            ...problemsPageRoundData
        }
        advanced {
            ...problemsPageRoundData
        }
    }
`;

const adminTournamentProblemsPageQuery = gql`
    query AdminTournamentProblemsPage($number: Int!) {
        tournament(number: $number) {
            _id
            number
            stage
            seasons {
                fall {
                    ...problemsPageSeasonData
                }
                spring {
                    ...problemsPageSeasonData
                }
            }
        }
    }

    ${TournamentProblemsFragments}
`;

export default function AdminTournamentProblemsPage() {
    const params = useParams<{ number: string }>();
    let { path, url } = useRouteMatch();
    const number = Number(params.number);
    const { data, loading } = useQuery(adminTournamentProblemsPageQuery, {
        variables: { number },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        tournament: { seasons, ...tournament },
    } = data;

    return (
        <Page>
            <h2>
                {t('TournamentTitle', {
                    number,
                })}{' '}
                — задачи
            </h2>
            <Switch>
                <Route exact={true} path={path}>
                    <Link to={`${url}/fall-ordinary/junior`}>
                        Осенний базовый младший
                    </Link>
                    <br />
                    <Link to={`${url}/fall-ordinary/senior`}>
                        Осенний базовый старший
                    </Link>
                    <br />
                    <Link to={`${url}/fall-advanced/junior`}>
                        Осенний сложный младший
                    </Link>
                    <br />
                    <Link to={`${url}/fall-advanced/senior`}>
                        Осенний сложный старший
                    </Link>
                    <br />
                    <br />
                    <Link to={`${url}/spring-ordinary/junior`}>
                        Весенний базовый младший
                    </Link>
                    <br />
                    <Link to={`${url}/spring-ordinary/senior`}>
                        Весенний базовый старший
                    </Link>
                    <br />
                    <Link to={`${url}/spring-advanced/junior`}>
                        Весенний сложный младший
                    </Link>
                    <br />
                    <Link to={`${url}/spring-advanced/senior`}>
                        Весенний сложный старший
                    </Link>
                    <br />
                </Route>
                <Route
                    path={`${path}/:round/:tier`}
                    render={({ match: { params } }) => {
                        const season = params.round.split('-')[0].toUpperCase();
                        const level = params.round.split('-')[1].toUpperCase();
                        const tier = params.tier.toUpperCase();

                        return (
                            <ProblemSet
                                problemSet={
                                    seasons[season.toLowerCase()][
                                        level.toLowerCase()
                                    ][tier.toLowerCase()]
                                }
                                tournamentId={tournament._id}
                                season={season}
                                level={level}
                                tier={tier}
                            />
                        );
                    }}
                />
            </Switch>
        </Page>
    );
}

function ProblemSet({ problemSet, season, level, tier, tournamentId }) {
    return (
        <>
            <h4 className="mt-3">
                {season === 'FALL' ? 'Осенний' : 'Весенний'}{' '}
                {level === 'ORDINARY' ? 'базовый' : 'сложный'}{' '}
                {tier === 'JUNIOR' ? 'младший' : 'старший'}
            </h4>
            {problemSet.problems.map((problem, i) => (
                <ProblemSettings
                    problemNumber={i}
                    key={i}
                    tournamentId={tournamentId}
                    problem={problem}
                    season={season}
                    level={level}
                    tier={tier}
                />
            ))}
            <h5 className="mt-4 mb-1">Добавить задачу</h5>
            <ProblemDataForm
                tournamentId={tournamentId}
                problem={null}
                season={season}
                level={level}
                tier={tier}
                problemNumber={problemSet.problems.length}
            />
        </>
    );
}

const updateProblemMarkToPointsMapMutation = gql`
    mutation UpdateProblemMarkToPointsMap(
        $tournamentId: ID!
        $season: Season!
        $level: Level!
        $tier: Tier!
        $problemNumber: Int!
        $subproblemMarkToPointsMaps: [SubproblemMarkToPointsMapInput]
    ) {
        updateProblemMarkToPointsMap(
            tournamentId: $tournamentId
            season: $season
            level: $level
            tier: $tier
            problemNumber: $problemNumber
            subproblemMarkToPointsMaps: $subproblemMarkToPointsMaps
        ) {
            __typename
            ... on UpdateProblemMarkToPointsMapSuccess {
                tournament {
                    _id
                    seasons {
                        fall {
                            ...problemsPageSeasonData
                        }
                        spring {
                            ...problemsPageSeasonData
                        }
                    }
                }
            }
        }
    }

    ${TournamentProblemsFragments}
`;

const marks = ['0', '-', '-.', '-+', '+/2', '+-', '+.', '+', '+!'];

function ProblemSettings({
    problem,
    tournamentId,
    season,
    level,
    tier,
    problemNumber,
}) {
    const { register, handleSubmit } = useForm();
    const [editing, setEditing] = useState(false);
    const [updateProblemMarkToPointsMap, { data, loading }] = useMutation(
        updateProblemMarkToPointsMapMutation
    );
    const { t } = useTranslation();
    const subproblemLabels = t('common:subproblemLabels').split('');

    return (
        <>
            <Row className="justify-content-center">
                <Col md={10}>
                    <h6>Задача {problemNumber + 1}</h6>
                    {editing ? (
                        <ProblemDataForm
                            tournamentId={tournamentId}
                            problem={problem}
                            season={season}
                            level={level}
                            tier={tier}
                            problemNumber={problemNumber}
                            onSuccess={() => setEditing(false)}
                        />
                    ) : (
                        <>
                            <ProblemText problem={problem} />
                            <Button
                                className="float-right"
                                variant="outline-primary"
                                onClick={() => setEditing(true)}
                            >
                                Редактировать
                            </Button>
                        </>
                    )}
                </Col>
            </Row>
            <br />
            <br />
            <Row className="justify-content-center">
                <Col md={10}>
                    <Form
                        onSubmit={handleSubmit(formData => {
                            updateProblemMarkToPointsMap({
                                variables: {
                                    tournamentId,
                                    season,
                                    level,
                                    tier,
                                    problemNumber,
                                    subproblemMarkToPointsMaps:
                                        problem.subproblems.map(
                                            (subproblem, i) => ({
                                                markToPointsMap: marks.map(
                                                    (mark, markIndex) => ({
                                                        mark,
                                                        points: Number(
                                                            formData[
                                                                `${problemNumber}-${i}-${markIndex}`
                                                            ]
                                                        ),
                                                    })
                                                ),
                                            })
                                        ),
                                },
                            });
                        })}
                    >
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th />
                                    {marks.map(mark => (
                                        <th className="text-center" key={mark}>
                                            {mark}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {problem.subproblems.map((subproblem, i) => (
                                    <tr key={i}>
                                        <th className="align-middle">
                                            {problem.subproblems.length > 1
                                                ? subproblemLabels[i]
                                                : ''}
                                        </th>
                                        {marks.map((mark, markIndex) => (
                                            <td key={mark}>
                                                <Form.Control
                                                    type="number"
                                                    {...register(
                                                        `${problemNumber}-${i}-${markIndex}`
                                                    )}
                                                    defaultValue={
                                                        subproblem.markToPointsMap?.find(
                                                            map =>
                                                                map.mark ===
                                                                mark
                                                        )?.points || 0
                                                    }
                                                    id={`problem-points-${problemNumber}-${i}-${markIndex}`}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <SubmitButton
                            loading={loading}
                            mutationData={data}
                            className="float-right"
                            variant="outline-primary"
                        >
                            Сохранить
                        </SubmitButton>
                    </Form>
                </Col>
            </Row>
            <br />
            <br />
            <br />
        </>
    );
}

const setProblemMutation = gql`
    mutation SetProblem(
        $tournamentId: ID!
        $season: Season!
        $level: Level!
        $tier: Tier!
        $problemNumber: Int!
        $text: String!
        $image: String
        $subproblems: [SubproblemInput!]!
    ) {
        setProblem(
            tournamentId: $tournamentId
            season: $season
            level: $level
            tier: $tier
            problemNumber: $problemNumber
            text: $text
            image: $image
            subproblems: $subproblems
        ) {
            __typename
            ... on SetProblemSuccess {
                tournament {
                    _id
                    seasons {
                        fall {
                            ...problemsPageSeasonData
                        }
                        spring {
                            ...problemsPageSeasonData
                        }
                    }
                }
            }
        }
    }

    ${TournamentProblemsFragments}
`;

function ProblemDataForm({
    problem,
    tournamentId,
    season,
    level,
    tier,
    problemNumber,
    onSuccess = () => {},
}) {
    const { register, handleSubmit, watch, reset } = useForm();
    const [setProblem, { data, loading }] = useMutation(setProblemMutation);
    const { t } = useTranslation();
    const subproblemLabels = t('common:subproblemLabels').split('');

    return (
        <Form
            onSubmit={handleSubmit(formData => {
                setProblem({
                    variables: {
                        tournamentId,
                        season,
                        level,
                        tier,
                        problemNumber,
                        ...problemFormDataToProblemInput(
                            formData,
                            subproblemLabels
                        ),
                    },
                }).then(({ data }) => {
                    if (data?.setProblem.__typename === 'SetProblemSuccess') {
                        reset();
                        onSuccess();
                    }
                });
            })}
        >
            <Form.Group controlId="problemData-text">
                <Form.Label>Текст задачи</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    {...register('text')}
                    defaultValue={problem?.text || ''}
                />
            </Form.Group>
            <Form.Group controlId="problemData-image">
                <Form.Label>URL картинки</Form.Label>
                <Form.Control
                    {...register('image')}
                    defaultValue={problem?.image || ''}
                />
            </Form.Group>
            {!watch('sub-0-text') && (
                <Form.Group controlId="problemData-sub-0-points">
                    <Form.Label>Баллы</Form.Label>
                    <Form.Control
                        {...register('sub-0-points')}
                        defaultValue={problem?.subproblems[0]?.points || '0'}
                    />
                </Form.Group>
            )}
            {subproblemLabels.map((name, subproblemNumber) => (
                <Row className="mb-2" key={subproblemNumber}>
                    <Col sm={1} className="text-right pt-2">
                        <strong>{name}</strong>
                    </Col>
                    {watch('sub-0-text') && (
                        <Col sm={2}>
                            <Form.Control
                                {...register(`sub-${subproblemNumber}-points`)}
                                defaultValue={
                                    problem?.subproblems[subproblemNumber]
                                        ?.points || '0'
                                }
                            />
                        </Col>
                    )}
                    <Col sm={watch('sub-0-text') ? 9 : 11}>
                        {
                            <Form.Control
                                {...register(`sub-${subproblemNumber}-text`)}
                                defaultValue={
                                    problem?.subproblems[subproblemNumber]
                                        ?.text || ''
                                }
                            />
                        }
                    </Col>
                </Row>
            ))}
            {watch('text') && (
                <>
                    <h6 className="mt-4">
                        Предпросмотр: Задача {problemNumber + 1}
                    </h6>
                    <ProblemText
                        problem={problemFormDataToProblemInput(
                            watch(),
                            subproblemLabels
                        )}
                    />
                </>
            )}
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="float-right"
            >
                Сохранить
            </SubmitButton>
        </Form>
    );
}

function problemFormDataToProblemInput(formData, subproblemLabels) {
    return {
        text: formData.text,
        image: formData.image || null,
        subproblems: subproblemLabels
            .map((name, subproblemNumber) => ({
                text: formData[`sub-${subproblemNumber}-text`] || '',
                points: Number(formData[`sub-${subproblemNumber}-points`] || 0),
            }))
            .filter(sp => sp.points || sp.text),
    };
}
