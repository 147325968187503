import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Page from '../Page';
import { MutationButton, SubmitButton } from 'Components/EnhancedButtons';
import { useForm } from 'react-hook-form';
import copy from 'copy-to-clipboard';

const adminUsersPageQuery = gql`
    query AdminUsersPage($role: Role) {
        users(role: $role) {
            _id
            name
            email
            role
            verified
        }
    }
`;

const adminUserByEmailQuery = gql`
    query AdminUsersByEmail($email: String!) {
        userByEmail(email: $email) {
            _id
            name
            email
            role
            verified
        }
    }
`;

const manuallyVerifyEmailMutation = gql`
    mutation ManuallyVerifyEmail($userId: ID!) {
        manuallyVerifyEmail(userId: $userId) {
            __typename
            ... on ManuallyVerifyEmailSuccess {
                user {
                    _id
                    name
                    email
                    role
                    verified
                }
            }
        }
    }
`;

const adminResetPasswordMutation = gql`
    mutation AdminResetPassword($userId: ID!) {
        adminResetPassword(userId: $userId) {
            __typename
            ... on AdminResetPasswordSuccess {
                resetLink
            }
        }
    }
`;

const addUserMutation = gql`
    mutation AddUser($email: String!, $role: Role!, $name: String!) {
        addUser(email: $email, name: $name, role: $role) {
            __typename
        }
    }
`;

const roles = {
    PARTICIPANT: 'Участник',
    ORGANIZER: 'Организатор',
    INPUTTER: 'Вбивальщик',
    JURY: 'Жюри',
    ADMIN: 'Админ',
};

export default function AdminUsersPage() {
    const params = useParams<{ role: string }>();
    const role = params.role.toUpperCase();

    const { data, loading, refetch } = useQuery(adminUsersPageQuery, {
        variables: {
            role,
        },
        skip: role === 'PARTICIPANT',
    });
    const [email, setEmail] = useState('');
    const { data: byEmailData, loading: byEmailLoading } = useQuery(
        adminUserByEmailQuery,
        {
            variables: {
                email,
            },
            skip: !email,
        }
    );
    const [manuallyVerifyEmail] = useMutation(manuallyVerifyEmailMutation);
    const [addUser, { data: addUserData, loading: addUserLoading }] =
        useMutation(addUserMutation);
    const { register, handleSubmit, reset } = useForm();
    const { t } = useTranslation();
    const [resetLinkData, setResetLinkData] = useState(null);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRoleUser, setSelectedRoleUser] = useState(null);

    const selectUser = e => {
        setSelectedUser(e.currentTarget.dataset.id);
    };

    const selectRoleUser = e => {
        setSelectedRoleUser(e.currentTarget.dataset.id);
    };

    if (loading || byEmailLoading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    let errorMessage = null;

    if (addUserData?.addUser.__typename === 'AlreadySignedUpError') {
        errorMessage = 'Пользователь уже зарегистрирован';
    }

    const users =
        data?.users ||
        (byEmailData?.userByEmail ? [byEmailData.userByEmail] : []);

    return (
        <Page>
            {['JURY', 'ADMIN', 'INPUTTER'].includes(role) && (
                <>
                    <h4>Добавить пользователя</h4>
                    <Form
                        inline={true}
                        onSubmit={handleSubmit(formData => {
                            addUser({ variables: formData }).then(
                                ({ data }) => {
                                    if (
                                        data?.addUser.__typename ===
                                        'AddUserSuccess'
                                    ) {
                                        reset();
                                        refetch({
                                            role,
                                        });
                                    }
                                }
                            );
                        })}
                    >
                        <Form.Label htmlFor="add-user-name" srOnly>
                            Имя
                        </Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2 add-user-name"
                            id="add-user-name"
                            placeholder="Имя"
                            defaultValue=""
                            {...register('name', { required: true })}
                        />
                        <Form.Label htmlFor="add-user-email" srOnly>
                            Email
                        </Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2 add-user-email"
                            id="add-user-email"
                            placeholder="Email"
                            defaultValue=""
                            {...register('email', { required: true })}
                        />
                        <Form.Label htmlFor="add-user-role" srOnly>
                            Роль
                        </Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2"
                            id="add-user-role"
                            {...register('role', { required: true })}
                            as="select"
                            defaultValue={role}
                        >
                            <option value="JURY">Жюри</option>
                            <option value="ADMIN">Админ</option>
                            <option value="INPUTTER">Вбивальщик</option>
                        </Form.Control>
                        <SubmitButton
                            loading={addUserLoading}
                            mutationData={addUserData}
                            className="mb-2"
                        >
                            Добавить
                        </SubmitButton>
                    </Form>
                    <br />
                    {errorMessage && (
                        <>
                            <Alert variant="warning">{errorMessage}</Alert>
                            <br />
                        </>
                    )}
                </>
            )}

            {role === 'PARTICIPANT' && (
                <>
                    <Form
                        inline={true}
                        onSubmit={handleSubmit(formData => {
                            setEmail(formData.email);
                        })}
                    >
                        <Form.Label htmlFor="search-user-email" srOnly>
                            Email
                        </Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2 add-user-name"
                            id="search-user-email"
                            placeholder="Email"
                            defaultValue=""
                            {...register('email', { required: true })}
                        />
                        <SubmitButton
                            loading={byEmailLoading}
                            mutationData={
                                byEmailData
                                    ? { found: { __typename: 'Success' } }
                                    : null
                            }
                            className="mb-2"
                        >
                            Найти
                        </SubmitButton>
                    </Form>
                    <br />
                    {errorMessage && (
                        <>
                            <Alert variant="warning">{errorMessage}</Alert>
                            <br />
                        </>
                    )}
                </>
            )}

            {!users.length && (email || role !== 'PARTICIPANT') ? (
                <h6>Не найдено пользователей</h6>
            ) : null}

            {users.length ? (
                <Table striped={true} hover={true} borderless={true} size="sm">
                    <thead>
                        <tr>
                            {['JURY', 'ADMIN', 'ORGANIZER'].includes(role) && (
                                <th>Имя</th>
                            )}
                            <th>Email</th>
                            <th>Роль</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                {['JURY', 'ADMIN', 'ORGANIZER'].includes(
                                    role
                                ) && (
                                    <td
                                        data-id={user._id}
                                        onClick={selectUser}
                                        className="users-table-name"
                                    >
                                        {user.name}
                                    </td>
                                )}
                                <td>{user.email}</td>
                                <td
                                    data-id={user._id}
                                    onClick={selectRoleUser}
                                    className="users-table-name"
                                >
                                    {roles[user.role]}
                                </td>
                                <td>
                                    {user.verified ? (
                                        'Подтверждён'
                                    ) : (
                                        <Button
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={() =>
                                                manuallyVerifyEmail({
                                                    variables: {
                                                        userId: user._id,
                                                    },
                                                })
                                            }
                                        >
                                            Подтвердить
                                        </Button>
                                    )}
                                </td>
                                <td>
                                    <MutationButton
                                        size="sm"
                                        variant="outline-danger"
                                        mutation={adminResetPasswordMutation}
                                        variables={{ userId: user._id }}
                                        onMutate={({ data }) => {
                                            if (
                                                data?.adminResetPassword
                                                    .__typename ===
                                                'AdminResetPasswordSuccess'
                                            ) {
                                                setResetLinkData({
                                                    email: user.email,
                                                    link: data
                                                        ?.adminResetPassword
                                                        .resetLink,
                                                });
                                            }
                                        }}
                                    >
                                        Сбросить пароль
                                    </MutationButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : null}
            {selectedUser && (
                <EditUserNameModal
                    user={users.find(u => u._id === selectedUser)}
                    onHide={() => setSelectedUser(null)}
                />
            )}
            {selectedRoleUser && (
                <EditUserRoleModal
                    user={users.find(u => u._id === selectedRoleUser)}
                    onHide={() => setSelectedRoleUser(null)}
                />
            )}
            {resetLinkData && (
                <ResetLinkDataModal
                    resetLinkData={resetLinkData}
                    onHide={() => setResetLinkData(null)}
                />
            )}
        </Page>
    );
}

function ResetLinkDataModal({ resetLinkData, onHide }) {
    const [copied, setCopied] = useState(false);

    return (
        <Modal show={true} size="lg" onHide={onHide} centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{resetLinkData.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3">
                    Ссылка для установления нового пароля (отправьте
                    пользователю):
                </p>
                <Form.Group>
                    <Form.Control
                        defaultValue={resetLinkData.link}
                        readOnly={true}
                        className="bg-white"
                    />
                </Form.Group>
                <SubmitButton
                    className="mt-3"
                    loading={false}
                    mutationData={
                        copied ? { a: { __typename: 'Success' } } : null
                    }
                    onClick={async () => {
                        await copy(resetLinkData.link);
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                    }}
                >
                    Скопировать
                </SubmitButton>
            </Modal.Body>
        </Modal>
    );
}

const setUserNameMutation = gql`
    mutation SetUserNameEmail($userId: ID!, $name: String!) {
        setUserName(userId: $userId, name: $name) {
            __typename
            ... on SetUserNameSuccess {
                user {
                    _id
                    name
                    email
                    role
                    verified
                }
            }
        }
    }
`;

function EditUserNameModal({ user, onHide }) {
    const { register, handleSubmit } = useForm();
    const [setUserName, { data, loading }] = useMutation(setUserNameMutation);

    return (
        <Modal show={true} size="lg" onHide={onHide} centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{user.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(({ name }) => {
                        setUserName({
                            variables: {
                                userId: user._id,
                                name,
                            },
                        });
                    })}
                >
                    <Form.Group controlId="edit-user-name">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                            {...register('name')}
                            defaultValue={user.name || ''}
                        />
                    </Form.Group>
                    <SubmitButton loading={loading} mutationData={data}>
                        Сохранить
                    </SubmitButton>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const setUserRoleMutation = gql`
    mutation SetUserRole($userId: ID!, $role: Role!) {
        setUserRole(userId: $userId, role: $role) {
            __typename
            ... on SetUserRoleSuccess {
                user {
                    _id
                    name
                    email
                    role
                    verified
                }
            }
        }
    }
`;

function EditUserRoleModal({ user, onHide }) {
    const { register, handleSubmit } = useForm();
    const [setUserRole, { data, loading }] = useMutation(setUserRoleMutation);

    const errorMessage =
        data?.setUserRole?.__typename === 'UserHasParticipantsError'
            ? 'Нельзя сменить роль пользователю, зарегистрированному на один из Турниров'
            : null;

    return (
        <Modal show={true} size="lg" onHide={onHide} centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{user.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(({ role }) => {
                        setUserRole({
                            variables: {
                                userId: user._id,
                                role,
                            },
                        });
                    })}
                >
                    <Form.Group controlId="edit-user-role">
                        <Form.Label>Роль</Form.Label>
                        <Form.Control
                            className="mb-2 mr-sm-2"
                            {...register('role', { required: true })}
                            as="select"
                            defaultValue={user.role}
                        >
                            <option value="PARTICIPANT">Участник</option>
                            <option value="ORGANIZER">Организатор</option>
                            <option value="INPUTTER">Вбивальщик</option>
                            <option value="JURY">Жюри</option>
                            <option value="ADMIN">Админ</option>
                        </Form.Control>
                    </Form.Group>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <SubmitButton loading={loading} mutationData={data}>
                        Сохранить
                    </SubmitButton>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
