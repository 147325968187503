import i18next, { addResources, getNavigatorLocale } from '@turgor/i18n';
import { initReactI18next } from 'react-i18next';
import en from './en';
import ru from './ru';
import { getLocalSetting, setLocalSetting } from '../localSettings';
import { apolloClient } from '../apolloClient';
import { gql } from '@apollo/client';

const setLanguageMutation = gql`
    mutation SetLanguageMutation($language: UserLanguage!) {
        setLanguage(language: $language) {
            ... on SetLanguageSuccess {
                __typename
                user {
                    _id
                    language
                }
            }
        }
    }
`;

i18next
    .use(initReactI18next)
    .init()
    .then(() => {
        changeLocale(
            getLocalSetting('user', 'locale', '') ||
                getNavigatorLocale(navigator)
        );
    });

addResources({ en, ru });

export function changeLocale(locale, saveLocal = false, saveRemote = false) {
    document.documentElement.setAttribute('lang', locale);
    i18next.changeLanguage(locale);

    if (saveLocal) {
        setLocalSetting('user', 'locale', locale);
    }

    if (saveRemote) {
        apolloClient.mutate({
            mutation: setLanguageMutation,
            variables: { language: locale.toUpperCase() },
        });
    }
}

export default i18next;
