import { Alert, Form } from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import Page from '../Page';
import { SubmitButton } from 'Components/EnhancedButtons';
import { Link, useParams } from 'react-router-dom';
import CheckEmailLink from '../Components/CheckEmailLink';
import {setLocalSetting} from "../localSettings";

const signUpMutation = gql`
    mutation SignUp(
        $email: String!
        $password: String!
        $consent: Boolean!
        $role: SignUpRole!
        $language: UserLanguage!
    ) {
        signUp(
            email: $email
            password: $password
            consent: $consent
            role: $role
            language: $language
        ) {
            __typename
            ... on Error {
                message
            }
            ... on SignUpSuccess {
                email
            }
        }
    }
`;

export default function SignUpPage() {
    const { t } = useTranslation();
    const params = useParams<{ role: string }>();
    const role =
        params.role?.toLowerCase() === 'organizer'
            ? 'ORGANIZER'
            : 'PARTICIPANT';

    const [registeredEmail, setRegisteredEmail] = useState<string | null>(null);

    useEffect(() => {
        if (role === 'ORGANIZER') {
            setLocalSetting('sign-up', 'isOrganizer', true, true);
        }
    }, [role])

    return (
        <Page layout="narrow">
            <h1 className="h2">
                {t(
                    role === 'ORGANIZER'
                        ? 'SignUpPage.HeaderOrganizers'
                        : 'SignUpPage.Header'
                )}
            </h1>
            {!registeredEmail && (
                <SignUpForm
                    onSuccess={email => setRegisteredEmail(email)}
                    role={role}
                />
            )}
            {registeredEmail && <SignUpSuccess email={registeredEmail} />}
        </Page>
    );
}

function SignUpForm({
    onSuccess,
    role,
}: {
    onSuccess: (email: string) => void;
    role: 'PARTICIPANT' | 'ORGANIZER';
}) {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const {
        register,
        handleSubmit,

        formState: { errors: formErrors },
    } = useForm();
    const [signUp, { loading, error, data }] = useMutation(signUpMutation);

    let errorMessage = null;

    if (
        error ||
        !['SignUpSuccess', 'AlreadySignedUpError', undefined].includes(
            data?.signUp.__typename
        )
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (data?.signUp.__typename === 'AlreadySignedUpError') {
        errorMessage = t('SignUpPage.AlreadySignedUpErrorMessage');
    }

    return (
        <>
            <p>
                {t('SignUpPage.AlreadyRegistered')}{' '}
                <Link to="/sign-in">{t('SignUpPage.SignIn')}</Link>
            </p>
            <Alert
                variant="warning"
                className={classnames({
                    invisible: !errorMessage,
                })}
            >
                {errorMessage || '&nbsp;'}
            </Alert>
            <Form
                onSubmit={handleSubmit(formData => {
                    signUp({
                        variables: {
                            ...formData,
                            role,
                            language: language === 'ru' ? 'RU' : 'EN',
                        },
                    }).then(({ data }) => {
                        if (data?.signUp.__typename === 'SignUpSuccess') {
                            try {
                                localStorage.setItem(
                                    'hasBeenSignedIn',
                                    new Date().toISOString()
                                );
                            } catch {}

                            onSuccess(data.signUp.email);
                        }
                    });
                })}
                noValidate={true}
            >
                <Form.Group controlId="sign-up-email">
                    <Form.Label>{t('SignUpPage.EmailLabel')}</Form.Label>
                    <Form.Control
                        {...register('email', {
                            required: true,
                            pattern: /^.+@.+$/,
                        })}
                        type="email"
                        defaultValue=""
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classnames('d-block', {
                            invisible: !formErrors.email,
                        })}
                    >
                        {!formErrors.email && '&nbsp;'}
                        {formErrors.email?.type === 'required' &&
                            t('SignUpPage.EmailRequired')}
                        {formErrors.email?.type === 'pattern' &&
                            t('SignUpPage.EmailPatternFailed')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="sign-up-password">
                    <Form.Label>{t('SignUpPage.PasswordLabel')}</Form.Label>
                    <Form.Control
                        {...register('password', {
                            required: true,
                            minLength: 8,
                        })}
                        type="password"
                        defaultValue=""
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classnames('d-block', {
                            invisible: !formErrors.password,
                        })}
                    >
                        {!formErrors.password && '&nbsp;'}
                        {formErrors.password?.type === 'required' &&
                            t('SignUpPage.PasswordRequired')}
                        {formErrors.password?.type === 'minLength' &&
                            t('SignUpPage.PasswordMinLength')}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="sign-up-consent">
                    <Form.Check type="checkbox">
                        <Form.Check.Input
                            type="checkbox"
                            {...register('consent', { required: true })}
                        />
                        <Form.Check.Label>
                            {t('SignUpPage.Consent')}{' '}
                            <Link to="/privacy-policy">
                                {t('SignUpPage.PrivacyPolicy')}
                            </Link>
                        </Form.Check.Label>
                        <Form.Control.Feedback
                            type="invalid"
                            className={classnames('d-block', {
                                invisible: !formErrors.consent,
                            })}
                        >
                            {formErrors.consent
                                ? t('SignUpPage.ConsentRequired')
                                : '&nbsp;'}
                        </Form.Control.Feedback>
                    </Form.Check>
                </Form.Group>
                <SubmitButton loading={loading} mutationData={data}>
                    {t('SignUpPage.SignUpButton')}
                </SubmitButton>
            </Form>
        </>
    );
}

function SignUpSuccess({ email }: { email: string }) {
    const { t } = useTranslation();

    return (
        <>
            <Alert variant="success">
                {t('SignUpPage.SignUpSuccessMessage')}
            </Alert>
            <p>
                <Trans
                    i18nKey="SignUpPage.CompleteSignUpProcess"
                    values={{ email }}
                />
            </p>
            <CheckEmailLink email={email} />
        </>
    );
}
