"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findPaperInParticipant = exports.findProblemsInTournament = exports.findProblemSetInTournament = exports.findLastByFields = exports.findByFields = void 0;
function findByFields(array, fields) {
    return (array.find(element => Object.keys(fields)
        .filter(field => fields[field] !== undefined)
        .every(field => Array.isArray(fields[field])
        ? fields[field].includes(element[field])
        : element[field] === fields[field])) || null);
}
exports.findByFields = findByFields;
function findLastByFields(array, fields) {
    return findByFields([...array].reverse(), fields);
}
exports.findLastByFields = findLastByFields;
function isTournament(tournamentOrSeasons) {
    return Boolean(tournamentOrSeasons.seasons);
}
function isFindProblemSetInTournamentPaperParams(params) {
    return typeof params[1] !== 'string';
}
function findProblemSetInTournament(...params) {
    const tournamentOrSeasons = params[0];
    const seasons = isTournament(tournamentOrSeasons)
        ? tournamentOrSeasons.seasons
        : tournamentOrSeasons;
    const season = isFindProblemSetInTournamentPaperParams(params)
        ? params[1].season
        : params[1];
    const level = isFindProblemSetInTournamentPaperParams(params)
        ? params[1].level
        : params[2];
    const tier = isFindProblemSetInTournamentPaperParams(params)
        ? params[1].tier
        : params[3];
    return seasons[season.toLowerCase()][level.toLowerCase()][tier.toLowerCase()];
}
exports.findProblemSetInTournament = findProblemSetInTournament;
function findProblemsInTournament(...params) {
    return findProblemSetInTournament(...params).problems;
}
exports.findProblemsInTournament = findProblemsInTournament;
function findPaperInParticipant(participant, papersFilter) {
    const { season, level, tier } = papersFilter;
    const stages = papersFilter.stage ||
        (papersFilter.submittedOnly
            ? ['SUBMITTED', 'UPLOADED', 'MARKED', 'PUBLISHED']
            : undefined);
    return findByFields(participant.papers, {
        stage: stages,
        season,
        level,
        tier,
    });
}
exports.findPaperInParticipant = findPaperInParticipant;
