import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../../Page';
import { Nav, Spinner, Table } from 'react-bootstrap';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';

const adminVenueParticipantsQuery = gql`
    query AdminVenueParticipants($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            organization
            participants {
                _id
                surname
                name
                school
                grade
                email
                townName
                papers {
                    _id
                    season
                    level
                    venue {
                        _id
                    }
                }
            }
            tournament {
                number
            }
        }
    }
`;

export default function AdminVenueParticipantsPage() {
    let { venueId, season } = useParams<{ venueId: string; season: string }>();
    season = season.toUpperCase();
    const { data, loading } = useQuery(adminVenueParticipantsQuery, {
        variables: { venueId },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const participants: Array<any> = data.venue.participants
        .filter(participant =>
            participant.papers.some(
                p => p.venue?._id === venueId && p.season === season
            )
        )
        .sort(participantsSortFn);

    const ordinaryCount = participants.filter(participant =>
        participant.papers.some(
            p =>
                p.venue?._id === venueId &&
                p.season === season &&
                p.level === 'ORDINARY'
        )
    ).length;

    const advancedCount = participants.filter(participant =>
        participant.papers.some(
            p =>
                p.venue?._id === venueId &&
                p.season === season &&
                p.level === 'ADVANCED'
        )
    ).length;

    return (
        <Page
            nav={
                <>
                    <Nav.Link
                        href={`/admin/${data.venue.tournament.number}/venues/${data.venue._id}`}
                    >
                        {data.venue.townName}
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to={`/tournaments/${data.venue.tournament.number}/venues/${data.venue._id}/papers/all`}
                    >
                        Работы
                    </Nav.Link>
                </>
            }
        >
            <h2>{data.venue.townName}</h2>
            {data.venue.organization && <h5>{data.venue.organization}</h5>}
            <br />
            <h3>Участники</h3>
            {!participants.length && 'Пока никто не зарегистрировался'}
            {participants.length > 0 && (
                <Table striped={true} hover={true} borderless={true} size="sm">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Город</th>
                            <th>Школа</th>
                            <th>Класс</th>
                            <th>Email</th>
                            <th>Базовый ({ordinaryCount})</th>
                            <th>Сложный ({advancedCount})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {participants.map((participant, i: number) => (
                            <tr key={participant._id}>
                                <td>
                                    <Link
                                        to={`/tournaments/${data.venue.tournament.number}/participants/${participant._id}`}
                                    >
                                        {i + 1}
                                    </Link>
                                </td>
                                <td>{participant.surname}</td>
                                <td>{participant.name}</td>
                                <td>{participant.townName}</td>
                                <td>{participant.school}</td>
                                <td>{participant.grade}</td>
                                <td>{participant.email}</td>
                                <td>
                                    {participant.papers.find(
                                        p =>
                                            p.venue?._id === venueId &&
                                            p.season === season &&
                                            p.level === 'ORDINARY'
                                    )
                                        ? '✓'
                                        : null}
                                </td>
                                <td>
                                    {participant.papers.find(
                                        p =>
                                            p.venue?._id === venueId &&
                                            p.season === season &&
                                            p.level === 'ADVANCED'
                                    )
                                        ? '✓'
                                        : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Page>
    );
}
