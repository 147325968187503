import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../Page';

export default function PrivacyPolicyPage() {
    const { i18n } = useTranslation();

    return (
        <Page layout="narrow">
            {i18n.language === 'ru' && <RuPrivacyPolicy />}
            {i18n.language === 'en' && <EnPrivacyPolicy />}
        </Page>
    );
}

function RuPrivacyPolicy() {
    return (
        <>
            <h1>Политика конфиденциальности и обработки персональных данных</h1>
            <ol>
                <li>
                    В соответствии с требованиями Федерального закона №152-ФЗ от
                    27.07.2006 «О персональных данных» физическое лицо (далее —
                    Пользователь), регистрируясь на сайте{' '}
                    {window.location.origin}, даёт добровольное согласие на
                    обработку своих персональных данных оператору (далее —
                    Оператор) списка победителей и призеров олимпиады «Турнир
                    городов» (ГАОУ ДПО ЦПМ), АНО ЦМО «Турнир городов», ЧОУ
                    Московский Центр Непрерывного Математического Образования, в
                    объёме и способами, изложенными в настоящей Политике.
                </li>
                <li>
                    Данные, в отношении которых Пользователь даёт согласие на
                    обработку, могут содержать: адрес электронной почты,
                    фамилию, имя, отчество, информацию о месте учёбы, номер
                    мобильного телефона.
                </li>
                <li>
                    Цель обработки персональных данных Оператором: проведение
                    олимпиады «Турнир городов», коммуникация с Пользователями в
                    рамках деятельности Оператора по проведению олимпиады.
                </li>
                <li>
                    Обработка персональных данных Пользователя может
                    осуществляться Оператором в ручном режиме и в
                    автоматизированной информационной системе и заключается в
                    сборе, записи, систематизации, накоплении, хранении,
                    уточнении (обновлении, изменении), извлечении,
                    использовании, передаче (распространении, предоставлении
                    доступа), удалении данных.
                </li>
                <li>
                    Следующие сведения: «фамилия, имя, отчество, название и
                    номер школы, класс обучения» могут быть размещены на сайте
                    https://turgor.ru в списках участников Турнира и в списках
                    победителей и призёров Турнира, указаны на дипломах.
                </li>
                <li>
                    Предоставленное согласие действует без ограничения по сроку,
                    до даты его отзыва Пользователем.
                </li>
                <li>
                    Предоставленное согласие может быть отозвано Пользователем
                    путем направления заявления в произвольной письменной форме
                    в адрес Оператора или на почту технической поддержки сайта
                    support@turgor.ru. В случае отзыва согласия Оператор вправе
                    продолжить обработку персональных данных при наличии
                    оснований, перечисленных в пп. 2 — 11 ч. 1 ст. 6, ч. 2 ст.
                    10, ч. 2 ст. 11 Федерального закона от 27.07.2006 г. №
                    152-ФЗ.
                </li>
            </ol>
        </>
    );
}

function EnPrivacyPolicy() {
    return (
        <>
            <h1>Privacy and personal data processing policy</h1>
            <ol>
                <li>
                    By signing up on {window.location.origin}, you agree to your
                    personal data being processed by the operator (hereinafter
                    referred to as the Operator) of the awardees list for the
                    Tournament of Towns (CPM), ANO CMO «Turnir Gorodov», Moscow
                    Center for Continuous Mathematical Education, as described
                    in this Privacy Policy.
                </li>
                <li>
                    Data that the User agrees to be processed may consist of:
                    email address, full name, affiliation information (e.g.
                    school name), phone number
                </li>
                <li>
                    Goal of the data processing: Conducting of the International
                    Mathematical Tournament of Towns, communication with the
                    User for the purposes of the olympiad.
                </li>
                <li>
                    Data processing may be carried out manually and/or using
                    automated systems.
                </li>
                <li>
                    The following data: "full name, school, grade" may be
                    published on https://turgor.ru in the participants and
                    awardees lists, printed on the diplomas.
                </li>
                <li>
                    The consent is considered active until the User withdraws
                    it.
                </li>
                <li>
                    The consent can we withdrawn by the User by contacting the
                    Operator at support@turgor.ru. Some data may be retained
                    under the conditions of applicable laws.
                </li>
            </ol>
        </>
    );
}
