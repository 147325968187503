"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ru = {
    translation: {},
    common: {
        count: {
            participants_0: '{{count}} участник',
            participants_1: '{{count}} участника',
            participants_2: '{{count}} участников',
            papers_0: '{{count}} работа',
            papers_1: '{{count}} работы',
            papers_2: '{{count}} работ',
            diplomas_0: '{{count}} диплом',
            diplomas_1: '{{count}} диплома',
            diplomas_2: '{{count}} дипломов',
            prizes_0: '{{count}} премия',
            prizes_1: '{{count}} премии',
            prizes_2: '{{count}} премий',
            points_0: '{{count}} балл',
            points_1: '{{count}} балла',
            points_2: '{{count}} баллов',
            venues_0: '{{count}} место проведения',
            venues_1: '{{count}} места проведения',
            venues_2: '{{count}} мест проведения',
            newVenueApplications_0: '{{count}} новая заявка на проведение',
            newVenueApplications_1: '{{count}} новых заявки на проведение',
            newVenueApplications_2: '{{count}} новых заявок на проведение',
            startedOnline_0: '{{count}} начал онлайн',
            startedOnline_1: '{{count}} начали онлайн',
            startedOnline_2: '{{count}} начало онлайн',
            finishedOnline_0: '{{count}} сдал онлайн',
            finishedOnline_1: '{{count}} сдали онлайн',
            finishedOnline_2: '{{count}} сдало онлайн',
            marked_0: '{{count}} проверена',
            marked_1: '{{count}} проверены',
            marked_2: '{{count}} проверено',
            published_0: '{{count}} опубликована',
            published_1: '{{count}} опубликованы',
            published_2: '{{count}} опубликовано',
            schools_0: '{{count}} школа',
            schools_1: '{{count}} школы',
            schools_2: '{{count}} школ',
        },
        problems: 'задачи',
        subproblemLabels: 'абвгде',
        SeasonNoun_fall: 'Осень',
        SeasonNoun_FALL: 'Осень',
        SeasonNoun_spring: 'Весна',
        SeasonNoun_SPRING: 'Весна',
        seasonNoun_fall: 'осень',
        seasonNoun_FALL: 'осень',
        seasonNoun_spring: 'весна',
        seasonNoun_SPRING: 'весна',
        Season_fall: 'Осенний',
        Season_FALL: 'Осенний',
        Season_spring: 'Весенний',
        Season_SPRING: 'Весенний',
        season_fall: 'осенний',
        season_FALL: 'осенний',
        season_spring: 'весенний',
        season_SPRING: 'весенний',
        level_ordinary: 'базовый',
        level_ORDINARY: 'базовый',
        level_advanced: 'сложный',
        level_ADVANCED: 'сложный',
        Level_ordinary: 'Базовый',
        Level_ORDINARY: 'Базовый',
        Level_advanced: 'Сложный',
        Level_ADVANCED: 'Сложный',
        tier_junior: 'младший',
        tier_JUNIOR: 'младший',
        tier_senior: 'старший',
        tier_SENIOR: 'старший',
        Tier_junior: 'Младший',
        Tier_JUNIOR: 'Младший',
        Tier_senior: 'Cтарший',
        Tier_SENIOR: 'Cтарший',
        fullLevelName: '$t(common:season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" })',
        FullLevelName: '$t(common:Season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" })',
        tierName: '$t(common:level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
        TierName: '$t(common:Level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
        fullTierName: '$t(common:season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
        FullTierName: '$t(common:Season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
    },
};
exports.default = ru;
