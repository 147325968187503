import { provideLayoutAndData } from '../Page';
import { gql, useMutation } from '@apollo/client';
import {
    AdminVenueApplicationPageQuery,
    AdminVenueApplicationPageQueryVariables,
} from '../GraphQL/types/AdminVenueApplicationPageQuery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateVenueApplicationForm } from '../Organizer/CreateVenueApplicationForm';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '../Components/EnhancedButtons';
import { Link } from 'react-router-dom';

const adminVenueApplicationPageQuery = gql`
    query AdminVenueApplicationPageQuery($venueApplicationId: ID!) {
        venueApplication(id: $venueApplicationId) {
            _id
            stage
            submittedAt
            townName
            season
            tournament {
                _id
                number
                seasons {
                    fall {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                    spring {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                }
            }
            levels
            organization
            organizationDescription
            email
            phone
            address
            infoMessage
            contacts {
                email
                phone
                website
                telegram
                whatsapp
                viber
                fbMessenger
            }
            startTime
            access
            accessComment
            accessDescription
            participantsNumberLimit
            comment
            organizer {
                _id
                language
                email
            }
            venue {
                _id
                townName
            }
        }
    }
`;

export default provideLayoutAndData<
    AdminVenueApplicationPageQuery,
    AdminVenueApplicationPageQueryVariables
>(adminVenueApplicationPageQuery, {
    variablesGetter: ({ venueApplicationId }) => ({ venueApplicationId }),
    authenticatedOnly: true,
    queryOptions: {
        fetchPolicy: 'network-only',
    },
})(function OrganizerVenueApplicationPage({
    data: { venueApplication },
    viewer,
}) {
    const { t } = useTranslation();

    const isActive = venueApplication.stage === 'CREATED';
    const { season, tournament } = venueApplication;

    return (
        <>
            <h2>
                {t('SeasonFullTitle', {
                    number: tournament.number,
                    season: venueApplication.season,
                })}
            </h2>
            {venueApplication.stage === 'REJECTED' && (
                <>
                    <Alert variant="danger">Данная заявка отклонена.</Alert>
                    <br />
                </>
            )}
            {venueApplication.stage === 'APPROVED' && (
                <>
                    <Alert variant="success">
                        Заявка одобрена. Место проведения:{' '}
                        <a
                            href={`/admin/${tournament.number}/venues/${venueApplication.venue._id}`}
                        >
                            {venueApplication.venue.townName}
                        </a>
                    </Alert>
                    <br />
                </>
            )}
            {isActive && venueApplication.venue && (
                <>
                    <Alert variant="info">
                        В этой точке проводился осенний тур:{' '}
                        <a
                            href={`/admin/${tournament.number}/venues/${venueApplication.venue._id}`}
                        >
                            {venueApplication.venue.townName}
                        </a>
                    </Alert>
                    <br />
                </>
            )}
            <CreateVenueApplicationForm
                viewerEmail=""
                onSubmit={() => {}}
                mutationData={null}
                mutationError={false}
                loading={false}
                language={venueApplication.organizer.language}
                application={venueApplication}
                submitButtonText="Сохранить"
                readOnly={true}
                ordinaryDate={
                    tournament.seasons[season.toLowerCase()].ordinary.date
                }
                advancedDate={
                    tournament.seasons[season.toLowerCase()].advanced.date
                }
            />

            {isActive && (
                <>
                    {' '}
                    <br />
                    <br />
                    <RejectApplicationButton
                        venueApplication={venueApplication}
                    />
                    {!venueApplication.venue && (
                        <Button
                            variant="primary"
                            as={Link}
                            to={`/tournaments/${tournament.number}/venues/add/${venueApplication._id}`}
                        >
                            Принять заявку
                        </Button>
                    )}
                    {venueApplication.venue && (
                        <Button
                            variant="primary"
                            as={Link}
                            to={`/tournaments/${tournament.number}/venues/${venueApplication.venue._id}/spring-application/${venueApplication._id}`}
                        >
                            Принять заявку
                        </Button>
                    )}
                </>
            )}
            <br />
            <br />
            <br />
            <br />
        </>
    );
});

const rejectVenueApplicationMutation = gql`
    mutation RejectVenueApplication(
        $venueApplicationId: ID!
        $message: String!
    ) {
        rejectVenueApplication(
            venueApplicationId: $venueApplicationId
            message: $message
        ) {
            ... on RejectVenueApplicationSuccess {
                __typename
                venueApplication {
                    _id
                    stage
                }
            }
        }
    }
`;

function RejectApplicationButton({ venueApplication }) {
    const [open, setOpen] = useState(false);
    const [rejectVenueApplication, { data, error, loading }] = useMutation(
        rejectVenueApplicationMutation
    );
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const errorMessage = error ? t('UnknownErrorMessage') : null;
    const defaultRejectionMessage = t(
        'AdminHomePage.DefaultVenueApplicationRejectionMessage',
        {
            lng: venueApplication.organizer.language.toLowerCase(),
            submittedAt: new Date(venueApplication.submittedAt),
            townName: venueApplication.townName,
        }
    );

    return (
        <>
            <Button
                variant="outline-danger"
                className="mr-4"
                onClick={() => {
                    setOpen(true);
                }}
            >
                Отклонить заявку
            </Button>
            <Modal
                show={open}
                size="lg"
                onHide={() => {
                    setOpen(false);
                }}
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Отклонить заявку</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        onSubmit={handleSubmit(({ message }) => {
                            rejectVenueApplication({
                                variables: {
                                    venueApplicationId: venueApplication._id,
                                    message,
                                },
                            }).then(({ data }) => {
                                if (
                                    data?.rejectVenueApplication.__typename ===
                                    'RejectVenueApplicationSuccess'
                                ) {
                                    setOpen(false);
                                }
                            });
                        })}
                    >
                        <Form.Group controlId="reject-venue-application-message">
                            <Form.Label>Сообщение для организатора</Form.Label>
                            <Form.Control
                                as="textarea"
                                {...register('message', {
                                    required: true,
                                })}
                                defaultValue={defaultRejectionMessage}
                                rows={8}
                            />
                            <Form.Text muted>
                                Опишите причины. Сообщение будет отправлено на{' '}
                                <b>{venueApplication.organizer.email}</b>.
                            </Form.Text>
                            {errors.message && (
                                <Form.Control.Feedback
                                    className="d-block"
                                    type="invalid"
                                >
                                    Поле обязательно для заполнения
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <SubmitButton
                            loading={loading}
                            mutationData={data}
                            variant="danger"
                        >
                            Отклонить заявку
                        </SubmitButton>
                    </Form>
                    {errorMessage && (
                        <Alert variant="warning" className="mt-2">
                            {errorMessage}
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}
