import { provideLayoutAndData } from '../Page';
import { gql, useMutation } from '@apollo/client';
import {
    OrganizerCreateApplicationPageQuery,
    OrganizerCreateApplicationPageQueryVariables,
} from '../GraphQL/types/OrganizerCreateApplicationPageQuery';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CreateVenueApplicationForm } from './CreateVenueApplicationForm';

const organizerCreateApplicationPageQuery = gql`
    query OrganizerCreateApplicationPageQuery(
        $number: Int!
        $venueId: ID
        $withVenue: Boolean!
    ) {
        viewer {
            _id
        }
        tournament(number: $number) {
            _id
            stage
            number
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                    }
                    advanced {
                        date
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                    }
                    advanced {
                        date
                    }
                }
            }
        }
        venue(id: $venueId) @include(if: $withVenue) {
            _id
            townName
            townNameEn
            organization
            organizationDescription
            email
            phone
            seasons {
                fall {
                    startTime
                    infoMessage
                    accessDescription
                    address
                    contacts {
                        email
                        phone
                        fbMessenger
                        telegram
                        viber
                        whatsapp
                        website
                    }
                }
            }
        }
    }
`;

const createVenueApplicationMutation = gql`
    mutation CreateVenueApplication(
        $tournamentId: ID!
        $season: Season!
        $venueInfo: VenueApplicationInput!
        $venueId: ID
    ) {
        createVenueApplication(
            tournamentId: $tournamentId
            season: $season
            venueInfo: $venueInfo
            venueId: $venueId
        ) {
            __typename
            ... on CreateVenueApplicationSuccess {
                venueApplication {
                    _id
                }
            }
        }
    }
`;

export default provideLayoutAndData<
    OrganizerCreateApplicationPageQuery,
    OrganizerCreateApplicationPageQueryVariables
>(organizerCreateApplicationPageQuery, {
    variablesGetter: params => ({
        number: Number(params.number),
        venueId: params.venueId,
        withVenue: Boolean(params.venueId),
    }),
    authenticatedOnly: true,
})(function OrganizerCreateApplicationPage({
    data: { tournament, venue },
    viewer,
}) {
    const { t } = useTranslation();
    const [formCompleted, setFormCompleted] = useState(false);

    const [createVenueApplication, { data, error, loading }] = useMutation(
        createVenueApplicationMutation
    );

    // TODO: use isAppropriateStage function
    const season = tournament.stage.startsWith('SPRING') ? 'SPRING' : 'FALL';

    return (
        <>
            <h2>
                {t('SeasonFullTitle', {
                    number: tournament.number,
                    season,
                })}
            </h2>
            {!formCompleted && (
                <CreateVenueApplicationForm
                    viewerEmail={viewer.email}
                    language={viewer.language}
                    venue={venue}
                    onSubmit={venueInfo => {
                        createVenueApplication({
                            variables: {
                                tournamentId: tournament._id,
                                season,
                                venueInfo,
                                venueId: venue?._id || undefined,
                            },
                        }).then(({ data }) => {
                            if (
                                data.createVenueApplication.__typename ===
                                'CreateVenueApplicationSuccess'
                            ) {
                                setFormCompleted(true);
                            }
                        });
                    }}
                    mutationData={data}
                    mutationError={
                        error ||
                        !['CreateVenueApplicationSuccess', undefined].includes(
                            data?.createVenueApplication.__typename
                        )
                    }
                    loading={loading}
                    submitButtonText={t('OrganizerHomePage.SendApplication')}
                    ordinaryDate={
                        tournament.seasons[season.toLowerCase()].ordinary.date
                    }
                    advancedDate={
                        tournament.seasons[season.toLowerCase()].advanced.date
                    }
                />
            )}
            {formCompleted && (
                <>
                    <Alert variant="success">
                        <Trans
                            i18nKey="OrganizerHomePage.VenueApplicationSuccessfullySentMessage"
                            values={{ email: viewer.email }}
                        />
                    </Alert>
                    <br />
                    <Link to="/">{t('OrganizerHomePage.ReturnToMain')}</Link>
                </>
            )}

            <br />
            <br />
            <br />
            <br />
        </>
    );
});
