import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface TournamentSectionLinksProps {
    number: number;
    section: string;
    seasons: {
        fall: { stage: string };
        spring: { stage: string };
    };
    depth: 'LEVEL' | 'TIER';
}

export default function TournamentSectionLinks({
    number,
    section,
    seasons,
    depth,
}: TournamentSectionLinksProps) {
    return (
        <>
            <TournamentSeasonSectionLinks
                number={number}
                season="FALL"
                section={section}
                depth={depth}
            />
            {['REGISTRATION', 'UPLOADING', 'FINISHED'].includes(
                seasons.spring.stage
            ) && (
                <>
                    <br />
                    <TournamentSeasonSectionLinks
                        number={number}
                        season="SPRING"
                        section={section}
                        depth={depth}
                    />
                </>
            )}
        </>
    );
}

function TournamentSeasonSectionLinks({ number, season, section, depth }) {
    if (depth === 'LEVEL') {
        return (
            <>
                <SectionLink
                    number={number}
                    season={season}
                    section={section}
                    level="ORDINARY"
                />
                <br />
                <SectionLink
                    number={number}
                    season={season}
                    section={section}
                    level="ADVANCED"
                />
                <br />
            </>
        );
    }

    return (
        <>
            <SectionLink
                number={number}
                season={season}
                section={section}
                level="ORDINARY"
                tier="JUNIOR"
            />
            <br />
            <SectionLink
                number={number}
                season={season}
                section={section}
                level="ORDINARY"
                tier="SENIOR"
            />
            <br />
            <SectionLink
                number={number}
                season={season}
                section={section}
                level="ADVANCED"
                tier="JUNIOR"
            />
            <br />
            <SectionLink
                number={number}
                season={season}
                section={section}
                level="ADVANCED"
                tier="SENIOR"
            />
            <br />
        </>
    );
}

function SectionLink({ number, section, season, level, tier = null }) {
    const { t } = useTranslation();

    let urlFragments = [
        'tournaments',
        number,
        section,
        `${season.toLowerCase()}-${level.toLowerCase()}`,
    ];

    if (tier) {
        urlFragments.push(tier.toLowerCase());
    }

    return (
        <Link to={`/${urlFragments.join('/')}`}>
            {tier
                ? t('common:FullTierName', { season, level, tier })
                : t('common:FullLevelName', { season, level })}
        </Link>
    );
}
