import React from 'react';
import { useViewer } from '../ViewerContext';
import PaperExaminationForm from './PaperExaminationForm';
import { useTranslation } from 'react-i18next';

export function PaperExaminations({ paper, problemSet, marksVersion }) {
    const { viewer } = useViewer();
    const { t } = useTranslation();

    const localExaminations = (paper.examinations || []).filter(
        exam => exam.type === 'LOCAL'
    );
    const centralExaminations = (paper.examinations || []).filter(
        exam => exam.type === 'CENTRAL'
    );

    const firstLocal = localExaminations.find(exam => exam.stage === 'FIRST');
    const secondLocal = localExaminations.find(exam => exam.stage === 'SECOND');

    const firstCentral = centralExaminations.find(
        exam => exam.stage === 'FIRST'
    );
    const secondCentral = centralExaminations.find(
        exam => exam.stage === 'SECOND'
    );
    const finalCentral = centralExaminations.find(
        exam => exam.stage === 'FINAL'
    );

    return (
        <div>
            {firstLocal || viewer.role === 'ORGANIZER' ? (
                <>
                    <h5>{t('ExaminationPaperView.Local')}</h5>
                    <PaperExaminationForm
                        key={'firstLocal' + marksVersion}
                        paper={paper}
                        problemSet={problemSet}
                        disabled={viewer.role === 'JURY'}
                        examination={
                            firstLocal || {
                                type: 'LOCAL',
                                stage: 'FIRST',
                                marks: [],
                            }
                        }
                    />
                </>
            ) : null}
            {secondLocal && (
                <PaperExaminationForm
                    key={'secondLocal' + marksVersion}
                    paper={paper}
                    problemSet={problemSet}
                    disabled={viewer.role === 'JURY'}
                    examination={secondLocal}
                />
            )}
            {['JURY', 'ADMIN'].includes(viewer.role) ? (
                <>
                    <h5>{t('ExaminationPaperView.Central')}</h5>
                    <PaperExaminationForm
                        key={'first' + marksVersion}
                        paper={paper}
                        problemSet={problemSet}
                        examination={
                            firstCentral || {
                                type: 'CENTRAL',
                                stage: 'FIRST',
                                marks: [],
                            }
                        }
                    />
                </>
            ) : null}
            {secondCentral && (
                <PaperExaminationForm
                    key={'second' + marksVersion}
                    paper={paper}
                    problemSet={problemSet}
                    examination={secondCentral}
                />
            )}
            {finalCentral && (
                <PaperExaminationForm
                    key={'final' + marksVersion}
                    paper={paper}
                    problemSet={problemSet}
                    examination={finalCentral}
                />
            )}
        </div>
    );
}
