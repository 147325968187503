import { useTranslation } from 'react-i18next';
import React from 'react';
import { Card } from 'react-bootstrap';

export function VenueParticipationInfo({
    venueInfo: {
        address,
        startTime,
        infoMessage,
        contacts: {
            email,
            phone,
            website,
            telegram,
            whatsapp,
            viber,
            fbMessenger,
        },
        access,
        accessDescription,
    },
    cardTitle = ''
}) {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Card.Title>{cardTitle || t('VenueParticipationInfo.Venue')}</Card.Title>
                <Card.Text className="text-pre-line">
                    {access === 'LIMITED' && accessDescription && (
                        <>
                            {accessDescription}
                            <br />
                            <br />
                        </>
                    )}
                    {address}
                    <br />
                    <br />
                    {t('VenueParticipationInfo.StartTime', { startTime })}
                    {infoMessage && (
                        <>
                            <br />
                            <br />
                            {infoMessage}
                        </>
                    )}
                    {email && (
                        <>
                            <br />
                            <br />
                            {t('VenueParticipationInfo.ContactEmail')}:{' '}
                            <a href={`mailto:${email}`}>{email}</a>
                        </>
                    )}
                    {phone && (
                        <>
                            <br />
                            {t('VenueParticipationInfo.ContactPhone')}: {phone}
                        </>
                    )}
                    {website && (
                        <>
                            <br />
                            {t('VenueParticipationInfo.ContactWebsite')}:{' '}
                            <a
                                href={
                                    /^https?:\/\//i.test(website)
                                        ? website
                                        : `https://${website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {website.replace(/^https?:\/\//i, '')}
                            </a>
                        </>
                    )}
                    <MessengerLinks
                        telegram={telegram}
                        whatsapp={whatsapp}
                        viber={viber}
                        fbMessenger={fbMessenger}
                    />
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

function getMessengerLink(type, handle) {
    switch (type) {
        case 'telegram':
            return (
                'https://t.me/' +
                (handle.startsWith('@') ? handle.replace(/^@/, '') : handle)
            );
        case 'whatsapp':
            return 'https://wa.me/' + handle;
        case 'viber':
            return 'viber://chat?number=' + handle;
        case 'fbMessenger':
            return 'https://m.me/' + handle;
    }
}

function MessengerLinks({ telegram, whatsapp, viber, fbMessenger }) {
    const { t } = useTranslation();
    const messengers = [
        { type: 'telegram', handle: telegram },
        { type: 'whatsapp', handle: whatsapp },
        { type: 'viber', handle: viber },
        { type: 'fbMessenger', handle: fbMessenger },
    ].filter(messenger => Boolean(messenger.handle));

    if (!messengers.length) {
        return null;
    }

    return (
        <>
            <br />
            {t('VenueParticipationInfo.ContactMessengers')}:{' '}
            {messengers.map(({ type, handle }, i) => {
                return (
                    <>
                        <a
                            href={getMessengerLink(type, handle)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('VenueParticipationInfo.ContactMessenger', {
                                context: type,
                            })}
                        </a>
                        {i < messengers.length - 1 && ', '}
                    </>
                );
            })}
        </>
    );
}
