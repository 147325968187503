import React, { ComponentType } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OperationVariables, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';

import { useViewer, Viewer } from './ViewerContext';
import {
    QueryHookOptions,
    QueryResult,
} from '@apollo/client/react/types/types';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

interface PageProps {
    children: any;
    nav?: any;
    layout?: 'wide' | 'narrow';
}

export default function Page({
    children,
    nav = null,
    layout = 'wide',
}: PageProps) {
    const { t } = useTranslation();

    return (
        <div className="page">
            <Helmet>
                <title>{t('Title')}</title>
            </Helmet>
            <TopNavigation>{nav}</TopNavigation>
            <Container className="page-content">
                {layout === 'wide' && children}
                {layout === 'narrow' && (
                    <Row className="justify-content-center">
                        <Col md={10} lg={8}>
                            {children}
                        </Col>
                    </Row>
                )}
            </Container>
            <Footer />
        </div>
    );
}

interface PageWithDataOptions<
    QueryData,
    QueryVariables,
    RouteParams = Record<string, string>
> {
    variablesGetter?: (params: RouteParams) => QueryVariables;
    queryOptions?: QueryHookOptions<QueryData, QueryVariables>;
    layout?: 'wide' | 'narrow';
    authenticatedOnly?: boolean;
    NavComponent?: ComponentType<{
        data: QueryResult<QueryData, QueryVariables>['data'];
        params: RouteParams;
    }>;
}

type PageWithDataContent<QueryData, QueryVariables> = ComponentType<{
    data: QueryResult<QueryData, QueryVariables>['data'];
    refetch: QueryResult<QueryData, QueryVariables>['refetch'];
    queryResult: QueryResult<QueryData, QueryVariables>;
    viewer: Viewer;
}>;

export function provideLayoutAndData<
    QueryData,
    QueryVariables = OperationVariables,
    RouteParams = Record<string, string>
>(
    query,
    options: PageWithDataOptions<QueryData, QueryVariables, RouteParams> = {}
) {
    return (Component: PageWithDataContent<QueryData, QueryVariables>) => {
        const {
            variablesGetter,
            queryOptions = {},
            layout,
            authenticatedOnly = false,
            NavComponent,
        } = options;

        return props => {
            const routeParams = useParams<RouteParams>();
            const variables = variablesGetter
                ? variablesGetter(routeParams)
                : undefined;
            const queryResult = useQuery<QueryData, QueryVariables>(query, {
                variables,
                ...queryOptions,
            });
            const { viewer, loading: viewerLoading } = useViewer();
            const { t } = useTranslation();

            if (queryResult.loading || (authenticatedOnly && viewerLoading)) {
                return (
                    <Page layout={layout}>
                        <div className="text-center">
                            <Spinner animation="border">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    </Page>
                );
            }

            if (authenticatedOnly && !viewer) {
                return null; // TODO: show error
            }

            return (
                <Page
                    layout={layout}
                    nav={
                        NavComponent ? (
                            <NavComponent
                                data={queryResult.data}
                                params={routeParams}
                            />
                        ) : null
                    }
                >
                    <Component
                        data={queryResult.data}
                        refetch={queryResult.refetch}
                        queryResult={queryResult}
                        viewer={viewer}
                        {...props}
                    />
                </Page>
            );
        };
    };
}
