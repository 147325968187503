import React from 'react';

export default function PaperUploadInstructions() {
    return (
        <>
            <p>
                Вам нужно будет записать решения на бумаге, отсканировать или
                сфотографировать их и загрузить на этот сайт.
            </p>
            <ul>
                <li>
                    Пишите на обычных листах A4. Не используйте тетрадные листы.
                </li>
                <li>
                    Пишите только с одной стороны, чтобы текст с обратной
                    стороны не просвечивал и не ухудшал читаемость.
                </li>
                <li>
                    Используйте синие или чёрные ручки, острые простые
                    карандаши. Не используйте маркеры.
                </li>
                <li>
                    Решение каждой задачи записывайте на отдельном листе (или
                    листах).
                </li>
                <li>
                    Наверху каждого листа напишите номер задачи. Если задача
                    записана на нескольких листах — добавьте номер страницы
                    (например, «Задача 3 стр. 1»)
                </li>
                <li>Отсканируйте или сфотографируйте каждый лист отдельно.</li>
                <li>
                    Если вы фотографируете лист на камеру мобильного телефона —
                    положите лист на ровную поверхность, держите телефон
                    параллельно листу, следите, чтобы в кадр попал весь лист и
                    ничего больше.
                </li>
                <li>
                    Убедитесь, что весь текст на каждом листе можно без труда
                    разобрать.
                </li>
                <li>
                    Каждый файл должен иметь формат <strong>jpeg</strong>,{' '}
                    <strong>png</strong> или <strong>webp</strong>. Допустимый
                    размер - от 50 Кб до 10 Мб.
                </li>
            </ul>
        </>
    );
}
