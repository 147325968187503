import classnames from 'classnames';
import './Marks.scss';

const markDisplay = {
    '': '',
    '0': '0',
    '-.': '-.',
    '-': '–',
    '-+': '∓',
    '+/2': '⁺/₂',
    '+-': '±',
    '+.': '+.',
    '+': '+',
    '+!': '+!',
};

interface MarksFromExaminationProps {
    examination: {
        marks: Array<{
            problemNumber: number;
            subproblemNumber: number;
            mark: string;
        }>;
    };
    problems: Array<{ subproblems: Array<unknown> }>;
    size?: 'sm' | 'lg';
}

interface MarksProps {
    marks:
        | Array<Array<string>>
        | Array<string>
        | Array<Array<{ mark: string }>>
        | Array<{ mark: string }>;
    size?: 'sm' | 'lg';
}

export function Marks(props: MarksFromExaminationProps | MarksProps) {
    let {
        marks,
        examination,
        problems,
        size = 'sm',
    } = props as MarksFromExaminationProps & MarksProps;

    if (marks) {
        marks = marks.flatMap(mark =>
            Array.isArray(mark) ? mark.map(m => m.mark || m) : mark.mark || mark
        );
    }

    if (examination) {
        marks = problems
            .flatMap((problem, i) =>
                problem.subproblems.map((subproblems, j) => ({
                    problemNumber: i,
                    subproblemNumber: j,
                }))
            )
            .map(
                problem =>
                    examination.marks.find(
                        mark =>
                            mark.problemNumber === problem.problemNumber &&
                            mark.subproblemNumber === problem.subproblemNumber
                    )?.mark || ''
            );
    }

    return (
        <>
            {marks?.flatMap((mark, i) => [
                <Mark mark={mark} size={size} />,
                i < marks.length - 1 ? ' ' : '',
            ])}
        </>
    );
}

export function Mark({
    mark,
    size = 'sm',
}: {
    mark: string;
    size?: 'sm' | 'lg';
}) {
    return (
        <span
            className={classnames('marks-display', {
                'plus-halved': mark === '+/2',
                'marks-display-sm': size === 'sm',
                'marks-display-lg': size === 'lg',
            })}
        >
            {markDisplay[mark]}
        </span>
    );
}
