import { gql } from '@apollo/client';

export default gql`
    fragment tournamentProblemsCountFields on Tournament {
        seasons {
            fall {
                ...tournamentProblemsCountSeasonFields
            }
            spring {
                ...tournamentProblemsCountSeasonFields
            }
        }
    }

    fragment tournamentProblemsCountSeasonFields on TournamentSeason {
        ordinary {
            ...tournamentProblemsCountRoundFields
        }
        advanced {
            ...tournamentProblemsCountRoundFields
        }
    }

    fragment tournamentProblemsCountRoundFields on TournamentRound {
        junior {
            ...tournamentProblemsCountProblemSetFields
        }
        senior {
            ...tournamentProblemsCountProblemSetFields
        }
    }

    fragment tournamentProblemsCountProblemSetFields on ProblemSet {
        problems {
            subproblems {
                points
            }
        }
    }
`;
