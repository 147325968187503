"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExaminationComplete = exports.isPaperExaminationComplete = exports.isPaperExaminationStarted = void 0;
const findByFields_1 = require("./findByFields");
function isPaperExaminationStarted(paper, type, stage) {
    if (!paper.examinations) {
        return false;
    }
    const examination = stage
        ? (0, findByFields_1.findByFields)(paper.examinations, {
            type,
            stage,
        })
        : (0, findByFields_1.findLastByFields)(paper.examinations, {
            type,
        });
    if (!examination) {
        return false;
    }
    return examination.marks.length > 0;
}
exports.isPaperExaminationStarted = isPaperExaminationStarted;
function isPaperExaminationComplete(paper, tournament, type, stage) {
    if (!paper.examinations) {
        return false;
    }
    const examination = stage
        ? (0, findByFields_1.findByFields)(paper.examinations, {
            type,
            stage,
        })
        : (0, findByFields_1.findLastByFields)(paper.examinations, {
            type,
        });
    if (!examination) {
        return false;
    }
    const problems = (0, findByFields_1.findProblemsInTournament)(tournament, paper.season, paper.level, paper.tier);
    return isExaminationComplete(examination.marks, problems);
}
exports.isPaperExaminationComplete = isPaperExaminationComplete;
function isExaminationComplete(marks, problems) {
    const flatProblems = problems.flatMap((problem, i) => problem.subproblems.map((subproblems, j) => ({
        problemNumber: i,
        subproblemNumber: j,
    })));
    return flatProblems.every(({ problemNumber, subproblemNumber }) => marks.find(m => m.problemNumber === problemNumber &&
        m.subproblemNumber === subproblemNumber &&
        m.mark));
}
exports.isExaminationComplete = isExaminationComplete;
