import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useViewer } from './ViewerContext';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Person, PersonFill } from 'react-bootstrap-icons';
import React from 'react';

interface TopNavigationProps {
    children: any;
}

const signOutMutation = gql`
    mutation SignOut {
        signOut {
            success
        }
    }
`;

export default function TopNavigation({ children }: TopNavigationProps) {
    const { t } = useTranslation();
    const { viewer, loading } = useViewer();
    const [signOut] = useMutation(signOutMutation);

    return (
        <Navbar
            variant={viewer ? 'dark' : 'light'}
            bg={viewer ? 'dark' : undefined}
            fixed="top"
        >
            <Container>
                {viewer?.role !== 'ORGANIZER' && viewer?.role !== 'ADMIN' && (
                    <Navbar.Brand as={Link} to="/">
                        {t('TopNavigation.Title')}
                    </Navbar.Brand>
                )}
                {viewer?.role === 'ORGANIZER' && (
                    <Navbar.Brand href="/org">
                        {t('TopNavigation.Title')}
                    </Navbar.Brand>
                )}
                {viewer?.role === 'ADMIN' && (
                    <Navbar.Brand href="/admin">
                        {t('TopNavigation.Title')}
                    </Navbar.Brand>
                )}
                <Nav>
                    {children}
                    {!loading && !viewer ? (
                        <Nav.Link
                            as={Link}
                            to="/sign-in"
                            title={t('TopNavigation.SignIn')}
                            aria-label={t('TopNavigation.SignIn')}
                        >
                            <Person size={24} aria-hidden={true} />
                        </Nav.Link>
                    ) : null}
                    {!loading && viewer ? (
                        <NavDropdown
                            id="topnav-user-menu"
                            title={<PersonFill size={24} />}
                            className="ml-4"
                        >
                            {viewer.role === 'PARTICIPANT' && (
                                <NavDropdown.Item
                                    as={Link}
                                    to="/past-tournaments"
                                >
                                    Прошедшие турниры
                                </NavDropdown.Item>
                            )}
                            <NavDropdown.Item
                                onClick={async () => {
                                    await signOut();

                                    window.location.href =
                                        window.location.origin;
                                }}
                            >
                                {t('TopNavigation.SignOut')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    ) : null}
                </Nav>
            </Container>
        </Navbar>
    );
}

export function VenueTopNavigation({
    townName,
    townNameEn,
    venueId,
    tournamentNumber,
}) {
    const { viewer } = useViewer();
    const {
        t,
        i18n: { language },
    } = useTranslation();

    return viewer?.role !== 'ORGANIZER' ? (
        <>
            <Nav.Link
                as={Link}
                to={`/tournaments/${tournamentNumber}/venues/${venueId}`}
            >
                {language === 'ru' ? townName : townNameEn || townName}
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={`/tournaments/${tournamentNumber}/venues/${venueId}/papers/all`}
            >
                {t('OrganizerHomePage.Papers')}
            </Nav.Link>
        </>
    ) : (
        <>
            <Nav.Link href={`/org/${tournamentNumber}/venues/${venueId}`}>
                {language === 'ru' ? townName : townNameEn || townName}
            </Nav.Link>
            <Nav.Link
                as={Link}
                to={`/tournaments/${tournamentNumber}/venues/${venueId}/papers/all`}
            >
                {t('OrganizerHomePage.Papers')}
            </Nav.Link>
        </>
    );
}

export function BatchTopNavigation({ batchId, tournamentNumber }) {
    return (
        <Nav.Link
            as={Link}
            to={`/tournaments/${tournamentNumber}/batches/${batchId}`}
        >
            Пачка
        </Nav.Link>
    );
}
