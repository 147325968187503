import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner, Table } from 'react-bootstrap';
import { Link, Switch, Route, useParams } from 'react-router-dom';
import React from 'react';
import VenueResultsInputPage from '../VenueResultsInputPage';
import ResultsInputPage from '../ResultsInputPage';
import InputterParticipantPage from './InputterParticipantPage';
import { venuesSortFn } from '@turgor/model-utils/lib/sort';

const inputterTournamentPageQuery = gql`
    query InputterTournamentPageQuery($number: Int!) {
        tournament(number: $number) {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                }
            }
            venues {
                _id
                townName
            }
        }
    }
`;

export default function InputterTournamentPage() {
    const params = useParams<{ number: string }>();
    const number = Number(params.number);
    const { data, loading } = useQuery(inputterTournamentPageQuery, {
        variables: { number },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { tournament } = data;

    return (
        <Switch>
            <Route exact={true} path="/tournaments/:number">
                <Page>
                    <InputterTournamentView tournament={tournament} />
                </Page>
            </Route>
            <Route
                path={`/tournaments/:number/participants/:participantId`}
                component={InputterParticipantPage}
            />
            <Route
                path={`/tournaments/:number/venues/:venueId/results-input`}
                exact={true}
                component={VenueResultsInputPage}
            />
            <Route
                path={`/tournaments/:number/venues/:venueId/results-input/:round/:tier/:grade?`}
                component={ResultsInputPage}
            />
        </Switch>
    );
}

export function InputterTournamentView({ tournament }) {
    const venues = [...tournament.venues].sort(venuesSortFn);

    return (
        <>
            <h2>{tournament.number}-й Турнир городов</h2>
            <Table striped={true} borderless={true} size="sm">
                <thead>
                    <tr>
                        <th>Город</th>
                        <th>Организация</th>
                    </tr>
                </thead>
                <tbody>
                    {venues.map(venue => (
                        <tr key={venue._id}>
                            <td className="align-middle">
                                <Link
                                    to={`/tournaments/${tournament.number}/venues/${venue._id}/results-input`}
                                >
                                    {venue.townName}
                                </Link>
                            </td>
                            <td className="align-middle">
                                {venue.organization}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}
