import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../../Page';
import { Spinner } from 'react-bootstrap';
import ParticipantsResults from '../../ParticipantsResults';

const adminVenueResultsQuery = gql`
    query AdminVenueResults($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            participants {
                _id
                award
                bestResult
                name
                surname
                school
                grade
                townName
                papers {
                    _id
                    card
                    season
                    level
                    tier
                    stage
                    sum
                    result
                    marks {
                        mark
                        points
                    }
                    flags {
                        annulled
                        centralExamination
                        pagesProblem
                        suspicious
                    }
                }
            }
            tournament {
                _id
                number
                stage
            }
        }
    }
`;

export default function AdminVenueResultsPage() {
    const { venueId } = useParams<{ venueId: string }>();
    const { data, loading } = useQuery(adminVenueResultsQuery, {
        variables: { venueId },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        venue: { participants, tournament, ...venue },
    } = data;

    return (
        <Page>
            <h2>
                {venue.townName} —{' '}
                {t('TournamentTitle', {
                    number: tournament.number,
                })}{' '}
                — результаты
            </h2>
            <br />
            <ParticipantsResults
                participants={participants}
                tournamentNumber={tournament.number}
            />
        </Page>
    );
}
