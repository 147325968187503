import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../Page';

export default function FaqPage() {
    const { i18n } = useTranslation();

    return (
        <Page layout="narrow">
            {i18n.language === 'ru' && <RuFaq />}
            {i18n.language === 'en' && <EnFaq />}
        </Page>
    );
}

function RuFaq() {
    return (
        <>
            <h1>Вопросы и ответы</h1>
            <ul>
                <li>
                    <strong>
                        Мне не приходит письмо для подтверждения email, что
                        делать?
                    </strong>
                    <br />
                    Проверьте, не попало ли письмо в папку «Спам». Если его там
                    нет, попробуйте выслать повторное письмо, нажав на кнопку
                    «Отправить ссылку ещё раз».
                    <br />
                    <br />
                </li>
                <li>
                    <strong>
                        Могу ли я участвовать в сложном туре, если не участвовал
                        в базовом?
                    </strong>
                    <br />
                    Да, участие в обоих турах свободное.
                    <br />
                    <br />
                </li>

                <li>
                    <strong>На какие классы рассчитаны задания?</strong>
                    <br />
                    Задания старшего варианта рассчитаны на 10-11 класс,
                    младшего — на 8-9 класс. Ученики более младших классов (≤ 7)
                    тоже могут участвовать в Турнире.
                    <br />
                    <br />
                </li>
                <li>
                    <strong>
                        Я учусь в 9 (или младше) классе. Могу ли я писать
                        старший вариант?
                    </strong>
                    <br />
                    Если вы уверены в своих силах, то да, можете. Ваш результат
                    будет посчитан по тем же правилам, что и результаты
                    участников 10 класса. Если вы участвуете онлайн и хотите
                    писать старший вариант, напишите нам на{' '}
                    <a href="mailto:support@turgor.ru">support@turgor.ru</a>
                    <br />
                    <br />
                </li>
            </ul>
            <p>
                Если у вас есть вопрос по работе системы или о самом Турнире
                городов — вы можете задать его нам на{' '}
                <a href="mailto:support@turgor.ru">support@turgor.ru</a>.
            </p>
        </>
    );
}

function EnFaq() {
    return (
        <>
            <h1>Frequently asked questions</h1>
            <ul>
                <li>
                    <strong>
                        I didn't get the email verification link, what should I
                        do?
                    </strong>
                    <br />
                    Please check your "Spam" folder. If you can't find the
                    email, try requesting a new link by clicking the «Resend
                    verification link» button.
                    <br />
                    <br />
                </li>
                <li>
                    <strong>
                        Can I participate in A-level if I skipped the O-level?
                    </strong>
                    <br />
                    Yes, you can freely choose what levels to participate in.
                    <br />
                    <br />
                </li>
            </ul>
            <p>
                If you have a question about this website or about the
                Tournament itself — you can contact us at{' '}
                <a href="mailto:support@turgor.ru">support@turgor.ru</a>.
            </p>
        </>
    );
}
