import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import classnames from 'classnames';
import Page from '../Page';
import { SubmitButton } from 'Components/EnhancedButtons';
import CheckEmailLink from '../Components/CheckEmailLink';

export default function ResetPasswordPage() {
    const { t } = useTranslation();
    const { state }: { state: { email?: string } } = useLocation();

    const search = window.location.search.replace(/^\?/, '');
    const kvPairs = search.split('&');
    let variables: any = {};

    for (let pair of kvPairs) {
        const [key, value] = pair.split('=');

        if (key === 'e' || key === 't' || key === 'email') {
            variables[key] = value;
        }
    }

    if (variables.e && variables.t) {
        return (
            <Page layout="narrow">
                <h1 className="h2">{t('ResetPasswordPage.RestorePassword')}</h1>
                <SetNewPasswordForm
                    encodedEmail={variables.e}
                    token={variables.t}
                />
            </Page>
        );
    }

    return (
        <Page layout="narrow">
            <h1 className="h2">{t('ResetPasswordPage.RestorePassword')}</h1>
            <ResetPasswordRequestForm email={state?.email} />
        </Page>
    );
}

const requestPasswordResetLinkMutation = gql`
    mutation RequestPasswordResetLink($email: String!) {
        requestPasswordResetLink(email: $email) {
            __typename
            ... on RequestPasswordResetLinkSuccess {
                email
            }
        }
    }
`;

function ResetPasswordRequestForm({ email }: { email?: string }) {
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,

        formState: { errors: formErrors },
    } = useForm();
    const [requestPasswordResetLink, { data, loading, error }] = useMutation(
        requestPasswordResetLinkMutation
    );

    let errorMessage = null;

    if (
        error ||
        ![
            'RequestPasswordResetLinkSuccess',
            'NotSignedUpError',
            undefined,
        ].includes(data?.requestPasswordResetLink.__typename)
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (data?.requestPasswordResetLink.__typename === 'NotSignedUpError') {
        errorMessage = t('ResetPasswordPage.NotSignedUp');
    }

    if (
        data?.requestPasswordResetLink.__typename ===
        'RequestPasswordResetLinkSuccess'
    ) {
        return (
            <ResetPasswordRequestSuccess
                email={data.requestPasswordResetLink.email}
            />
        );
    }

    return (
        <>
            <Alert
                variant="warning"
                className={classnames({ invisible: !errorMessage })}
            >
                {errorMessage || '&nbsp;'}
            </Alert>
            <Form
                noValidate={true}
                onSubmit={handleSubmit(data => {
                    requestPasswordResetLink({ variables: data });
                })}
            >
                <Form.Group controlId="password-reset-email">
                    <Form.Label>{t('ResetPasswordPage.EmailLabel')}</Form.Label>
                    <Form.Control
                        {...register('email', {
                            required: true,
                            pattern: /^.+@.+$/,
                        })}
                        type="email"
                        defaultValue={email || ''}
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classnames('d-block', {
                            invisible: !formErrors.email,
                        })}
                    >
                        {!formErrors.email && '&nbsp;'}
                        {formErrors.email?.type === 'required' &&
                            t('ResetPasswordPage.EmailRequired')}
                        {formErrors.email?.type === 'pattern' &&
                            t('ResetPasswordPage.EmailPatternFailed')}
                    </Form.Control.Feedback>
                </Form.Group>
                <SubmitButton
                    loading={loading}
                    mutationData={data}
                    className="mt-2"
                >
                    {t('ResetPasswordPage.GetRestoreLink')}
                </SubmitButton>
            </Form>
        </>
    );
}

function ResetPasswordRequestSuccess({ email }: { email: string }) {
    const { t } = useTranslation();

    return (
        <>
            <Alert variant="success">
                {t('ResetPasswordPage.ResetPasswordRequestSuccessMessage')}
            </Alert>
            <p>
                <Trans
                    i18nKey="ResetPasswordPage.FollowTheLink"
                    values={{ email }}
                />
            </p>
            <CheckEmailLink email={email} />
        </>
    );
}

const resetPasswordMutation = gql`
    mutation ResetPassword(
        $token: String!
        $encodedEmail: String!
        $newPassword: String!
    ) {
        resetPassword(
            token: $token
            encodedEmail: $encodedEmail
            newPassword: $newPassword
        ) {
            __typename
            ... on PasswordResetTokenExpiredError {
                email
            }
        }
    }
`;

function SetNewPasswordForm({
    encodedEmail,
    token,
}: {
    encodedEmail: string;
    token: string;
}) {
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,

        formState: { errors: formErrors },
    } = useForm();
    const [resetPassword, { data, loading, error }] = useMutation(
        resetPasswordMutation
    );

    let errorMessage = null;

    if (
        error ||
        ![
            'PasswordResetTokenExpiredError',
            'ResetPasswordSuccess',
            undefined,
        ].includes(data?.resetPassword.__typename)
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (data?.resetPassword.__typename === 'PasswordResetTokenExpiredError') {
        return (
            <Alert variant="warning">
                {t('ResetPasswordPage.PasswordResetTokenExpired')}{' '}
                <Link
                    to={{
                        pathname: '/reset-password',
                        state: { email: data?.resetPassword.email },
                    }}
                >
                    {t('ResetPasswordPage.RequestNewLink')}
                </Link>
            </Alert>
        );
    }

    if (data?.resetPassword.__typename === 'ResetPasswordSuccess') {
        return (
            <Alert variant="success">
                {t('ResetPasswordPage.SetPasswordSuccess')}
            </Alert>
        );
    }

    return (
        <>
            <Alert
                variant="warning"
                className={classnames({ invisible: !errorMessage })}
            >
                {errorMessage || '&nbsp;'}
            </Alert>
            <Form
                noValidate={true}
                onSubmit={handleSubmit(({ newPassword }) => {
                    resetPassword({
                        variables: { newPassword, encodedEmail, token },
                    }).then(({ data }) => {
                        if (
                            data?.resetPassword.__typename ===
                            'ResetPasswordSuccess'
                        ) {
                            setTimeout(() => {
                                window.location.href = window.location.origin;
                            }, 2000);
                        }
                    });
                })}
            >
                <Form.Group controlId="password-reset-new-password">
                    <Form.Label>
                        {t('ResetPasswordPage.PasswordLabel')}
                    </Form.Label>
                    <Form.Control
                        {...register('newPassword', {
                            required: true,
                            minLength: 8,
                        })}
                        type="password"
                        defaultValue=""
                    />
                    <Form.Control.Feedback
                        type="invalid"
                        className={classnames('d-block', {
                            invisible: !formErrors.newPassword,
                        })}
                    >
                        {!formErrors.newPassword && '&nbsp;'}
                        {formErrors.newPassword?.type === 'required' &&
                            t('ResetPasswordPage.PasswordRequired')}
                        {formErrors.newPassword?.type === 'minLength' &&
                            t('ResetPasswordPage.PasswordMinLength')}
                    </Form.Control.Feedback>
                </Form.Group>
                <SubmitButton
                    loading={loading}
                    className="mt-2"
                    mutationData={data}
                >
                    {t('ResetPasswordPage.SetPassword')}
                </SubmitButton>
            </Form>
        </>
    );
}
