"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    translation: {},
    common: {
        count: {
            participants: '{{count}} participant',
            participants_plural: '{{count}} participants',
            papers: '{{count}} paper',
            papers_plural: '{{count}} papers',
            diplomas: '{{count}} diploma',
            diplomas_plural: '{{count}} diplomas',
            prizes: '{{count}} prize',
            prizes_plural: '{{count}} prizes',
            points: '{{count}} point',
            points_plural: '{{count}} points',
            venues: '{{count}} venue',
            venues_plural: '{{count}} venues',
            newVenueApplications: '{{count}} new application',
            newVenueApplications_plural: '{{count}} new applications',
            startedOnline: '{{count}} started online',
            startedOnline_plural: '{{count}} started online',
            finishedOnline: '{{count}} finished online',
            finishedOnline_plural: '{{count}} finished online',
            marked: '{{count}} marked',
            marked_plural: '{{count}} marked',
            published: '{{count}} published',
            published_plural: '{{count}} published',
            schools: '{{count}} school',
            schools_plural: '{{count}} schools',
        },
        problems: 'problems',
        subproblemLabels: 'abcdef',
        SeasonNoun_fall: 'Fall',
        SeasonNoun_FALL: 'Fall',
        SeasonNoun_spring: 'Spring',
        SeasonNoun_SPRING: 'Spring',
        seasonNoun_fall: 'fall',
        seasonNoun_FALL: 'fall',
        seasonNoun_spring: 'spring',
        seasonNoun_SPRING: 'spring',
        Season_fall: 'Fall',
        Season_FALL: 'Fall',
        Season_spring: 'Spring',
        Season_SPRING: 'Spring',
        season_fall: 'fall',
        season_FALL: 'fall',
        season_spring: 'spring',
        season_SPRING: 'spring',
        level_ordinary: 'O-level',
        level_ORDINARY: 'O-level',
        level_advanced: 'A-level',
        level_ADVANCED: 'A-level',
        Level_ordinary: 'O-level',
        Level_ORDINARY: 'O-level',
        Level_advanced: 'A-level',
        Level_ADVANCED: 'A-level',
        tier_junior: 'junior',
        tier_JUNIOR: 'junior',
        tier_senior: 'senior',
        tier_SENIOR: 'senior',
        Tier_junior: 'Junior',
        Tier_JUNIOR: 'Junior',
        Tier_senior: 'Senior',
        Tier_SENIOR: 'Senior',
        fullLevelName: '$t(common:season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" })',
        FullLevelName: '$t(common:Season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" })',
        tierName: '$t(common:level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
        TierName: '$t(common:Level, { "context": "{{ level }}" }) $t(common:tier, { "context": "{{ tier }}" })',
        fullTierName: '$t(season, { "context": "{{ season }}" }) $t(level, { "context": "{{ level }}" }) $t(tier, { "context": "{{ tier }}" })',
        FullTierName: '$t(Season, { "context": "{{ season }}" }) $t(level, { "context": "{{ level }}" }) $t(tier, { "context": "{{ tier }}" })',
    },
};
exports.default = en;
