import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CheckEmailLink({ email }: { email: string }) {
    const { t } = useTranslation();

    let emailProviderLink = null;
    let provider = null;
    const domain = email.split('@').pop() || '';

    if (['gmail.com'].includes(domain)) {
        emailProviderLink = 'gmail.com';
        provider = 'gmail.com';
    }

    if (['mail.ru', 'inbox.ru', 'list.ru', 'bk.ru'].includes(domain)) {
        emailProviderLink = 'e.mail.ru';
        provider = 'mail.ru';
    }

    if (['yandex.ru', 'ya.ru'].includes(domain)) {
        emailProviderLink = 'mail.yandex.ru';
        provider = 'yandex.ru';
    }

    if (!emailProviderLink) {
        return null;
    }

    return (
        <p>
            <a href={`https://${emailProviderLink}`}>
                {t('CheckEmailLink.CheckEmailAt', { provider })}
            </a>
        </p>
    );
}
