import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Form, Image, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';
import ms from 'ms';
import { PageGalleryModal } from '../Paper/PageGallery';

const organizerQuestionsQuery = gql`
    query OrganizerTournamentQuestions(
        $tournamentNumber: Int!
        $season: Season!
        $level: Level!
    ) {
        viewer {
            _id
            questions(
                tournamentNumber: $tournamentNumber
                season: $season
                level: $level
                type: LIVE
            ) {
                _id
                question
                askedAt
                answers {
                    answer
                    answeredAt
                    file
                }
                level
                season
                tier
                problemNumber
            }
        }
        tournament(number: $tournamentNumber) {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                        junior {
                            problems {
                                text
                                image
                            }
                        }
                        senior {
                            problems {
                                text
                                image
                            }
                        }
                    }
                    advanced {
                        date
                        stage
                        junior {
                            problems {
                                text
                                image
                            }
                        }
                        senior {
                            problems {
                                text
                                image
                            }
                        }
                    }
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                        junior {
                            problems {
                                text
                                image
                            }
                        }
                        senior {
                            problems {
                                text
                                image
                            }
                        }
                    }
                    advanced {
                        date
                        stage
                        junior {
                            problems {
                                text
                                image
                            }
                        }
                        senior {
                            problems {
                                text
                                image
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function OrganizerQuestionsPage() {
    let { number, round } = useParams<{
        number: string;
        round: string;
    }>();

    const [season, level] = round.split('-').map(s => s.toUpperCase());

    const { data, loading, startPolling, stopPolling } = useQuery(
        organizerQuestionsQuery,
        {
            variables: {
                tournamentNumber: Number(number),
                season,
                level,
            },
        }
    );

    const { t } = useTranslation();

    const { questions = [] } = data?.viewer || {};

    useEffect(() => {
        if (questions.length) {
            startPolling(15 * 1000);
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [questions.length, startPolling, stopPolling]);

    const pages = questions
        .flatMap(question => question.answers.map(answer => answer.file))
        .filter(file => Boolean(file))
        .map((file, index) => ({
            file,
            _id: `page_${index}`,
        }));

    const [clickedPageIndex, setClickedPageIndex] = useState<number | null>(
        null
    );

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { tournament } = data;

    const roundDate = new Date(
        tournament.seasons[season.toLowerCase()][level.toLowerCase()].date
    );
    const questionFormAvailable =
        Date.now() > roundDate.getTime() - ms('2h') &&
        Date.now() < roundDate.getTime() + ms('8h');

    return (
        <Page>
            {questionFormAvailable && questions.length === 0 && (
                <div className="online-round-question-empty text-muted">
                    {t('OrganizerHomePage.HereYouCanAskQuestionsAboutProblems')}
                </div>
            )}
            {!questionFormAvailable && questions.length === 0 && (
                <div className="online-round-question-empty text-muted">
                    {t('OrganizerHomePage.DuringRoundYouCanAskQuestions')}
                </div>
            )}
            {questions.length > 0 &&
                questions.map(question => (
                    <div key={question._id}>
                        <div className="online-round-question-bubble online-round-question-bubble-me">
                            {question.tier &&
                            Number.isInteger(question.problemNumber) ? (
                                <h6>
                                    {t('TierAndProblemWithNumber', {
                                        tier: question.tier,
                                        problemNumber:
                                            question.problemNumber + 1,
                                    })}
                                </h6>
                            ) : null}
                            {question.tier &&
                            !Number.isInteger(question.problemNumber) ? (
                                <h6>
                                    {t('TierTitle', {
                                        tier: question.tier,
                                    })}
                                </h6>
                            ) : null}
                            {!question.tier &&
                            Number.isInteger(question.problemNumber) ? (
                                <h6>
                                    {t('ProblemWithNumber', {
                                        problemNumber:
                                            question.problemNumber + 1,
                                    })}
                                </h6>
                            ) : null}
                            {question.question}
                        </div>
                        {question.answers.map(answer =>
                            answer.answer ? (
                                <div className="online-round-question-bubble online-round-question-bubble-them">
                                    {answer.answer}
                                </div>
                            ) : answer.file ? (
                                <div className="online-round-question-bubble online-round-question-bubble-them">
                                    <Image
                                        src={`${process.env.REACT_APP_BACKEND_URL}${answer.file}`}
                                        thumbnail={true}
                                        width={200}
                                        onClick={() => {
                                            setClickedPageIndex(
                                                pages.findIndex(
                                                    page =>
                                                        page.file ===
                                                        answer.file
                                                )
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            ) : null
                        )}
                    </div>
                ))}
            {!questionFormAvailable && questions.length > 0 && (
                <p>
                    {t(
                        'OrganizerHomePage.QuestionsAreOnlyAvailableDuringRound'
                    )}
                </p>
            )}
            {questionFormAvailable && (
                <AskQuestionForm
                    tournament={tournament}
                    season={season}
                    level={level}
                />
            )}
            {clickedPageIndex !== null && (
                <PageGalleryModal
                    initialPage={clickedPageIndex}
                    pages={pages}
                    onClose={() => setClickedPageIndex(null)}
                />
            )}
        </Page>
    );
}

const organizerAskQuestionMutation = gql`
    mutation OrganizerAskQuestion(
        $tournamentId: ID!
        $tournamentNumber: Int
        $season: Season!
        $level: Level!
        $tier: Tier
        $problemNumber: Int
        $question: String!
    ) {
        organizerAskQuestion(
            tournamentId: $tournamentId
            season: $season
            level: $level
            tier: $tier
            problemNumber: $problemNumber
            question: $question
        ) {
            __typename
            ... on OrganizerAskQuestionSuccess {
                user {
                    _id
                    questions(
                        tournamentNumber: $tournamentNumber
                        season: $season
                        level: $level
                        type: LIVE
                    ) {
                        _id
                        question
                        askedAt
                        answers {
                            answer
                            answeredAt
                            file
                        }
                        level
                        season
                        tier
                        problemNumber
                    }
                }
            }
        }
    }
`;

function AskQuestionForm({ tournament, season, level }) {
    const { t } = useTranslation();
    const [organizerAskQuestion, { data, loading }] = useMutation(
        organizerAskQuestionMutation
    );
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const tier = watch('tier');
    const problemsCount = tier
        ? tournament.seasons[season.toLowerCase()][level.toLowerCase()][
              tier.toLowerCase()
          ]?.problems?.length || 0
        : 0;

    return (
        <Form
            noValidate={true}
            className="mt-4"
            onSubmit={handleSubmit(({ question, problemNumber, tier }) => {
                organizerAskQuestion({
                    variables: {
                        question,
                        tournamentId: tournament._id,
                        tournamentNumber: tournament.number,
                        season,
                        level,
                        problemNumber:
                            problemNumber !== 'other'
                                ? Number(problemNumber)
                                : null,
                        tier,
                    },
                }).then(({ data }) => {
                    if (
                        data?.organizerAskQuestion.__typename ===
                        'OrganizerAskQuestionSuccess'
                    ) {
                        reset();
                    }
                });
            })}
        >
            <Form.Group controlId="ask-question-tier">
                <Form.Label>{t('Version')}</Form.Label>
                <Form.Control
                    as="select"
                    {...register('tier', { required: true })}
                    defaultValue=""
                >
                    <option value="" disabled={true} key="none" />
                    <option key="JUNIOR" value="JUNIOR">
                        {t('common:Tier', { context: 'JUNIOR' })}
                    </option>
                    <option key="SENIOR" value="SENIOR">
                        {t('common:Tier', { context: 'SENIOR' })}
                    </option>
                </Form.Control>
                {errors.tier && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {t('OrganizerHomePage.ChooseVersion')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="ask-question-problemNumber">
                <Form.Label>{t('OrganizerHomePage.QuestionTopic')}</Form.Label>
                <Form.Control
                    as="select"
                    {...register('problemNumber', { required: true })}
                    defaultValue=""
                >
                    <option value="" disabled={true} key="none" />
                    {new Array(problemsCount).fill(0).map((_, i) => (
                        <option key={i} value={i}>
                            {t('ProblemWithNumber', {
                                problemNumber: i + 1,
                            })}
                        </option>
                    ))}
                    <option key="other" value="other">
                        {t('OrganizerHomePage.other')}
                    </option>
                </Form.Control>
                {errors.problemNumber && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {t('OrganizerHomePage.ChooseQuestionTopic')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="ask-question-text">
                <Form.Label>{t('OrganizerHomePage.YourQuestion')}</Form.Label>
                <Form.Control
                    as="textarea"
                    {...register('question', { required: true })}
                    rows={5}
                    defaultValue=""
                />
                {errors.question && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {t('OrganizerHomePage.EnterYourQuestion')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="mt-2 mb-2"
            >
                {t('OrganizerHomePage.Send')}
            </SubmitButton>
        </Form>
    );
}
