import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner, Table } from 'react-bootstrap';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';

const adminTournamentParticipantsPageQuery = gql`
    query AdminTournamentParticipantsPage($number: Int!) {
        tournament(number: $number) {
            _id
            number
            participants {
                _id
                surname
                name
                school
                grade
                email
                townName
                papers {
                    type
                    season
                    level
                    venue {
                        _id
                        townName
                    }
                }
            }
        }
    }
`;

export default function AdminTournamentParticipantsPage() {
    const params = useParams<{ number: string; season: string }>();
    const number = Number(params.number);
    const season = params.season.toUpperCase();
    const { data, loading } = useQuery(adminTournamentParticipantsPageQuery, {
        variables: { number },
    });
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const participants: Array<any> = data.tournament.participants
        .filter(participant =>
            participant.papers.some(p => p.season === season)
        )
        .map(({ papers, ...participant }: { papers: Array<any> }) => ({
            ...participant,
            ordinaryPaper: papers.find(
                p => p.season === season && p.level === 'ORDINARY'
            ),
            advancedPaper: papers.find(
                p => p.season === season && p.level === 'ADVANCED'
            ),
        }))
        .sort(participantsSortFn);

    return (
        <Page>
            <h1 className="h2">Участники</h1>
            {!participants.length && 'Пока никто не зарегистрировался'}
            {participants.length > 0 && (
                <Table striped={true} hover={true} borderless={true} size="sm">
                    <thead>
                        <tr>
                            <th> </th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Школа</th>
                            <th>Класс</th>
                            <th>Email</th>
                            <th>Базовый</th>
                            <th>Сложный</th>
                        </tr>
                    </thead>
                    <tbody>
                        {participants.map((participant, i: number) => (
                            <tr key={participant._id}>
                                <td>
                                    <Link
                                        to={`/tournaments/${data.tournament.number}/participants/${participant._id}`}
                                    >
                                        {i + 1}
                                    </Link>
                                </td>
                                <td>{participant.surname}</td>
                                <td>{participant.name}</td>
                                <td>{participant.school}</td>
                                <td>{participant.grade}</td>
                                <td>{participant.email}</td>
                                <td>
                                    {participant.ordinaryPaper && (
                                        <a
                                            href={`/admin/${data.tournament.number}/venues/${participant.ordinaryPaper.venue._id}`}
                                        >
                                            {
                                                participant.ordinaryPaper.venue
                                                    .townName
                                            }
                                        </a>
                                    )}
                                </td>
                                <td>
                                    {participant.advancedPaper && (
                                        <a
                                            href={`/admin/${data.tournament.number}/venues/${participant.advancedPaper.venue._id}`}
                                        >
                                            {
                                                participant.advancedPaper.venue
                                                    .townName
                                            }
                                        </a>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Page>
    );
}
