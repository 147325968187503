import React, { useCallback, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Page from '../Page';
import { Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { SubmitButton } from 'Components/EnhancedButtons';
import { useForm } from 'react-hook-form';
import ExaminationPaperView from './ExaminationPaperView';
import { PaperExaminations } from './PaperExaminations';
import { createSortFn } from '@turgor/model-utils/lib/sort';
import { useViewer } from '../ViewerContext';

const paperPageQuery = gql`
    query PaperPage($paperId: ID!) {
        participant(paperId: $paperId) {
            _id
            grade
            surname
            name
            school
            townName
            tournament {
                _id
                number
                seasons {
                    fall {
                        ...paperPageSeasonData
                    }
                    spring {
                        ...paperPageSeasonData
                    }
                }
            }
            papers {
                _id
                stage
                season
                level
                tier
                type
                card
                onlineStarted
                onlineDeadline
                onlineFinished
                file
                fileName
                pages {
                    _id
                    file
                    fileName
                    problemNumber
                    rotation
                    comments {
                        _id
                        jury {
                            name
                        }
                        text
                        path
                        style
                    }
                }
                venue {
                    _id
                    townName
                    townNameEn
                }
                comment
                flags {
                    centralExamination
                    suspicious
                    annulled
                    pagesProblem
                }
                examinations {
                    type
                    stage
                    marks {
                        _id
                        mark
                        jury {
                            _id
                            name
                        }
                        problemNumber
                        subproblemNumber
                    }
                }
            }
        }
    }

    fragment paperPageProblemSetData on ProblemSet {
        files {
            file
            language
        }
        problems {
            text
            image
            author
            subproblems {
                text
                points
            }
        }
    }

    fragment paperPageRoundData on TournamentRound {
        date
        stage
        junior {
            ...paperPageProblemSetData
        }
        senior {
            ...paperPageProblemSetData
        }
    }

    fragment paperPageSeasonData on TournamentSeason {
        stage
        ordinary {
            ...paperPageRoundData
        }
        advanced {
            ...paperPageRoundData
        }
    }
`;

export default function PaperPage() {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { viewer } = useViewer();
    const [marksVersion, setMarksVersion] = useState(0);
    const handleMarksSaved = useCallback(() => setMarksVersion(v => v + 1), []);

    const { paperId, venueId, batchId } = useParams<{
        paperId: string;
        venueId?: string;
        batchId?: string;
    }>();
    const { data, loading } = useQuery(paperPageQuery, {
        variables: {
            paperId,
        },
    });

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { tournament, papers, ...participant } = data.participant;
    const paper = papers.find(p => p._id === paperId);
    const problemSet =
        tournament.seasons[paper.season.toLowerCase()][
            paper.level.toLowerCase()
        ][paper.tier.toLowerCase()];
    const pages = (paper.pages ? [...paper.pages] : [])
        .map((page, index) => ({
            page,
            index,
            problemNumber: page.problemNumber,
        }))
        .sort(createSortFn('problemNumber', 'index'))
        .map(({ page }) => page);

    return (
        <Page
            nav={
                venueId && paper.venue ? (
                    viewer?.role !== 'ORGANIZER' ? (
                        <>
                            <Nav.Link
                                as={Link}
                                to={`/tournaments/${tournament.number}/venues/${paper.venue._id}`}
                            >
                                {language === 'ru'
                                    ? paper.venue.townName
                                    : paper.venue.townNameEn ||
                                      paper.venue.townName}
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to={`/tournaments/${tournament.number}/venues/${paper.venue._id}/papers/all`}
                            >
                                {t('OrganizerHomePage.Papers')}
                            </Nav.Link>
                        </>
                    ) : (
                        <>
                            <Nav.Link
                                href={`/org/${tournament.number}/venues/${paper.venue._id}`}
                            >
                                {language === 'ru'
                                    ? paper.venue.townName
                                    : paper.venue.townNameEn ||
                                      paper.venue.townName}
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to={`/tournaments/${tournament.number}/venues/${paper.venue._id}/papers/all`}
                            >
                                {t('OrganizerHomePage.Papers')}
                            </Nav.Link>
                        </>
                    )
                ) : batchId ? (
                    <Nav.Link
                        as={Link}
                        to={`/tournaments/${tournament.number}/batches/${batchId}`}
                    >
                        Пачка
                    </Nav.Link>
                ) : null
            }
        >
            <h3>
                {t('LevelFullTitle', {
                    number: tournament.number,
                    season: paper.season,
                    level: paper.level,
                })}
            </h3>
            <h4>
                {t('TierTitle', { tier: paper.tier })},{' '}
                {t('ParticipationInfo.GradeNumber', {
                    grade: participant.grade,
                })}
            </h4>
            <h5>
                {participant.name} {participant.surname}, {participant.townName}
            </h5>
            <PaperFlags paper={paper} />
            <br />
            <PaperCommentForm paper={paper} />
            <br />
            <h4 className="mt-4">{t('ExaminationPaperView.Examinations')}</h4>
            <PaperExaminations
                paper={paper}
                problemSet={problemSet}
                marksVersion={marksVersion}
            />
            <br />
            <br />
            <ExaminationPaperView
                paper={paper}
                pages={pages}
                problemSet={problemSet}
                onMarksSaved={handleMarksSaved}
            />
            <br />
        </Page>
    );
}

const setPaperFlagsMutation = gql`
    mutation SetPaperFlags($paperId: ID!, $flags: PaperFlagsInput!) {
        setPaperFlags(paperId: $paperId, flags: $flags) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    flags {
                        centralExamination
                        suspicious
                        annulled
                        pagesProblem
                    }
                }
            }
        }
    }
`;

function PaperFlags({ paper }) {
    const [setPaperFlags] = useMutation(setPaperFlagsMutation);
    const { t } = useTranslation();

    const setFlag = event => {
        const flag = event.currentTarget.id.split('-').pop();

        setPaperFlags({
            variables: {
                paperId: paper._id,
                flags: {
                    [flag]: event.currentTarget.checked,
                },
            },
        });
    };

    return (
        <Row className="mt-4">
            <Col
                xs={6}
                sm={3}
                className={classnames('paper-flag-checkbox', {
                    'paper-flag-ok': paper.flags?.centralExamination,
                })}
            >
                <Form.Check
                    label={t('PaperFlag', { context: 'centralExamination' })}
                    id="flag-centralExamination"
                    onChange={setFlag}
                    checked={paper.flags?.centralExamination}
                />
            </Col>
            <Col
                xs={6}
                sm={3}
                className={classnames('paper-flag-checkbox', {
                    'paper-flag-warning': paper.flags?.pagesProblem,
                })}
            >
                <Form.Check
                    label={t('PaperFlag', { context: 'pagesProblem' })}
                    id="flag-pagesProblem"
                    onChange={setFlag}
                    checked={paper.flags?.pagesProblem}
                />
            </Col>
            <Col
                xs={6}
                sm={3}
                className={classnames('paper-flag-checkbox', {
                    'paper-flag-warning': paper.flags?.suspicious,
                })}
            >
                <Form.Check
                    label={t('PaperFlag', { context: 'suspicious' })}
                    id="flag-suspicious"
                    onChange={setFlag}
                    checked={paper.flags?.suspicious}
                />
            </Col>
            <Col
                xs={6}
                sm={3}
                className={classnames('paper-flag-checkbox', {
                    'paper-flag-danger': paper.flags?.annulled,
                })}
            >
                <Form.Check
                    label={t('PaperFlag', { context: 'annulled' })}
                    id="flag-annulled"
                    onChange={setFlag}
                    checked={paper.flags?.annulled}
                />
            </Col>
        </Row>
    );
}

const updatePaperCommentMutation = gql`
    mutation UpdatePaperComment($paperId: ID!, $comment: String!) {
        updatePaperComment(paperId: $paperId, comment: $comment) {
            __typename
            ... on ExaminePaperSuccess {
                paper {
                    _id
                    comment
                }
            }
        }
    }
`;

function PaperCommentForm({ paper }) {
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();
    const [updatePaperComment, { loading, data }] = useMutation(
        updatePaperCommentMutation
    );

    return (
        <Form
            onSubmit={handleSubmit(({ comment }) => {
                updatePaperComment({
                    variables: {
                        paperId: paper._id,
                        comment,
                    },
                });
            })}
        >
            <Form.Group controlId="paper-page-comment">
                <Form.Control
                    as="textarea"
                    rows={4}
                    {...register('comment')}
                    placeholder={t('ExaminationPaperView.CommentPlaceholder')}
                    defaultValue={paper.comment || ''}
                />
            </Form.Group>
            <SubmitButton loading={loading} mutationData={data}>
                {t('ExaminationPaperView.SaveComment')}
            </SubmitButton>
        </Form>
    );
}
