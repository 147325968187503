import { Trans, useTranslation } from 'react-i18next';
import { Alert, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitButton } from '../Components/EnhancedButtons';
import { VenueParticipationInfo } from '../Components/VenueParticipationInfo';

export function CreateVenueApplicationForm({
    viewerEmail,
    onSubmit,
    mutationData,
    loading,
    mutationError,
    submitButtonText,
    application = null,
    venue = null,
    language,
    readOnly = false,
    ordinaryDate,
    advancedDate,
}) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger,
    } = useForm();
    const [selectedTab, setSelectedTab] = useState('basic');

    const errorMessage = mutationError ? t('UnknownErrorMessage') : null;
    const townName =
        language === 'EN' && venue?.townNameEn
            ? venue?.townNameEn
            : venue?.townName;

    return (
        <Form
            onSubmit={handleSubmit(formData =>
                onSubmit({
                    ...formData,
                    accessComment:
                        formData.access === 'LIMITED'
                            ? formData.accessComment
                            : '',
                    accessDescription:
                        formData.access === 'LIMITED'
                            ? formData.accessDescription
                            : '',
                    email: viewerEmail,
                })
            )}
        >
            <Tabs activeKey={selectedTab} onSelect={tab => setSelectedTab(tab)}>
                <Tab
                    title={t('VenueApplicationForm.OrganizersInfo')}
                    eventKey="basic"
                >
                    <br />
                    <Form.Group controlId="add-venue-townName">
                        <Form.Label>
                            {t('VenueApplicationForm.Town')}*
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('townName', { required: true })}
                            defaultValue={
                                application?.townName || townName || ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        {errors.townName && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-organization">
                        <Form.Label>
                            {t('VenueApplicationForm.HoldingOrganization')}*
                        </Form.Label>
                        <Form.Control
                            {...register('organization', {
                                required: true,
                            })}
                            defaultValue={
                                application?.organization ||
                                venue?.organization ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            <Trans i18nKey="VenueApplicationForm.HoldingOrganizationHint" />
                        </Form.Text>
                        {errors.organization && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-organizationDescription">
                        <Form.Label>
                            {t(
                                'VenueApplicationForm.HoldingOrganizationDescription'
                            )}
                            *
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            {...register('organizationDescription', {
                                required: true,
                            })}
                            defaultValue={
                                application?.organizationDescription ||
                                venue?.organizationDescription ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            {t(
                                'VenueApplicationForm.HoldingOrganizationDescriptionHint'
                            )}
                        </Form.Text>
                        {errors.organizationDescription && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-email">
                        <Form.Label>
                            {t('VenueApplicationForm.Email')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            disabled={true}
                            {...register('email')}
                            defaultValue={
                                application?.email ||
                                venue?.email ||
                                viewerEmail ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-phone">
                        <Form.Label>
                            {t('VenueApplicationForm.EmergencyPhone')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('phone')}
                            defaultValue={
                                application?.phone || venue?.phone || ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            {t('VenueApplicationForm.EmergencyPhoneHint')}
                        </Form.Text>
                    </Form.Group>
                    <br />
                    {!readOnly && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'townName',
                                        'organization',
                                        'organizationDescription',
                                        'email',
                                        'phone',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    if (!watch('accessDescription')) {
                                        setValue(
                                            'accessDescription',
                                            getAccessDescription(
                                                watch('organization'),
                                                language
                                            )
                                        );
                                    }
                                    setSelectedTab('access');
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                </Tab>
                <Tab
                    title={t('VenueApplicationForm.Holding')}
                    eventKey="access"
                >
                    <br />
                    {t('VenueApplicationForm.PlannedRounds')}
                    <br />
                    <Row className="mt-1 mb-4">
                        <Col md={4}>
                            <Form.Check
                                {...register('levels', { required: true })}
                                value="ORDINARY"
                                label={t('VenueApplicationForm.OrdinaryDate', {
                                    date: new Date(ordinaryDate),
                                })}
                                id="add-venue-ordinary"
                                defaultChecked={
                                    application?.levels.includes('ORDINARY') ||
                                    false
                                }
                                readOnly={readOnly}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Check
                                {...register('levels', { required: true })}
                                value="ADVANCED"
                                label={t('VenueApplicationForm.AdvancedDate', {
                                    date: new Date(advancedDate),
                                })}
                                id="add-venue-advanced"
                                defaultChecked={
                                    application?.levels.includes('ADVANCED') ||
                                    false
                                }
                                readOnly={readOnly}
                            />
                        </Col>
                    </Row>
                    {errors.levels && (
                        <Form.Control.Feedback
                            className="d-block mt-n4"
                            type="invalid"
                        >
                            {t('VenueApplicationForm.ChooseAtLeastOneLevel')}
                        </Form.Control.Feedback>
                    )}
                    <br />
                    <Form.Group controlId="add-venue-access">
                        <Form.Label>
                            {t('VenueApplicationForm.WhoCanParticipate')}*
                        </Form.Label>
                        {errors.access && (
                            <Form.Control.Feedback
                                className="d-block mt-n2"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                        <Form.Check
                            {...register('access', { required: true })}
                            type="radio"
                            value="LIMITED"
                            label={t('VenueApplicationForm.LimitedAccess')}
                            defaultChecked={
                                application?.access === 'LIMITED' || false
                            }
                            readOnly={readOnly}
                            id="venue-application-access-limited"
                        />
                        <Form.Check
                            {...register('access', { required: true })}
                            type="radio"
                            value="REGISTRATION"
                            label={t('VenueApplicationForm.RegistrationAccess')}
                            defaultChecked={
                                application?.access === 'REGISTRATION' || false
                            }
                            readOnly={readOnly}
                            id="venue-application-access-registration"
                        />
                        <Form.Check
                            {...register('access', { required: true })}
                            type="radio"
                            value="FREE"
                            label={t('VenueApplicationForm.FreeAccess')}
                            defaultChecked={
                                application?.access === 'FREE' || false
                            }
                            readOnly={readOnly}
                            id="venue-application-access-free"
                        />
                    </Form.Group>
                    {watch('access') === 'LIMITED' && (
                        <Form.Group controlId="add-venue-accessComment">
                            <Form.Label>
                                {t('VenueApplicationForm.AccessDetails')}
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                {...register('accessComment')}
                                defaultValue={application?.accessComment || ''}
                                readOnly={readOnly}
                                className="bg-white"
                            />
                            <Form.Text muted>
                                {t('VenueApplicationForm.AccessDetailsHint')}
                            </Form.Text>
                        </Form.Group>
                    )}
                    <Form.Group controlId="add-venue-participantsNumberLimit">
                        <Form.Label>
                            {t('VenueApplicationForm.ParticipantsNumberLimit')}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            {...register('participantsNumberLimit', {
                                valueAsNumber: true,
                            })}
                            defaultValue={
                                application?.participantsNumberLimit || ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            {t(
                                'VenueApplicationForm.ParticipantsNumberLimitHint'
                            )}
                        </Form.Text>
                    </Form.Group>
                    <br />
                    {!readOnly && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'levels',
                                        'access',
                                        'participantsNumberLimit',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    setSelectedTab('participationInfo');
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                </Tab>
                <Tab
                    title={t('VenueApplicationForm.InformationForParticipants')}
                    eventKey="participationInfo"
                >
                    <br />
                    {venue && (
                        <Alert variant="warning">
                            {t('VenueApplicationForm.DoubleCheckInfo')}
                        </Alert>
                    )}
                    {watch('access') === 'LIMITED' && (
                        <Form.Group controlId="add-venue-accessDescription">
                            <Form.Label>
                                {t(
                                    'VenueApplicationForm.AccessDescriptionForParticipants'
                                )}
                            </Form.Label>
                            <Form.Control
                                {...register('accessDescription')}
                                defaultValue={
                                    application?.accessDescription ||
                                    venue?.seasons?.fall?.accessDescription ||
                                    ''
                                }
                                readOnly={readOnly}
                                className="bg-white"
                            />
                            <Form.Text muted>
                                <Trans i18nKey="VenueApplicationForm.AccessDescriptionForParticipantsHint" />
                            </Form.Text>
                        </Form.Group>
                    )}
                    <Form.Group controlId="add-venue-address">
                        <Form.Label>
                            {t('VenueApplicationForm.Address')}*
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            {...register('address', { required: true })}
                            defaultValue={
                                application?.address ||
                                venue?.seasons?.fall?.address ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        {errors.address && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-startTime">
                        <Form.Label>
                            {t('VenueApplicationForm.StartTime')}*
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('startTime', { required: true })}
                            defaultValue={
                                application?.startTime ||
                                venue?.seasons?.fall?.startTime ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            {t('VenueApplicationForm.StartTimeHint')}
                        </Form.Text>
                        {errors.startTime && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-infoMessage">
                        <Form.Label>
                            {t('VenueApplicationForm.Notes')}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            {...register('infoMessage')}
                            defaultValue={
                                application?.infoMessage ||
                                venue?.seasons?.fall?.infoMessage ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        <Form.Text muted>
                            {t('VenueApplicationForm.NotesHint')}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactEmail">
                        <Form.Label>
                            {t('VenueApplicationForm.EmailForParticipants')}*
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('contactEmail', {
                                required: true,
                            })}
                            defaultValue={
                                application?.contacts?.email ||
                                venue?.seasons?.fall?.contacts?.email ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                        {errors.contactEmail && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactPhone">
                        <Form.Label>
                            {t('VenueApplicationForm.PhoneForParticipants')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('contactPhone')}
                            defaultValue={
                                application?.contacts?.phone ||
                                venue?.seasons?.fall?.contacts?.phone ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactWebsite">
                        <Form.Label>
                            {t('VenueApplicationForm.Website')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('contactWebsite')}
                            defaultValue={
                                application?.contacts?.website ||
                                venue?.seasons?.fall?.contacts?.website ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactTelegram">
                        <Form.Label>
                            {t('VenueApplicationForm.Telegram')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('contactTelegram')}
                            defaultValue={
                                application?.contacts?.telegram ||
                                venue?.seasons?.fall?.contacts?.telegram ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactWhatsapp">
                        <Form.Label>
                            {t('VenueApplicationForm.Whatsapp')}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            {...register('contactWhatsapp')}
                            defaultValue={
                                application?.contacts?.whatsapp ||
                                venue?.seasons?.fall?.contacts?.whatsapp ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactViber">
                        <Form.Label>
                            {t('VenueApplicationForm.Viber')}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            {...register('contactViber')}
                            defaultValue={
                                application?.contacts?.viber ||
                                venue?.seasons?.fall?.contacts?.viber ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-contactFbMessenger">
                        <Form.Label>
                            {t('VenueApplicationForm.FbMessenger')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('contactFbMessenger')}
                            defaultValue={
                                application?.contacts?.fbMessenger ||
                                venue?.seasons?.fall?.contacts?.fbMessenger ||
                                ''
                            }
                            readOnly={readOnly}
                            className="bg-white"
                        />
                    </Form.Group>
                    <br />
                    {!readOnly && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'address',
                                        'startTime',
                                        'infoMessage',
                                        'contactPhone',
                                        'contactEmail',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    setSelectedTab('comment');
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                    <br />
                    <br />
                    {watch('address') &&
                    watch('startTime') &&
                    watch('contactEmail') ? (
                        <>
                            <h4>
                                {t(
                                    'VenueApplicationForm.HowWillThisLookForParticipants'
                                )}
                            </h4>
                            <p className="text-muted">
                                {t('VenueApplicationForm.CorrectionsPossible')}
                            </p>
                            <VenueParticipationInfo
                                venueInfo={{
                                    address: watch('address'),
                                    startTime: watch('startTime'),
                                    infoMessage: watch('infoMessage'),
                                    contacts: {
                                        email: watch('contactEmail'),
                                        phone: watch('contactPhone'),
                                        website: watch('contactWebsite'),
                                        telegram: watch('contactTelegram'),
                                        whatsapp: watch('contactWhatsapp'),
                                        viber: watch('contactViber'),
                                        fbMessenger:
                                            watch('contactFbMessenger'),
                                    },
                                    access: watch('access'),
                                    accessDescription:
                                        watch('accessDescription'),
                                }}
                            />
                        </>
                    ) : null}
                </Tab>
                <Tab
                    title={t('VenueApplicationForm.Comments')}
                    eventKey="comment"
                >
                    <br />
                    <Form.Group controlId="add-venue-comment">
                        <Form.Label>
                            {t(
                                'VenueApplicationForm.CommentsSuggestionsQuestions'
                            )}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            {...register('comment')}
                            defaultValue={application?.comment || ''}
                            className="bg-white"
                            readOnly={readOnly}
                        />
                    </Form.Group>
                    <br />
                    {!readOnly && (
                        <SubmitButton
                            loading={loading}
                            mutationData={mutationData}
                            className="mt-2"
                        >
                            {submitButtonText}
                        </SubmitButton>
                    )}
                </Tab>
            </Tabs>
            {errorMessage && (
                <Alert variant="warning" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
        </Form>
    );
}

function getAccessDescription(organization, language = 'RU') {
    if (language === 'EN') {
        return `Only for students of ${organization}`;
    }

    if (language === 'RU') {
        let declination = organization
            .replace(/ий лицей/i, 'ого лицея')
            .replace(/ый лицей/i, 'ого лицея')
            .replace(/ой лицей/i, 'ого лицея')
            .replace(/лицей/i, 'лицея')
            .replace(/ая школа/i, 'ой школы')
            .replace(/яя школа/i, 'ей школы')
            .replace(/школа/i, 'школы')
            .replace(/ий центр/i, 'ого центра')
            .replace(/ый центр/i, 'ого центра')
            .replace(/ой центр/i, 'ого центра')
            .replace(/центр/i, 'центра');
        return 'Только для учеников ' + declination;
    }

    return '';
}
