import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import OnlineRoundUploadZone from './OnlineRoundUploadZone';
import PaperUploadInstructions from './PaperUploadInstructions';

const addPageToOnlineTestPaperMutation = gql`
    mutation AddPageToOnlineTestPaper(
        $participantId: ID!
        $fileData: String!
        $fileName: String!
    ) {
        addPageToOnlineTestPaper(
            participantId: $participantId
            fileData: $fileData
            fileName: $fileName
        ) {
            __typename
            ... on UnknownFileFormat {
                detectedExt
                detectedMime
            }
            ... on AddPageToOnlineTestPaperSuccess {
                participant {
                    _id
                    onlineTestPaper {
                        _id
                        pages {
                            _id
                            file
                            fileName
                        }
                    }
                }
            }
        }
    }
`;

const removePageFromOnlineTestPaperMutation = gql`
    mutation RemovePageFromOnlineTestPaper($participantId: ID!, $pageId: ID!) {
        removePageFromOnlineTestPaper(
            participantId: $participantId
            pageId: $pageId
        ) {
            __typename
            ... on RemovePageFromOnlineTestPaperSuccess {
                onlineTestPaper {
                    _id
                    pages {
                        _id
                        file
                        fileName
                    }
                }
            }
        }
    }
`;

export default function OnlineTestPaper({ participant }) {
    const { t } = useTranslation();
    const [addPageToOnlineTestPaper] = useMutation(
        addPageToOnlineTestPaperMutation
    );
    const [removePageFromOnlineTestPaper] = useMutation(
        removePageFromOnlineTestPaperMutation
    );

    return (
        <>
            <h4>{t('OnlineTestPaper.HowToUpload')}</h4>
            <p>
                Во время проведения Турнира на этой странице вам будут доступны
                условия задач.
            </p>
            <PaperUploadInstructions />
            <p>
                Ниже вы можете попробовать загрузить файлы <strong>до</strong>{' '}
                Турнира, чтобы потренироваться и проверить свою технику.
                <br />
                Во время Турнира такое поле для загрузки будет доступно для
                каждой задачи.
            </p>
            <h4>{t('OnlineTestPaper.TestUpload')}</h4>
            <br />
            <OnlineRoundUploadZone
                pages={participant.onlineTestPaper?.pages || []}
                upload={(fileData, fileName) => {
                    return addPageToOnlineTestPaper({
                        variables: {
                            fileData,
                            participantId: participant._id,
                            fileName,
                        },
                    });
                }}
                onRemoveClick={pageId =>
                    removePageFromOnlineTestPaper({
                        variables: {
                            participantId: participant._id,
                            pageId,
                        },
                    })
                }
            />
        </>
    );
}
