import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './theme.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { UserContextProvider } from './ViewerContext';

import App from './App';
import { apolloClient } from './apolloClient';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <ApolloProvider client={apolloClient}>
                <DndProvider backend={HTML5Backend}>
                    <I18nextProvider i18n={i18n}>
                        <Router>
                            <UserContextProvider>
                                <App />
                            </UserContextProvider>
                        </Router>
                    </I18nextProvider>
                </DndProvider>
            </ApolloProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
