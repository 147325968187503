import React from 'react';
import { provideLayoutAndData } from '../Page';
import { Link, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import RegisterForTournament from './RegisterForTournament';
import ParticipationInfo from './ParticipationInfo';
import OnlineTestPaper from './OnlineTestPaper';
import ReadyToStartOnline from './ReadyToStartOnline';
import {
    ParticipationAwardInformation,
    PastSeasonParticipationInformation,
} from './ParticipantPastTournamentsPage';
import { ParticipantHomePageQuery } from '../GraphQL/types/ParticipantHomePageQuery';
import { NotVerifiedMessage } from '../NotVerifiedMessage';
import { useTranslation } from 'react-i18next';

const participantHomePageQuery = gql`
    query ParticipantHomePageQuery {
        viewer {
            _id
            email
            participants {
                _id
                name
                surname
                email
                school
                townName
                grade
                phone
                award
                tournament {
                    ...participantHomePageTournamentData
                }
                papers {
                    _id
                    stage
                    season
                    level
                    tier
                    type
                    file
                    card
                    venue {
                        _id
                        townName
                        townNameEn
                        organization
                        seasons {
                            fall {
                                levels
                                address
                                infoMessage
                                contacts {
                                    email
                                    phone
                                    website
                                    telegram
                                    whatsapp
                                    viber
                                    fbMessenger
                                }
                                startTime
                                access
                                registrationOpen
                                accessDescription
                                type
                            }
                            spring {
                                levels
                                address
                                infoMessage
                                contacts {
                                    email
                                    phone
                                    website
                                    telegram
                                    whatsapp
                                    viber
                                    fbMessenger
                                }
                                startTime
                                access
                                registrationOpen
                                accessDescription
                                type
                            }
                        }
                    }
                    marks {
                        mark
                        points
                    }
                    sum
                    result
                }
                onlineTestPaper {
                    _id
                    pages {
                        _id
                        file
                        fileName
                    }
                }
            }
        }
        currentTournament {
            ...participantHomePageTournamentData
        }
    }

    fragment participantHomePageTournamentData on Tournament {
        _id
        number
        stage
        seasons {
            fall {
                ...participantHomePageSeasonData
            }
            spring {
                ...participantHomePageSeasonData
            }
        }
    }

    fragment participantHomePageSeasonData on TournamentSeason {
        ordinary {
            date
            onlineStart
            onlineDeadline
        }
        advanced {
            date
            onlineStart
            onlineDeadline
        }
        problemsPublicLink
        criteriaPublicLink
    }
`;

export default provideLayoutAndData<ParticipantHomePageQuery>(
    participantHomePageQuery,
    {
        authenticatedOnly: true,
    }
)(function ParticipantHomePage({ data, viewer }) {
    const { t } = useTranslation();

    if (!viewer.verified) {
        return <NotVerifiedMessage viewer={viewer} />;
    }

    const { currentTournament } = data;

    const currentTournamentParticipant =
        data.viewer.participants
            .filter(
                participant =>
                    participant.tournament._id === currentTournament._id
            )
            ?.pop() || null;

    const previousTournamentsParticipants = data.viewer.participants
        .filter(
            participant => participant._id !== currentTournamentParticipant?._id
        )
        .sort((a, b) => b.tournament.number - a.tournament.number);

    const season =
        // checkStage(currentTournament.stage, 'SPRING_VENUE_APPLICATIONS') ? 'SPRING' : 'FALL';
        currentTournament.stage === 'FINISHED' ||
        currentTournament.stage.startsWith('SPRING')
            ? 'SPRING'
            : 'FALL';

    let startedLevel = null;

    if (currentTournament.stage.endsWith('ORDINARY_STARTED')) {
        startedLevel = 'ORDINARY';
    }

    if (currentTournament.stage.endsWith('ADVANCED_STARTED')) {
        startedLevel = 'ADVANCED';
    }

    if (startedLevel) {
        const paper = currentTournamentParticipant?.papers.find(
            p => p.season === season && p.level === startedLevel
        );

        if (paper?.type === 'ONLINE') {
            if (paper.stage === 'REGISTERED') {
                return (
                    <ReadyToStartOnline
                        participant={currentTournamentParticipant}
                        paper={paper}
                    />
                );
            }

            if (paper.stage === 'ONLINE_STARTED') {
                return <Redirect to={`/online/${paper._id}`} />;
            }

            if (paper.stage === 'UPLOADED') {
                return (
                    <>
                        <h4>{t('ParticipantHomePage.YourPaperIsSubmitted')}</h4>
                        <p>
                            {t(
                                'ParticipantHomePage.ResultsWillBeAvailableAfterMarking'
                            )}
                        </p>
                    </>
                );
            }
        }
    }

    return (
        <>
            <h2>
                {t('TournamentTitle', {
                    number: currentTournament.number,
                })}
            </h2>
            {currentTournament.stage === 'PLANNED' ||
            currentTournament.stage.endsWith('VENUE_APPLICATIONS') ? (
                <p>
                    {t('ParticipantHomePage.RegistrationWillBeOpenSoon', {
                        season,
                        number: currentTournament.number,
                    })}
                </p>
            ) : (
                <CurrentSeasonParticipationInformation
                    tournament={currentTournament}
                    participant={currentTournamentParticipant}
                    previousTournamentParticipant={
                        previousTournamentsParticipants[0]
                    }
                    season={season}
                />
            )}
            <br />
            <br />
            {previousTournamentsParticipants.length > 0 && (
                <h3>
                    <Link to="/past-tournaments">
                        {t('ParticipantHomePage.PastTournaments')}
                    </Link>
                </h3>
            )}
        </>
    );
});

function CurrentSeasonParticipationInformation({
    tournament,
    participant,
    previousTournamentParticipant,
    season,
}) {
    if (tournament.stage === `${season}_ADVANCED_FINISHED`) {
        return (
            <>
                {
                    /*tournament.number === 45 &&
                season === 'FALL' &&
                participant?.papers.some(
                    p => p.venue?.townName === 'Москва'
                ) ? (
                    <>
                        <p>
                            Закрытие московского Турнира городов пройдёт 24
                            декабря в школе № 179. Там можно будет посмотреть
                            свою работу и обсудить её с жюри. Подробнее{' '}
                            <a href="https://www.turgor.ru/moscow">
                                на сайте Турнира городов
                            </a>
                            .
                        </p>
                        <p>
                            Если по каким-то причинам вы не сможете прийти на
                            закрытие 24 декабря, вы можете (после появления
                            результатов в личном кабинете) подать апелляцию на
                            свои оценки через форму личном кабинете участника не
                            позднее 9:00 24 декабря.
                        </p>
                        <p>
                            Жюри постарается рассмотреть поданные электронно
                            апелляции 24 декабря.
                        </p>
                        <p>
                            О порядке получения дипломов, не полученных на
                            награждении 24 декабря, призов, а также электронных
                            копий дипломов, будет объявлено дополнительно.
                        </p>
                    </>
                ) : */ null
                }
                <PastSeasonParticipationInformation
                    tournament={tournament}
                    participant={participant}
                    season={season}
                />
                <ParticipationAwardInformation
                    tournament={tournament}
                    participant={participant}
                />
                <br />
                <br />
            </>
        );
    }

    if (!participant?.papers.find(paper => paper.season === season)) {
        const previousData = {
            name: previousTournamentParticipant?.name || '',
            surname: previousTournamentParticipant?.surname || '',
            phone: previousTournamentParticipant?.phone || '',
            townName: previousTournamentParticipant?.townName || '',
            school: previousTournamentParticipant?.school || '',
        };
        const { name, surname, phone, grade, townName, school } =
            participant || previousData;

        return (
            <RegisterForTournament
                tournament={tournament}
                season={season}
                participantInfo={{
                    name,
                    surname,
                    phone,
                    grade,
                    townName,
                    school,
                }}
            />
        );
    }

    const level = [
        `${season}_ORDINARY_FINISHED`,
        `${season}_ADVANCED_STARTED`,
        `${season}_ADVANCED_FINISHED`,
    ].includes(tournament.stage)
        ? 'ADVANCED'
        : 'ORDINARY';

    return (
        <>
            <ParticipationInfo
                participant={participant}
                tournament={tournament}
                season={season}
                level={level}
            />
            {participant.papers.find(
                p => p.season === season && p.level === level
            )?.type === 'ONLINE' &&
                level === 'ORDINARY' && (
                    <OnlineTestPaper participant={participant} />
                )}
        </>
    );
}
