import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AdminAddVenuePage from './AdminVenues/AdminAddVenuePage';
import AdminVenuePage from './AdminVenues/AdminVenuePage';
import AdminTournamentParticipantsPage from './AdminTournamentParticipantsPage';
import AdminParticipantPage from './AdminParticipantPage';
import TournamentQuestionsPage from '../TournamentQuestionsPage';
import PaperPage from '../Paper/PaperPage';
import VenuePapersPage from '../VenuePapersPage';
import AdminVenueParticipantsPage from './AdminVenues/AdminVenueParticipantsPage';
import TournamentBatchesPage from '../TournamentBatchesPage';
import BatchPage from '../BatchPage';
import VenueUploadPage from '../VenueUploadPage';
import AdminVenueBatchesPage from './AdminVenues/AdminVenueBatchesPage';
import AdminTournamentProblemsPage from './AdminTournamentProblemsPage';
import TournamentResultsPage from '../TournamentResultsPage';
import AdminVenueResultsPage from './AdminVenues/AdminVenueResultsPage';
import TournamentAppealsPage from '../TournamentAppealsPage';
import AdminVenueScanQrPage from './AdminVenues/AdminVenueScanQrPage';
import AdminTournamentSchoolsPage from './AdminTournamentSchoolsPage';
import AdminDeduplicateTournamentParticipantsPage from './AdminDeduplicateTournamentParticipantsPage';
import AdminDeduplicateVenueParticipantsPage from './AdminDeduplicateVenueParticipantsPage';
import ResultsInputPage from '../ResultsInputPage';
import VenueResultsInputPage from '../VenueResultsInputPage';
import TournamentProblemMarksStatisticsPage from '../TournamentProblemMarksStatisticsPage';
import AdminVenueApplicationsPage from './AdminVenueApplicationsPage';
import AdminVenueApplicationPage from './AdminVenueApplicationPage';
import VenueAppealsPage from './AdminVenues/VenueAppealsPage';

export default function AdminTournamentPage() {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${path}/venues/add/:venueApplicationId?`}
                component={AdminAddVenuePage}
            />
            <Route
                path={`${path}/venues/:venueId/participants/:season`}
                component={AdminVenueParticipantsPage}
            />
            <Route
                path={`${path}/venues/:venueId/results`}
                component={AdminVenueResultsPage}
                exact={true}
            />
            <Route
                path={`${path}/venues/:venueId/upload/:season`}
                component={VenueUploadPage}
            />
            <Route
                path={`${path}/venues/:venueId/appeals/:season`}
                component={VenueAppealsPage}
            />
            <Route
                path={`${path}/venues/:venueId/scan-qr/:season`}
                component={AdminVenueScanQrPage}
            />
            <Route
                path={`${path}/venues/:venueId/papers/:round/:paperId`}
                component={PaperPage}
            />
            <Route
                path={`${path}/venues/:venueId/papers/:round`}
                component={VenuePapersPage}
            />
            <Route
                path={`${path}/venues/:venueId/batches/:round`}
                component={AdminVenueBatchesPage}
            />
            <Route
                path={`${path}/venues/:venueId/results-input`}
                exact={true}
                component={VenueResultsInputPage}
            />
            <Route
                path={`${path}/venues/:venueId/results-input/:round/:tier/:grade?`}
                component={ResultsInputPage}
            />
            <Route
                path={`${path}/venues/:venueId/deduplicate-participants`}
                component={AdminDeduplicateVenueParticipantsPage}
            />
            <Route
                path={`${path}/venues/:venueId/schools`}
                component={AdminTournamentSchoolsPage}
            />
            <Route
                path={`${path}/venues/:venueId/spring-application/:venueApplicationId`}
                component={AdminVenuePage}
            />
            <Route
                path={`${path}/venues/:venueId`}
                component={AdminVenuePage}
            />
            <Route
                exact={true}
                path={`${path}/venue-applications/:season(fall|spring)`}
                component={AdminVenueApplicationsPage}
            />
            <Route
                exact={true}
                path={`${path}/venue-applications/:venueApplicationId`}
                component={AdminVenueApplicationPage}
            />
            <Route
                path={`${path}/problems`}
                component={AdminTournamentProblemsPage}
            />
            <Route
                exact={true}
                path={`${path}/results`}
                component={TournamentResultsPage}
            />
            <Route
                exact={true}
                path={`${path}/marks-statistics`}
                component={TournamentProblemMarksStatisticsPage}
            />
            <Route
                path={`${path}/participants/:season(fall|spring)`}
                component={AdminTournamentParticipantsPage}
            />
            <Route
                path={`${path}/participants/:participantId`}
                component={AdminParticipantPage}
            />
            <Route
                path={`${path}/questions/:round`}
                component={TournamentQuestionsPage}
            />
            <Route
                path={`${path}/appeals/:round/:tier`}
                component={TournamentAppealsPage}
            />
            <Route
                path={`${path}/schools`}
                component={AdminTournamentSchoolsPage}
            />
            <Route
                path={`${path}/deduplicate-participants`}
                component={AdminDeduplicateTournamentParticipantsPage}
            />
            <Route
                exact={true}
                path={`${path}/batches/:round/:tier`}
                component={TournamentBatchesPage}
            />
            <Route
                exact={true}
                path={`${path}/batches/:batchId`}
                component={BatchPage}
            />
            <Route
                path={`${path}/batches/:batchId/papers/:paperId`}
                component={PaperPage}
            />
            <Route path={`${path}/papers/:paperId`} component={PaperPage} />
            <Redirect to={path} />
        </Switch>
    );
}
