import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';

interface PageGalleryProps {
    pages: any[];
    onRemoveClick?: (string) => any;
}

export default function PageGallery({
    pages = [],
    onRemoveClick,
}: PageGalleryProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

    return (
        <>
            <Row className="align-items-end">
                {pages.map(page => (
                    <PageThumbnail
                        page={page}
                        key={page._id}
                        onRemoveClick={onRemoveClick}
                        onClick={pageId => {
                            setCurrentPageIndex(
                                pages.findIndex(p => p._id === pageId)
                            );
                            setModalOpen(true);
                        }}
                    />
                ))}
            </Row>
            <GalleryModal
                onClose={() => setModalOpen(false)}
                open={modalOpen}
                currentPageIndex={currentPageIndex}
                setCurrentPageIndex={setCurrentPageIndex}
                pages={pages}
            />
        </>
    );
}

export function PageGalleryModal({ pages = [], onClose, initialPage = 0 }) {
    const [currentPageIndex, setCurrentPageIndex] =
        useState<number>(initialPage);

    return (
        <GalleryModal
            onClose={onClose}
            open={true}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            pages={pages}
        />
    );
}

function GalleryModal({
    open,
    currentPageIndex,
    setCurrentPageIndex,
    onClose,
    pages,
}) {
    const [aspectRatio, setAspectRatio] = useState(1);
    const windowAspectRatio = window.innerWidth / window.innerHeight;

    useEffect(() => {
        if (open) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [open]);

    return (
        <>
            <div
                className={classnames('paper-full-view-overlay', {
                    'd-none': !open,
                })}
            >
                <div className="paper-full-view-page">
                    {pages?.length === 0 && (
                        <h1 className="text-center text-white mt-5">
                            Нет ни одной страницы
                        </h1>
                    )}
                    {pages?.[currentPageIndex] ? (
                        <Image
                            className="paper-full-view-page-image"
                            src={`${process.env.REACT_APP_BACKEND_URL}${pages[currentPageIndex].file}`}
                            onLoad={event => {
                                const { naturalWidth, naturalHeight } =
                                    event.currentTarget;

                                setAspectRatio(naturalWidth / naturalHeight);
                            }}
                            style={
                                aspectRatio < 1 && windowAspectRatio > 1
                                    ? { height: '150%' }
                                    : {}
                            }
                        />
                    ) : null}
                </div>
            </div>
            <Button
                variant="secondary"
                className={classnames('paper-full-view-next-button', {
                    'd-none': !open || currentPageIndex === pages.length - 1,
                })}
                onClick={() =>
                    setCurrentPageIndex(
                        currentPageIndex === pages.length - 1
                            ? currentPageIndex
                            : currentPageIndex + 1
                    )
                }
            >
                <CaretRightFill />
            </Button>
            <Button
                variant="secondary"
                className={classnames('paper-full-view-prev-button', {
                    'd-none': !open || currentPageIndex === 0,
                })}
                disabled={currentPageIndex === 0}
                onClick={() =>
                    setCurrentPageIndex(
                        currentPageIndex === 0
                            ? currentPageIndex
                            : currentPageIndex - 1
                    )
                }
            >
                <CaretLeftFill />
            </Button>
            <Button
                variant="secondary"
                className={classnames('paper-full-view-close-button', {
                    'd-none': !open,
                })}
                onClick={onClose}
            >
                <span>&times;</span>
            </Button>
        </>
    );
}

interface PageThumbnailProps {
    page: any;
    onRemoveClick?: (string) => any;
    onClick: (string) => any;
}

function PageThumbnail({ page, onRemoveClick, onClick }: PageThumbnailProps) {
    const { t } = useTranslation();

    return (
        <Col sm={2} xs={4} className="text-center">
            <Image
                src={`${process.env.REACT_APP_BACKEND_URL}${page.file}`}
                thumbnail={true}
                onClick={() => onClick(page._id)}
                style={{ cursor: 'pointer' }}
            />
            <strong className="d-block mb-1 paper-page-preview-filename">
                {page.fileName}
            </strong>
            {onRemoveClick && (
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => onRemoveClick(page._id)}
                    className="mb-4"
                >
                    {t('OnlineRoundUploadZone.Delete')}
                </Button>
            )}
        </Col>
    );
}
