let settings = {};

export function getLocalSetting(
    prefix: string,
    setting: string,
    defaultValue: any
) {
    if (settings[setting] !== undefined) {
        return settings[setting];
    }

    try {
        let locallySaved =
            sessionStorage.getItem(`${prefix}-${setting}`) ||
            localStorage.getItem(`${prefix}-${setting}`);

        if (locallySaved) {
            return JSON.parse(locallySaved);
        }

        return defaultValue;
    } catch {
        return defaultValue;
    }
}

export function setLocalSetting(
    prefix: string,
    setting: string,
    value: any,
    temp: boolean = false
) {
    settings[setting] = value;

    try {
        return (temp ? sessionStorage : localStorage).setItem(
            `${prefix}-${setting}`,
            JSON.stringify(value)
        );
    } catch {}
}
