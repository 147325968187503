import { provideLayoutAndData } from './Page';
import { Table } from 'react-bootstrap';
import React from 'react';
import { gql } from '@apollo/client';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useViewer } from './ViewerContext';
import classnames from 'classnames';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';
import { Marks } from 'Components/Marks';
import tournamentProblemsCountFields from 'GraphQL/fragments/tournamentProblemsCountFields';
import {
    VenuePapersPageQuery,
    VenuePapersPageQueryVariables,
} from 'GraphQL/types/VenuePapersPageQuery';
import { VenueTopNavigation } from './TopNavigation';
import { useTranslation } from 'react-i18next';

const venuePapersPageQuery = gql`
    query VenuePapersPageQuery($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            townNameEn
            participants {
                _id
                surname
                name
                school
                grade
                townName
                papers {
                    _id
                    season
                    level
                    tier
                    stage
                    venue {
                        _id
                    }
                    flags {
                        annulled
                        centralExamination
                        pagesProblem
                        suspicious
                    }
                    examinations {
                        type
                        stage
                        marks {
                            mark
                            problemNumber
                            subproblemNumber
                        }
                    }
                }
            }
            tournament {
                _id
                number
                ...tournamentProblemsCountFields
            }
        }
    }

    ${tournamentProblemsCountFields}
`;

export default provideLayoutAndData<
    VenuePapersPageQuery,
    VenuePapersPageQueryVariables
>(venuePapersPageQuery, {
    variablesGetter: ({ venueId }) => ({ venueId }),
    NavComponent: ({
        params: { venueId },
        data: {
            venue: {
                townName,
                townNameEn,
                tournament: { number: tournamentNumber },
            },
        },
    }) => (
        <VenueTopNavigation
            venueId={venueId}
            townName={townName}
            townNameEn={townNameEn}
            tournamentNumber={tournamentNumber}
        />
    ),
})(function VenuePapersPage({ data }) {
    const { round } = useParams<{ round: string }>();
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const {
        venue: { participants, tournament, ...venue },
    } = data;

    const townName =
        language === 'en' && venue.townNameEn
            ? venue.townNameEn
            : venue.townName;

    return (
        <>
            <h2>
                {t('OrganizerHomePage.Papers')} — {townName}
            </h2>
            {(round === 'all' ||
                round === 'fall' ||
                round === 'fall-ordinary') && (
                <>
                    <h4>
                        {t('common:FullLevelName', {
                            season: 'FALL',
                            level: 'ORDINARY',
                        })}
                    </h4>
                    <RoundVenuePapers
                        participants={getPapers(
                            participants,
                            'FALL',
                            'ORDINARY',
                            venue._id
                        )}
                        problemSets={tournament.seasons.fall.ordinary}
                        season="FALL"
                        level="ORDINARY"
                    />
                    <br />
                    <br />
                </>
            )}
            {(round === 'all' ||
                round === 'fall' ||
                round === 'fall-advanced') && (
                <>
                    <h4>
                        {t('common:FullLevelName', {
                            season: 'FALL',
                            level: 'ADVANCED',
                        })}
                    </h4>
                    <RoundVenuePapers
                        participants={getPapers(
                            participants,
                            'FALL',
                            'ADVANCED',
                            venue._id
                        )}
                        problemSets={tournament.seasons.fall.advanced}
                        season="FALL"
                        level="ADVANCED"
                    />
                    <br />
                    <br />
                </>
            )}
            {(round === 'all' ||
                round === 'spring' ||
                round === 'spring-ordinary') && (
                <>
                    <h4>
                        {t('common:FullLevelName', {
                            season: 'SPRING',
                            level: 'ORDINARY',
                        })}
                    </h4>
                    <RoundVenuePapers
                        participants={getPapers(
                            participants,
                            'SPRING',
                            'ORDINARY',
                            venue._id
                        )}
                        problemSets={tournament.seasons.spring.ordinary}
                        season="SPRING"
                        level="ORDINARY"
                    />
                    <br />
                    <br />
                </>
            )}
            {(round === 'all' ||
                round === 'spring' ||
                round === 'spring-advanced') && (
                <>
                    <h4>
                        {t('common:FullLevelName', {
                            season: 'SPRING',
                            level: 'ADVANCED',
                        })}
                    </h4>
                    <RoundVenuePapers
                        participants={getPapers(
                            participants,
                            'SPRING',
                            'ADVANCED',
                            venue._id
                        )}
                        problemSets={tournament.seasons.spring.advanced}
                        season="SPRING"
                        level="ADVANCED"
                    />
                    <br />
                    <br />
                </>
            )}
        </>
    );
});

function RoundVenuePapers({ participants, season, level, problemSets }) {
    const { viewer } = useViewer();
    const { t } = useTranslation();

    if (!participants.length) {
        return <h5>{t('VenuePapersPage.NoPapers')}</h5>;
    }

    return (
        <Table striped={true} hover={true} borderless={true} size="sm">
            <thead>
                <tr>
                    <th> </th>
                    <th>{t('ParticipationInfo.Name')}</th>
                    {viewer.role !== 'ADMIN' && (
                        <th>{t('ParticipationInfo.School')}</th>
                    )}
                    <th>{t('ParticipationInfo.Grade')}</th>
                    <th>{t('Version')}</th>
                    <th>{t('VenuePapersPage.LocalMarking')}</th>
                    {viewer.role === 'ADMIN' && (
                        <th>{t('VenuePapersPage.Marking')}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {participants.map((participant, i) => (
                    <ParticipantPapersRow
                        participant={participant}
                        index={i}
                        key={participant._id}
                        level={level}
                        season={season}
                        problemSets={problemSets}
                    />
                ))}
            </tbody>
        </Table>
    );
}

function ParticipantPapersRow({
    participant,
    index,
    season,
    level,
    problemSets,
}) {
    let { url } = useRouteMatch();
    const { viewer } = useViewer();
    const { t } = useTranslation();

    const paper = participant.papers.find(
        p => p.season === season && p.level === level
    );

    const flatProblems = problemSets[paper.tier.toLowerCase()].problems.flatMap(
        (problem, i) =>
            problem.subproblems.map((subproblems, j) => ({
                problemNumber: i,
                subproblemNumber: j,
            }))
    );

    const localMarks =
        paper.examinations?.filter(exam => exam.type === 'LOCAL').pop()
            ?.marks || [];

    const marks =
        paper.examinations?.filter(exam => exam.type === 'CENTRAL').pop()
            ?.marks || [];

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <Link to={`${url}/${paper._id}`}>
                    {participant.name} {participant.surname}
                </Link>
            </td>
            {viewer.role !== 'ADMIN' && <td>{participant.school}</td>}
            <td>{participant.grade}</td>
            <td>{t('common:Tier', { context: paper.tier })}</td>
            <td
                className={classnames(
                    'venue-papers-participant-row-marks text-monospace',
                    {
                        'examination-marks-not-completed':
                            viewer.role === 'ADMIN' &&
                            localMarks.length !== flatProblems.length,
                    }
                )}
            >
                <Marks
                    marks={flatProblems.map(
                        fp =>
                            localMarks.find(
                                mark =>
                                    mark.problemNumber === fp.problemNumber &&
                                    mark.subproblemNumber ===
                                        fp.subproblemNumber
                            )?.mark || ''
                    )}
                />
            </td>
            {viewer.role === 'ADMIN' && (
                <td className="venue-papers-participant-row-marks text-monospace">
                    <Marks
                        marks={flatProblems.map(
                            fp =>
                                marks.find(
                                    mark =>
                                        mark.problemNumber ===
                                            fp.problemNumber &&
                                        mark.subproblemNumber ===
                                            fp.subproblemNumber
                                )?.mark || ''
                        )}
                    />
                </td>
            )}
        </tr>
    );
}

function getPapers(participants, season, level, venueId) {
    return participants
        .filter(p =>
            p.papers.some(
                p =>
                    p.venue._id === venueId &&
                    p.season === season &&
                    p.level === level &&
                    ['SUBMITTED', 'UPLOADED', 'MARKED', 'PUBLISHED'].includes(
                        p.stage
                    )
            )
        )
        .sort(participantsSortFn);
}
