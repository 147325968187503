import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../Page';
import { QuestionsList } from '../../QuestionsList';

export default function VenueAppealsPage() {
    const params = useParams<{
        number: string;
        venueId: string;
        season: string;
    }>();
    const number = Number(params.number);
    const season = params.season.toUpperCase();

    return (
        <Page>
            <QuestionsList
                number={number}
                season={season}
                venueId={params.venueId}
                type="APPEAL"
                paperMode="LINK"
            />
        </Page>
    );
}
