import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Spinner, Table, Form, Button, Modal } from 'react-bootstrap';
import React, { useCallback, useRef, useState } from 'react';
import { createSortFn } from '@turgor/model-utils/lib/sort';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';
import { PencilFill } from 'react-bootstrap-icons';

const adminTournamentSchoolsPageQuery = gql`
    query AdminTournamentSchoolsPage(
        $number: Int!
        $awardedOnly: Boolean
        $venueId: ID
    ) {
        tournament(number: $number) {
            _id
            number
            schools(awardedOnly: $awardedOnly, venueId: $venueId) {
                townName
                school
                participantsCount
            }
        }
    }
`;

export default function AdminTournamentSchoolsPage() {
    const params = useParams<{ number: string; venueId?: string }>();
    const number = Number(params.number);
    const venueId = params.venueId;
    const [awardedOnly, setAwardedOnly] = useState(false);
    const { data, loading } = useQuery(adminTournamentSchoolsPageQuery, {
        variables: { number, awardedOnly, venueId },
    });
    const { t } = useTranslation();

    const [selectedIndexes, setSelectedIndexes] = useState<Array<number>>([]);
    const lastSelectedIndex = useRef(null);

    const [modalMode, setModalMode] = useState('closed');

    const toggleIndexInSelection = useCallback(event => {
        const index = Number(event.target.name);

        setSelectedIndexes(indexes => {
            if (event.target.checked) {
                return Array.from(new Set([...indexes, index]));
            }

            return indexes.filter(i => i !== index);
        });
    }, []);

    const handleCheckClick = useCallback(event => {
        const index = Number(event.target.name);

        const prevLastSelectedIndex = lastSelectedIndex.current;
        lastSelectedIndex.current = event.target.checked ? index : null;

        if (event.shiftKey && event.target.checked) {
            setSelectedIndexes(indexes => {
                if (!indexes.length) {
                    return [index];
                }

                if (prevLastSelectedIndex === null) {
                    return [...indexes, index];
                }

                return Array.from(
                    new Set([
                        ...indexes,
                        ...createRange(index, prevLastSelectedIndex),
                    ])
                );
            });
        }
    }, []);

    const clearSelection = useCallback(() => {
        setSelectedIndexes([]);
        lastSelectedIndex.current = null;
    }, []);

    const handleSchoolEditClick = useCallback(event => {
        const index = Number(event.currentTarget.dataset.index);

        setSelectedIndexes([index]);
        setModalMode('full');
    }, []);

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        tournament: { schools: unsortedSchools, ...tournament },
    } = data;

    const schools = [...unsortedSchools].sort(
        createSortFn('townName', 'school')
    );

    return (
        <Page>
            <h2>Школы</h2>
            <Form.Check
                type="checkbox"
                label="Только с наградами"
                name="awardedOnly"
                checked={awardedOnly}
                onChange={() => setAwardedOnly(prev => !prev)}
                className="mb-2"
            />
            <p
                className={classnames('page-actions-buttons', {
                    invisible: selectedIndexes.length === 0,
                })}
            >
                <Button
                    variant="outline-primary"
                    className="mr-4"
                    onClick={() => setModalMode('townOnly')}
                >
                    Поправить город
                </Button>
                <Button
                    variant="outline-primary"
                    className="mr-5"
                    onClick={() => setModalMode('full')}
                >
                    Поправить город и школу
                </Button>
                <Button variant="outline-primary" onClick={clearSelection}>
                    Сбросить выбор
                </Button>
            </p>
            <Table borderless={true} striped={true} size="sm">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }} />
                        <th style={{ width: '30%' }}>Город</th>
                        <th style={{ width: '50%' }}>Школа</th>
                        <th style={{ width: '10%' }}>Участники</th>
                    </tr>
                </thead>
                <tbody>
                    {schools.map((school, i) => (
                        <tr
                            key={`${school.townName}-${school.school}`}
                            className={classnames({
                                'school-info-looks-pretty': looksPretty(
                                    school.townName,
                                    school.school
                                ),
                            })}
                        >
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    title="Выбрать"
                                    name={String(i)}
                                    checked={selectedIndexes.includes(i)}
                                    onChange={toggleIndexInSelection}
                                    onClick={handleCheckClick}
                                    className="d-inline-block"
                                />
                                <Button
                                    variant="link"
                                    size="sm"
                                    className="d-inline school-info-edit-button ml-3"
                                    data-index={i}
                                    onClick={handleSchoolEditClick}
                                >
                                    <PencilFill color="black" size="0.8rem" />
                                </Button>
                            </td>
                            <td>{school.townName}</td>
                            <td>{school.school}</td>
                            <td>{school.participantsCount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {modalMode !== 'closed' && (
                <UpdateSchoolInfoModal
                    tournamentId={tournament._id}
                    venueId={venueId}
                    onHide={() => setModalMode('closed')}
                    onSuccess={() => {
                        clearSelection();
                        setModalMode('closed');
                    }}
                    townOnly={modalMode === 'townOnly'}
                    schools={selectedIndexes.map(index => schools[index])}
                    awardedOnly={awardedOnly}
                />
            )}
        </Page>
    );
}

function createRange(a, b) {
    const start = Math.min(a, b);
    const end = Math.max(a, b);

    let range = [];

    for (let i = start; i <= end; i++) {
        range.push(i);
    }

    return range;
}

const updateSchoolInfoMutation = gql`
    mutation UpdateSchoolInfo(
        $tournamentId: ID!
        $venueId: ID
        $schools: [SchoolInfoInput]
        $newTownName: String!
        $newSchoolName: String
        $awardedOnly: Boolean
    ) {
        updateSchoolInfo(
            tournamentId: $tournamentId
            venueId: $venueId
            schools: $schools
            newTownName: $newTownName
            newSchoolName: $newSchoolName
        ) {
            ... on UpdateSchoolInfoSuccess {
                tournament {
                    _id
                    schools(awardedOnly: $awardedOnly, venueId: $venueId) {
                        townName
                        school
                        participantsCount
                    }
                }
            }
        }
    }
`;

function UpdateSchoolInfoModal({
    onSuccess,
    venueId,
    onHide,
    tournamentId,
    schools,
    townOnly,
    awardedOnly,
}) {
    const { register, handleSubmit, setValue } = useForm();
    const [updateSchoolInfo, { data, loading }] = useMutation(
        updateSchoolInfoMutation
    );
    const { t } = useTranslation();

    const biggestSchool = [...schools].sort(
        createSortFn('-participantsCount')
    )[0];

    const guess = () => {
        setValue('newSchoolName', prettifySchoolName(biggestSchool.school));
        setValue('newTownName', prettifyTownName(biggestSchool.townName));
    };

    return (
        <Modal show={true} size="lg" onHide={onHide} centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    {t('common:count.schools', { count: schools.length })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(({ newTownName, newSchoolName }) => {
                        updateSchoolInfo({
                            variables: {
                                tournamentId,
                                venueId,
                                schools: schools.map(
                                    ({ townName, school }) => ({
                                        townName,
                                        school,
                                    })
                                ),
                                newTownName,
                                newSchoolName: townOnly
                                    ? undefined
                                    : newSchoolName,
                                awardedOnly,
                            },
                        }).then(({ data }) => {
                            if (
                                data?.updateSchoolInfo.__typename ===
                                'UpdateSchoolInfoSuccess'
                            ) {
                                onSuccess();
                            }
                        });
                    })}
                >
                    <Form.Group controlId="edit-town-name">
                        <Form.Label>Город</Form.Label>
                        <Form.Control
                            {...register('newTownName')}
                            defaultValue={biggestSchool?.townName || ''}
                        />
                    </Form.Group>
                    {!townOnly && (
                        <Form.Group controlId="edit-school-name">
                            <Form.Label>Школа</Form.Label>
                            <Form.Control
                                {...register('newSchoolName')}
                                defaultValue={biggestSchool?.school || ''}
                            />
                        </Form.Group>
                    )}
                    <SubmitButton loading={loading} mutationData={data}>
                        Сохранить
                    </SubmitButton>
                    {!townOnly && (
                        <Button
                            variant="outline-primary"
                            className="ml-4"
                            onClick={guess}
                        >
                            Угадать
                        </Button>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

function looksPretty(townName: string, school: string): boolean {
    const townFormats = [
        /^[А-Я][а-я]+$/,
        /^[А-Я][а-я]+ [А-Я][а-я]+$/,
        /^[А-Я][а-я]+-[А-Я][а-я]+$/,
        /^[А-Я][а-я]+-[А-Яа-я][а-я]*-[А-Я][а-я]+$/,
    ];

    const schoolFormats = [
        /^школа № [0-9]+$/,
        /^лицей № [0-9]+$/,
        /^гимназия № [0-9]+$/,
    ];

    return (
        townFormats.some(format => format.test(townName)) &&
        schoolFormats.some(format => format.test(school))
    );
}

function prettifyTownName(townName: string): string {
    return townName
        .trim()
        .replace(/^г\./i, '')
        .replace(/^г /i, '')
        .replace(/^город /i, '')
        .trim();
}

function prettifySchoolName(school: string): string {
    school = school
        .replace(/^ГБОУ МО( СО?Ш)?/, '')
        .replace(/^ГБОУ( СО?Ш)?/, '')
        .replace(/^ГАОУ( СО?Ш)?/, '')
        .replace(/^МАОУ( СО?Ш)?/, '')
        .replace(/^МБУ ДО( СО?Ш)?/, '')
        .replace(/^МБУ( СО?Ш)?/, '')
        .replace(/^МБОУ( СО?Ш)?/, '')
        .replace(/^АОУ( СО?Ш)?/, '')
        .replace(/^МОУ( СО?Ш)?/, '')
        .replace(/^НОЧУ/, '')
        .replace(/^СОШ/, '')
        .replace('№', ' № ')
        .replace(/([0-9]+)/, '№ $1')
        .replace('№№', '№')
        .replace('№ №', '№')
        .replace('№  №', '№')
        .replace(/школа №/i, 'школа №')
        .replace(/лицей №/i, 'лицей №')
        .replace(/гимназия №/i, 'гимназия №')
        .replace(/  +/g, ' ')
        .trim();

    if (school.toUpperCase().indexOf('СУНЦ МГУ') !== -1) {
        school = 'СУНЦ МГУ';
    }

    if (school.toUpperCase().indexOf('СУНЦ НГУ') !== -1) {
        school = 'СУНЦ НГУ';
    }

    if (/^"[^"]+"$/.test(school)) {
        school = school.replace(/"/g, '');
    }

    if (/^№ [0-9]+$/.test(school)) {
        school = `школа ${school}`;
    }

    return school;
}
