import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from './Page';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import React from 'react';
import { getProblemsNames } from '@turgor/model-utils/lib/getProblemsNames';
import { Mark } from './Components/Marks';

const tournamentProblemMarksStatisticsPageQuery = gql`
    query TournamentProblemMarksStatisticsPageQuery($number: Int!) {
        tournament(number: $number) {
            _id
            number
            stage
            problemMarksStatistics {
                fall {
                    ordinary {
                        junior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                        senior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                    }
                    advanced {
                        junior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                        senior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                    }
                }
                spring {
                    ordinary {
                        junior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                        senior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                    }
                    advanced {
                        junior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                        senior {
                            papersCount
                            statistics {
                                mark
                                count
                            }
                        }
                    }
                }
            }
        }
    }
`;

const marks = ['0', '-', '-.', '-+', '+/2', '+-', '+.', '+', '+!'];

export default function TournamentProblemMarksStatisticsPage() {
    const params = useParams<{ number: string }>();
    const number = Number(params.number);
    const { data, loading } = useQuery(
        tournamentProblemMarksStatisticsPageQuery,
        {
            variables: { number },
        }
    );
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        tournament: { problemMarksStatistics: marksStats, ...tournament },
    } = data;

    return (
        <Page>
            <h2>
                {t('TournamentTitle', {
                    number: tournament.number,
                })}{' '}
                — статистика оценок
            </h2>
            <br />
            {['fall', 'spring'].flatMap(season =>
                ['ordinary', 'advanced'].flatMap(level => (
                    <React.Fragment key={`${season}-${level}`}>
                        <h4>{t('common:FullLevelName', { season, level })}</h4>
                        <Row>
                            {['junior', 'senior'].map(tier => (
                                <Col key={tier} md={6}>
                                    <h6 className="mb-3">
                                        {t('common:Tier', { context: tier })}{' '}
                                        вариант (
                                        {t('common:count.papers', {
                                            count: marksStats[season][level][
                                                tier
                                            ].papersCount,
                                        })}
                                        )
                                    </h6>
                                    {marksStats[season][level][tier]
                                        .papersCount > 0 && (
                                        <TierMarksStatisticsTable
                                            statistics={
                                                marksStats[season][level][tier]
                                                    .statistics
                                            }
                                        />
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <br />
                        <br />
                    </React.Fragment>
                ))
            )}
        </Page>
    );
}

const tdStyle = { width: '64px', padding: '0.3rem 0.6rem' };

function TierMarksStatisticsTable({ statistics }) {
    const { t } = useTranslation();

    return (
        <Table size="sm" borderless={true} hover={true} className="w-auto">
            <thead>
                <th style={tdStyle} />
                {getProblemsNames(statistics, t).map(name => (
                    <th key={name} style={tdStyle}>
                        {name}
                    </th>
                ))}
            </thead>
            <tbody>
                {marks.map(mark => (
                    <tr key={mark}>
                        <th style={tdStyle}>
                            <Mark mark={mark} />
                        </th>
                        {statistics.flatMap((problem, i) =>
                            problem.map((subproblem, j) => (
                                <td key={`${i}-${j}`} style={tdStyle}>
                                    {subproblem.find(item => item.mark === mark)
                                        ?.count || 0}
                                </td>
                            ))
                        )}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
