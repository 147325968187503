"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProblemsNames = void 0;
function getProblemsNames(problemsOrMarks, t) {
    const subproblemLabels = t('common:subproblemLabels').split('');
    return problemsOrMarks.flatMap((problem, i) => (Array.isArray(problem) ? problem : problem.subproblems).map((subproblem, j, subproblems) => subproblems.length === 1
        ? `${i + 1}`
        : `${i + 1}${subproblemLabels[j]}`));
}
exports.getProblemsNames = getProblemsNames;
