import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import { provideLayoutAndData } from './Page';
import { Button, Table } from 'react-bootstrap';
import { useViewer } from './ViewerContext';
import classnames from 'classnames';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';
import tournamentProblemsCountFields from 'GraphQL/fragments/tournamentProblemsCountFields';
import {
    isPaperExaminationComplete,
    isPaperExaminationStarted,
} from '@turgor/model-utils/lib/examinationStatus';
import {
    BatchPageQuery,
    BatchPageQueryVariables,
} from 'GraphQL/types/BatchPageQuery';
import { PaperExaminationMarks } from 'Components/ExaminationMarks';

const batchPageQuery = gql`
    query BatchPageQuery($batchId: ID!) {
        batch(batchId: $batchId) {
            _id
            codeName
            season
            level
            tier
            venue {
                _id
                townName
            }
            statistics {
                count
                startedFirst
                markedFirst
                startedSecond
                markedSecond
                startedFinal
                markedFinal
            }
            firstStarted
            firstFinished
            firstJury {
                _id
                name
            }
            secondStarted
            secondFinished
            secondJury {
                _id
                name
            }
            finalStarted
            finalFinished
            finalJury {
                _id
                name
            }
            participants {
                _id
                surname
                name
                school
                grade
                townName
                papers {
                    _id
                    season
                    level
                    tier
                    stage
                    flags {
                        annulled
                        centralExamination
                        pagesProblem
                        suspicious
                    }
                    examinations {
                        type
                        stage
                        marks {
                            mark
                            problemNumber
                            subproblemNumber
                        }
                    }
                }
            }
            tournament {
                _id
                number
                ...tournamentProblemsCountFields
            }
        }
    }

    ${tournamentProblemsCountFields}
`;

const startBatchExaminationMutation = gql`
    mutation BatchPageStartBatch(
        $batchId: ID!
        $stage: PaperExaminationStage!
    ) {
        startBatchExamination(batchId: $batchId, stage: $stage) {
            __typename
            ... on StartBatchExaminationSuccess {
                batch {
                    _id
                    firstStarted
                    firstFinished
                    firstJury {
                        _id
                        name
                    }
                    secondStarted
                    secondFinished
                    secondJury {
                        _id
                        name
                    }
                    finalStarted
                    finalFinished
                    finalJury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const stopBatchExaminationMutation = gql`
    mutation BatchPageStopBatch($batchId: ID!, $stage: PaperExaminationStage!) {
        stopBatchExamination(batchId: $batchId, stage: $stage) {
            __typename
            ... on StopBatchExaminationSuccess {
                batch {
                    _id
                    firstStarted
                    firstFinished
                    firstJury {
                        _id
                        name
                    }
                    secondStarted
                    secondFinished
                    secondJury {
                        _id
                        name
                    }
                    finalStarted
                    finalFinished
                    finalJury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const cancelBatchExaminationMutation = gql`
    mutation BatchPageCancelBatch(
        $batchId: ID!
        $stage: PaperExaminationStage!
    ) {
        cancelBatchExamination(batchId: $batchId, stage: $stage) {
            __typename
            ... on CancelBatchExaminationSuccess {
                batch {
                    _id
                    firstStarted
                    firstFinished
                    firstJury {
                        _id
                        name
                    }
                    secondStarted
                    secondFinished
                    secondJury {
                        _id
                        name
                    }
                    finalStarted
                    finalFinished
                    finalJury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const resetBatchExaminationsMutation = gql`
    mutation BatchPageResetBatch($batchId: ID!) {
        resetBatchExaminations(batchId: $batchId) {
            __typename
            ... on ResetExaminationsSuccess {
                batch {
                    _id
                    firstStarted
                    firstFinished
                    firstJury {
                        _id
                        name
                    }
                    secondStarted
                    secondFinished
                    secondJury {
                        _id
                        name
                    }
                    finalStarted
                    finalFinished
                    finalJury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

export default provideLayoutAndData<BatchPageQuery, BatchPageQueryVariables>(
    batchPageQuery,
    { variablesGetter: ({ batchId }) => ({ batchId }) }
)(function BatchPage({ data, queryResult: { refetch } }) {
    const [startBatchExamination] = useMutation(startBatchExaminationMutation);
    const [resetBatchExaminations] = useMutation(
        resetBatchExaminationsMutation
    );
    const { viewer } = useViewer();

    const {
        batch: { tournament, participants, ...batch },
    } = data;

    const papers = getPapers(
        participants,
        batch.season,
        batch.level,
        batch.tier
    );

    const problems =
        tournament.seasons[batch.season.toLowerCase()][
            batch.level.toLowerCase()
        ][batch.tier.toLowerCase()].problems;

    const subproblemsCount = problems.flatMap(
        problem => problem.subproblems
    ).length;

    const markedFirst = papers.filter(paper =>
        isPaperExaminationComplete(paper.paper, tournament, 'CENTRAL', 'FIRST')
    ).length;
    const markedSecond = papers.filter(paper =>
        isPaperExaminationComplete(paper.paper, tournament, 'CENTRAL', 'SECOND')
    ).length;
    const markedFinal = papers.filter(paper =>
        isPaperExaminationComplete(paper.paper, tournament, 'CENTRAL', 'FINAL')
    ).length;

    return (
        <>
            <h2>{tournament.number}-й Турнир</h2>
            <h3>
                {batch.season === 'FALL' ? 'Осенний' : 'Весенний'}{' '}
                {batch.level === 'ORDINARY' ? 'базовый' : 'сложный'}{' '}
                {batch.tier === 'JUNIOR' ? 'младший' : 'старший'} вариант
            </h3>
            <h3>
                {batch.venue?.townName} {batch.codeName}
            </h3>
            <br />
            <Table striped={false} borderless={true}>
                <thead>
                    <tr>
                        <th className="batch-page-examinations-table-cell">
                            Первая проверка
                        </th>
                        <th className="batch-page-examinations-table-cell">
                            Вторая проверка
                        </th>
                        <th className="batch-page-examinations-table-cell">
                            Финиш
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count === markedFirst,
                                'batch-finished': Boolean(batch.firstFinished),
                            })}
                        >
                            {batch.firstJury ? (
                                <>
                                    {markedFirst} / {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.firstJury.name}
                                    </span>
                                </>
                            ) : (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'FIRST',
                                            },
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            )}
                        </td>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count === markedSecond,
                                'batch-finished': Boolean(batch.secondFinished),
                            })}
                        >
                            {batch.secondJury ? (
                                <>
                                    {markedSecond} / {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.secondJury.name}
                                    </span>
                                </>
                            ) : null}
                            {batch.firstFinished &&
                            !batch.secondJury &&
                            batch.firstJury?._id !== viewer._id &&
                            batch.statistics.count === markedFirst ? (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'SECOND',
                                            },
                                        }).then(({ data }) => {
                                            if (
                                                data?.startBatchExamination
                                                    .__typename ===
                                                'StartBatchExaminationSuccess'
                                            ) {
                                                refetch();
                                            }
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            ) : null}
                        </td>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count === markedFinal,
                                'batch-finished': Boolean(batch.finalFinished),
                            })}
                        >
                            {batch.finalJury ? (
                                <>
                                    {markedFinal} / {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.finalJury.name}
                                    </span>
                                </>
                            ) : null}
                            {batch.secondFinished &&
                            !batch.finalJury &&
                            batch.statistics.count === markedSecond ? (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'FINAL',
                                            },
                                        }).then(({ data }) => {
                                            if (
                                                data?.startBatchExamination
                                                    .__typename ===
                                                'StartBatchExaminationSuccess'
                                            ) {
                                                refetch();
                                            }
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <BatchStopButtons
                                stage="FIRST"
                                batch={batch}
                                papers={papers}
                                subproblemsCount={subproblemsCount}
                            />
                        </td>
                        <td>
                            <BatchStopButtons
                                stage="SECOND"
                                batch={batch}
                                papers={papers}
                                subproblemsCount={subproblemsCount}
                            />
                        </td>
                        <td>
                            <BatchStopButtons
                                stage="FINAL"
                                batch={batch}
                                papers={papers}
                                subproblemsCount={subproblemsCount}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
            {(batch.finalFinished && batch.statistics.count !== markedFinal) ||
            (batch.secondFinished && batch.statistics.count !== markedSecond) ||
            (batch.firstFinished && batch.statistics.count !== markedFirst) ? (
                <Button
                    size="sm"
                    variant="primary"
                    className="mb-5"
                    onClick={() =>
                        resetBatchExaminations({
                            variables: {
                                batchId: batch._id,
                            },
                        })
                    }
                >
                    Вернуть на допроверку
                </Button>
            ) : null}
            <br />
            <BatchPapers participants={papers} tournament={tournament} />
        </>
    );
});

function BatchPapers({ participants, tournament }) {
    if (!participants.length) {
        return <h5>Нет работ</h5>;
    }

    return (
        <Table striped={true} borderless={true} size="sm">
            <thead>
                <tr>
                    <th> </th>
                    <th>Имя</th>
                    <th>Класс</th>
                    <th>Первая проверка</th>
                    <th>Вторая проверка</th>
                    <th>Финиш</th>
                </tr>
            </thead>
            <tbody>
                {participants.map((participant, i) => (
                    <ParticipantPapersRow
                        participant={participant}
                        index={i}
                        key={participant._id}
                        tournament={tournament}
                    />
                ))}
            </tbody>
        </Table>
    );
}

function ParticipantPapersRow({ participant, index, tournament }) {
    let { url } = useRouteMatch();
    const { paper } = participant;

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <Link to={`${url}/papers/${paper._id}`}>
                    {participant.name} {participant.surname}
                </Link>
            </td>
            <td>{participant.grade}</td>
            <td>
                <PaperExaminationMarks
                    paper={paper}
                    type="CENTRAL"
                    stage="FIRST"
                    tournament={tournament}
                />
            </td>
            <td>
                <PaperExaminationMarks
                    paper={paper}
                    type="CENTRAL"
                    stage="SECOND"
                    tournament={tournament}
                />
            </td>
            <td
                className={classnames({
                    'examination-marks-not-completed':
                        isPaperExaminationStarted(paper, 'CENTRAL', 'FINAL') &&
                        !isPaperExaminationComplete(
                            paper,
                            tournament,
                            'CENTRAL',
                            'FINAL'
                        ),
                })}
            >
                <PaperExaminationMarks
                    paper={paper}
                    type="CENTRAL"
                    stage="FINAL"
                    tournament={tournament}
                />
            </td>
        </tr>
    );
}

function getPapers(participants, season, level, tier) {
    return participants
        .filter(p =>
            p.papers.some(
                p =>
                    p.season === season &&
                    p.level === level &&
                    p.tier === tier &&
                    ['UPLOADED', 'MARKED', 'PUBLISHED'].includes(p.stage)
            )
        )
        .sort(participantsSortFn)
        .map(participant => ({
            ...participant,
            paper: participant.papers.find(
                p =>
                    p.season === season &&
                    p.level === level &&
                    p.tier === tier &&
                    ['UPLOADED', 'MARKED', 'PUBLISHED'].includes(p.stage)
            ),
        }));
}

function BatchStopButtons({ batch, stage, papers, subproblemsCount }) {
    const { viewer } = useViewer();
    const [stopBatchExamination] = useMutation(stopBatchExaminationMutation);
    const [cancelBatchExamination] = useMutation(
        cancelBatchExaminationMutation
    );
    const prefix = stage.toLowerCase();
    const jury = batch[prefix + 'Jury'];
    const finished = batch[prefix + 'Finished'];

    const allMarked =
        papers.filter(
            paper =>
                paper.paper.examinations
                    ?.find(
                        exam => exam.type === 'CENTRAL' && exam.stage === stage
                    )
                    ?.marks.filter(m => m.mark !== '').length ===
                subproblemsCount
        ).length === papers.length;

    return (
        <>
            {jury?._id &&
                !finished &&
                (viewer.role === 'ADMIN' || jury?._id === viewer._id) &&
                allMarked && (
                    <Button
                        variant="outline-primary"
                        className="d-block"
                        onClick={() =>
                            stopBatchExamination({
                                variables: {
                                    batchId: batch._id,
                                    stage,
                                },
                            })
                        }
                    >
                        Закончить проверку
                    </Button>
                )}
            {jury?._id &&
                !allMarked &&
                !finished &&
                (viewer.role === 'ADMIN' || jury?._id === viewer._id) && (
                    <Button
                        variant="outline-danger"
                        className="d-block mt-2"
                        onClick={() =>
                            cancelBatchExamination({
                                variables: {
                                    batchId: batch._id,
                                    stage,
                                },
                            })
                        }
                    >
                        {jury?._id === viewer._id
                            ? 'Бросить пачку'
                            : 'Отобрать пачку'}
                    </Button>
                )}
        </>
    );
}
