import React, { useCallback, useEffect, useRef, useState } from 'react';
import OverlayModal from 'react-overlays/Modal';
import classnames from 'classnames';
import {
    BrightnessHigh,
    BrightnessLow,
    ChatRightText,
    ChevronRight,
    EyeSlash,
    Pencil,
    ZoomIn,
    ZoomOut,
} from 'react-bootstrap-icons';
import { Eraser, Highlighter } from '../Components/Icons';
import PageOfPaperWithComments from './PageOfPaperWithComments';
import PaperExaminationForm from './PaperExaminationForm';

import './ExaminationModal.scss';
import { Modal } from 'react-bootstrap';
import { ProblemText } from '../Components/Problem';
import { getLocalSetting, setLocalSetting } from '../localSettings';

export function ExaminationModal({
    selectedPageId,
    paper,
    pages,
    problemSet,
    onClose,
    examination,
    onMarksSaved,
}) {
    const [modalIn, setModalIn] = useState(false);
    const [selectedProblem, setSelectedProblem] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(
        getLocalSetting('examination-modal', 'sidebarOpen', true)
    );
    const [zoom, setStateZoom] = useState(
        getLocalSetting('examination-modal', 'zoom', 70)
    );
    const [filterLevel, setFilterLevel] = useState(
        getLocalSetting('examination-modal', 'filterLevel', 0)
    );
    const [tool, setTool] = useState(null);
    const [showComments, setShowComments] = useState(true);
    const paperDivRef = useRef(null);
    const selectedPageRef = useRef(null);
    const [scrolledOnOpen, setScrolledOnOpen] = useState(false);
    const problemHeadersRefs = useRef({});

    const setProblemHeaderRef = useCallback(header => {
        if (header) {
            problemHeadersRefs.current[header.dataset.number] = header;
        }
    }, []);

    const filter = `contrast(${1 + filterLevel / 10}) brightness(${
        1 + filterLevel / 75
    }) grayscale(${filterLevel * 8}%)`;

    const setZoom = newZoom => {
        const { scrollTop, offsetHeight } = paperDivRef.current;
        const position = scrollTop / offsetHeight;
        setStateZoom(newZoom);

        setTimeout(() => {
            paperDivRef.current.scrollTo({
                top: paperDivRef.current.offsetHeight * position,
                left: paperDivRef.current.scrollLeft,
            });
        }, 0);
    };

    useEffect(() => setModalIn(true), []);

    useEffect(() => {
        if (!scrolledOnOpen) {
            const timeout = setTimeout(() => {
                paperDivRef.current.focus();

                const pageIndex = pages.findIndex(
                    p => p._id === selectedPageId
                );

                if (pageIndex === 0) {
                    return;
                }

                const firstPageOfAProblem =
                    pages[pageIndex - 1].problemNumber !==
                    pages[pageIndex].problemNumber;

                paperDivRef.current.scrollTo({
                    top:
                        selectedPageRef.current.offsetTop -
                        (firstPageOfAProblem ? 64 : 16),
                    left: paperDivRef.current.scrollLeft,
                });

                setScrolledOnOpen(true);
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [scrolledOnOpen, pages, selectedPageId]);

    return (
        <OverlayModal
            show={true}
            backdrop={false}
            className={classnames('examination-modal-backdrop', {
                'examination-modal-backdrop-in': modalIn,
            })}
        >
            <div className="examination-modal-content">
                <div
                    className={classnames(
                        'examination-modal-sidebar-placeholder',
                        { 'sidebar-open': sidebarOpen }
                    )}
                >
                    <div className="examination-modal-sidebar">
                        <div className="examination-modal-sidebar-marks">
                            {examination.type === 'CENTRAL' && (
                                <span className="text-white d-block mb-3">
                                    {examination.stage === 'FIRST'
                                        ? 'Первая проверка'
                                        : null}
                                    {examination.stage === 'SECOND'
                                        ? 'Вторая проверка'
                                        : null}
                                    {examination.stage === 'FINAL'
                                        ? 'Финиш'
                                        : null}
                                </span>
                            )}
                            <PaperExaminationForm
                                paper={paper}
                                problemSet={problemSet}
                                examination={examination}
                                unfocusable={!sidebarOpen}
                                view="sidebar"
                                onGoToPageClick={problemNumber => {
                                    const header =
                                        problemHeadersRefs.current[
                                            problemNumber
                                        ];

                                    if (header) {
                                        paperDivRef.current.scrollTo({
                                            top: header.offsetTop - 16,
                                            left: paperDivRef.current
                                                .scrollLeft,
                                            behavior: 'smooth',
                                        });
                                    }
                                }}
                                onGoToProblemTextClick={problemNumber =>
                                    setSelectedProblem(problemNumber)
                                }
                                onMarksSaved={onMarksSaved}
                            />
                        </div>
                        <div className="examination-modal-sidebar-tools">
                            <button
                                className="tool"
                                title={
                                    sidebarOpen
                                        ? 'Скрыть панель'
                                        : 'Открыть панель'
                                }
                                onClick={() => {
                                    setLocalSetting(
                                        'examination-modal',
                                        'sidebarOpen',
                                        !sidebarOpen
                                    );
                                    setSidebarOpen(open => !open);
                                }}
                            >
                                <ChevronRight
                                    size={24}
                                    className={classnames(
                                        'examination-modal-sidebar-show-icon',
                                        {
                                            'examination-modal-sidebar-show-icon-open':
                                                sidebarOpen,
                                        }
                                    )}
                                />
                            </button>
                            <div className="tool-divider" />
                            <button
                                title="Нарисовать линию"
                                className={classnames('tool', {
                                    'tool-selected': tool === 'pencil',
                                })}
                                onClick={() =>
                                    setTool(tool =>
                                        tool === 'pencil' ? null : 'pencil'
                                    )
                                }
                            >
                                <Pencil size={24} />
                            </button>
                            <button
                                title="Выделить текст"
                                className={classnames('tool', {
                                    'tool-selected': tool === 'highlighter',
                                })}
                                onClick={() =>
                                    setTool(tool =>
                                        tool === 'highlighter'
                                            ? null
                                            : 'highlighter'
                                    )
                                }
                            >
                                <Highlighter size={24} />
                            </button>
                            <button
                                title="Добавить текстовый комментарий"
                                className={classnames('tool', {
                                    'tool-selected': tool === 'text',
                                })}
                                onClick={() =>
                                    setTool(tool =>
                                        tool === 'text' ? null : 'text'
                                    )
                                }
                            >
                                <ChatRightText size={24} />
                            </button>
                            <button
                                title="Стереть"
                                className={classnames('tool', {
                                    'tool-selected': tool === 'eraser',
                                })}
                                onClick={() =>
                                    setTool(tool =>
                                        tool === 'eraser' ? null : 'eraser'
                                    )
                                }
                            >
                                <Eraser size={24} />
                            </button>
                            <div className="tool-divider" />
                            <button
                                title="Скрыть комментарии"
                                className={classnames('tool', {
                                    'tool-selected': !showComments,
                                })}
                                onClick={() => setShowComments(show => !show)}
                            >
                                <EyeSlash size={24} />
                            </button>
                            <div className="tool-divider" />
                            <button
                                className="tool"
                                title="Увеличить"
                                disabled={zoom === 100}
                                onClick={() => {
                                    const newZoom = Math.min(100, zoom + 10);

                                    setLocalSetting(
                                        'examination-modal',
                                        'zoom',
                                        newZoom
                                    );
                                    setZoom(newZoom);
                                }}
                            >
                                <ZoomIn size={24} />
                            </button>
                            <button
                                className="tool"
                                title="Уменьшить"
                                disabled={zoom === 50}
                                onClick={() => {
                                    const newZoom = Math.max(50, zoom - 10);

                                    setLocalSetting(
                                        'examination-modal',
                                        'zoom',
                                        newZoom
                                    );
                                    setZoom(newZoom);
                                }}
                            >
                                <ZoomOut size={24} />
                            </button>
                            <div className="tool-divider" />
                            <button
                                className="tool"
                                title="Контраст +"
                                disabled={filterLevel === 7}
                                onClick={() => {
                                    const newFilterLevel = Math.min(
                                        7,
                                        filterLevel + 1
                                    );

                                    setLocalSetting(
                                        'examination-modal',
                                        'filterLevel',
                                        newFilterLevel
                                    );
                                    setFilterLevel(newFilterLevel);
                                }}
                            >
                                <BrightnessHigh size={24} />
                            </button>
                            <button
                                className="tool"
                                title="Контраст -"
                                disabled={filterLevel === 0}
                                onClick={() => {
                                    const newFilterLevel = Math.max(
                                        0,
                                        filterLevel - 1
                                    );

                                    setLocalSetting(
                                        'examination-modal',
                                        'filterLevel',
                                        newFilterLevel
                                    );
                                    setFilterLevel(newFilterLevel);
                                }}
                            >
                                <BrightnessLow size={24} />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="examination-modal-paper"
                    ref={paperDivRef}
                    tabIndex={0}
                >
                    {paper.type === 'ONLINE' &&
                        problemSet.problems.map((problem, problemNumber) => {
                            const problemPages = pages.filter(
                                page => page.problemNumber === problemNumber
                            );

                            if (problemPages.length === 0) {
                                return null;
                            }

                            return (
                                <div key={problemNumber}>
                                    <h2
                                        className="examination-modal-problem-header"
                                        style={{ maxWidth: `${zoom}%` }}
                                        ref={setProblemHeaderRef}
                                        data-number={problemNumber}
                                    >
                                        Задача {problemNumber + 1}
                                    </h2>
                                    {problemPages.map((page, i) => (
                                        <PageOfPaperWithComments
                                            key={i}
                                            paperId={paper._id}
                                            page={page}
                                            zoom={zoom}
                                            tool={tool}
                                            filter={filter}
                                            alt={`Задача ${
                                                problemNumber + 1
                                            }, страница ${i + 1}`}
                                            showComments={showComments}
                                            wrapperRef={
                                                page._id === selectedPageId
                                                    ? selectedPageRef
                                                    : undefined
                                            }
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    {paper.type === 'OFFLINE' && (
                        <div>
                            {pages.map((page, i) => (
                                <PageOfPaperWithComments
                                    key={i}
                                    paperId={paper._id}
                                    page={page}
                                    zoom={zoom}
                                    tool={tool}
                                    filter={filter}
                                    alt={`Страница ${i + 1}`}
                                    showComments={showComments}
                                    wrapperRef={
                                        page._id === selectedPageId
                                            ? selectedPageRef
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
                <button
                    className="examination-modal-close-button"
                    title="Закрыть"
                    onClick={() => {
                        setModalIn(false);
                        setTimeout(() => onClose(), 300);
                    }}
                >
                    <span>&times;</span>
                </button>
                {selectedProblem !== null && (
                    <Modal
                        show={true}
                        onHide={() => setSelectedProblem(null)}
                        size="lg"
                        className="examination-modal-problem-modal"
                        backdropClassName="examination-modal-problem-modal-backdrop"
                        aria-labelledby="problem-modal-title"
                        centered={true}
                    >
                        <Modal.Header closeButton={true}>
                            <Modal.Title id="problem-modal-title">
                                Задача {selectedProblem + 1}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ProblemText
                                problem={problemSet.problems[selectedProblem]}
                            />
                            <br />
                            <div className="criteria">
                                <h5>Общие критерии проверки работ</h5>
                                <p>
                                    <strong className="plus-minus">+</strong>{' '}
                                    ставится за любое правильное решение;
                                    <br />
                                    <strong className="plus-minus">
                                        +-
                                    </strong>{' '}
                                    за решение с существенным, но легко
                                    восполнимым пробелом;
                                    <br />
                                    <strong className="plus-minus">
                                        -+
                                    </strong>{' '}
                                    за неверное решение, однако с существенным
                                    продвижением;
                                    <br />
                                    <strong className="plus-minus">-</strong> за
                                    неверное решение.
                                    <br />
                                    <strong>0</strong> ставится, если задача не
                                    записана.
                                </p>
                                <p>
                                    Оценки{' '}
                                    <strong className="plus-minus">+.</strong>,{' '}
                                    <strong className="plus-minus">-.</strong>{' '}
                                    (варианты{' '}
                                    <strong className="plus-minus">+</strong> и{' '}
                                    <strong className="plus-minus">-</strong>)
                                    ставятся в случае менее существенных
                                    недостатков (продвижений), чем{' '}
                                    <strong className="plus-minus">+-</strong> и{' '}
                                    <strong className="plus-minus">-+</strong>.
                                </p>
                                <p>
                                    Оценка{' '}
                                    <strong className="plus-minus">+</strong>
                                    <strong className="plus-halved">
                                        /2
                                    </strong>{' '}
                                    ставится в отдельных случаях, когда в тексте
                                    присутствует правильная идея, недостаточно
                                    развитая, чтобы считать задачу решённой. Эта
                                    оценка также ставится и в том случае, если
                                    задача естественно распадается на две
                                    равноценные половины, из которых решена
                                    одна.
                                </p>
                                <p>
                                    Если жюри хочет обратить внимание на
                                    необычное достижение учащегося (краткость,
                                    красота, усиление результата и т.п.), это
                                    отмечается знаком{' '}
                                    <strong className="plus-minus">+</strong>
                                    <strong>!</strong>.
                                </p>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        </OverlayModal>
    );
}
