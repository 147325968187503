import Page from '../Page';
import { Nav, Spinner, Table } from 'react-bootstrap';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { participantsSortFn } from '@turgor/model-utils/lib/sort';

const organizerVenueParticipantsQuery = gql`
    query OrganizerVenueParticipants($id: ID!) {
        venue(id: $id) {
            _id
            townName
            townNameEn
            participants {
                _id
                surname
                name
                school
                grade
                townName
                email
                papers {
                    _id
                    card
                    season
                    level
                    tier
                    venue {
                        _id
                    }
                }
            }
            tournament {
                _id
                number
            }
        }
    }
`;

export default function OrganizerVenueParticipantsPage() {
    const params = useParams<{ id: string; season: string }>();
    const venueId = params.id;
    const { data, loading } = useQuery(organizerVenueParticipantsQuery, {
        variables: { id: venueId },
    });
    const season = params.season.toUpperCase();
    const {
        t,
        i18n: { language },
    } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const { venue } = data;

    const townName =
        language === 'en' && venue.townNameEn
            ? venue.townNameEn
            : venue.townName;

    const participants: Array<any> = venue.participants
        .filter(participant =>
            participant.papers.some(
                p => p.venue?._id === venueId && p.season === season
            )
        )
        .sort(participantsSortFn);

    const ordinaryCount = participants.filter(participant =>
        participant.papers.some(
            p =>
                p.venue?._id === venueId &&
                p.season === season &&
                p.level === 'ORDINARY'
        )
    ).length;

    const advancedCount = participants.filter(participant =>
        participant.papers.some(
            p =>
                p.venue?._id === venueId &&
                p.season === season &&
                p.level === 'ADVANCED'
        )
    ).length;

    return (
        <Page
            nav={
                <>
                    <Nav.Link
                        href={`/org/${venue.tournament.number}/venues/${venue._id}`}
                    >
                        {townName}
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to={`/tournaments/${venue.tournament.number}/venues/${venue._id}/papers/all`}
                    >
                        {t('OrganizerHomePage.Papers')}
                    </Nav.Link>
                </>
            }
        >
            <h2>
                {t('OrganizerHomePage.Participants')} — {venue.townName}
            </h2>
            {!participants.length &&
                t('OrganizerHomePage.NobodyHasRegisteredYet')}
            {participants.length > 0 && (
                <Table striped={true} hover={true} borderless={true} size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                {t('RegisterForTournamentPage.SurnameLabel')}
                            </th>
                            <th>{t('RegisterForTournamentPage.NameLabel')}</th>
                            <th>
                                {t('RegisterForTournamentPage.SchoolLabel')}
                            </th>
                            <th>{t('RegisterForTournamentPage.GradeLabel')}</th>
                            <th>{t('RegisterForTournamentPage.EmailLabel')}</th>
                            <th>{t('OrganizerHomePage.TitlePage')}</th>
                            <th>
                                {t('common:Level', { context: 'ORDINARY' })} (
                                {ordinaryCount})
                            </th>
                            <th>
                                {t('common:Level', { context: 'ADVANCED' })} (
                                {advancedCount})
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {participants.map((participant, i: number) => (
                            <tr key={participant._id}>
                                <td>{i + 1}</td>
                                <td>{participant.surname}</td>
                                <td>{participant.name}</td>
                                <td>{participant.school}</td>
                                <td>{participant.grade}</td>
                                <td>{participant.email}</td>
                                <td>
                                    {participant.papers.find(
                                        p =>
                                            p.venue?._id === venueId &&
                                            p.season === season
                                    )?.card && (
                                        <a
                                            href={`${
                                                process.env
                                                    .REACT_APP_BACKEND_URL
                                            }/documents?template=${
                                                language === 'ru'
                                                    ? 'titlePage'
                                                    : 'titlePageEn'
                                            }&data=${encodeURIComponent(
                                                JSON.stringify({
                                                    t: venue.tournament.number,
                                                    s: season,
                                                    name: participant.name,
                                                    surname:
                                                        participant.surname,
                                                    school: participant.school,
                                                    townName:
                                                        participant.townName,
                                                    email: participant.email,
                                                    grade: participant.grade,
                                                    cardNumber:
                                                        participant.papers.find(
                                                            p =>
                                                                p.venue?._id ===
                                                                    venueId &&
                                                                p.season ===
                                                                    season
                                                        )?.card,
                                                })
                                            )}`}
                                        >
                                            {
                                                participant.papers.find(
                                                    p =>
                                                        p.venue?._id ===
                                                            venueId &&
                                                        p.season === season
                                                )?.card
                                            }
                                        </a>
                                    )}
                                </td>
                                <td>
                                    {participant.papers.find(
                                        p =>
                                            p.venue?._id === venueId &&
                                            p.season === season &&
                                            p.level === 'ORDINARY'
                                    )
                                        ? '✓'
                                        : null}
                                </td>
                                <td>
                                    {participant.papers.find(
                                        p =>
                                            p.venue?._id === venueId &&
                                            p.season === season &&
                                            p.level === 'ADVANCED'
                                    )
                                        ? '✓'
                                        : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Page>
    );
}
