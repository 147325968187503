import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Page from '../Page';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useViewer } from '../ViewerContext';
import { JuryTournamentView } from './JuryTournamentPage';

const juryHomePageQuery = gql`
    query JuryHomePage {
        viewer {
            _id
            batches {
                _id
                season
                level
                tier
                firstJury {
                    _id
                    name
                }
                firstFinished
                secondJury {
                    _id
                    name
                }
                secondFinished
                finalJury {
                    _id
                    name
                }
                finalFinished
                codeName
                venue {
                    townName
                }
                tournament {
                    _id
                    number
                }
            }
        }
        tournaments {
            _id
            number
            stage
            seasons {
                fall {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                    criteriaInternalLink
                }
                spring {
                    stage
                    ordinary {
                        date
                        stage
                    }
                    advanced {
                        date
                        stage
                    }
                    criteriaInternalLink
                }
            }
        }
    }
`;

export default function JuryHomePage() {
    const { data, loading } = useQuery(juryHomePageQuery);
    const { t } = useTranslation();
    const { viewer } = useViewer();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        tournaments,
        viewer: { batches },
    } = data;
    const activeBatches = batches.filter(
        batch =>
            (!batch.firstFinished && batch.firstJury?._id === viewer._id) ||
            (!batch.secondFinished && batch.secondJury?._id === viewer._id) ||
            (!batch.finalFinished && batch.finalJury?._id === viewer._id)
    );

    return (
        <Page>
            {activeBatches.length > 0 && (
                <>
                    <h2>Текущие пачки</h2>
                    {activeBatches.map(batch => (
                        <div key={batch._id}>
                            <Link
                                to={`/tournaments/${batch.tournament.number}/batches/${batch._id}`}
                            >
                                {batch.venue?.townName} {batch.codeName} (
                                {batch.season === 'FALL'
                                    ? 'осенний'
                                    : 'весенний'}{' '}
                                {batch.level === 'ORDINARY'
                                    ? 'базовый'
                                    : 'сложный'}{' '}
                                {batch.tier === 'JUNIOR'
                                    ? 'младший'
                                    : 'старший'}
                                )
                            </Link>
                            <br />
                        </div>
                    ))}
                    <br />
                    <br />
                </>
            )}
            {tournaments.map(tournament => (
                <JuryTournamentView
                    tournament={tournament}
                    key={tournament._id}
                />
            ))}
            <br />
            <br />
            <Row>
                <Col md={4}>
                    <h4>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://docs.google.com/forms/d/e/1FAIpQLSfQAEQcQP748UXDY6LAuSprftfH2QdG9eTpthnXzEU240V-YA/viewform?usp=pp_url&entry.1931807854=${encodeURIComponent(
                                viewer.name || ''
                            )}&entry.1709222661=${
                                new Date().toISOString().split('T')[0]
                            }`}
                        >
                            Учёт часов
                        </a>
                    </h4>
                </Col>
                <Col md={4}>
                    <h4>Инструкции</h4>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.google.com/document/d/1sw_vqqpb7jBn3IkDd5YihJo_sthyK28MlomweBittms/edit?ts=5f952506"
                    >
                        Описание в гугл-док
                    </a>
                    <br />
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://youtu.be/-QHFSg2HcpU"
                    >
                        Видео
                    </a>
                </Col>
            </Row>
        </Page>
    );
}
