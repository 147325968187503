import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useViewer } from './ViewerContext';
import { Button, Form, Modal, Spinner, Table, Image } from 'react-bootstrap';
import PageGallery, { PageGalleryModal } from './Paper/PageGallery';
import { useForm } from 'react-hook-form';
import { SubmitButton } from 'Components/EnhancedButtons';
import {
    TournamentQuestionsQuery,
    TournamentQuestionsQueryVariables,
} from './GraphQL/types/TournamentQuestionsQuery';
import classnames from 'classnames';
import { Paperclip } from 'react-bootstrap-icons';
import { fileToBase64 } from './FileDropzone';

const tournamentQuestionsQuery = gql`
    query TournamentQuestionsQuery(
        $number: Int!
        $season: Season!
        $level: Level
        $type: QuestionType!
        $tier: Tier
        $venueId: ID
    ) {
        tournament(number: $number) {
            _id
            number
            questions(
                season: $season
                level: $level
                type: $type
                tier: $tier
                venueId: $venueId
            ) {
                _id
                question
                askedAt
                answers {
                    answer
                    answeredAt
                    file
                }
                level
                season
                tier
                problemNumber
                participant {
                    _id
                    name
                    surname
                    grade
                    townName
                }
                organizer {
                    _id
                    name
                    venues {
                        _id
                        townName
                        tournament {
                            _id
                        }
                    }
                }
                jury {
                    _id
                    name
                }
            }
        }
    }
`;

const takeQuestionMutation = gql`
    mutation TakeQuestion($questionId: ID!) {
        takeQuestion(questionId: $questionId) {
            __typename
            ... on TakeQuestionSuccess {
                question {
                    _id
                    question
                    askedAt
                    level
                    season
                    tier
                    problemNumber
                    answers {
                        answer
                        answeredAt
                        file
                    }
                    participant {
                        _id
                        name
                        surname
                        grade
                        townName
                    }
                    organizer {
                        _id
                        name
                    }
                    jury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const answerQuestionMutation = gql`
    mutation AnswerQuestion($questionId: ID!, $answer: String!) {
        answerQuestion(questionId: $questionId, answer: $answer) {
            __typename
            ... on AnswerQuestionSuccess {
                question {
                    _id
                    question
                    askedAt
                    level
                    season
                    tier
                    problemNumber
                    answers {
                        answer
                        answeredAt
                        file
                    }
                    jury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const answerQuestionImageMutation = gql`
    mutation AnswerQuestionImage(
        $questionId: ID!
        $fileData: String!
        $fileName: String!
    ) {
        answerQuestionImage(
            questionId: $questionId
            fileData: $fileData
            fileName: $fileName
        ) {
            __typename
            ... on AnswerQuestionSuccess {
                question {
                    _id
                    question
                    askedAt
                    level
                    season
                    tier
                    problemNumber
                    answers {
                        answer
                        answeredAt
                        file
                    }
                    jury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

export function QuestionsList({
    number,
    season,
    level = undefined,
    type,
    tier = undefined,
    venueId = undefined,
    paperMode,
}) {
    let { path, url } = useRouteMatch();
    url = url.replace(/\/$/, '');
    let history = useHistory();
    const { data, loading, refetch } = useQuery<
        TournamentQuestionsQuery,
        TournamentQuestionsQueryVariables
    >(tournamentQuestionsQuery, {
        variables: { number, season, level, type, tier, venueId },
        pollInterval: 10 * 1000,
    });
    const [takeQuestion] = useMutation(takeQuestionMutation);
    const [answerQuestion] = useMutation(answerQuestionMutation);
    const { t } = useTranslation();
    const { viewer } = useViewer();

    const handleTakeQuestionClick = useCallback(
        e => {
            takeQuestion({
                variables: {
                    questionId: e.currentTarget.dataset.question,
                },
            }).then(({ data }) => {
                if (data?.takeQuestion.__typename === 'TakeQuestionSuccess') {
                    const { participant, organizer, level } =
                        data.takeQuestion.question;

                    if (participant) {
                        history.push(
                            `${url}/participant/${
                                participant._id
                            }/${level.toLowerCase()}`
                        );
                    }

                    if (organizer) {
                        history.push(`${url}/organizer/${organizer._id}`);
                    }
                } else {
                    refetch();
                }
            });
        },
        [takeQuestion, history, url, refetch]
    );

    const handleNoAnswerNeededClick = useCallback(
        e => {
            answerQuestion({
                variables: {
                    questionId: e.currentTarget.dataset.question,
                    answer: '',
                },
            });
        },
        [answerQuestion]
    );

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border">
                    <span className="sr-only">{t('Loading')}</span>
                </Spinner>
            </div>
        );
    }

    const tournamentId = data.tournament._id;

    const questions = [...data.tournament.questions].sort((a, b) => {
        if (
            !a.answers.length &&
            !b.answers.length &&
            a.jury?._id === viewer._id &&
            b.jury?._id !== viewer._id
        ) {
            return -1;
        }

        if (
            !a.answers.length &&
            !b.answers.length &&
            a.jury?._id !== viewer._id &&
            b.jury?._id === viewer._id
        ) {
            return 1;
        }

        if (!a.answers.length && !b.answers.length && !a.jury && b.jury) {
            return -1;
        }

        if (!a.answers.length && !b.answers.length && a.jury && !b.jury) {
            return 1;
        }

        if (!a.answers.length && b.answers.length) {
            return -1;
        }

        if (a.answers.length && !b.answers.length) {
            return 1;
        }

        return new Date(b.askedAt).getTime() - new Date(a.askedAt).getTime();
    });

    return (
        <>
            {questions.length === 0 && (
                <h4>Пока никто не задал ни одного вопроса</h4>
            )}
            <Table striped={true} hover={true} borderless={true} size="sm">
                <thead>
                    <tr>
                        <th>Участник</th>
                        <th>Класс</th>
                        <th>Вариант</th>
                        <th>Задача</th>
                        <th>Вопрос</th>
                        <th>Ответ</th>
                    </tr>
                </thead>
                <tbody>
                    {questions.length > 0 &&
                        questions.map(question => (
                            <tr key={question._id}>
                                <td>
                                    {question.participant && (
                                        <Link
                                            to={`${url}/participant/${
                                                question.participant._id
                                            }/${question.level.toLowerCase()}`}
                                        >
                                            {question.participant.name}{' '}
                                            {question.participant.surname}
                                        </Link>
                                    )}
                                    {question.organizer && (
                                        <Link
                                            to={`${url}/organizer/${question.organizer._id}`}
                                        >
                                            {question.organizer.name
                                                ? `${question.organizer.name}, организатор`
                                                : 'Организатор'}{' '}
                                            (
                                            {
                                                question.organizer.venues.find(
                                                    venue =>
                                                        venue.tournament._id ===
                                                        tournamentId
                                                )?.townName
                                            }
                                            )
                                        </Link>
                                    )}
                                </td>
                                <td>{question.participant?.grade || ''}</td>
                                <td>
                                    {question.tier === 'JUNIOR' && 'младший'}
                                    {question.tier === 'SENIOR' && 'старший'}
                                </td>
                                <td>
                                    {Number.isInteger(question.problemNumber) &&
                                        question.problemNumber + 1}
                                </td>
                                <td className="tournament-questions-table-text">
                                    {question.question}
                                    <p className="text-right text-muted mb-0 mt-2 pr-2 small">
                                        <DateString date={question.askedAt} />
                                    </p>
                                </td>
                                <td className="tournament-questions-table-text">
                                    {question.answers.length ? (
                                        question.answers.filter(
                                            answer =>
                                                answer.file || answer.answer
                                        ).length ? (
                                            question.answers
                                                .filter(
                                                    answer =>
                                                        answer.file ||
                                                        answer.answer
                                                )
                                                .map((answer, i) => (
                                                    <>
                                                        {answer.answer || null}
                                                        {answer.file && (
                                                            <em>
                                                                Прикреплён файл
                                                            </em>
                                                        )}
                                                        {i !==
                                                            question.answers.filter(
                                                                answer =>
                                                                    answer.file ||
                                                                    answer.answer
                                                            ).length -
                                                                1 && <hr />}
                                                    </>
                                                ))
                                        ) : (
                                            <em>Не требует ответа</em>
                                        )
                                    ) : question.jury ? (
                                        <strong>
                                            Отвечает {question.jury.name}
                                        </strong>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outline-primary"
                                                onClick={
                                                    handleTakeQuestionClick
                                                }
                                                data-question={question._id}
                                                size="sm"
                                            >
                                                Взять вопрос
                                            </Button>
                                            <Button
                                                className="ml-2"
                                                variant="outline-secondary"
                                                onClick={
                                                    handleNoAnswerNeededClick
                                                }
                                                data-question={question._id}
                                                size="sm"
                                            >
                                                Не требует ответа
                                            </Button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Route
                path={`${path}/participant/:participantId/:level?`}
                render={({ match: { params } }) => (
                    <ParticipantQuestionsModal
                        tournamentNumber={number}
                        participantId={params.participantId}
                        season={season}
                        level={params.level?.toUpperCase() || level}
                        type={type}
                        paperMode={paperMode}
                        onHide={() => {
                            history.replace(url);
                        }}
                    />
                )}
            />
            <Route
                path={`${path}/organizer/:organizerId`}
                render={({ match: { params } }) => (
                    <OrganizerQuestionsModal
                        tournamentId={tournamentId}
                        organizerId={params.organizerId}
                        season={season}
                        level={level}
                        type={type}
                        onHide={() => {
                            history.replace(url);
                        }}
                    />
                )}
            />
        </>
    );
}

const participantQuestionsQuery = gql`
    query ParticipantQuestions(
        $participantId: ID!
        $season: Season!
        $level: Level!
        $type: QuestionType!
    ) {
        participant(participantId: $participantId) {
            _id
            name
            surname
            grade
            townName
            questions(season: $season, level: $level, type: $type) {
                _id
                question
                askedAt
                level
                season
                tier
                problemNumber
                answers {
                    answer
                    answeredAt
                    file
                }
                jury {
                    _id
                    name
                }
            }
            papers {
                _id
                season
                level
                tier
                pages {
                    _id
                    file
                    fileName
                    problemNumber
                }
            }
        }
    }
`;

function ParticipantQuestionsModal({
    tournamentNumber,
    participantId,
    season,
    level,
    type,
    onHide,
    paperMode,
}) {
    const { data, loading, startPolling, stopPolling } = useQuery(
        participantQuestionsQuery,
        {
            variables: {
                participantId,
                season,
                level,
                type,
            },
            fetchPolicy: 'network-only',
        }
    );
    const { t } = useTranslation();

    useEffect(() => {
        startPolling(10 * 1000);

        return () => {
            stopPolling();
        };
    }, [startPolling, stopPolling]);

    const { questions = [] } = data?.participant || {};

    let paper = data?.participant.papers.find(
        p => p.season === season && p.level === level
    );

    const pages = questions
        .flatMap(question => question.answers.map(answer => answer.file))
        .filter(file => Boolean(file))
        .map((file, index) => ({
            file,
            _id: `page_${index}`,
        }));

    const [clickedPageIndex, setClickedPageIndex] = useState<number | null>(
        null
    );

    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                size="lg"
                aria-labelledby="participant-questions-modal"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="participant-questions-modal">
                        {data?.participant ? (
                            <>
                                {data.participant.name}{' '}
                                {data.participant.surname},{' '}
                                {data.participant.grade}-й класс
                            </>
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {questions.map(question => (
                                <div key={question._id}>
                                    <div className="online-round-question-bubble online-round-question-bubble-them">
                                        {Number.isInteger(
                                            question.problemNumber
                                        ) && (
                                            <h6>
                                                Задача{' '}
                                                {question.problemNumber + 1}
                                            </h6>
                                        )}
                                        {question.question}
                                        <p className="text-right text-muted mb-0 mt-2 pr-2 small">
                                            <DateString
                                                date={question.askedAt}
                                            />
                                        </p>
                                    </div>
                                    {question.answers.map(answer => (
                                        <JuryAnswerView
                                            answer={answer}
                                            hasOtherAnswers={
                                                question.answers.length > 1
                                            }
                                            key={answer._id}
                                            onImageClick={() => {
                                                setClickedPageIndex(
                                                    pages.findIndex(
                                                        page =>
                                                            page.file ===
                                                            answer.file
                                                    )
                                                );
                                            }}
                                        />
                                    ))}
                                    <div className="online-round-question-bubble online-round-question-bubble-me">
                                        <QuestionAnswerForm
                                            question={question}
                                            allowCompact={Boolean(
                                                question.answers.length
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                            {paper && paperMode === 'PAGES' ? (
                                <ParticipantQuestionsModalPaperView
                                    paper={paper}
                                />
                            ) : null}
                            {paper && paperMode === 'LINK' ? (
                                <Link
                                    to={`/tournaments/${tournamentNumber}/papers/${paper._id}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Посмотреть работу
                                </Link>
                            ) : null}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {clickedPageIndex !== null && (
                <PageGalleryModal
                    initialPage={clickedPageIndex}
                    pages={pages}
                    onClose={() => setClickedPageIndex(null)}
                />
            )}
        </>
    );
}

function JuryAnswerView({ answer, hasOtherAnswers, onImageClick }) {
    if (answer.answer) {
        return (
            <div className="online-round-question-bubble online-round-question-bubble-me">
                {answer.answer}
                <p className="text-right text-muted mb-0 mt-2 pr-2 small">
                    <DateString date={answer.answeredAt} />
                </p>
            </div>
        );
    }

    if (answer.file) {
        return (
            <div className="online-round-question-bubble online-round-question-bubble-me">
                <Image
                    src={`${process.env.REACT_APP_BACKEND_URL}${answer.file}`}
                    thumbnail={true}
                    width={200}
                    onClick={onImageClick}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        );
    }

    if (!hasOtherAnswers) {
        return (
            <div className="online-round-question-bubble online-round-question-bubble-me">
                <em>Не требует ответа</em>
            </div>
        );
    }

    return null;
}

const organizerQuestionsQuery = gql`
    query OrganizerQuestions(
        $organizerId: ID!
        $season: Season!
        $level: Level!
        $tournamentId: ID!
        $type: QuestionType!
    ) {
        user(userId: $organizerId) {
            _id
            name
            venues {
                _id
                townName
                tournament {
                    _id
                }
            }
            questions(
                season: $season
                level: $level
                type: $type
                tournamentId: $tournamentId
            ) {
                _id
                question
                askedAt
                level
                season
                tier
                problemNumber
                answers {
                    answer
                    answeredAt
                    file
                }
                jury {
                    _id
                    name
                }
            }
        }
    }
`;

function OrganizerQuestionsModal({
    tournamentId,
    organizerId,
    season,
    level,
    type,
    onHide,
}) {
    const { data, loading, startPolling, stopPolling } = useQuery(
        organizerQuestionsQuery,
        {
            variables: {
                organizerId,
                tournamentId,
                season,
                level,
                type,
            },
            fetchPolicy: 'network-only',
        }
    );
    const { t } = useTranslation();

    useEffect(() => {
        startPolling(10 * 1000);

        return () => {
            stopPolling();
        };
    }, [startPolling, stopPolling]);

    const { questions = [] } = data?.user || {};

    const venue =
        data?.user.venues.find(
            venue => venue.tournament._id === tournamentId
        ) || null;

    const pages = questions
        .flatMap(question => question.answers.map(answer => answer.file))
        .filter(file => Boolean(file))
        .map((file, index) => ({
            file,
            _id: `page_${index}`,
        }));

    const [clickedPageIndex, setClickedPageIndex] = useState<number | null>(
        null
    );

    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                size="lg"
                aria-labelledby="organizer-questions-modal"
                centered={true}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="organizer-questions-modal">
                        {data?.user ? (
                            <>
                                {data.user.name
                                    ? `${data.user.name}, организатор`
                                    : 'Организатор'}
                                {venue && ` (${venue.townName})`}
                            </>
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border">
                                <span className="sr-only">{t('Loading')}</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            {questions.map(question => (
                                <div key={question._id}>
                                    <div className="online-round-question-bubble online-round-question-bubble-them">
                                        {Number.isInteger(
                                            question.problemNumber
                                        ) || question.tier ? (
                                            <h6>
                                                {question.tier &&
                                                    t('common:Tier', {
                                                        context: question.tier,
                                                    }) + ' вариант'}
                                                {Number.isInteger(
                                                    question.problemNumber
                                                ) &&
                                                    (question.tier
                                                        ? `, задача ${
                                                              question.problemNumber +
                                                              1
                                                          }`
                                                        : `Задача ${
                                                              question.problemNumber +
                                                              1
                                                          }`)}
                                            </h6>
                                        ) : (
                                            ''
                                        )}
                                        {question.question}
                                        <p className="text-right text-muted mb-0 mt-2 pr-2 small">
                                            <DateString
                                                date={question.askedAt}
                                            />
                                        </p>
                                    </div>
                                    {question.answers.map(answer => (
                                        <JuryAnswerView
                                            answer={answer}
                                            hasOtherAnswers={
                                                question.answers.length > 1
                                            }
                                            key={answer._id}
                                            onImageClick={() => {
                                                setClickedPageIndex(
                                                    pages.findIndex(
                                                        page =>
                                                            page.file ===
                                                            answer.file
                                                    )
                                                );
                                            }}
                                        />
                                    ))}
                                    <div className="online-round-question-bubble online-round-question-bubble-me">
                                        <QuestionAnswerForm
                                            question={question}
                                            allowCompact={Boolean(
                                                question.answers.length
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {clickedPageIndex !== null && (
                <PageGalleryModal
                    initialPage={clickedPageIndex}
                    pages={pages}
                    onClose={() => setClickedPageIndex(null)}
                />
            )}
        </>
    );
}

function QuestionAnswerForm({ question, allowCompact = false }) {
    const [answerQuestion, { loading, data }] = useMutation(
        answerQuestionMutation
    );
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const compact = allowCompact && !watch('answer');

    return (
        <Form
            noValidate={true}
            className="position-relative"
            onSubmit={handleSubmit(async ({ answer }) => {
                const { data } = await answerQuestion({
                    variables: {
                        questionId: question._id,
                        answer,
                    },
                });

                if (
                    data?.answerQuestion?.__typename === 'AnswerQuestionSuccess'
                ) {
                    reset();
                }
            })}
        >
            <Form.Group
                controlId="ask-question-text"
                className={classnames({
                    'pr-6': compact,
                })}
            >
                <Form.Control
                    as="textarea"
                    {...register('answer', { required: true })}
                    rows={compact ? 1 : 5}
                    defaultValue=""
                    placeholder="Ваш ответ"
                />
                {errors.answer && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        Введите ваш ответ
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <SubmitButton
                loading={loading}
                mutationData={data}
                className={classnames('mt-2 mb-2', { 'd-none': compact })}
            >
                Ответить
            </SubmitButton>
            <QuestionImageAnswerForm question={question} compact={compact} />
        </Form>
    );
}

function QuestionImageAnswerForm({ question, compact }) {
    const [answerQuestionImage, { loading, data }] = useMutation(
        answerQuestionImageMutation
    );
    const fileInput = useRef<HTMLInputElement>(null);

    return (
        <>
            <input
                type="file"
                className="d-none"
                accept="image/jpeg, image/png, image/webp, image/jp2, image/jpx"
                ref={fileInput}
                onChange={async () => {
                    if (!fileInput.current?.files?.[0]) {
                        return;
                    }

                    const file = fileInput.current.files[0];
                    const fileData = await fileToBase64(file);

                    await answerQuestionImage({
                        variables: {
                            questionId: question._id,
                            fileData,
                            fileName: file.name,
                        },
                    });

                    fileInput.current.value = null;
                }}
                multiple={false}
            />
            <SubmitButton
                type="button"
                variant="outline-primary"
                loading={loading}
                mutationData={data}
                replaceContent={true}
                className={classnames({
                    'mt-2 ml-2 mb-2': !compact,
                    'float-right': !compact,
                    'question-answer-attach-button-compact': compact,
                })}
                onClick={() => {
                    fileInput.current?.click();
                }}
            >
                <Paperclip className="question-answer-attach-icon" />
            </SubmitButton>
        </>
    );
}

function ParticipantQuestionsModalPaperView({ paper }) {
    if (!paper?.pages?.length) {
        return null;
    }

    return (
        <>
            <br />
            <br />
            <h4>Работа участника</h4>
            {paper.pages &&
                Array.from(new Set(paper.pages.map(page => page.problemNumber)))
                    .sort((a: number, b: number) => a - b)
                    .map((problemNumber: number) => (
                        <div key={problemNumber}>
                            <h6>Задача {problemNumber + 1}</h6>
                            <PageGallery
                                pages={paper.pages.filter(
                                    page => page.problemNumber === problemNumber
                                )}
                            />
                            <br />
                        </div>
                    ))}
        </>
    );
}

function DateString({ date }: { date: string | Date }) {
    const { t } = useTranslation();
    const d = new Date(date);
    const now = new Date();

    if (
        d.getFullYear() === now.getFullYear() &&
        d.getMonth() === now.getMonth() &&
        d.getDate() === now.getDate()
    ) {
        return <>{t('timestamp', { date: d })}</>;
    }

    return (
        <>
            {t('dateWithMonth', { date: d })}
            {d.getFullYear() === now.getFullYear() ? '' : ' ' + d.getFullYear()}
            , {t('timestamp', { date: d })}
        </>
    );
}
