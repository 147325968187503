import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useViewer } from './ViewerContext';
import SignInPage from 'Pages/SignInPage';
import SignUpPage from 'Pages/SignUpPage';
import ParticipantHomePage from './Participant/ParticipantHomePage';
import ParticipantPaperPage from './Participant/ParticipantPaperPage';
import ParticipantPastTournamentsPage from './Participant/ParticipantPastTournamentsPage';
import PrivacyPolicyPage from 'Pages/PrivacyPolicyPage';
import FaqPage from 'Pages/FaqPage';
import VerifyEmailPage from 'Pages/VerifyEmailPage';
import ResetPasswordPage from 'Pages/ResetPasswordPage';
import AdminTournamentPage from './Admin/AdminTournamentPage';
import JuryTournamentPage from './Jury/JuryTournamentPage';
import JuryHomePage from './Jury/JuryHomePage';
import AdminUsersPage from './Admin/AdminUsersPage';
import OrganizerVenueParticipantsPage from './Organizer/OrganizerVenueParticipantsPage';
import VenuePapersPage from './VenuePapersPage';
import PaperPage from './Paper/PaperPage';
import OrganizerQuestionsPage from './Organizer/OrganizerQuestionsPage';
import InputterHomePage from './Inputter/InputterHomePage';
import InputterTournamentPage from './Inputter/InputterTournamentPage';
import OnlineRoundPage from './Participant/OnlineRoundPage';
import AwardInfoPage from './Pages/AwardInfoPage';
import OrganizerCreateApplicationPage from './Organizer/OrganizerCreateApplicationPage';
import OrganizerVenueApplicationPage from './Organizer/OrganizerVenueApplicationPage';
import { getLocalSetting } from './localSettings';

const commonPages = [
    <Route
        path="/privacy-policy"
        key="privacy-policy"
        component={PrivacyPolicyPage}
    />,
    <Route path="/faq" key="faq" component={FaqPage} />,
    <Route
        path="/verify-email"
        key="verify-email"
        component={VerifyEmailPage}
    />,
    <Route
        path="/reset-password"
        key="reset-password"
        component={ResetPasswordPage}
    />,
    <Route path="/award/:code" key="award" component={AwardInfoPage} />,
];

function App() {
    const { viewer, loading } = useViewer();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!loading && pathname === '/' && viewer?.role === 'ORGANIZER') {
            window.location.href = window.location.origin + '/org';
        }

        if (!loading && pathname === '/' && viewer?.role === 'ADMIN') {
            window.location.href = window.location.origin + '/admin';
        }

        if (!loading && pathname === '/' && viewer?.role === 'PARTICIPANT') {
            window.location.href = window.location.origin;
        }
    }, [loading, viewer?.role, pathname]);

    if (loading) {
        return null;
    }

    if (!viewer) {
        let hasBeenSignedIn = false;
        let isOrganizer = getLocalSetting('sign-up', 'isOrganizer', false);

        try {
            if (localStorage.getItem('hasBeenSignedIn')) {
                hasBeenSignedIn = true;
            }
        } catch {}

        return (
            <Switch>
                {commonPages}
                <Route path="/sign-in" component={SignInPage} />
                <Route path="/sign-up/:role?" component={SignUpPage} />
                <Redirect
                    to={
                        hasBeenSignedIn
                            ? '/sign-in'
                            : `/sign-up${isOrganizer ? '/organizer' : ''}`
                    }
                />
            </Switch>
        );
    }

    if (viewer.role === 'PARTICIPANT') {
        return (
            <Switch>
                {commonPages}
                <Route
                    path="/tournaments/:number/papers/:paperId"
                    component={ParticipantPaperPage}
                />
                <Route path="/online/:paperId" component={OnlineRoundPage} />
                <Route exact={true} path="/" component={ParticipantHomePage} />
                <Route
                    exact={true}
                    path="/past-tournaments"
                    component={ParticipantPastTournamentsPage}
                />
                <Redirect to="/" />
            </Switch>
        );
    }

    if (viewer.role === 'ADMIN') {
        return (
            <Switch>
                {commonPages}
                <Route
                    path="/tournaments/:number"
                    component={AdminTournamentPage}
                />
                <Route path="/users/:role" component={AdminUsersPage} />
                <Redirect to="/" />
            </Switch>
        );
    }

    if (viewer.role === 'JURY') {
        return (
            <Switch>
                {commonPages}
                <Route
                    path="/tournaments/:number"
                    component={JuryTournamentPage}
                />
                <Route exact={true} path="/jury" component={JuryHomePage} />
                <Redirect to="/jury" />
            </Switch>
        );
    }

    if (viewer.role === 'INPUTTER') {
        return (
            <Switch>
                {commonPages}
                <Route
                    path="/tournaments/:number"
                    component={InputterTournamentPage}
                />
                <Route
                    exact={true}
                    path="/input"
                    component={InputterHomePage}
                />
                <Redirect to="/input" />
            </Switch>
        );
    }

    if (viewer.role === 'ORGANIZER') {
        return (
            <Switch>
                {commonPages}
                <Route
                    path="/tournaments/:number/venues/:id/participants/:season"
                    component={OrganizerVenueParticipantsPage}
                />
                <Route
                    path={`/tournaments/:number/venues/:venueId/papers/:round/:paperId`}
                    component={PaperPage}
                />
                <Route
                    path="/tournaments/:number/venues/:venueId/papers/:round"
                    component={VenuePapersPage}
                />
                <Route
                    path="/tournaments/:number/venue-applications/:venueApplicationId"
                    component={OrganizerVenueApplicationPage}
                    exact={true}
                />
                <Route
                    path="/tournaments/:number/new-venue-application"
                    component={OrganizerCreateApplicationPage}
                    exact={true}
                />
                <Route
                    path="/tournaments/:number/new-application/venue/:venueId"
                    component={OrganizerCreateApplicationPage}
                    exact={true}
                />
                <Route
                    path="/tournaments/:number/questions/:round"
                    component={OrganizerQuestionsPage}
                />
                <Redirect to="/" />
            </Switch>
        );
    }

    return null;
}

export default App;
