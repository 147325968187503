import { gql } from '@apollo/client';
import { provideLayoutAndData } from '../Page';
import React from 'react';
import { AdminDeduplicateVenueParticipantsPageQuery } from 'GraphQL/types/AdminDeduplicateVenueParticipantsPageQuery';
import DeduplicateParticipants from '../DeduplicateParticipants';

const adminDeduplicateVenueParticipantsPageQuery = gql`
    query AdminDeduplicateVenueParticipantsPageQuery($venueId: ID!) {
        venue(id: $venueId) {
            _id
            tournament {
                _id
                number
            }
            duplicatedParticipants {
                _id
                email
                phone
                name
                surname
                townName
                school
                grade
                user {
                    _id
                    email
                }
                papers(filter: { submittedOnly: true }) {
                    _id
                    type
                    season
                    level
                    tier
                    stage
                    venue {
                        _id
                        townName
                    }
                }
            }
        }
    }
`;

export default provideLayoutAndData<AdminDeduplicateVenueParticipantsPageQuery>(
    adminDeduplicateVenueParticipantsPageQuery,
    {
        variablesGetter: ({ venueId }) => ({ venueId }),
    }
)(function AdminDeduplicateVenueParticipantsPage({ data }) {
    const {
        venue: { duplicatedParticipants, tournament },
    } = data;

    return (
        <DeduplicateParticipants
            duplicatedParticipants={duplicatedParticipants}
            tournament={tournament}
            refetchQueryName="AdminDeduplicateVenueParticipantsPageQuery"
        />
    );
});
