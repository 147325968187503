import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../Page';
import { Button, Spinner } from 'react-bootstrap';
import { PaperResults } from '../ParticipantsResults';
import { PagesRow, ProblemPagesRow } from '../Paper/ExaminationPaperView';
import { getLocalSetting, setLocalSetting } from '../localSettings';
import OverlayModal from 'react-overlays/Modal';
import classnames from 'classnames';
import {
    BrightnessHigh,
    BrightnessLow,
    EyeSlash,
    ZoomIn,
    ZoomOut,
} from 'react-bootstrap-icons';
import PageOfPaperWithComments from '../Paper/PageOfPaperWithComments';
import ParticipantQuestionModal from './ParticipantQuestionModal';
import { createSortFn } from '@turgor/model-utils/lib/sort';
import { ParticipantPaperQuery } from '../GraphQL/types/ParticipantPaperQuery';

const participantPaperQuery = gql`
    query ParticipantPaperQuery {
        viewer {
            _id
            email
            participants {
                _id
                name
                surname
                email
                school
                townName
                grade
                phone
                tournament {
                    _id
                    number
                    stage
                    seasons {
                        fall {
                            ...participantPaperPageSeasonData
                        }
                        spring {
                            ...participantPaperPageSeasonData
                        }
                    }
                }
                papers {
                    _id
                    stage
                    season
                    level
                    tier
                    type
                    card
                    sum
                    result
                    published
                    appealDeadline
                    marks {
                        mark
                        points
                    }
                    file
                    fileName
                    pages {
                        _id
                        comments {
                            path
                            style
                            text
                        }
                        file
                        fileName
                        problemNumber
                        rotation
                    }
                }
                questions(type: APPEAL) {
                    _id
                    season
                    level
                }
            }
        }
    }

    fragment participantPaperPageProblemSetData on ProblemSet {
        problems {
            text
        }
    }

    fragment participantPaperPageRoundData on TournamentRound {
        junior {
            ...participantPaperPageProblemSetData
        }
        senior {
            ...participantPaperPageProblemSetData
        }
    }

    fragment participantPaperPageSeasonData on TournamentSeason {
        ordinary {
            ...participantPaperPageRoundData
        }
        advanced {
            ...participantPaperPageRoundData
        }
    }
`;

export default function ParticipantPaperPage() {
    let { paperId, number } = useParams<{
        paperId: string;
        number: string;
    }>();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [questionModalOpen, setQuestionModalOpen] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(null);

    const { data, loading } = useQuery<ParticipantPaperQuery>(
        participantPaperQuery
    );
    const { t } = useTranslation();

    if (loading) {
        return (
            <Page>
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const {
        viewer: { participants },
    } = data;

    const participant = participants.find(
        p => p.tournament.number === Number(number)
    );
    const paper = participant.papers.find(p => p._id === paperId);
    const pages = (paper.pages ? [...paper.pages] : [])
        .map((page, index) => ({
            page,
            index,
            problemNumber: page.problemNumber,
        }))
        .sort(createSortFn('problemNumber', 'index'))
        .map(({ page }) => page);
    const problemNumbers = Array.from(
        new Set(pages.map(page => page.problemNumber))
    ).sort((a, b) => a - b);

    const tournament = participant.tournament;

    const problemSet =
        tournament.seasons[paper.season.toLowerCase()][
            paper.level.toLowerCase()
        ][paper.tier.toLowerCase()];
    const totalProblemsNumber = problemSet.problems.length;

    const appealDeadline = new Date(paper.appealDeadline);

    const appealDeadlineHasNotPassed = Date.now() < appealDeadline.getTime();

    const hasAppealed = participant.questions.some(
        question =>
            question.season === paper.season && question.level === paper.level
    );

    return (
        <Page>
            <h2>
                {t('SeasonTitle', { season: paper.season })}.{' '}
                {t('LevelTitle', { level: paper.level })}
            </h2>
            <PaperResults paper={paper} tournamentNumber={tournament.number} />
            <br />
            {paper.type === 'ONLINE' &&
                problemNumbers.map(problemNumber => (
                    <ProblemPagesRow
                        key={problemNumber}
                        pages={pages}
                        paperId={paper._id}
                        problemNumber={problemNumber}
                        problemsCount={0}
                        onPageClick={pageId => {
                            setSelectedPageId(pageId);
                            setModalOpen(true);
                        }}
                    />
                ))}
            {paper.type === 'OFFLINE' && pages.length > 0 && (
                <PagesRow
                    pages={pages}
                    paperId={paper._id}
                    onPageClick={pageId => {
                        setSelectedPageId(pageId);
                        setModalOpen(true);
                    }}
                    fileName={paper.fileName}
                    file={paper.file}
                />
            )}
            {paper.type === 'OFFLINE' && !pages.length && (
                <>
                    <em>{t('ParticipantHomePage.PaperWasNotScanned')}</em>
                    <br />
                    <br />
                </>
            )}
            {modalOpen && (
                <PaperModal
                    paper={paper}
                    pages={pages}
                    selectedPageId={selectedPageId}
                    onClose={() => setModalOpen(false)}
                    problemNumbers={problemNumbers}
                />
            )}
            <ParticipantQuestionModal
                modalTitle={t('ParticipantHomePage.Appeal')}
                open={questionModalOpen}
                onHide={() => setQuestionModalOpen(false)}
                participantId={participant._id}
                season={paper.season}
                level={paper.level}
                tier={paper.tier}
                type="APPEAL"
                problemsCount={totalProblemsNumber}
            />
            <p>
                {appealDeadlineHasNotPassed ? (
                    <Trans
                        i18nKey="ParticipationInfo.AppealDeadline"
                        values={{
                            publishDate: new Date(paper.published),
                            appealDeadline,
                        }}
                    />
                ) : (
                    <Trans
                        i18nKey="ParticipationInfo.AppealDeadlinePassed"
                        values={{
                            publishDate: new Date(paper.published),
                            appealDeadline,
                        }}
                    />
                )}
            </p>
            {(appealDeadlineHasNotPassed || hasAppealed) && (
                <Button
                    variant="outline-primary"
                    className="mb-4"
                    onClick={() => setQuestionModalOpen(true)}
                >
                    {appealDeadlineHasNotPassed
                        ? t('ParticipantHomePage.SubmitAppeal')
                        : t('ParticipantHomePage.ViewAppeal')}
                </Button>
            )}
        </Page>
    );
}

function PaperModal({ selectedPageId, paper, pages, onClose, problemNumbers }) {
    const { t } = useTranslation();
    const [modalIn, setModalIn] = useState(false);
    const [zoom, setStateZoom] = useState(
        getLocalSetting('examination-modal', 'zoom', 70)
    );
    const [filterLevel, setFilterLevel] = useState(
        getLocalSetting('examination-modal', 'filterLevel', 0)
    );
    const [showComments, setShowComments] = useState(true);
    const paperDivRef = useRef(null);
    const selectedPageRef = useRef(null);
    const [scrolledOnOpen, setScrolledOnOpen] = useState(false);
    const problemHeadersRefs = useRef({});

    const setProblemHeaderRef = useCallback(header => {
        if (header) {
            problemHeadersRefs.current[header.dataset.number] = header;
        }
    }, []);

    const filter = `contrast(${1 + filterLevel / 10}) brightness(${
        1 + filterLevel / 50
    }) grayscale(${filterLevel * 10}%)`;

    const setZoom = newZoom => {
        const { scrollTop, offsetHeight } = paperDivRef.current;
        const position = scrollTop / offsetHeight;
        setStateZoom(newZoom);

        setTimeout(() => {
            paperDivRef.current.scrollTo({
                top: paperDivRef.current.offsetHeight * position,
                left: paperDivRef.current.scrollLeft,
            });
        }, 0);
    };

    useEffect(() => setModalIn(true), []);

    useEffect(() => {
        if (!scrolledOnOpen) {
            const timeout = setTimeout(() => {
                paperDivRef.current.focus();

                const pageIndex = pages.findIndex(
                    p => p._id === selectedPageId
                );

                if (pageIndex === 0) {
                    return;
                }

                const firstPageOfAProblem =
                    pages[pageIndex - 1].problemNumber !==
                    pages[pageIndex].problemNumber;

                paperDivRef.current.scrollTo({
                    top:
                        selectedPageRef.current.offsetTop -
                        (firstPageOfAProblem ? 64 : 16),
                    left: paperDivRef.current.scrollLeft,
                });

                setScrolledOnOpen(true);
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [scrolledOnOpen, pages, selectedPageId]);

    return (
        <OverlayModal
            show={true}
            backdrop={false}
            className={classnames('examination-modal-backdrop', {
                'examination-modal-backdrop-in': modalIn,
            })}
        >
            <div className="examination-modal-content">
                <div
                    className={classnames(
                        'examination-modal-sidebar-placeholder'
                    )}
                >
                    <div className="examination-modal-sidebar">
                        <div className="examination-modal-sidebar-tools">
                            <button
                                title={t('PaperModal.HideComments')}
                                className={classnames('tool', {
                                    'tool-selected': !showComments,
                                })}
                                onClick={() => setShowComments(show => !show)}
                            >
                                <EyeSlash size={24} />
                            </button>
                            <div className="tool-divider" />
                            <button
                                className="tool"
                                title={t('PaperModal.ZoomIn')}
                                disabled={zoom === 90}
                                onClick={() => {
                                    const newZoom = Math.min(90, zoom + 10);

                                    setLocalSetting(
                                        'examination-modal',
                                        'zoom',
                                        newZoom
                                    );
                                    setZoom(newZoom);
                                }}
                            >
                                <ZoomIn size={24} />
                            </button>
                            <button
                                className="tool"
                                title={t('PaperModal.ZoomOut')}
                                disabled={zoom === 50}
                                onClick={() => {
                                    const newZoom = Math.max(50, zoom - 10);

                                    setLocalSetting(
                                        'examination-modal',
                                        'zoom',
                                        newZoom
                                    );
                                    setZoom(newZoom);
                                }}
                            >
                                <ZoomOut size={24} />
                            </button>
                            <div className="tool-divider" />
                            <button
                                className="tool"
                                title={t('PaperModal.ContrastPlus')}
                                disabled={filterLevel === 5}
                                onClick={() => {
                                    const newFilterLevel = Math.min(
                                        5,
                                        filterLevel + 1
                                    );

                                    setLocalSetting(
                                        'examination-modal',
                                        'filterLevel',
                                        newFilterLevel
                                    );
                                    setFilterLevel(newFilterLevel);
                                }}
                            >
                                <BrightnessHigh size={24} />
                            </button>
                            <button
                                className="tool"
                                title={t('PaperModal.ContrastMinus')}
                                disabled={filterLevel === 0}
                                onClick={() => {
                                    const newFilterLevel = Math.max(
                                        0,
                                        filterLevel - 1
                                    );

                                    setLocalSetting(
                                        'examination-modal',
                                        'filterLevel',
                                        newFilterLevel
                                    );
                                    setFilterLevel(newFilterLevel);
                                }}
                            >
                                <BrightnessLow size={24} />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="examination-modal-paper"
                    ref={paperDivRef}
                    tabIndex={0}
                >
                    {paper.type === 'ONLINE' &&
                        problemNumbers.map(problemNumber => {
                            const problemPages = pages.filter(
                                page => page.problemNumber === problemNumber
                            );

                            if (problemPages.length === 0) {
                                return null;
                            }

                            return (
                                <div key={problemNumber}>
                                    <h2
                                        className="examination-modal-problem-header"
                                        style={{ maxWidth: `${zoom}%` }}
                                        ref={setProblemHeaderRef}
                                        data-number={problemNumber}
                                    >
                                        {t('PaperModal.Problem')}{' '}
                                        {problemNumber + 1}
                                    </h2>
                                    {problemPages.map((page, i) => (
                                        <PageOfPaperWithComments
                                            key={i}
                                            paperId={paper._id}
                                            page={page}
                                            zoom={zoom}
                                            filter={filter}
                                            tool={null}
                                            alt={`${t('PaperModal.Problem')} ${
                                                problemNumber + 1
                                            }, ${t('PaperModal.page')} ${
                                                i + 1
                                            }`}
                                            showComments={showComments}
                                            wrapperRef={
                                                page._id === selectedPageId
                                                    ? selectedPageRef
                                                    : undefined
                                            }
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    {paper.type === 'OFFLINE' && (
                        <div>
                            {pages.map((page, i) => (
                                <PageOfPaperWithComments
                                    key={i}
                                    paperId={paper._id}
                                    page={page}
                                    zoom={zoom}
                                    filter={filter}
                                    tool={null}
                                    alt={`${t('PaperModal.Page')} ${i + 1}`}
                                    showComments={showComments}
                                    wrapperRef={
                                        page._id === selectedPageId
                                            ? selectedPageRef
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
                <button
                    className="examination-modal-close-button"
                    title={t('PaperModal.Close')}
                    onClick={() => {
                        setModalIn(false);
                        setTimeout(() => onClose(), 300);
                    }}
                >
                    <span>&times;</span>
                </button>
            </div>
        </OverlayModal>
    );
}
