import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { FIRST_SENIOR_GRADE } from '@turgor/model-utils/lib/getTierByGrade';
import { VenueParticipationInfo } from '../../Components/VenueParticipationInfo';
import { SubmitButton } from '../../Components/EnhancedButtons';

const multipliers = [2, 2, 2, 2, 2, 2, 2, 1.5, 1.3333, 1, 1.25, 1, 1, 1];

export function VenueForm({
    onSubmit,
    mutationData,
    loading,
    mutationError,
    showSubmitOnEveryTab = false,
    showCommentTab = false,
    submitButtonText,
    tournament,
    venue = null,
    venueApplication = null,
    customTabName = null,
    onSwitchCustomTab = null,
    scrollToSpring = false,
}) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();
    const [selectedTab, setSelectedTab] = useState('basic');

    const errorMessage = mutationError ? t('UnknownErrorMessage') : null;

    const venueDefaults = venueApplication || venue || null;
    let fallDefaults =
        (venueApplication?.season === 'FALL'
            ? { ...venueApplication, registrationOpen: true }
            : null) ||
        venue?.seasons?.fall ||
        null;
    let springDefaults =
        (venueApplication?.season === 'SPRING'
            ? { ...venueApplication, registrationOpen: true }
            : null) ||
        venue?.seasons?.spring ||
        null;

    const firstSeniorGrade = watch('firstSeniorGrade', FIRST_SENIOR_GRADE);

    // TODO: use isAppropriateStage instead
    const showSpring = Boolean(tournament.seasons.spring.ordinary.date);

    useEffect(() => {
        if (scrollToSpring) {
            setSelectedTab('spring');
        }
    }, [scrollToSpring]);

    return (
        <Form
            onSubmit={handleSubmit(
                ({ fallInfo, springInfo, firstSeniorGrade, ...venueInfo }) => {
                    onSubmit({
                        fallInfo: fallInfo
                            ? {
                                  ...fallInfo,
                                  levels: Array.isArray(fallInfo.levels)
                                      ? fallInfo.levels
                                      : fallInfo.levels
                                      ? [fallInfo.levels]
                                      : [],
                                  participantsNumberLimit:
                                      fallInfo.participantsNumberLimit || null,
                                  registrationOpen: Boolean(
                                      fallInfo.registrationOpen
                                  ),
                                  accessComment:
                                      fallInfo.access === 'LIMITED'
                                          ? fallInfo.accessComment
                                          : '',
                                  accessDescription:
                                      fallInfo.access === 'LIMITED'
                                          ? fallInfo.accessDescription
                                          : '',
                              }
                            : null,
                        springInfo: springInfo
                            ? {
                                  ...springInfo,
                                  levels: Array.isArray(springInfo.levels)
                                      ? springInfo.levels
                                      : springInfo.levels
                                      ? [springInfo.levels]
                                      : [],
                                  participantsNumberLimit:
                                      springInfo.participantsNumberLimit ||
                                      null,
                                  registrationOpen: Boolean(
                                      springInfo.registrationOpen
                                  ),
                                  accessComment:
                                      springInfo.access === 'LIMITED'
                                          ? springInfo.accessComment
                                          : '',
                                  accessDescription:
                                      springInfo.access === 'LIMITED'
                                          ? springInfo.accessDescription
                                          : '',
                              }
                            : null,
                        venueInfo: {
                            ...venueInfo,
                            gradeNormalizationShift:
                                firstSeniorGrade - FIRST_SENIOR_GRADE,
                            gradeChangesAtWinter: Boolean(
                                venueInfo.gradeChangesAtWinter
                            ),
                            awardsPrizes: Boolean(venueInfo.awardsPrizes),
                        },
                    });
                }
            )}
        >
            <Tabs
                activeKey={selectedTab}
                onSelect={tab => {
                    setSelectedTab(tab);
                    onSwitchCustomTab?.(tab === 'custom');
                }}
            >
                <Tab
                    title={t('VenueApplicationForm.OrganizersInfo')}
                    eventKey="basic"
                >
                    <br />
                    <Form.Group controlId="add-venue-townName">
                        <Form.Label>
                            {t('VenueApplicationForm.Town')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('townName', { required: true })}
                            defaultValue={venueDefaults?.townName || ''}
                        />
                        {errors.townName && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-townNameEn">
                        <Form.Label>Название на английском</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('townNameEn')}
                            defaultValue={venueDefaults?.townNameEn || ''}
                        />
                        {errors.townNameEn && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-organization">
                        <Form.Label>
                            {t('VenueApplicationForm.HoldingOrganization')}
                        </Form.Label>
                        <Form.Control
                            {...register('organization')}
                            defaultValue={venueDefaults?.organization || ''}
                        />
                        <Form.Text muted>
                            <Trans i18nKey="VenueApplicationForm.HoldingOrganizationHint" />
                        </Form.Text>
                        {errors.organization && (
                            <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                            >
                                {t('VenueApplicationForm.FieldIsRequired')}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="add-venue-organizationDescription">
                        <Form.Label>Подробнее об организаторах</Form.Label>
                        <Form.Control
                            as="textarea"
                            {...register('organizationDescription')}
                            defaultValue={
                                venueDefaults?.organizationDescription || ''
                            }
                        />
                        <Form.Text muted>
                            {t(
                                'VenueApplicationForm.HoldingOrganizationDescriptionHint'
                            )}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="add-venue-email">
                        <Form.Label>
                            {t('VenueApplicationForm.Email')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('email')}
                            defaultValue={venueDefaults?.email || ''}
                        />
                    </Form.Group>
                    <Form.Group controlId="add-venue-phone">
                        <Form.Label>
                            {t('VenueApplicationForm.EmergencyPhone')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            {...register('phone')}
                            defaultValue={venueDefaults?.phone || ''}
                        />
                        <Form.Text muted>
                            Этот телефон предназначен только для оргкомитета
                        </Form.Text>
                    </Form.Group>
                    <br />
                    {!showSubmitOnEveryTab && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'townName',
                                        'townNameEn',
                                        'organization',
                                        'organizationDescription',
                                        'email',
                                        'phone',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    setSelectedTab('fall');
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                </Tab>
                <Tab title="Осень" eventKey="fall">
                    <SeasonInfoFields
                        season="FALL"
                        register={register}
                        watch={watch}
                        errors={errors}
                        tournament={tournament}
                        defaults={fallDefaults}
                    />
                    <br />
                    {!showSubmitOnEveryTab && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'fallInfo.address',
                                        'fallInfo.startTime',
                                        'fallInfo.infoMessage',
                                        'fallInfo.contactPhone',
                                        'fallInfo.contactEmail',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    setSelectedTab(
                                        showSpring ? 'spring' : 'diplomas'
                                    );
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                </Tab>
                {showSpring && (
                    <Tab title="Весна" eventKey="spring">
                        <SeasonInfoFields
                            season="SPRING"
                            register={register}
                            watch={watch}
                            errors={errors}
                            tournament={tournament}
                            defaults={springDefaults}
                        />
                        <br />
                        {!showSubmitOnEveryTab && (
                            <Button
                                onClick={async () => {
                                    const valid = await trigger(
                                        [
                                            'springInfo.address',
                                            'springInfo.startTime',
                                            'springInfo.infoMessage',
                                            'springInfo.contactPhone',
                                            'springInfo.contactEmail',
                                        ],
                                        { shouldFocus: true }
                                    );

                                    if (valid) {
                                        setSelectedTab('diplomas');
                                    }
                                }}
                            >
                                {t('Next')}
                            </Button>
                        )}
                    </Tab>
                )}
                <Tab title="Классы и дипломы" eventKey="diplomas">
                    <br />
                    Настройки классов
                    <Row>
                        <Col md={4} className="mt-2">
                            <Form.Check
                                {...register('gradeChangesAtWinter')}
                                label="Смена класса зимой"
                                id="update-venue-info-gradeChangesAtWinter"
                                defaultChecked={false}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label>Первый старший класс</Form.Label>
                            <Form.Control
                                className="d-inline-block w-25 ml-3"
                                type="number"
                                {...register('firstSeniorGrade', {
                                    valueAsNumber: true,
                                })}
                                defaultValue={FIRST_SENIOR_GRADE}
                            />
                            {firstSeniorGrade !== FIRST_SENIOR_GRADE ? (
                                <Form.Control.Feedback className="d-block text-muted">
                                    <table>
                                        <tbody>
                                            <tr>
                                                {[...new Array(6).keys()].map(
                                                    i => (
                                                        <td key={i}>
                                                            {firstSeniorGrade -
                                                                4 +
                                                                i}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                            <tr>
                                                {[...new Array(6).keys()].map(
                                                    i => (
                                                        <td
                                                            key={i}
                                                            className="pr-3"
                                                        >
                                                            {multipliers[
                                                                FIRST_SENIOR_GRADE -
                                                                    4 +
                                                                    i
                                                            ]?.toFixed(2)}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Row>
                    <br />
                    <Form.Group controlId="update-venue-info-diplomaTownName">
                        <Form.Label>Название в родительном падеже</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('diplomaTownName')}
                            defaultValue={venueDefaults?.diplomaTownName || ''}
                        />
                    </Form.Group>
                    <Form.Group controlId="update-venue-info-organizingCommitteePresident">
                        <Form.Label>Председатель оргкомитета</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('organizingCommitteePresident')}
                            defaultValue={
                                venueDefaults?.organizingCommitteePresident ||
                                ''
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="update-venue-info-juryPresident">
                        <Form.Label>Председатель жюри</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('juryPresident')}
                            defaultValue={venueDefaults?.juryPresident || ''}
                        />
                    </Form.Group>
                    <br />
                    <Form.Check
                        {...register('awardsPrizes')}
                        label="Выдаются премии от имени местного оргкомитета"
                        id="add-venue-awardsPrizes"
                        defaultChecked={venueDefaults?.awardsPrizes || false}
                    />
                    <br />
                    {!showSubmitOnEveryTab && showCommentTab && (
                        <Button
                            onClick={async () => {
                                const valid = await trigger(
                                    [
                                        'awardsPrizes',
                                        'gradeChangesAtWinter',
                                        'firstSeniorGrade',
                                        'juryPresident',
                                        'organizingCommitteePresident',
                                        'diplomaTownName',
                                    ],
                                    { shouldFocus: true }
                                );

                                if (valid) {
                                    setSelectedTab('comment');
                                }
                            }}
                        >
                            {t('Next')}
                        </Button>
                    )}
                </Tab>
                {showCommentTab && (
                    <Tab title="Комментарии" eventKey="comment">
                        <br />
                        <Form.Group controlId={`add-venue-comment`}>
                            <Form.Label>
                                Коммментарии (видны только оргкомитету и жюри)
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                {...register('comment')}
                                defaultValue={venue?.comment || ''}
                            />
                        </Form.Group>
                        <br />
                    </Tab>
                )}
                {customTabName && (
                    <Tab title={customTabName} eventKey="custom" />
                )}
            </Tabs>
            {(showSubmitOnEveryTab ||
                (!showCommentTab && selectedTab === 'diplomas') ||
                selectedTab === 'comment') &&
                selectedTab !== 'custom' && (
                    <SubmitButton
                        loading={loading}
                        mutationData={mutationData}
                        className="mt-2"
                    >
                        {submitButtonText}
                    </SubmitButton>
                )}
            {errorMessage && selectedTab !== 'custom' && (
                <Alert variant="warning" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
        </Form>
    );
}

function SeasonInfoFields({
    season,
    register,
    watch,
    errors,
    tournament,
    defaults,
}) {
    const { t } = useTranslation();

    season = season.toLowerCase();

    return (
        <>
            <br />
            <Form.Check
                {...register(season + 'Info.registrationOpen')}
                label="Регистрация открыта"
                defaultChecked={defaults?.registrationOpen || false}
                id={'venue-application-registrationOpen-' + season}
            />
            <br />
            <Form.Group controlId={`add-venue-${season}-type`}>
                <Form.Label>Очность</Form.Label>
                <Form.Control
                    as="select"
                    {...register(season + 'Info.type')}
                    defaultValue={defaults?.type || 'OFFLINE'}
                >
                    <option value="OFFLINE">Очно</option>
                    <option value="ONLINE">Онлайн</option>
                </Form.Control>
            </Form.Group>
            {t('VenueApplicationForm.PlannedRounds')}
            <br />
            <Row className="mt-1 mb-4">
                <Col md={4}>
                    <Form.Check
                        {...register(season + 'Info.levels')}
                        value="ORDINARY"
                        label={t('VenueApplicationForm.OrdinaryDate', {
                            date: new Date(
                                tournament.seasons[season].ordinary.date
                            ),
                        })}
                        id={'add-venue-ordinary-' + season}
                        defaultChecked={
                            defaults?.levels.includes('ORDINARY') || false
                        }
                    />
                </Col>
                <Col md={4}>
                    <Form.Check
                        {...register(season + 'Info.levels')}
                        value="ADVANCED"
                        label={t('VenueApplicationForm.AdvancedDate', {
                            date: new Date(
                                tournament.seasons[season].advanced.date
                            ),
                        })}
                        id={'add-venue-advanced-' + season}
                        defaultChecked={
                            defaults?.levels.includes('ADVANCED') || false
                        }
                    />
                </Col>
            </Row>
            {errors[season + 'Info.levels'] && (
                <Form.Control.Feedback className="d-block mt-n4" type="invalid">
                    {t('VenueApplicationForm.ChooseAtLeastOneLevel')}
                </Form.Control.Feedback>
            )}
            <Form.Group controlId={`add-venue-${season}-access`}>
                <Form.Label>
                    {t('VenueApplicationForm.WhoCanParticipate')}
                </Form.Label>
                {errors[season + 'Info.access'] && (
                    <Form.Control.Feedback
                        className="d-block mt-n2"
                        type="invalid"
                    >
                        {t('VenueApplicationForm.FieldIsRequired')}
                    </Form.Control.Feedback>
                )}
                <Form.Check
                    {...register(season + 'Info.access')}
                    type="radio"
                    value="LIMITED"
                    label={t('VenueApplicationForm.LimitedAccess')}
                    defaultChecked={defaults?.access === 'LIMITED' || false}
                    id={'venue-application-access-limited-' + season}
                />
                <Form.Check
                    {...register(season + 'Info.access')}
                    type="radio"
                    value="REGISTRATION"
                    label={t('VenueApplicationForm.RegistrationAccess')}
                    defaultChecked={
                        defaults?.access === 'REGISTRATION' || !defaults?.access
                    }
                    id={'venue-application-access-registration-' + season}
                />
                <Form.Check
                    {...register(season + 'Info.access')}
                    type="radio"
                    value="FREE"
                    label={t('VenueApplicationForm.FreeAccess')}
                    defaultChecked={defaults?.access === 'FREE' || false}
                    id={'venue-application-access-free-' + season}
                />
            </Form.Group>
            {watch(season + 'Info.access') === 'LIMITED' && (
                <Form.Group controlId={`add-venue-${season}-accessComment`}>
                    <Form.Label>
                        {t('VenueApplicationForm.AccessDetails')}
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        {...register(season + 'Info.accessComment')}
                        defaultValue={defaults?.accessComment || ''}
                    />
                </Form.Group>
            )}
            <Form.Group
                controlId={`add-venue-${season}-participantsNumberLimit`}
            >
                <Form.Label>
                    {t('VenueApplicationForm.ParticipantsNumberLimit')}
                </Form.Label>
                <Form.Control
                    type="number"
                    {...register(season + 'Info.participantsNumberLimit', {
                        valueAsNumber: true,
                    })}
                    defaultValue={defaults?.participantsNumberLimit || ''}
                />
            </Form.Group>
            <br />
            <h5>Информация для участников</h5>
            {watch(season + 'Info.access') === 'LIMITED' && (
                <Form.Group controlId={`add-venue-${season}-accessDescription`}>
                    <Form.Label>
                        {t(
                            'VenueApplicationForm.AccessDescriptionForParticipants'
                        )}
                    </Form.Label>
                    <Form.Control
                        {...register(season + 'Info.accessDescription')}
                        defaultValue={defaults?.accessDescription || ''}
                    />
                    <Form.Text muted>
                        Короткое описание для участников. Например,{' '}
                        <strong>Только для учеников школы № 179</strong>
                    </Form.Text>
                </Form.Group>
            )}
            <Form.Group controlId={`add-venue-${season}-address`}>
                <Form.Label>{t('VenueApplicationForm.Address')}</Form.Label>
                <Form.Control
                    as="textarea"
                    {...register(season + 'Info.address')}
                    defaultValue={defaults?.address || ''}
                />
                {errors[season + 'Info.address'] && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('VenueApplicationForm.FieldIsRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-startTime`}>
                <Form.Label>{t('VenueApplicationForm.StartTime')}</Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.startTime')}
                    defaultValue={defaults?.startTime || ''}
                />
                <Form.Text muted>
                    {t('VenueApplicationForm.StartTimeHint')}
                </Form.Text>
                {errors[season + 'Info.startTime'] && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('VenueApplicationForm.FieldIsRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-infoMessage`}>
                <Form.Label>{t('VenueApplicationForm.Notes')}</Form.Label>
                <Form.Control
                    as="textarea"
                    {...register(season + 'Info.infoMessage')}
                    defaultValue={defaults?.infoMessage || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactEmail`}>
                <Form.Label>
                    {t('VenueApplicationForm.EmailForParticipants')}
                </Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.contactEmail')}
                    defaultValue={defaults?.contacts?.email || ''}
                />
                {errors[season + 'Info.contactEmail'] && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('VenueApplicationForm.FieldIsRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactPhone`}>
                <Form.Label>
                    {t('VenueApplicationForm.PhoneForParticipants')}
                </Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.contactPhone')}
                    defaultValue={defaults?.contacts?.phone || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactWebsite`}>
                <Form.Label>{t('VenueApplicationForm.Website')}</Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.contactWebsite')}
                    defaultValue={defaults?.contacts?.website || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactTelegram`}>
                <Form.Label>{t('VenueApplicationForm.Telegram')}</Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.contactTelegram')}
                    defaultValue={defaults?.contacts?.telegram || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactWhatsapp`}>
                <Form.Label>{t('VenueApplicationForm.Whatsapp')}</Form.Label>
                <Form.Control
                    type="number"
                    {...register(season + 'Info.contactWhatsapp')}
                    defaultValue={defaults?.contacts?.whatsapp || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactViber`}>
                <Form.Label>{t('VenueApplicationForm.Viber')}</Form.Label>
                <Form.Control
                    type="number"
                    {...register(season + 'Info.contactViber')}
                    defaultValue={defaults?.contacts?.viber || ''}
                />
            </Form.Group>
            <Form.Group controlId={`add-venue-${season}-contactFbMessenger`}>
                <Form.Label>{t('VenueApplicationForm.FbMessenger')}</Form.Label>
                <Form.Control
                    type="text"
                    {...register(season + 'Info.contactFbMessenger')}
                    defaultValue={defaults?.contacts?.fbMessenger || ''}
                />
            </Form.Group>
            <br />
            {watch(season + 'Info.address') &&
            watch(season + 'Info.startTime') &&
            watch(season + 'Info.contactEmail') ? (
                <>
                    <h5>
                        {t(
                            'VenueApplicationForm.HowWillThisLookForParticipants'
                        )}
                    </h5>
                    <VenueParticipationInfo
                        venueInfo={{
                            address: watch(season + 'Info.address'),
                            startTime: watch(season + 'Info.startTime'),
                            infoMessage: watch(season + 'Info.infoMessage'),
                            contacts: {
                                email: watch(season + 'Info.contactEmail'),
                                phone: watch(season + 'Info.contactPhone'),
                                website: watch(season + 'Info.contactWebsite'),
                                telegram: watch(
                                    season + 'Info.contactTelegram'
                                ),
                                whatsapp: watch(
                                    season + 'Info.contactWhatsapp'
                                ),
                                viber: watch(season + 'Info.contactViber'),
                                fbMessenger: watch(
                                    season + 'Info.contactFbMessenger'
                                ),
                            },
                            access: watch(season + 'Info.access'),
                            accessDescription: watch(
                                season + 'Info.accessDescription'
                            ),
                        }}
                    />
                    <br />
                </>
            ) : null}
        </>
    );
}
