import {
    findByFields,
    findLastByFields,
    findPaperInParticipant,
    findProblemsInTournament,
    PapersFilter,
} from '@turgor/model-utils/lib/findByFields';
import { Marks } from './Marks';

interface Participant {
    papers: Array<Paper>;
}

interface Paper {
    season: 'FALL' | 'SPRING';
    level: 'ORDINARY' | 'ADVANCED';
    tier: 'JUNIOR' | 'SENIOR';
    stage:
        | 'REGISTERED'
        | 'ONLINE_STARTED'
        | 'SUBMITTED'
        | 'UPLOADED'
        | 'MARKED'
        | 'PUBLISHED';
    examinations?: Array<Examination>;
}

type ExaminationType = 'LOCAL' | 'CENTRAL';
type ExaminationStage = 'FIRST' | 'SECOND' | 'FINAL';

interface Examination {
    type: ExaminationType;
    stage: ExaminationStage;
    marks: Array<{
        problemNumber: number;
        subproblemNumber: number;
        mark: string;
    }>;
}

interface Tournament {
    seasons: {
        fall: TournamentSeason;
        spring: TournamentSeason;
    };
}

interface TournamentSeason {
    ordinary: TournamentLevel;
    advanced: TournamentLevel;
}

interface TournamentLevel {
    junior: TournamentTier;
    senior: TournamentTier;
}

interface TournamentTier {
    problems: Array<{ subproblems: Array<unknown> }>;
}

interface ParticipantExaminationMarksProps {
    participant: Participant;
    papersFilter?: PapersFilter;
    tournament?: Tournament;
    type: ExaminationType;
    stage?: ExaminationStage;
}

export function ParticipantExaminationMarks({
    participant,
    papersFilter,
    tournament,
    type,
    stage,
}: ParticipantExaminationMarksProps) {
    const paper = papersFilter
        ? findPaperInParticipant(participant, papersFilter)
        : participant.papers[0];

    if (!paper) {
        return null;
    }

    return (
        <PaperExaminationMarks
            paper={paper}
            tournament={tournament}
            type={type}
            stage={stage}
        />
    );
}

interface PaperExaminationMarksProps {
    paper: Paper;
    tournament?: Tournament;
    type: ExaminationType;
    stage?: ExaminationStage;
}

export function PaperExaminationMarks({
    paper,
    tournament,
    type,
    stage,
}: PaperExaminationMarksProps) {
    const problems = findProblemsInTournament(
        tournament,
        paper.season,
        paper.level,
        paper.tier
    );

    const examination = stage
        ? findByFields(paper.examinations, {
              type,
              stage,
          })
        : findLastByFields(paper.examinations, {
              type,
          });

    if (!examination) {
        return null;
    }

    return <Marks examination={examination} problems={problems} />;
}
