import React, {createContext, useContext, useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';

import { ViewerQuery } from 'GraphQL/types/ViewerQuery';
import {changeLocale} from "./i18n";

export interface Viewer {
    _id: string;
    email: string;
    name?: string;
    role: 'PARTICIPANT' | 'ORGANIZER' | 'INPUTTER' | 'JURY' | 'ADMIN';
    language: 'RU' | 'EN';
    verified: boolean;
}

export interface ViewerContextInterface {
    loading: boolean;
    viewer: Viewer | null;
}

export const ViewerContext = createContext<ViewerContextInterface>({
    loading: true,
    viewer: null,
});

const viewerQuery = gql`
    query ViewerQuery {
        viewer {
            _id
            email
            name
            role
            language
            verified
        }
    }
`;

export const UserContextProvider = ({ children }) => {
    const { loading, data } = useQuery<ViewerQuery>(viewerQuery);

    useEffect(() => {
        if (data?.viewer?.language) {
            changeLocale(data?.viewer?.language.toLowerCase(), true);
        }
    }, [data?.viewer?.language])

    return (
        <ViewerContext.Provider
            value={{ loading, viewer: data ? data.viewer : null }}
        >
            {children}
        </ViewerContext.Provider>
    );
};

export const useViewer = () => useContext(ViewerContext);
