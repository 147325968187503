import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link, useParams, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { provideLayoutAndData } from './Page';
import { Button, Form, Table } from 'react-bootstrap';
import { useViewer } from './ViewerContext';
import { getLocalSetting, setLocalSetting } from './localSettings';
import {
    TournamentBatchesPageQuery,
    TournamentBatchesPageQueryVariables,
} from './GraphQL/types/TournamentBatchesPageQuery';
import {
    createParamsParser,
    getNumber,
    getSeasonAndLevel,
    getTier,
} from './routeParams';

const tournamentBatchesPageQuery = gql`
    query TournamentBatchesPageQuery(
        $tournamentNumber: Int!
        $unfinishedOnly: Boolean
        $season: Season
        $level: Level
        $tier: Tier
    ) {
        tournament(number: $tournamentNumber) {
            _id
            number
            batches(
                unfinishedOnly: $unfinishedOnly
                season: $season
                level: $level
                tier: $tier
            ) {
                _id
                codeName
                season
                level
                tier
                venue {
                    _id
                    townName
                }
                statistics {
                    count
                    startedFirst
                    markedFirst
                    startedSecond
                    markedSecond
                    startedFinal
                    markedFinal
                }
                firstStarted
                firstFinished
                firstJury {
                    _id
                    name
                }
                secondStarted
                secondFinished
                secondJury {
                    _id
                    name
                }
                finalStarted
                finalFinished
                finalJury {
                    _id
                    name
                }
            }
        }
    }
`;

interface TournamentBatchesPageRouteParams {
    number: string;
    round: `${'fall' | 'spring'}-${'ordinary' | 'advanced'}`;
    tier: 'junior' | 'senior';
}

const parseParams = createParamsParser(getSeasonAndLevel, getNumber, getTier);

export default provideLayoutAndData<
    TournamentBatchesPageQuery,
    TournamentBatchesPageQueryVariables,
    TournamentBatchesPageRouteParams
>(tournamentBatchesPageQuery, {
    variablesGetter: params => {
        const {
            season,
            level,
            tier,
            number: tournamentNumber,
        } = parseParams(params);

        return {
            tournamentNumber,
            season,
            level,
            tier,
            unfinishedOnly: false,
        };
    },
})(function TournamentBatchesPage({ data, refetch }) {
    const params = useParams<TournamentBatchesPageRouteParams>();
    const { number, season, level, tier } = parseParams(params);
    const [unfinishedOnly, setUnfinishedOnly] = useState(
        getLocalSetting('tournament-batches', 'unfinishedOnly', false)
    );

    const batches = data.tournament.batches.filter(b =>
        unfinishedOnly ? !b.finalFinished : true
    );

    return (
        <>
            <h2>{number}-й Турнир</h2>
            <h3>
                {season === 'FALL' ? 'Осенний' : 'Весенний'}{' '}
                {level === 'ORDINARY' ? 'базовый' : 'сложный'}{' '}
                {tier === 'JUNIOR' ? 'младший' : 'старший'} вариант
            </h3>
            <Form.Check
                label="Только нефинишированные"
                id="unfinishedOnly-checkbox"
                onChange={event => {
                    const newValue = event.currentTarget.checked;

                    setUnfinishedOnly(newValue);
                    setLocalSetting(
                        'tournament-batches',
                        'unfinishedOnly',
                        newValue
                    );
                }}
                checked={unfinishedOnly}
                className="mb-2"
            />
            <BatchesTable
                batches={batches}
                tournamentNumber={number}
                onStartExaminationFail={refetch}
            />
        </>
    );
});

const startBatchExaminationMutation = gql`
    mutation TournamentBatchesPageStartBatch(
        $batchId: ID!
        $stage: PaperExaminationStage
    ) {
        startBatchExamination(batchId: $batchId, stage: $stage) {
            __typename
            ... on StartBatchExaminationSuccess {
                batch {
                    _id
                    firstStarted
                    firstFinished
                    firstJury {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

const publishVenueResultsMutation = gql`
    mutation PublishVenueResultsByBatch(
        $venueId: ID!
        $season: Season!
        $level: Level!
        $tier: Tier
        $batchId: ID
    ) {
        publishVenueResults(
            venueId: $venueId
            season: $season
            level: $level
            tier: $tier
            batchId: $batchId
        ) {
            __typename
            ... on PublishVenueResultsSuccess {
                venue {
                    _id
                    batches {
                        _id
                        statistics {
                            published
                        }
                    }
                }
            }
        }
    }
`;

export function BatchesTable({
    batches,
    tournamentNumber,
    onStartExaminationFail,
    showPublished = false,
}) {
    let history = useHistory();
    const [startBatchExamination] = useMutation(startBatchExaminationMutation);
    const [publishVenueResults] = useMutation(publishVenueResultsMutation);
    const { viewer } = useViewer();

    return (
        <Table striped={true} borderless={true} size="sm">
            <thead>
                <tr>
                    <th>Пачка</th>
                    <th>Работ</th>
                    <th>Первая проверка</th>
                    <th>Вторая проверка</th>
                    <th>Финиш</th>
                    {showPublished && <th>Опубликовано</th>}
                </tr>
            </thead>
            <tbody>
                {batches.map(batch => (
                    <tr key={batch._id}>
                        <td>
                            <Link
                                to={`/tournaments/${tournamentNumber}/batches/${batch._id}`}
                            >
                                {batch.venue?.townName} {batch.codeName}
                            </Link>
                        </td>
                        <td>{batch.statistics.count}</td>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count ===
                                    batch.statistics.markedFirst,
                                'batch-finished': Boolean(batch.firstFinished),
                            })}
                        >
                            {batch.firstJury ? (
                                <>
                                    {batch.statistics.markedFirst} /{' '}
                                    {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.firstJury.name}
                                    </span>
                                </>
                            ) : (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'FIRST',
                                            },
                                        }).then(({ data }) => {
                                            if (
                                                data?.startBatchExamination
                                                    .__typename ===
                                                'StartBatchExaminationSuccess'
                                            ) {
                                                history.push(
                                                    `/tournaments/${tournamentNumber}/batches/${batch._id}`
                                                );
                                            } else {
                                                onStartExaminationFail();
                                            }
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            )}
                        </td>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count ===
                                    batch.statistics.markedSecond,
                                'batch-finished': Boolean(batch.secondFinished),
                            })}
                        >
                            {batch.secondJury ? (
                                <>
                                    {batch.statistics.markedSecond} /{' '}
                                    {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.secondJury.name}
                                    </span>
                                </>
                            ) : null}
                            {batch.firstFinished &&
                            !batch.secondJury &&
                            batch.firstJury?._id !== viewer._id &&
                            batch.statistics.count ===
                                batch.statistics.markedFirst ? (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'SECOND',
                                            },
                                        }).then(({ data }) => {
                                            if (
                                                data?.startBatchExamination
                                                    .__typename ===
                                                'StartBatchExaminationSuccess'
                                            ) {
                                                history.push(
                                                    `/tournaments/${tournamentNumber}/batches/${batch._id}`
                                                );
                                            } else {
                                                onStartExaminationFail();
                                            }
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            ) : null}
                        </td>
                        <td
                            className={classnames({
                                'batch-almost-finished':
                                    batch.statistics.count ===
                                    batch.statistics.markedFinal,
                                'batch-finished': Boolean(batch.finalFinished),
                            })}
                        >
                            {batch.finalJury ? (
                                <>
                                    {batch.statistics.markedFinal} /{' '}
                                    {batch.statistics.count}{' '}
                                    <span className="ml-3">
                                        {batch.finalJury.name}
                                    </span>
                                </>
                            ) : null}
                            {batch.secondFinished &&
                            !batch.finalJury &&
                            batch.statistics.count ===
                                batch.statistics.markedSecond ? (
                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() =>
                                        startBatchExamination({
                                            variables: {
                                                batchId: batch._id,
                                                stage: 'FINAL',
                                            },
                                        }).then(({ data }) => {
                                            if (
                                                data?.startBatchExamination
                                                    .__typename ===
                                                'StartBatchExaminationSuccess'
                                            ) {
                                                history.push(
                                                    `/tournaments/${tournamentNumber}/batches/${batch._id}`
                                                );
                                            } else {
                                                onStartExaminationFail();
                                            }
                                        })
                                    }
                                >
                                    Взять на проверку
                                </Button>
                            ) : null}
                        </td>
                        {showPublished && (
                            <td
                                className={classnames({
                                    'batch-almost-finished':
                                        batch.statistics.count === 0,
                                    'batch-finished':
                                        batch.statistics.markedFinal > 0 &&
                                        batch.statistics.markedFinal ===
                                            batch.statistics.published,
                                })}
                            >
                                {batch.statistics.markedFinal > 0 &&
                                    `${batch.statistics.published} / ${batch.statistics.markedFinal}`}
                                {batch.statistics.markedFinal &&
                                batch.statistics.published <
                                    batch.statistics.markedFinal ? (
                                    <Button
                                        size="sm"
                                        className="ml-2"
                                        variant="outline-primary"
                                        onClick={() =>
                                            publishVenueResults({
                                                variables: {
                                                    venueId: batch.venue._id,
                                                    season: batch.season,
                                                    level: batch.level,
                                                    tier: batch.tier,
                                                    batchId: batch._id,
                                                },
                                            })
                                        }
                                    >
                                        Опубликовать
                                    </Button>
                                ) : null}
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
