import { provideLayoutAndData } from '../Page';
import { gql, useMutation } from '@apollo/client';
import {
    OrganizerVenueApplicationPageQuery,
    OrganizerVenueApplicationPageQueryVariables,
} from '../GraphQL/types/OrganizerVenueApplicationPageQuery';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CreateVenueApplicationForm } from './CreateVenueApplicationForm';

const organizerVenueApplicationPageQuery = gql`
    query OrganizerVenueApplicationPageQuery($venueApplicationId: ID!) {
        venueApplication(id: $venueApplicationId) {
            _id
            stage
            townName
            season
            tournament {
                _id
                number
                seasons {
                    fall {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                    spring {
                        stage
                        ordinary {
                            date
                        }
                        advanced {
                            date
                        }
                    }
                }
            }
            levels
            organization
            organizationDescription
            email
            phone
            address
            infoMessage
            contacts {
                email
                phone
                website
                telegram
                whatsapp
                viber
                fbMessenger
            }
            startTime
            access
            accessComment
            accessDescription
            participantsNumberLimit
            comment
        }
    }
`;

const updateVenueApplicationMutation = gql`
    mutation UpdateVenueApplication(
        $venueApplicationId: ID!
        $venueInfo: VenueApplicationInput!
    ) {
        updateVenueApplication(
            venueApplicationId: $venueApplicationId
            venueInfo: $venueInfo
        ) {
            __typename
            ... on UpdateVenueApplicationSuccess {
                venueApplication {
                    _id
                }
            }
        }
    }
`;

export default provideLayoutAndData<
    OrganizerVenueApplicationPageQuery,
    OrganizerVenueApplicationPageQueryVariables
>(organizerVenueApplicationPageQuery, {
    variablesGetter: ({ venueApplicationId }) => ({ venueApplicationId }),
    authenticatedOnly: true,
    queryOptions: {
        fetchPolicy: 'network-only',
    },
})(function OrganizerVenueApplicationPage({
    data: { venueApplication },
    viewer,
}) {
    const { t } = useTranslation();
    const [formCompleted, setFormCompleted] = useState(false);

    const [updateVenueApplication, { data, error, loading }] = useMutation(
        updateVenueApplicationMutation
    );

    const { season, tournament } = venueApplication;

    if (venueApplication.stage === 'REJECTED') {
        return (
            <>
                <h2>
                    {t('SeasonFullTitle', {
                        number: tournament.number,
                        season,
                    })}
                </h2>
                <>
                    <Alert variant="danger">
                        <Trans
                            i18nKey="OrganizerHomePage.VenueApplicationRejectedMessage"
                            values={{ email: viewer.email }}
                        />
                    </Alert>
                    <br />
                    <Link to="/">{t('OrganizerHomePage.ReturnToMain')}</Link>
                </>
            </>
        );
    }

    return (
        <>
            <h2>
                {t('SeasonFullTitle', {
                    number: tournament.number,
                    season,
                })}
            </h2>
            {!formCompleted && (
                <CreateVenueApplicationForm
                    viewerEmail={viewer.email}
                    onSubmit={venueInfo => {
                        updateVenueApplication({
                            variables: {
                                venueApplicationId: venueApplication._id,
                                venueInfo,
                            },
                        }).then(({ data }) => {
                            if (
                                data.updateVenueApplication.__typename ===
                                'UpdateVenueApplicationSuccess'
                            ) {
                                setFormCompleted(true);
                            }
                        });
                    }}
                    mutationData={data}
                    mutationError={
                        error ||
                        !['UpdateVenueApplicationSuccess', undefined].includes(
                            data?.updateVenueApplication.__typename
                        )
                    }
                    language={viewer.language}
                    loading={loading}
                    application={venueApplication}
                    submitButtonText={t('Save')}
                    ordinaryDate={
                        tournament.seasons[season.toLowerCase()].ordinary.date
                    }
                    advancedDate={
                        tournament.seasons[season.toLowerCase()].advanced.date
                    }
                />
            )}
            {formCompleted && (
                <>
                    <Alert variant="success">
                        <Trans
                            i18nKey="OrganizerHomePage.VenueApplicationSuccessfullyEditedMessage"
                            values={{ email: viewer.email }}
                        />
                    </Alert>
                    <br />
                    <Link to="/">{t('OrganizerHomePage.ReturnToMain')}</Link>
                </>
            )}

            <br />
            <br />
            <br />
            <br />
        </>
    );
});
