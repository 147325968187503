import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Alert, Button, Jumbotron } from 'react-bootstrap';
import OnlineTestPaper from './OnlineTestPaper';
import { useTranslation } from 'react-i18next';

const startOnlineParticipationMutation = gql`
    mutation StartOnlineParticipation($paperId: ID!) {
        startOnlineParticipation(paperId: $paperId) {
            __typename
            ... on StartOnlineParticipationSuccess {
                paper {
                    _id
                    stage
                    season
                    level
                    tier
                    type
                    card
                    venue {
                        townName
                    }
                }
            }
        }
    }
`;

export default function ReadyToStartOnline({ participant, paper }) {
    const { t } = useTranslation();
    const [startOnlineParticipation, { data, error }] = useMutation(
        startOnlineParticipationMutation
    );

    return (
        <>
            <Jumbotron className="jumbotron">
                <h2>
                    {paper.level === 'ORDINARY' ? 'Базовый' : 'Сложный'} тур{' '}
                    {paper.season === 'FALL' ? 'осеннего' : 'весеннего'} Турнира
                    городов стартовал
                </h2>
                <p>
                    Вы можете приступить к решению задач.
                    <br />
                    Прочитайте правила оформления и загрузки работ ниже и
                    нажмите «Начать», когда будете готовы приступить.
                    <br />
                    <br />
                    После начала у вас будет 5 часов 30 минут, чтобы записать на
                    бумаге и загрузить свои решения.
                    <br />
                    Загрузка работ возможна до{' '}
                    {new Date('2021-03-28T16:00:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}{' '}
                    (16:00 мск), поэтому если вы приступите позже{' '}
                    {new Date('2021-03-28T10:30:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}{' '}
                    (10:30 мск), у вас будет меньше времени для решения.
                    <br />
                    Например, если вы начнёте в{' '}
                    {new Date('2021-03-28T10:15:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}
                    , то сможете решать задачи до{' '}
                    {new Date('2021-03-28T15:45:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}
                    , если начнёте в{' '}
                    {new Date('2021-03-28T10:30:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}{' '}
                    — до{' '}
                    {new Date('2021-03-28T16:00:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}
                    , а если в{' '}
                    {new Date('2021-03-28T11:00:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}{' '}
                    — тоже только до{' '}
                    {new Date('2021-03-28T16:00:00.000+03:00')
                        .toTimeString()
                        .substr(0, 5)}
                    .
                    <br />
                    Вопросы по условиям можно будет задать через специальную
                    форму после начала выполнения заданий.
                    <br />
                    <br />
                    Все задания должны быть выполнены самостоятельно.
                    <br />
                    Работы, которые жюри сочтёт выполненными несамостоятельно,
                    будут аннулированы.
                    <br />
                    До 16:00 мск запрещены передача и обсуждение условий задач
                    (кроме вопросов по условиям членам жюри).
                </p>
                <p>
                    <Button
                        variant="primary"
                        onClick={() => {
                            startOnlineParticipation({
                                variables: {
                                    paperId: paper._id,
                                },
                            }).then(({ data }) => {
                                if (
                                    data?.startOnlineParticipation
                                        .__typename === 'AlreadyStartedError'
                                ) {
                                    window.location.reload();
                                }
                            });
                        }}
                    >
                        Начать
                    </Button>
                </p>
                {(error ||
                    ![
                        'StartOnlineParticipationSuccess',
                        'AlreadyStartedError',
                        undefined,
                    ].includes(data?.startOnlineParticipation.__typename)) && (
                    <Alert variant="warning">{t('UnknownErrorMessage')}</Alert>
                )}
            </Jumbotron>
            <OnlineTestPaper participant={participant} />
        </>
    );
}
