import React, { useRef, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import Page from '../Page';
import { SpinnerButton } from 'Components/EnhancedButtons';
import { useViewer } from '../ViewerContext';
import { createSortFn } from '@turgor/model-utils/lib/sort';
import { VenueParticipationInfo } from '../Components/VenueParticipationInfo';

export const venuesQuery = gql`
    query Venues {
        currentTournament {
            _id
            number
            venues {
                _id
                townName
                townNameEn
                organization
                gradeChangesAtWinter
                seasons {
                    fall {
                        levels
                        address
                        infoMessage
                        contacts {
                            email
                            phone
                            website
                            telegram
                            whatsapp
                            viber
                            fbMessenger
                        }
                        startTime
                        access
                        registrationOpen
                        accessDescription
                        type
                        levelsWithAvailableSpots
                    }
                    spring {
                        levels
                        address
                        infoMessage
                        contacts {
                            email
                            phone
                            website
                            telegram
                            whatsapp
                            viber
                            fbMessenger
                        }
                        startTime
                        access
                        registrationOpen
                        accessDescription
                        type
                        levelsWithAvailableSpots
                    }
                }
            }
        }
    }
`;

interface RegisterForTournamentProps {
    season: 'FALL' | 'SPRING';
    tournament: any;
    participantInfo: any;
}

export default function RegisterForTournament({
    season,
    tournament,
    participantInfo,
}: RegisterForTournamentProps) {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { data, loading } = useQuery(venuesQuery);
    const [participant, setParticipant] = useState<object | null>(null);
    const [venueId, setVenueId] = useState<string | null>(null);

    if (loading) {
        return (
            <Page layout="narrow">
                <div className="text-center">
                    <Spinner animation="border">
                        <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                </div>
            </Page>
        );
    }

    const venues = data.currentTournament.venues
        .filter(
            venue =>
                venue.seasons?.[season.toLowerCase()]?.registrationOpen &&
                venue.seasons?.[season.toLowerCase()]?.levels?.length
        )
        .map(
            ({
                _id,
                townName,
                townNameEn,
                organization,
                gradeChangesAtWinter,
                seasons,
            }) => ({
                _id,
                townName:
                    language === 'ru' || !townNameEn ? townName : townNameEn,
                organization,
                gradeChangesAtWinter,
                ...(seasons?.[season.toLowerCase()] || {}),
                accessPriority: ['FREE', 'REGISTRATION', 'LIMITED'].indexOf(
                    seasons?.[season.toLowerCase()]?.access
                ),
            })
        )
        .sort(createSortFn('townName', 'accessPriority', 'organization'));

    const venue = venueId ? venues.find(({ _id }) => _id === venueId) : null;

    return (
        <>
            {!participant && (
                <>
                    <h1 className="h2">
                        {season === 'FALL'
                            ? t(
                                  'RegisterForTournamentPage.RegisterForFallRound'
                              )
                            : t(
                                  'RegisterForTournamentPage.RegisterForSpringRound'
                              )}
                    </h1>
                    {!venueId && (
                        <SelectVenue
                            venues={venues}
                            onSelect={venueId => setVenueId(venueId)}
                        />
                    )}
                    {venueId && (
                        <RegisterForTournamentForm
                            season={season}
                            tournament={tournament}
                            venue={venue}
                            participantInfo={participantInfo}
                            onSuccess={participant => {
                                setParticipant(participant);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            }}
                        />
                    )}
                </>
            )}
            {participant && <RegisterForTournamentSuccess />}
        </>
    );
}

export function SelectVenue({ venues, onSelect }) {
    const { t } = useTranslation();
    const [selectedVenueId, setSelectedVenueId] = useState<string | null>(null);
    const selectRef = useRef(null);

    const selectedVenue = selectedVenueId
        ? venues.find(({ _id }) => _id === selectedVenueId)
        : null;

    return (
        <>
            <Form.Group controlId="register-venue-select">
                <Form.Label>
                    {t('RegisterForTournamentPage.VenueLabel')}
                </Form.Label>
                <Form.Control
                    as="select"
                    value={selectedVenueId || ''}
                    onChange={event =>
                        setSelectedVenueId(event.currentTarget.value)
                    }
                    ref={selectRef}
                >
                    <option value="">{''}</option>
                    {venues.map(venue => (
                        <option key={venue._id} value={venue._id}>
                            {venue.townName}
                            {venue.organization &&
                            (venues.filter(v => v.townName === venue.townName)
                                .length > 1 ||
                                venue.access === 'LIMITED')
                                ? ` (${venue.organization})`
                                : ''}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            {selectedVenue && (
                <VenueParticipationInfo
                    venueInfo={selectedVenue}
                    cardTitle={selectedVenue.townName}
                />
            )}
            <br />
            {selectedVenue &&
                ['FREE', 'REGISTRATION'].includes(selectedVenue.access) && (
                    <Button onClick={() => onSelect(selectedVenueId)}>
                        {t('Next')}
                    </Button>
                )}
            {selectedVenue && selectedVenue.access === 'LIMITED' && (
                <>
                    <Alert variant="warning">
                        <Trans i18nKey="RegisterForTournamentPage.AttentionVenueHoldsTournament" />{' '}
                        <AccessDescription
                            description={selectedVenue.accessDescription || ''}
                        />
                    </Alert>
                    <Button
                        variant="outline-primary"
                        className="mr-2"
                        onClick={() => {
                            setSelectedVenueId('');
                            selectRef.current?.focus();
                        }}
                    >
                        {t('RegisterForTournamentPage.ChooseAnotherVenue')}
                    </Button>
                    <Button onClick={() => onSelect(selectedVenueId)}>
                        {t('RegisterForTournamentPage.YesIUnderstand')}
                    </Button>
                </>
            )}
            <br />
        </>
    );
}

function AccessDescription({ description }) {
    if (/^Только для учеников /i.test(description)) {
        return (
            <>
                только для учеников{' '}
                <strong>
                    {description.replace(/^Только для учеников /i, '')}
                </strong>
            </>
        );
    }

    if (/^Only for students of /i.test(description)) {
        return (
            <>
                only for students of{' '}
                <strong>
                    {description.replace(/^Only for students of /i, '')}
                </strong>
            </>
        );
    }

    return <>{lowerFisrt(description)}</>;
}

function lowerFisrt(str: string) {
    str = str || '';
    return str.charAt(0).toLowerCase() + str.substring(1);
}

const registerForTournamentSeasonMutation = gql`
    mutation RegisterForTournamentSeason(
        $season: Season!
        $tournamentId: ID!
        $venueId: ID
        $participantRegistrationData: ParticipantRegistrationDataInput!
        $levels: [Level!]!
    ) {
        registerForTournamentSeason(
            season: $season
            tournamentId: $tournamentId
            venueId: $venueId
            levels: $levels
            participantRegistrationData: $participantRegistrationData
        ) {
            __typename
            ... on RegisterForTournamentSeasonSuccess {
                participant {
                    _id
                }
            }
        }
    }
`;

interface RegisterForTournamentFormProps {
    season: 'FALL' | 'SPRING';
    tournament: any;
    participantInfo: any;
    venue: any;
    onSuccess: (participant: object) => void;
}

function RegisterForTournamentForm({
    season,
    tournament,
    venue,
    participantInfo,
    onSuccess,
}: RegisterForTournamentFormProps) {
    const { t } = useTranslation();
    const { viewer } = useViewer();
    const [registerForTournamentSeason, { data, error, loading }] = useMutation(
        registerForTournamentSeasonMutation
    );
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm();

    let errorMessage = null;

    if (
        error ||
        !['RegisterForTournamentSeasonSuccess', undefined].includes(
            data?.registerForTournamentSeason.__typename
        )
    ) {
        errorMessage = t('UnknownErrorMessage');
    }

    if (data?.registerForTournamentSeason.__typename === 'NoSpotsLeftError') {
        errorMessage = t('RegisterForTournamentPage.NoSpotsLeft');
    }

    let defaultGrade = '';

    if (participantInfo?.grade) {
        defaultGrade = (
            season === 'SPRING' && venue?.gradeChangesAtWinter
                ? participantInfo.grade + 1
                : participantInfo.grade
        ).toString();
    }

    return (
        <Form
            noValidate={true}
            onSubmit={handleSubmit(formData => {
                const { grade, levels, ...restData } = formData;

                console.log(restData);

                registerForTournamentSeason({
                    variables: {
                        season,
                        tournamentId: tournament._id,
                        venueId: venue._id,
                        levels,
                        participantRegistrationData: {
                            ...restData,
                            grade: Number(grade),
                        },
                    },
                }).then(({ data }) => {
                    if (
                        data?.registerForTournamentSeason.__typename ===
                        'RegisterForTournamentSeasonSuccess'
                    ) {
                        onSuccess(data.registerForTournamentSeason.participant);
                    }
                });
            })}
        >
            <VenueParticipationInfo
                venueInfo={venue}
                cardTitle={venue.townName}
            />
            <br />
            <Form.Group controlId="register-email">
                <Form.Label>
                    {t('RegisterForTournamentPage.EmailLabel')}
                </Form.Label>
                <Form.Control
                    {...register('email', {
                        required: true,
                        pattern: /^.+@.+$/,
                    })}
                    type="email"
                    defaultValue={viewer?.email}
                    disabled={true}
                />
            </Form.Group>
            <Form.Group controlId="register-name">
                <Form.Label>
                    {t('RegisterForTournamentPage.NameLabel')}
                </Form.Label>
                <Form.Control
                    {...register('name', {
                        required: true,
                    })}
                    type="text"
                    defaultValue={participantInfo?.name || ''}
                />
                {formErrors.name && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('RegisterForTournamentPage.NameRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="register-surname">
                <Form.Label>
                    {t('RegisterForTournamentPage.SurnameLabel')}
                </Form.Label>
                <Form.Control
                    {...register('surname', {
                        required: true,
                    })}
                    type="text"
                    defaultValue={participantInfo?.surname || ''}
                />
                {formErrors.surname && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('RegisterForTournamentPage.SurnameRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="register-phone">
                <Form.Label>
                    {t('RegisterForTournamentPage.PhoneLabel')}
                </Form.Label>
                <Form.Control
                    {...register('phone', {
                        required: true,
                        pattern: /^[0-9\- ,+#()]+$/,
                    })}
                    type="tel"
                    defaultValue={participantInfo?.phone || ''}
                />
                {formErrors.phone && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {formErrors.phone?.type === 'required' &&
                            t('RegisterForTournamentPage.PhoneRequired')}
                        {formErrors.phone?.type === 'pattern' &&
                            t('RegisterForTournamentPage.PhonePatternFailed')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="register-grade">
                <Form.Label>
                    {t('RegisterForTournamentPage.GradeLabel')}
                </Form.Label>
                <Form.Control
                    {...register('grade', {
                        required: true,
                        pattern: /^(?:[1-9]|1[0-3])$/,
                    })}
                    onKeyDown={event => {
                        if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    type="number"
                    defaultValue={defaultGrade}
                />
                {formErrors.grade && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {formErrors.grade?.type === 'required' &&
                            t('RegisterForTournamentPage.GradeRequired')}
                        {formErrors.grade?.type === 'pattern' &&
                            t('RegisterForTournamentPage.GradePatternFailed')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="register-town">
                <Form.Label>
                    {t('RegisterForTournamentPage.TownNameLabel')}
                </Form.Label>
                <Form.Control
                    {...register('townName', {
                        required: true,
                    })}
                    type="text"
                    defaultValue={participantInfo?.townName || ''}
                />
                {formErrors.townName && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('RegisterForTournamentPage.TownNameRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="register-school">
                <Form.Label>
                    {t('RegisterForTournamentPage.SchoolLabel')}
                </Form.Label>
                <Form.Control
                    {...register('school', {
                        required: true,
                    })}
                    type="text"
                    defaultValue={participantInfo?.school || ''}
                />
                {formErrors.school && (
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {t('RegisterForTournamentPage.SchoolRequired')}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            {t('RegisterForTournamentPage.WhatLevelsDoYouWantToParticipateIn')}{' '}
            {venue?.levels?.length > 1 &&
                t('RegisterForTournamentPage.youCanChooseOneOrBoth')}
            <br />
            <Form.Check
                {...register('levels', {
                    required: true,
                })}
                value="ORDINARY"
                inline
                label={
                    t('RegisterForTournamentPage.LevelDate', {
                        level: 'ORDINARY',
                        date: new Date(
                            tournament.seasons[
                                season.toLowerCase()
                            ].ordinary.date
                        ),
                    }) +
                    (!venue?.levelsWithAvailableSpots?.includes('ORDINARY')
                        ? venue?.levels?.includes('ORDINARY')
                            ? t(
                                  'RegisterForTournamentPage.noSpotsForLevelLeftAtVenue'
                              )
                            : t(
                                  'RegisterForTournamentPage.levelNotAvailableAtVenue'
                              )
                        : '')
                }
                type="checkbox"
                id="register-ordinary"
                defaultChecked={
                    (venue?.levelsWithAvailableSpots?.length === 1 &&
                        venue?.levelsWithAvailableSpots?.includes(
                            'ORDINARY'
                        )) ||
                    false
                }
                disabled={
                    !venue?.levelsWithAvailableSpots?.includes('ORDINARY')
                }
            />
            <Form.Check
                {...register('levels', {
                    required: true,
                })}
                value="ADVANCED"
                inline
                label={
                    t('RegisterForTournamentPage.LevelDate', {
                        level: 'ADVANCED',
                        date: new Date(
                            tournament.seasons[
                                season.toLowerCase()
                            ].advanced.date
                        ),
                    }) +
                    (!venue?.levelsWithAvailableSpots?.includes('ADVANCED')
                        ? venue?.levels?.includes('ADVANCED')
                            ? t(
                                'RegisterForTournamentPage.noSpotsForLevelLeftAtVenue'
                            )
                            : t(
                                'RegisterForTournamentPage.levelNotAvailableAtVenue'
                            )
                        : '')
                }
                type="checkbox"
                id="register-advanced"
                defaultChecked={
                    (venue?.levelsWithAvailableSpots?.length === 1 &&
                        venue?.levelsWithAvailableSpots?.includes(
                            'ADVANCED'
                        )) ||
                    false
                }
                disabled={
                    !venue?.levelsWithAvailableSpots?.includes('ADVANCED')
                }
            />
            <br />
            {formErrors.levels && (
                <Form.Control.Feedback className="d-block" type="invalid">
                    {t('RegisterForTournamentPage.LevelRequired')}
                </Form.Control.Feedback>
            )}
            <br />
            <SpinnerButton
                variant="primary"
                type="submit"
                loading={loading}
                className="mt-2"
            >
                {t('RegisterForTournamentPage.RegisterButtonLabel')}
            </SpinnerButton>
            {errorMessage && (
                <Alert variant="warning" className="mt-4">
                    {errorMessage}
                </Alert>
            )}
        </Form>
    );
}

function RegisterForTournamentSuccess() {
    const { t } = useTranslation();

    return (
        <>
            <Alert variant="success">
                {t('RegisterForTournamentPage.RegistrationSuccess')}
            </Alert>
        </>
    );
}
