import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { provideLayoutAndData } from './Page';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import FileDropzone from './FileDropzone';
import { useDrag, useDrop } from 'react-dnd';
import classnames from 'classnames';
import { PageGalleryModal } from './Paper/PageGallery';
import ParticipantDataForm from './ParticipantDataForm';
import { PencilFill } from 'react-bootstrap-icons';
import { SubmitButton } from 'Components/EnhancedButtons';
import { useForm } from 'react-hook-form';
import { createSortFn, participantsSortFn } from '@turgor/model-utils/lib/sort';
import { VenueTopNavigation } from './TopNavigation';
import {
    VenueUploadPageQuery,
    VenueUploadPageQueryVariables,
} from './GraphQL/types/VenueUploadPageQuery';

const venueUploadPageQuery = gql`
    query VenueUploadPageQuery($venueId: ID!) {
        venue(id: $venueId) {
            _id
            townName
            townNameEn
            gradeNormalizationShift
            gradeChangesAtWinter
            paperFiles {
                _id
                season
                level
                tier
                file
                fileName
                pages
                attached
            }
            participants {
                _id
                surname
                name
                school
                grade
                townName
                email
                papers {
                    _id
                    type
                    stage
                    card
                    season
                    level
                    tier
                    fileName
                    pages {
                        _id
                        file
                        fileName
                    }
                }
            }
            tournament {
                _id
                number
            }
        }
    }
`;

const uploadPaperFileMutation = gql`
    mutation UploadPaperFile(
        $venueId: ID!
        $fileName: String!
        $fileData: String!
        $season: Season!
    ) {
        uploadPaperFile(
            venueId: $venueId
            fileName: $fileName
            fileData: $fileData
            season: $season
        ) {
            __typename
        }
    }
`;

const attachPaperFileMutation = gql`
    mutation AttachPaperFile(
        $participantId: ID!
        $paperFileId: ID!
        $level: Level
    ) {
        attachPaperFile(
            participantId: $participantId
            paperFileId: $paperFileId
            level: $level
        ) {
            __typename
        }
    }
`;

export default provideLayoutAndData<
    VenueUploadPageQuery,
    VenueUploadPageQueryVariables
>(venueUploadPageQuery, {
    variablesGetter: ({ venueId }) => ({ venueId }),
    NavComponent: ({
        params: { venueId },
        data: {
            venue: {
                townName,
                townNameEn,
                tournament: { number: tournamentNumber },
            },
        },
    }) => (
        <VenueTopNavigation
            venueId={venueId}
            townName={townName}
            townNameEn={townNameEn}
            tournamentNumber={tournamentNumber}
        />
    ),
})(function VenueUploadPage({ data, refetch }) {
    const { season } = useParams<{
        season: string;
    }>();

    const [uploadPaperFile] = useMutation(uploadPaperFileMutation);
    const [attachPaperFile] = useMutation(attachPaperFileMutation);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [selectedPaperFile, setSelectedPaperFile] = useState(null);
    const [selectedParticipantId, setSelectedParticipantId] = useState(null);

    const handlePaperFileDrop = useCallback(
        async (paperFileId, participantId, level, tier) => {
            const { data } = await attachPaperFile({
                variables: {
                    participantId,
                    paperFileId,
                    level,
                },
            });

            if (data?.attachPaperFile.__typename === 'AttachPaperFileSuccess') {
                refetch();
            }
        },
        [attachPaperFile, refetch]
    );

    const {
        venue: {
            paperFiles: unsortedPaperFiles,
            participants: unsortedParticipants,
            tournament,
            ...venue
        },
    } = data;

    const paperFiles = unsortedPaperFiles
        .filter(file => file.season === season.toUpperCase() && !file.attached)
        .sort(createSortFn('fileName'));

    const participants = [...unsortedParticipants].sort(participantsSortFn);

    return (
        <>
            <h2>
                Загрузка работ — {season === 'fall' ? 'осень' : 'весна'},{' '}
                {venue.townName}
            </h2>
            <br />
            <FileDropzone
                dropzoneSettings={{
                    accept: [
                        'application/pdf',
                        'application/zip',
                        'multipart/x-zip',
                        'application/octet-stream',
                        'application/zip-compressed',
                        'application/x-zip-compressed',
                    ],
                    maxSize: 20000000,
                }}
                upload={async (fileData, fileName) => {
                    const uploadResult = await uploadPaperFile({
                        variables: {
                            fileData,
                            fileName,
                            venueId: venue._id,
                            season: season.toUpperCase(),
                        },
                    });

                    if (
                        uploadResult?.data?.uploadPaperFile.__typename ===
                        'UploadPaperFileSuccess'
                    ) {
                        refetch();
                    }

                    return uploadResult;
                }}
            />
            <Row>
                {paperFiles.map(file => (
                    <PaperFile
                        paperFile={file}
                        key={file._id}
                        onSelect={() => setSelectedPaperFile(file)}
                    />
                ))}
            </Row>
            <br />
            <AddParticipantForm
                onSuccess={refetch}
                venueId={venue._id}
                season={season.toUpperCase()}
            />
            <br />
            <Table striped={true} hover={true} borderless={true}>
                <thead>
                    <tr>
                        <th />
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Школа</th>
                        <th>Класс</th>
                        <th>Базовый</th>
                        <th>Сложный</th>
                    </tr>
                </thead>
                <tbody>
                    {participants.map(participant => (
                        <ParticipantRow
                            participant={participant}
                            season={season.toUpperCase()}
                            venue={venue}
                            onSelectPaper={paper => setSelectedPaper(paper)}
                            onSelectParticipantId={participantId =>
                                setSelectedParticipantId(participantId)
                            }
                            onPaperFileDrop={handlePaperFileDrop}
                            key={participant._id}
                        />
                    ))}
                </tbody>
            </Table>
            {selectedPaper && (
                <PageGalleryModal
                    pages={selectedPaper.pages}
                    onClose={() => {
                        setSelectedPaper(null);
                    }}
                />
            )}
            {selectedPaperFile && (
                <PageGalleryModal
                    pages={selectedPaperFile.pages.map((page, index) => ({
                        _id: `page_${index}`,
                        file: page,
                    }))}
                    onClose={() => {
                        setSelectedPaperFile(null);
                    }}
                />
            )}
            {selectedParticipantId && (
                <Modal
                    show={true}
                    size="lg"
                    onHide={() => {
                        setSelectedParticipantId(null);
                    }}
                    centered={true}
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            Редактировать данные участника
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ParticipantDataForm
                            participant={participants.find(
                                p => p._id === selectedParticipantId
                            )}
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
});

const addParticipantMutation = gql`
    mutation AddParticipant(
        $season: Season!
        $venueId: ID!
        $participantData: ParticipantDataInput!
    ) {
        addParticipant(
            season: $season
            venueId: $venueId
            participantData: $participantData
        ) {
            __typename
        }
    }
`;

function AddParticipantForm({ venueId, season, onSuccess }) {
    const [addParticipant, { data, loading }] = useMutation(
        addParticipantMutation
    );
    const { register, handleSubmit, reset } = useForm();

    return (
        <Form
            inline={true}
            onSubmit={handleSubmit(formData => {
                addParticipant({
                    variables: {
                        venueId,
                        season,
                        participantData: {
                            ...formData,
                            grade: Number(formData.grade),
                        },
                    },
                }).then(({ data }) => {
                    if (
                        data?.addParticipant.__typename ===
                        'AddParticipantSuccess'
                    ) {
                        reset();
                        onSuccess();
                    }
                });
            })}
        >
            <Form.Label htmlFor="add-participant-surname" srOnly>
                Фамилия
            </Form.Label>
            <Form.Control
                className="mb-2 mr-sm-2"
                id="add-participant-surname"
                placeholder="Фамилия"
                defaultValue=""
                {...register('surname', { required: true })}
            />
            <Form.Label htmlFor="add-participant-name" srOnly>
                Имя
            </Form.Label>
            <Form.Control
                className="mb-2 mr-sm-2"
                id="add-participant-name"
                placeholder="Имя"
                defaultValue=""
                {...register('name', { required: true })}
            />
            <Form.Label htmlFor="add-participant-school" srOnly>
                Школа
            </Form.Label>
            <Form.Control
                className="mb-2 mr-sm-2"
                id="add-participant-school"
                placeholder="Школа"
                defaultValue=""
                {...register('school', { required: true })}
            />
            <Form.Label htmlFor="add-participant-grade" srOnly>
                Класс
            </Form.Label>
            <Form.Control
                className="mb-2 mr-sm-2 venue-upload-add-participant-grade"
                id="add-participant-grade"
                placeholder="Класс"
                defaultValue=""
                {...register('grade', {
                    required: true,
                    pattern: /^(?:[1-9]|1[0-3])$/,
                })}
                type="number"
            />
            <SubmitButton
                loading={loading}
                mutationData={data}
                className="mb-2"
            >
                Добавить
            </SubmitButton>
        </Form>
    );
}

function PaperFile({ paperFile, onSelect }) {
    const [{ isDragging }, drag] = useDrag({
        type: 'PaperFile',
        item: { _id: paperFile._id },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <Col
            md={4}
            ref={drag}
            className={classnames('uploaded-paper-file', {
                'uploaded-paper-file-dragging': isDragging,
            })}
        >
            <span className="pseudo-link" onClick={onSelect}>
                {paperFile.fileName}
            </span>
        </Col>
    );
}

function ParticipantRow({
    participant,
    season,
    venue,
    onSelectPaper,
    onSelectParticipantId,
    onPaperFileDrop,
}) {
    let ordinaryPaper = participant.papers?.find(
        p =>
            p.level === 'ORDINARY' &&
            p.season === season &&
            ['UPLOADED', 'MARKED', 'PUBLISHED'].includes(p.stage)
    );
    let advancedPaper = participant.papers?.find(
        p =>
            p.level === 'ADVANCED' &&
            p.season === season &&
            ['UPLOADED', 'MARKED', 'PUBLISHED'].includes(p.stage)
    );

    return (
        <tr>
            <td>
                <span
                    className="pseudo-link venue-upload-participant-edit-button"
                    onClick={() => onSelectParticipantId(participant._id)}
                >
                    <PencilFill size={16} />
                </span>
            </td>
            <td>{participant.surname}</td>
            <td>{participant.name}</td>
            <td>{participant.school}</td>
            <td>{participant.grade}</td>
            <td>
                {ordinaryPaper && (
                    <span
                        className="pseudo-link"
                        onClick={() => onSelectPaper(ordinaryPaper)}
                    >
                        {ordinaryPaper.type === 'ONLINE' && 'онлайн'}
                        {ordinaryPaper.type === 'OFFLINE' &&
                            ordinaryPaper.fileName}
                    </span>
                )}
                {!ordinaryPaper && (
                    <PaperDropTarget
                        onDrop={({ _id }) =>
                            onPaperFileDrop(_id, participant._id, 'ORDINARY')
                        }
                    />
                )}
            </td>
            <td>
                {advancedPaper && (
                    <span
                        className="pseudo-link"
                        onClick={() => onSelectPaper(advancedPaper)}
                    >
                        {advancedPaper.type === 'ONLINE' && 'онлайн'}
                        {advancedPaper.type === 'OFFLINE' &&
                            advancedPaper.fileName}
                    </span>
                )}
                {!advancedPaper && (
                    <PaperDropTarget
                        onDrop={({ _id }) =>
                            onPaperFileDrop(_id, participant._id, 'ADVANCED')
                        }
                    />
                )}
            </td>
        </tr>
    );
}

function PaperDropTarget({ onDrop }) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'PaperFile',
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div
            ref={drop}
            className={classnames('venue-upload-paper-drop-target', {
                'venue-upload-paper-drop-target-can-drop': isOver && canDrop,
            })}
        >
            {isOver && canDrop ? 'Прикрепить файл' : null}
        </div>
    );
}
